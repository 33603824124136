/// <reference types="@uiw/react-amap-types" />
import { NORMALSTYLE } from '@/constants';
import useMainStore from '@/store/main';
import { useCity } from '@/store/reactShareSelectedStore';
import { JLLMap } from 'jlld';
import PropertyMarks from './PropertyMarks';
import styles from './style.module.less';
import DistrictAndMarketPolygon from './DistrictAndMarketPolygon';
import MapModeSwitch from './MapModeSwitch';
import HeatMap from './HeatMap';
import AreaMarks from './AreaMarks';
import MapLegend from './MapLegend';
import useIndustrialStore from '../../store';
import { memo, useEffect } from 'react';
import Controler from 'jlld/es/JLLMap/Controler';
import useZoomChange from 'jlld/es/JLLMap/useZoomChange';
import { useShallow } from 'zustand/react/shallow';
import { useMapContext } from '@uiw/react-amap-map';
import useAreaPolygonList from './useAreaPolygonList';
import { autoFitAllPoints } from 'jlld/es/JLLMap/untils';
import useCommonOptionsStore from '@/store/commonOptions';
import classNames from 'classnames';
import mainStyles from '../../style.module.less';

const MapContent = () => {
  const { map } = useMapContext();
  const city = useCity();
  const { mapMode, toggleMapMode, setSubMarketScale, queryPageData, formData, boundariesType } =
    useIndustrialStore(
      useShallow((state) => ({
        mapMode: state.mapMode,
        formData: state.formData,
        queryPageData: state.queryPageData,
        center: state.center,
        toggleMapMode: state.toggleMapMode,
        boundariesType: state.boundariesType,
        setSubMarketScale: state.setSubMarketScale,
        summary: state.summary,
      }))
    );
  useEffect(() => {
    // 切换城市、行政区、子市场时需要重新设定地图zooms和地图状态
    map?.setZooms([8, 13]);
    toggleMapMode('districtAndMarketMode');
  }, [city, boundariesType]);

  useEffect(() => {
    if (mapMode === 'districtAndMarketMode') {
      setSubMarketScale(false);
      // 初始化网络请求在topForm里
    }
  }, [mapMode]);

  const listener = (zoom: number) => {
    let mode = mapMode;
    console.log('mode', mode);
    if (mode === 'districtAndMarketMode') {
      // 从撒点状态缩放到行政区子市场状态需要重新设定地图zooms
      map?.setZooms([8, 13]);
    } else if (mode === 'heatMapMode') {
      map?.setZooms([8, 20]);
    }
    if (zoom > 13 && zoom <= 20 && mapMode === 'districtAndMarketMode') {
      mode = 'projectMarksMode';
    } else if (zoom <= 13 && mapMode === 'projectMarksMode') {
      // 因为下转获取了部分数据，出来重新说去，时间上可以缓存下转数据的，但是开发难度较大，暂时不做
      queryPageData(formData);
      mode = 'districtAndMarketMode';
    } else if (zoom > 13 && zoom <= 16 && mapMode === 'heatMapMode') {
      mode = 'areaMarksMode';
    } else if (zoom <= 16 && mapMode === 'heatProjectMarksMode') {
      mode = 'areaMarksMode';
    } else if (zoom > 16 && mapMode === 'areaMarksMode') {
      // mode = 'heatProjectMarksMode';
    } else if (zoom <= 13 && mapMode === 'areaMarksMode') {
      mode = 'heatMapMode';
    }
    // console.log(zoom, mode);

    if (mode !== mapMode) {
      toggleMapMode(mode);
    }
  };
  useZoomChange(listener, [mapMode]);

  const showPolygn = ['districtAndMarketMode', 'heatMapMode', 'areaMarksMode'].some(
    (mode) => mode === mapMode
  );

  useEffect(() => {
    if (mapMode === 'districtAndMarketMode') {
      map?.setZooms([8, 13]);
    } else if (mapMode === 'heatMapMode') {
      map?.setZooms([8, 20]);
    }
  }, []);
  const areaPolygonList = useAreaPolygonList();
  useEffect(() => {
    if (map && areaPolygonList && showPolygn) {
      setTimeout(() => {
        autoFitAllPoints(
          areaPolygonList.map((item) => item.center).filter((item) => item),
          map,
          { avoid: [100, 100, 100, 100] }
        );
      }, 100);
    }
  }, [map, areaPolygonList, showPolygn]);
  return (
    <>
      {showPolygn && (
        <DistrictAndMarketPolygon
          pathList={areaPolygonList}
          showType={
            mapMode === 'heatMapMode' || mapMode === 'areaMarksMode' ? 'borderOnly' : undefined
          }
        />
      )}
      <PropertyMarks />
      <HeatMap />
      <AreaMarks />
      <MapModeSwitch />
      <Controler />
      {mapMode === 'districtAndMarketMode' ? <MapLegend /> : null}
    </>
  );
};

export default memo(() => {
  const authCityData = useMainStore((state) => state.authCityData);
  const { hasData } = useCommonOptionsStore((state) => ({
    hasData: state.hasData,
  }));
  const { center: mapCenter, summary } = useIndustrialStore(
    useShallow((state) => ({
      center: state.center,
      summary: state.summary,
    }))
  );
  const city = useCity();
  // 切换城市地图会自动切换center
  const { center } = authCityData[city];
  const renderNoDataTip = () => {
    if (hasData && summary?.enterpriseCount === 0 && summary?.propertyCount === 0) {
      return (
        <div className={classNames(mainStyles.NoDataTip)}>
          当前条件下无符合条件的产业数据，请更换条件后查看，如有更多疑问发送邮件到ruijian.data@jll.com
        </div>
      );
    } else if (summary === null || !hasData) {
      return (
        <div className={classNames(mainStyles.NoDataTip)}>
          当前城市暂无数据，如想了解该城市的产业布局结构，欢迎发送邮件至ruijian.data@jll.com
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles.mapView}>
      {renderNoDataTip()}
      <JLLMap
        animateEnable={false}
        zoom={13}
        zooms={[8, 20]}
        mapStyle={NORMALSTYLE}
        center={mapCenter?.length ? mapCenter : [...center]}
      >
        <MapContent />
      </JLLMap>
    </div>
  );
});
