import { useEffect } from 'react';

import TopForm from './components/TopForm';
import ClinchTable from './components/ClinchTable';
import SurrenderTable from './components/SurrenderTable';
import TransactionChart from './components/TransactionChart';
import styles from './style.module.less';
import useTransactionAnalysisStore from './store';
import useMainFrameContext from '@/hooks/useMainFrameContext';

const TransactionAnalysis = () => {
  const {
    getClinchStatistics,
    getSurrenderDetail,
    getClinchChartData,
    transactionType,
    dataShowType,
    periodStartTime,
    periodEndTime,
    initPage,
    resetStoreData,
  } = useTransactionAnalysisStore((state: any) => ({
    initPage: state.initPage,
    getClinchStatistics: state.getClinchStatistics,
    resetStoreData: state.resetStoreData,
    getSurrenderDetail: state.getSurrenderDetail,
    getClinchChartData: state.getClinchChartData,
    transactionType: state.transactionType,
    dataShowType: state.dataShowType,
    periodStartTime: state.periodStartTime,
    periodEndTime: state.periodEndTime,
  }));

  const { data: mainFrameData } = useMainFrameContext();
  const projectId = mainFrameData.params.id;

  useEffect(() => {
    const data = {
      projectId,
      current: 1,
      size: 15,
    };
    initPage(data);
  }, []);

  useEffect(() => {
    const data = {
      current: 1,
      projectId,
      size: 15,
    };
    if (dataShowType === 'table' && periodStartTime && periodEndTime) {
      if (transactionType === '1') {
        getClinchStatistics(data);
      } else if (transactionType === '2') {
        getSurrenderDetail(data);
      }
    }
  }, [transactionType, periodStartTime, periodEndTime]);

  useEffect(() => {
    if (dataShowType === 'chart' && periodStartTime && periodEndTime) {
      getClinchChartData({
        projectId,
        queryType: transactionType,
        timeInterval: `${periodStartTime}至${periodEndTime}`,
      });
    }
    return () => {
      resetStoreData();
    };
  }, [dataShowType, periodStartTime, periodEndTime]);

  return (
    <div className={styles.transactionAnalysisView}>
      <div className={styles.content}>
        <TopForm />
        {dataShowType === 'table' ? (
          transactionType === '1' ? (
            <ClinchTable projectId={projectId} />
          ) : (
            <SurrenderTable projectId={projectId} />
          )
        ) : (
          <TransactionChart />
        )}
      </div>
    </div>
  );
};

export default TransactionAnalysis;
