import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { ReportList, FormData, Report } from '../types';
import { getLatestQuarter, getReportList } from '../api';

interface State {
  reportList: Report[];
  currentPage: number;
  pageSize: number;
  total: number;
  latestQuarter: string;
  sortType: number; //1-升序排列；2-降序排列 默认2
  formData: FormData;
}
const initState: State = {
  reportList: [],
  currentPage: 1,
  pageSize: 15,
  total: 0,
  latestQuarter: '',
  sortType: 2,
  formData: {} as FormData,
};
const useMarketReportStore = create(
  immer(
    combine(initState, (set, get) => ({
      initQueryTime: async (city: string) => {
        const latestQuarter =
          (await getLatestQuarter({ cityName: city })) || `${new Date().getFullYear()}`;
        set({
          latestQuarter,
        });

        return latestQuarter;
      },
      queryReportList: async (params: FormData, pageNo?: number) => {
        const { queryTime, keyWord } = params;
        set((state) => ({
          formData: params,
          currentPage: pageNo || state.currentPage,
        }));
        const result: ReportList = await getReportList({
          ...params,
          keyWord: keyWord?.trim(),
          isYear: queryTime ? !Number.isNaN(+queryTime) : false,
          currentPage: get().currentPage,
          articlesPage: get().pageSize,
          sortType: get().sortType,
        });

        if (result) {
          const { total = 0, records = [] } = result;
          set({
            total,
            reportList: records,
          });
        }
      },
      setTableChange: (current: number, pageSize?: number, sort?: number) => {
        set((state) => ({
          currentPage: current,
          pageSize: pageSize || state.pageSize,
          sortType: sort || state.sortType,
        }));
      },
    }))
  )
);

export default useMarketReportStore;
