import { Form, JLLSelect } from 'jlld';
import { useRef } from 'react';
import {
  ShowDataTabType,
  SummaryShowType,
  allTabOptions,
  tabOptions,
  dealAnalysisChartTypeList,
  dealAnalysisIndicators,
  dealAnalysisTransferList,
  indicatorProportionOptions,
} from '../../config';
import styles from '../../style.module.less';
import { CommonTableWrap } from '../../components';
import { TopForm, Table, Chart, Detail } from './components';
import useDealAnalysisStore, { queryCurrentTabData, queryDealAnalysisDetail } from './store';
import useDealAnalysisSettingStore, { SettingState, setSettingData } from './store/settingStore';
import exportDataAnalysisTable, { exportDataAnalysisDetailTable } from '../../exportTable';
import useUserAuth from '@/hooks/useUserAuth';
import { ChartRef } from '../D-FutureSupply/components/Chart';
import { columns } from './components/Detail';

const DealAnalysis = () => {
  const chartRef = useRef<ChartRef>(null);
  const settingStore = useDealAnalysisSettingStore((state) => state);
  const loading = useDealAnalysisStore((state) => state.loading);
  const tabItems = {
    [ShowDataTabType.table]: <Table />,
    [ShowDataTabType.chart]: <Chart ref={chartRef} />,
    [ShowDataTabType.detail]: <Detail />,
  };

  const hasDetailAuth = useUserAuth('front:deal:details:query');

  const onChange =
    (key: keyof SettingState, query = true) =>
    (value: SettingState[keyof SettingState]) => {
      setSettingData({ [key]: value });

      if (query) {
        queryCurrentTabData();
      }
    };

  const onDownload = async () => {
    switch (settingStore.tableActiveTab) {
      case ShowDataTabType.table: {
        exportDataAnalysisTable(
          '流动分析',
          useDealAnalysisStore.getState().tableData,
          settingStore.dimensionValues,
          settingStore.tableFormValues.dataIndicator,
          settingStore.tableFormValues.unitType,
          settingStore.tableFormValues.summaryShowType
        );
        break;
      }
      case ShowDataTabType.chart: {
        chartRef?.current!.downloadChart();
        break;
      }
      case ShowDataTabType.detail: {
        const detailData = useDealAnalysisStore.getState().detailData;
        const detailTotal = useDealAnalysisStore.getState().detailTotal;
        // 因为明细有分页，下载前需要做一次全量查询
        if (detailData && detailTotal <= 10) {
          exportDataAnalysisDetailTable('数据分析-流动分析明细', detailData, columns);
        } else {
          await queryDealAnalysisDetail(1, detailTotal);
          const exportDetailData = useDealAnalysisStore.getState().exportDetailData;
          exportDataAnalysisDetailTable('数据分析-流动分析明细', exportDetailData, columns);
        }

        break;
      }
    }
  };

  return (
    <div className={styles.viewContainer}>
      <TopForm tabModuleName="流动分析" />
      <CommonTableWrap
        loading={loading.topForm}
        tableLoading={loading.table}
        chartLoading={loading.chart}
        detailLoading={loading.detail}
        tabModuleName="流动分析"
        tabOptions={hasDetailAuth ? allTabOptions : tabOptions}
        dataIndicatorOptions={dealAnalysisIndicators}
        dismensionList={dealAnalysisTransferList}
        dimensionValues={settingStore.dimensionValues}
        tableFormValues={settingStore.tableFormValues}
        activeTab={settingStore.tableActiveTab}
        downloadAuth="front:city:analyze:deal:export"
        detailAuth="front:deal:details:export"
        onDimensionChange={(values) => {
          if (values.length === 1) {
            onChange(
              'tableFormValues',
              false
            )({
              ...settingStore.tableFormValues,
              summaryShowType: [SummaryShowType.total],
            });
          }
          if (values.length > 1) {
            const { summaryShowType } = settingStore.tableFormValues;
            onChange(
              'tableFormValues',
              false
            )({
              ...settingStore.tableFormValues,
              summaryShowType: summaryShowType?.includes(SummaryShowType.category)
                ? summaryShowType
                : [...summaryShowType, SummaryShowType.category],
            });
          }
          onChange('dimensionValues')(values);
        }}
        onTableColumnChange={onChange('tableFormValues')}
        onTabChange={onChange('tableActiveTab', false)}
        onDownloadTable={onDownload}
        customControl={
          settingStore.tableActiveTab === ShowDataTabType.chart && (
            <Form
              layout="inline"
              initialValues={settingStore.chartSettingFormValues}
              onValuesChange={(_, chartSettingFormValues) => {
                onChange('chartSettingFormValues')(chartSettingFormValues);
              }}
            >
              <Form.Item
                label="一级分类轴"
                name="analyzeOneRowHead"
              >
                <JLLSelect
                  style={{ width: 172 }}
                  options={dealAnalysisChartTypeList}
                />
              </Form.Item>
              <Form.Item
                label="指标占比"
                name="indicatorProportion"
                style={{ marginLeft: 24 }}
              >
                <JLLSelect
                  style={{ width: 172 }}
                  options={indicatorProportionOptions}
                />
              </Form.Item>
            </Form>
          )
        }
      >
        {tabItems[settingStore.tableActiveTab]}
      </CommonTableWrap>
    </div>
  );
};

export default DealAnalysis;
