import styles from './style.module.less';

export default () => {
  return (
    <div className={styles.mapLegend}>
      <div className={styles.legendWrap}>
        <div className={styles.legend}>
          <div className={styles.hasData} />
          <div className={styles.name}>有数据</div>
        </div>
        <div className={styles.legend}>
          <div className={styles.noData} />
          <div className={styles.name}>无数据</div>
        </div>
      </div>
    </div>
  );
};
