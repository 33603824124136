/**
 * demo: sendEventTrick({button:'xxx'}, 'e_click')
 * 数据分析的页面，请明确传入page属性，如{button:'xxx',page:'数据分析-市场表现'}
 */
import dayjs from 'dayjs';
import useReactShareUserStore from '@/store/reactShareUserStore';
import { createUUid } from '@/utils/tool';
import { aplus } from '@/utils/frameMethod';
import { EventNames } from '@/types';
import { useEffect } from 'react';

interface Payload {
  port?: 'member';
  page?: string;
  button?: string;
  prevPage?: string;
  nextPage?: string;
  eventId?: string;
  ip?: string;
  time?: string;
  useName?: string;
  org?: string;
  module?: string;
}

const useUserEvent = () => {
  const { routePath, user } = useReactShareUserStore((state) => ({
    routePath: state.routePath,
    user: state.user,
  }));
  const payload: Payload = {
    port: 'member',
    page: '',
    button: '',
    prevPage: '',
    nextPage: '',
    eventId: '',
    ip: '',
    time: '',
    useName: '',
    org: '',
    module: '',
  };

  const getIp = () => {
    return new Promise((resolve) => {
      // 这里的 "jsonCallBack" ，和调用的 jsonp 的 url 中的 callback 值相对应（见粗体字）
      window.IPCallBack = (result: any) => {
        resolve(result);
      };
      const JSONP = document.createElement('script');
      JSONP.type = 'text/javascript';
      JSONP.src = 'https://whois.pconline.com.cn/ipJson.jsp';
      JSONP.referrerPolicy = 'no-referrer';
      document.getElementsByTagName('head')[0].appendChild(JSONP);
      setTimeout(() => {
        document.getElementsByTagName('head')[0].removeChild(JSONP);
      }, 500);
    });
  };
  getIp().then((res: any) => {
    payload.ip = res.ip;
  });

  const sendUserEvent = (coverPayload: Payload, eventKey: EventNames) => {
    console.log('🚀 ~ event da', coverPayload);
    const page = routePath?.[routePath.length - 1]?.pageName || ''; // 当前页
    const prevPage = routePath?.[routePath.length - 2]?.pageName || ''; // 上一页
    const useName = user.name;
    const eventId = createUUid();
    const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
    aplus.record(eventKey || 'e_click', {
      ...payload,
      page,
      prevPage,
      eventId,
      useName,
      time,
      ...coverPayload,
      org: user.departmentName,
    });
  };

  return { sendUserEvent };
};

export const pageShowEvent = (page: string) => {
  const { sendUserEvent } = useUserEvent();

  useEffect(() => {
    sendUserEvent({ page }, 'e_view');
  }, []);
};

export default useUserEvent;
