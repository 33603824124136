import ReactECharts from 'echarts-for-react';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';
import BlockTitle, { SwitchType } from '../BlockTitle';

const RentArea = (props: { changeType?: () => void; switchType?: SwitchType }) => {
  const summary = useIndustrialStore((state) => state.summary);

  const convertData = {
    category: [] as string[],
    number: [] as number[],
    // percentage: [] as number[],
  };

  summary?.leaseholdAreaDistribution?.forEach((item) => {
    const nameMatch = item.name.replace(/[\\[\\)）]/g, '').split(',');
    if (nameMatch[0] === '0') {
      convertData.category.push(`<${nameMatch[1]}`);
    } else if (nameMatch[1] === '∞') {
      convertData.category.push(`≥${nameMatch[0]}`);
    } else {
      convertData.category.push(`[${nameMatch[0]}~${nameMatch[1]})`);
    }
    convertData.number.push(
      props.switchType === 'area' ? item.areaPercentage : item.countPercentage
    );
    // convertData.percentage.push(switchType === 'area' ? item.areaPercentage : item.countPercentage);
  });

  const getOption = () => {
    const option = {
      tooltip: {
        show: true,
        borderColor: 'transparent',
        formatter(params: any) {
          const str = `<div style="width: 140px;"><p style="height: 20px; line-height: 16px; color: #01151c; margin-bottom: 5px; font-weight: bold;">
              <span>租赁面积段分布</span>
            </p>
            <p style="display: flex; align-items: center;">
            <span style="width: 70px; color: #60666E; flex: 1;">${
              props.switchType === 'area' ? '面积占比' : '个数占比'
            }</span>
            <span style="color: #01151c;">${params.value}%</span>
          </p>`;
          return `${str}</div>`;
        },
      },
      grid: {
        top: '20px',
        left: '40px',
        right: '10px',
        bottom: '60px',
      },
      xAxis: {
        type: 'category',
        nameLocation: 'end',
        axisLabel: {
          fontSize: 10,
          rotate: 50,
        },
        axisTick: {
          show: false,
        },
        data: convertData.category,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: convertData.number,
          type: 'bar',
          barWidth: 24,
          color: '#FF838B',
        },
      ],
    };
    return option;
  };

  return (
    <div>
      <BlockTitle
        title="租赁面积段分布"
        showSwitch
        switchType={props.switchType!}
        onChangeType={props.changeType!}
      />
      {convertData.category?.length > 0 ? (
        <ReactECharts
          option={getOption()}
          notMerge={true}
          lazyUpdate={true}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
          // opts={}
        />
      ) : (
        <div className="chartEmpty">
          <img src="/empty-chart.png" />
          <p>暂无数据</p>
        </div>
      )}
    </div>
  );
};

export default RentArea;
