export const fitTable = (hasData: boolean) => {
  // 解决没有滚动条时，thead宽度与tbody宽度不一致的问题
  const tbody = document.querySelector('.ant-table-tbody');
  const body = document.querySelector('.ant-table-body');
  const thead = document.querySelector('.ant-table-thead tr');
  const tableContainer = document.querySelector('.ant-table-expanded-row-fixed');
  const length = thead?.children.length;
  const offsetY = tbody!.clientHeight - body!.clientHeight;

  if (offsetY > 0) {
    body!.style.overflowY = 'auto';
    thead!.children[length - 2].style.right = '8px';
  } else {
    body!.style.overflowY = 'hidden';
    thead!.children[length - 2].style.right = 0;
  }
  if (!hasData && tableContainer) {
    const width = tableContainer?.clientWidth;
    tableContainer.style.width = `${width + 8}px`;
  }
};
