import { useState } from 'react';
import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import styles from './style.module.less';

interface ColumnHeaderWithSortProps {
  title: string;
  arrowAlign?: string;
  currentKey: string;
  sortKey: string;
  sortAsc: boolean;
  sortIconClick: any;
}

const ColumnHeaderWithSort = (props: ColumnHeaderWithSortProps) => {
  const { arrowAlign, title, sortKey, currentKey, sortAsc, sortIconClick } = props;
  const [asc, setAsc] = useState(sortAsc);

  const iconClick = () => {
    sortIconClick(currentKey, asc);
    setAsc(!asc);
  };

  return (
    <div
      className={classNames(styles.titleWithSort, arrowAlign === 'right' ? styles.arrowRight : '')}
      onClick={iconClick}
    >
      <p>{title}</p>
      <div className={styles.sortArea}>
        <div className={styles.up}>
          <JLLSvg
            icon="sort_up"
            size={7}
            color={sortKey === currentKey && sortAsc ? '#01151C' : '#B6C0C2'}
          />
        </div>
        <div className={styles.down}>
          <JLLSvg
            icon="sort_down"
            size={7}
            color={sortKey === currentKey && !sortAsc ? '#01151C' : '#B6C0C2'}
          />
        </div>
      </div>
    </div>
  );
};

export default ColumnHeaderWithSort;
