import { useLayoutEffect, useState } from 'react';

interface Props {
  containerClassName: string;
  tableClassName: string;
  excludeHeight?: number;
}

const useTableLayout = (props: Props) => {
  const [scrollY, setScrollY] = useState(400);
  const { containerClassName, tableClassName, excludeHeight = 0 } = props;

  useLayoutEffect(() => {
    setScrollY(
      document.querySelector(`.${containerClassName}`).offsetHeight -
        document.querySelector(`.${tableClassName}`)?.getBoundingClientRect().y -
        excludeHeight,
    );
  }, []);

  return { scrollY };
};

export default useTableLayout;
