export const sendFormChange = (changeValues: any, sendUserEvent: any) => {
  if (changeValues.city) {
    sendUserEvent({ button: '城市切换', module: '条件搜索' }, 'e_click');
  }
  if (changeValues.secondaryIndustries) {
    sendUserEvent({ button: '行业切换', module: '条件搜索' }, 'e_click');
  }
  if (changeValues.propertyType) {
    sendUserEvent({ button: '物业类型切换', module: '条件搜索' }, 'e_click');
  }
  if (changeValues.queryTime) {
    sendUserEvent({ button: '时间切换', module: '条件搜索' }, 'e_click');
  }
  if (changeValues.districtAndSubmarket) {
    sendUserEvent({ button: '行政区子市场切换', module: '条件搜索' }, 'e_click');
  }
  if (changeValues.leaseType) {
    sendUserEvent({ button: '租户类型切换', module: '条件搜索' }, 'e_click');
  }
  if (changeValues.enterpriseSources) {
    sendUserEvent({ button: '租户来源切换', module: '条件搜索' }, 'e_click');
  }
};
