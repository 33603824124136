import { jllRequest } from '@/utils/request';
import { QueryMarketReportFormParams, QueryLatestQuarterParams } from '../store/types';

export function getLatestQuarter(params: QueryLatestQuarterParams) {
  return jllRequest({
    url: '/datatime/getLatestQuarter',
    method: 'get',
    params,
  });
}

// 获取报告
export function getReportList(data: QueryMarketReportFormParams) {
  return jllRequest({
    url: '/report/list',
    method: 'POST',
    data,
  });
}
