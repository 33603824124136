import { useEffect, useState } from 'react';
import styles from './style.module.less';
import { Checkbox, JLLSvg } from 'jlld';
import classNames from 'classnames';

export type TransferOptionItem = {
  label: string;
  value: string;
  disabled?: boolean;
  disabledUp?: boolean;
  disabledDown?: boolean;
};

export interface JLLTransferProps {
  listTitle: string;
  valueTitle: string;
  options: TransferOptionItem[];
  maxLength?: number;
  value?: TransferOptionItem[];
  onChange?: (value: TransferOptionItem[]) => void;
}

const JLLTransfer = (props: JLLTransferProps) => {
  const [value, setValue] = useState<TransferOptionItem[]>(props.value || []);

  const optionList = props.options.map((item) => {
    // 如果设置了最大长度，且当前长度大于等于最大长度，则禁用
    if (props.maxLength && value.length >= props.maxLength) {
      return { ...item, disabled: !value.find((v) => v.value === item.value) };
    }
    return { ...item, disabled: item.disabled ?? false };
  });

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value || []);
    }
  }, [props.value]);

  const handleSort = (index: number, direction: 'up' | 'down') => {
    let newValue = [...value];
    if (direction === 'up' && index > 0) {
      const [pickUp] = newValue.splice(index, 1);
      newValue.splice(index - 1, 0, pickUp);
    } else if (direction === 'down' && index < value.length) {
      newValue = [...value];
      const [pickUp] = newValue.splice(index, 1);
      newValue.splice(index + 1, 0, pickUp);
    }
    setValue(newValue);
    props?.onChange?.(newValue);
  };

  return (
    <div className={styles.transferComponent}>
      <div className={styles.transferBlock}>
        <div className={styles.transferBlockTitle}>{props.listTitle}</div>
        <div className={styles.transferCheckboxGroup}>
          {optionList.map((item) => {
            return (
              <Checkbox
                key={item.value}
                value={item.value}
                disabled={item.disabled}
                checked={value.some((v) => v.value === item.value)}
                onChange={(event) => {
                  const checked = event.target.checked;
                  let newValues: TransferOptionItem[] = [];
                  if (checked) {
                    newValues = [...value, item];
                  } else {
                    newValues = value.filter((v) => v.value !== item.value);
                  }

                  setValue(newValues);
                  props?.onChange?.(newValues);
                }}
              >
                {item.label}
              </Checkbox>
            );
          })}
        </div>
      </div>
      <div className={styles.transferBlock}>
        <div className={styles.transferBlockTitle}>{props.valueTitle}</div>
        <div className={styles.showList}>
          {value?.map((item, index) => {
            return (
              <div
                key={item.value}
                className={styles.showItem}
              >
                <span className={styles.label}>{item.label}</span>
                <span
                  className={classNames(styles.itemSortButton, {
                    [styles.disabled]: index === 0 || item.disabled || item.disabledUp,
                  })}
                  onClick={() => handleSort(index, 'up')}
                >
                  <JLLSvg
                    icon="arrow-circle-up"
                    size={20}
                  />
                </span>
                <span
                  className={classNames(styles.itemSortButton, {
                    [styles.disabled]:
                      index === value.length - 1 || item.disabled || item.disabledDown,
                  })}
                  onClick={() => handleSort(index, 'down')}
                >
                  <JLLSvg
                    icon="arrow-circle-down"
                    size={20}
                  />
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default JLLTransfer;
