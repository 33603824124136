import classNames from 'classnames';
import { RelocateTrailDimensionType } from '@/constants/enums';
import styles from './style.module.less';

interface dimensionSwitchProps {
  currentDemension: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
  onClick: (v: RelocateTrailDimensionType) => void;
}

// TODO 和项目分析的一起提到最外面
const switchConfig = [
  {
    label: '看迁入',
    value: RelocateTrailDimensionType.moveIn,
  },
  {
    label: '看迁出',
    value: RelocateTrailDimensionType.moveOut,
  },
];

export default (props: dimensionSwitchProps) => {
  return (
    <div className={styles.dimensionSwitch}>
      {switchConfig.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames(styles.demensionBtn, {
              [styles.active]: item.value === props.currentDemension,
            })}
            onClick={() => props.onClick(item.value)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};
