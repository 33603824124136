import styles from './style.module.less';
import { JLLDatePicker, Radio, ConfigProvider } from 'jlld';
import type { RadioChangeEvent } from 'jlld';
import useTransactionAnalysisStore from '../../store';
import { compareQuarter } from '@/utils/tool';
import dayjs from 'dayjs';

export default () => {
  const {
    setTransactionType,
    setDataShowType,
    transactionType,
    dataShowType,
    setPeriodTime,
    periodStartTime,
    periodEndTime,
    rangeStartTime,
    rangeEndTime,
  } = useTransactionAnalysisStore();

  const transactionTypeOptions = [
    { label: '成交明细', value: '1' },
    { label: '退租明细', value: '2' },
  ];

  const dataShowTypeOptions = [
    { label: '表格', value: 'table' },
    { label: '图表', value: 'chart' },
  ];

  const setTime = (time: any) => {
    setPeriodTime(time);
  };

  const onTransactionTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setTransactionType(value);
    setDataShowType('table');
  };

  const onDataShowTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setDataShowType(value);
  };

  const disabledDate = (current: any) => {
    return (
      compareQuarter(dayjs(current).format('YYYY-[Q]Q'), rangeEndTime) === 1 ||
      compareQuarter(dayjs(current).format('YYYY-[Q]Q'), rangeStartTime) === -1
    );
  };

  return (
    <div className={styles.topForm}>
      <ConfigProvider
        theme={{
          components: {
            Radio: {
              buttonColor: '#606266',
              buttonSolidCheckedBg: '#01151c',
              buttonSolidCheckedHoverBg: '#01151c',
              buttonCheckedColorDisabled: 'rgb(152, 152, 152)',
              buttonSolidCheckedActiveBg: '#01151c',
              colorBorder: 'rgb(238, 239, 240)',
              colorPrimaryBorder: 'rgb(238, 239, 240)',
              colorPrimary: 'rgb(1, 21, 28)',
              colorPrimaryHover: 'rgb(238, 239, 240)',
              colorPrimaryActive: 'rgb(1, 21, 28)',
              buttonSolidCheckedColor: '#ffffff',
            },
          },
        }}
      >
        <Radio.Group
          className={styles.radioGroupItem}
          options={transactionTypeOptions}
          onChange={onTransactionTypeChange}
          value={transactionType}
          optionType="button"
          buttonStyle="solid"
        />
        <Radio.Group
          className={styles.radioGroupItem}
          options={dataShowTypeOptions}
          onChange={onDataShowTypeChange}
          value={dataShowType}
          optionType="button"
          buttonStyle="solid"
        />
        时间：
        <JLLDatePicker.JLLRangePicker
          allowClear={false}
          picker="quarter"
          separator="至"
          className={styles.transactionTimeRangePicker}
          onChange={setTime}
          value={[periodStartTime, periodEndTime]}
          disabledDate={disabledDate}
        />
      </ConfigProvider>
    </div>
  );
};
