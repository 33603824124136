interface CircleSVGProps {
  data: {
    color: string;
    percent: number;
  }[];
  radius: number;
  strokeWidth: number;
  value: string | number;
}

const CircleSVG = (props: CircleSVGProps) => {
  const renderContent = () => {
    const { radius, strokeWidth, value } = props;
    const innerRadius = radius - strokeWidth * 2;
    const padding = 1;
    const r = radius;

    let prevEndX = radius + 1;
    let prevEndY = padding;
    let prevDec = 0;

    return (
      <div
        style={{
          width: `${radius + 4}px`,
          height: `${radius + 4}px`,
          backgroundColor: '#FFF',
          borderRadius: `${radius}px`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: `${radius}px`,
            height: `${radius}px`,
            position: 'relative',
          }}
        >
          <svg
            style={{ display: 'block' }}
            width={radius}
            height={radius}
            viewBox={`0 0 ${radius * 2 + 2} ${radius * 2 + 2}`}
          >
            {props.data.map((subItem, index) => {
              if (subItem.percent) {
                if (subItem.percent === 1) {
                  // a命令无法绘制整圆 https://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path
                  return (
                    <circle
                      key={index}
                      cx="51"
                      cy="51"
                      r={r}
                      fill={subItem.color}
                    />
                  );
                }
                const currDec = Math.PI * 2 * subItem.percent;
                const startX = prevEndX;
                const startY = prevEndY;
                const largeArcFlag = currDec > Math.PI ? 1 : 0;
                // 需要加前一次的角度获取对应的终点坐标
                const allDec = currDec + prevDec;
                const endX = padding + r + r * Math.sin(allDec);
                const endY = padding + r - r * Math.cos(allDec);
                // 留出每一段之间的间隔
                const endXd = padding + r + r * Math.sin(allDec - 0.15);
                const endYd = padding + r - r * Math.cos(allDec - 0.15);
                prevEndX = endX;
                prevEndY = endY;
                prevDec = allDec;
                return (
                  <path
                    key={subItem.color}
                    d={`M ${radius + 1} ${radius + 1} L ${startX} ${startY} A ${r} ${r} 0 ${largeArcFlag} 1 ${endXd} ${endYd} z`}
                    fill={subItem.color}
                  />
                );
              }
              return null;
            })}
          </svg>
          <div
            style={{
              width: `${innerRadius}px`,
              height: `${innerRadius}px`,
              borderRadius: `${innerRadius}px`,
              backgroundColor: '#FFF',
              position: 'absolute',
              top: `${strokeWidth}px`,
              left: `${strokeWidth}px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                color: '#01151D',
                fontWeight: 'bold',
              }}
            >
              {value}
            </span>
          </div>
        </div>
      </div>
    );
  };
  return <div>{renderContent()}</div>;
};

export default CircleSVG;
