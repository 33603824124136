import { request } from '@/utils/frameMethod';
import {
  CommonRequestParams,
  DealAnalysisDetailParam,
} from '@/pages/F-DataAnalysis/types/apiTypes';
import { TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';
import { jllRequest } from '@/utils/request';

export interface getDealAnalysisDetailRes {
  cityName: string;
  insertYear: string;
  adminDistrict: string;
  marketName: string;
  projectCnName: string;
  projectLevel: string;
  titleFrom: string;
  developerBg: string;
  leedLevel: string;
  tenantCnName: string;
  oneIndustry: string;
  twoIndustry: string;
  floorNum: string;
  roomNum: string;
  leaseArea: string;
  leaseMoney: string;
  leaseType: string;
  tenantId: string;
  projectId: string;
  floorId: string;
}

export type getFutureSupplyDetailParams = CommonRequestParams;

/** 城市分析-成交分析明细列表，数据分析-流动分析-明细 */
export const getDealDataDetailList = async (data: DealAnalysisDetailParam) => {
  const result = await jllRequest<getDealAnalysisDetailRes[]>({
    url: '/dataAnalysis/queryDealDataDetails',
    method: 'post',
    data,
  });

  return result;
};
