import { Building } from '@/components/BuildingList/type';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PropertyType } from '../../../types';
import {
  FloorDetail,
  FloorDetailItem,
  IFSData,
  IndustryForm,
  Tab,
  TenantArea,
  TenantProportion,
  TenantRank,
  TypeForm,
} from '../types';
import business from './business';
import industry from './industry';
import { getBuildingList } from '@/pages/Z-ProjectDetail/api';

interface State {
  existTime: string[];
  queryTime: string;
  busId: string;
  queryType: number;
  buildingList: Building[];
  rankingList: TenantRank[];
  industryFormList: IndustryForm[];
  tenantArea: TenantArea[];
  tenantProportion: TenantProportion;
  typeFormList: TypeForm[];
  tenantIFSData: IFSData;
  floorDetailMap: Record<string, FloorDetail>;
}

const initState: State = {
  existTime: [],
  queryTime: '',
  busId: '',
  queryType: 1,
  buildingList: [],
  rankingList: [],
  industryFormList: [],
  tenantArea: [],
  tenantProportion: {} as TenantProportion,
  typeFormList: [],
  tenantIFSData: {},
  floorDetailMap: {},
};

export const unit: Record<Tab, string> = {
  面积: 'm²',
  个数: '个',
};

const useTenantAnalysisStore = create(
  immer(
    combine(initState, (set, get) => ({
      initExistsTime: async (projectId: string, propertyType: PropertyType) => {
        let getExistTime = null;
        if (propertyType === 'business') {
          getExistTime = business.getExistsTime;
        } else if (propertyType === 'industry') {
          getExistTime = industry.getExistsTime;
        }
        const existTime = await getExistTime?.(projectId);
        set({
          existTime,
          queryTime: existTime.length ? existTime[existTime.length - 1] : '',
        });
      },
      initBuildingList: async (projectId: string) => {
        const result = await getBuildingList(projectId);
        set({
          buildingList: result?.buildingManagementList,
        });
      },
      queryChartData: (projectId: string, propertyType: PropertyType) => {
        const getter = get();
        getter.getTenantAnalysis(projectId, propertyType);
        getter.getTenantIFS(projectId, propertyType);
      },
      getTenantAnalysis: async (projectId: string, propertyType: PropertyType) => {
        const getter = get();
        if (getter.queryTime) {
          let data;
          if (propertyType === 'business') {
            data = await business.getTenantAnalysis({
              projectId,
              dueTime: getter.queryTime,
            });
          } else if (propertyType === 'industry' && getter.busId === projectId) {
            data = await industry.getTenantAnalysis({
              busId: projectId,
              queryTime: getter.queryTime,
            });
          }
          set({
            ...data,
          });
        } else {
          set({
            rankingList: [],
            industryFormList: [],
            tenantArea: [],
            tenantProportion: {} as TenantProportion,
            typeFormList: [],
          });
        }
      },
      getTenantIFS: async (projectId: string, propertyType: PropertyType) => {
        const getter = get();
        if (getter.queryTime) {
          let data;
          if (propertyType === 'business') {
            data = await business.getTenantIFS({
              projectId,
              queryTime: getter.queryTime,
              queryType: getter.queryType,
            });
          } else if (propertyType === 'industry' && projectId !== getter.busId) {
            data = await industry.getTenantIFS({
              busId: getter.busId,
              queryTime: getter.queryTime,
              queryType: getter.queryType,
            });
          }
          set({
            tenantIFSData: data,
          });
        } else {
          set({
            tenantIFSData: {},
          });
        }
      },
      getTenantDetail: async (propertyType: PropertyType, floorBusId: string) => {
        const getter = get();
        if (getter.queryTime) {
          let data: FloorDetailItem[];
          if (propertyType === 'business') {
            data = await business.getTenantDetail({
              floorIds: [floorBusId],
              dataQuarter: getter.queryTime,
            });
          } else if (propertyType === 'industry') {
            data = await industry.getTenantDetail({ floorBusId, queryTime: getter.queryTime });
          }
          set((state) => {
            state.floorDetailMap = {
              ...state.floorDetailMap,
              [floorBusId]: {
                open: true,
                floorDetail: data,
              },
            };
          });
        }
      },
      setTime: (queryTime: string) => {
        set({
          queryTime,
          floorDetailMap: {},
        });
      },
      setBusId: (busId: string) => {
        set({ busId });
      },
      setQueryType: (type: number) => {
        set({ queryType: type });
      },
      getTenantProportion: () => {
        const { newRentRate, survivalRate, vacancyRate } = get().tenantProportion;
        return [
          {
            name: '空置率',
            value: vacancyRate,
          },
          {
            name: '存租率',
            value: survivalRate,
          },
          {
            name: '新租率',
            value: newRentRate,
          },
        ];
      },
      toggleTenantDetail: (propertyType: PropertyType, floorBusId: string) => {
        if (floorBusId) {
          if (get().floorDetailMap[floorBusId]?.floorDetail) {
            set((state) => {
              state.floorDetailMap[floorBusId].open = !state.floorDetailMap[floorBusId].open;
            });
          } else {
            get().getTenantDetail(propertyType, floorBusId);
          }
        }
      },
      openFirstTenantDetail: (propertyType: PropertyType) => {
        get().toggleTenantDetail(propertyType, get().tenantIFSData.floorList[0]?.floorBusId);
      },
      closeAllTenantDetail: () => {
        set((state) => {
          for (const key in state.floorDetailMap) {
            state.floorDetailMap[key].open = false;
          }
        });
      },
    }))
  )
);

export default useTenantAnalysisStore;
