/* eslint-disable react/jsx-no-duplicate-props */
import { Table, TableColumnsType } from 'jlld';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import useTableLayout from '@/hooks/useTableLayout';
import { getDealAnalysisDetailRes } from '../../api';
import useDealAnalysisStore, { queryDealAnalysisDetail, setLoading } from '../../store';
import './style.less';

export const columns: TableColumnsType<getDealAnalysisDetailRes> = [
  {
    title: '城市',
    dataIndex: 'cityName',
    key: 'cityName',
    width: 100,
    fixed: 'left',
  },
  {
    title: '交易时间',
    dataIndex: 'insertYear',
    key: 'insertYear',
    width: 110,
  },
  {
    title: '项目名称',
    dataIndex: 'projectCnName',
    key: 'projectCnName',
    width: 180,
  },
  { title: '子市场', dataIndex: 'marketName', key: 'marketName', width: 120 },
  { title: '行政区', dataIndex: 'adminDistrict', key: 'adminDistrict', width: 120 },
  { title: '项目等级', dataIndex: 'projectLevel', key: 'projectLevel', width: 120 },
  { title: '业权形态', dataIndex: 'titleFrom', key: 'titleFrom', width: 120 },
  { title: '开发商', dataIndex: 'developerBg', key: 'developerBg', width: 120 },
  { title: 'LEED等级', dataIndex: 'leedLevel', key: 'leedLevel', width: 120 },
  { title: '租户', dataIndex: 'tenantCnName', key: 'tenantCnName', width: 190 },
  { title: '一级行业', dataIndex: 'oneIndustry', key: 'oneIndustry', width: 150 },
  { title: '二级行业', dataIndex: 'twoIndustry', key: 'twoIndustry', width: 150 },
  { title: '楼层', dataIndex: 'floorNum', key: 'floorNum', width: 120 },
  { title: '租赁面积', dataIndex: 'leaseArea', key: 'leaseArea', width: 120 },
  { title: '租金（元/㎡/月）', dataIndex: 'leaseMoney', key: 'leaseMoney', width: 150 },
  { title: '租约类型', dataIndex: 'leaseType', key: 'leaseType', width: 130 },
];

const Detail = () => {
  const { detailCurrentPage, detailData, detailTotal, loading } = useDealAnalysisStore((state) => ({
    detailCurrentPage: state.detailCurrentPage,
    detailData: state.detailData,
    detailTotal: state.detailTotal,
    loading: state.loading,
  }));

  useOnload(queryDealAnalysisDetail, ShowDataTabType.detail, setLoading);

  const pagination = {
    current: detailCurrentPage,
    total: detailTotal,
    pageSize: 50,
    showSizeChanger: false,
    onChange: (pageNo: number) => {
      queryDealAnalysisDetail(pageNo);
    },
    showTotal: (total: number) => `共 ${total} 条`,
  };

  const { scrollY } = useTableLayout({
    containerClassName: 'el-container',
    tableClassName: 'dealDetailTable',
    excludeHeight: 130,
  });

  return (
    <Table
      rowKey={(record) =>
        `${record.projectId}-${record.tenantId}-${record.floorId}-${record.roomNum}-${record.leaseArea}-${record.insertYear}-${record.twoIndustry}`
      }
      className="dealDetailTable"
      columns={columns}
      dataSource={detailData!}
      loading={loading.detail}
      scroll={{ y: scrollY, x: 2050 }}
      pagination={pagination}
    />
  );
};

export default Detail;
