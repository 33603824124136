import CommonDataAnalysisTable from '@/pages/F-DataAnalysis/components/CommonTable';
import useMarketAnalysisStore, { queryMarketTable, setLoading } from '../../store';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import useMarketSettingStore from '../../store/settingStore';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';

const MarketTable = () => {
  useOnload(queryMarketTable, ShowDataTabType.table, setLoading);
  const tableData = useMarketAnalysisStore((state) => state.tableData);
  const dimensionValues = useMarketSettingStore((state) => state.dimensionValues);
  const tableFormValues = useMarketSettingStore((state) => state.tableFormValues);
  const loading = useMarketAnalysisStore((state) => state.loading);

  return (
    <CommonDataAnalysisTable
      loading={loading.table}
      data={tableData}
      dimensionValues={dimensionValues}
      dataIndicator={tableFormValues.dataIndicator}
      unitType={tableFormValues.unitType}
      summaryShowType={tableFormValues.summaryShowType}
    />
  );
};

export default MarketTable;
