import CommonDataAnalysisTable from '@/pages/F-DataAnalysis/components/CommonTable';
import useDealAnalysisStore, { queryDealTable, setLoading } from '../../store';
import useDealAnalysisSettingStore from '../../store/settingStore';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';

import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';

const DealTable = () => {
  useOnload(queryDealTable, ShowDataTabType.table, setLoading);
  const tableData = useDealAnalysisStore((state) => state.tableData);
  const loading = useDealAnalysisStore((state) => state.loading);
  const { dimensionValues, tableFormValues } = useDealAnalysisSettingStore((state) => ({
    dimensionValues: state.dimensionValues,
    tableFormValues: state.tableFormValues,
  }));

  return (
    <CommonDataAnalysisTable
      loading={loading.table}
      data={tableData}
      dimensionValues={dimensionValues}
      dataIndicator={tableFormValues.dataIndicator}
      unitType={tableFormValues.unitType}
      summaryShowType={tableFormValues.summaryShowType}
    />
  );
};

export default DealTable;
