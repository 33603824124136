import { Position } from 'jlld/es/JLLMap/types';
// 贝塞尔曲线点位
export const BPoint = (start: Position, control: Position, end: Position, t: number): Position => {
  const [startX, startY] = start;
  const [controlX, controlY] = control;
  const [endX, endY] = end;
  return [
    Math.pow(1 - t, 2) * startX + 2 * t * (1 - t) * controlX + Math.pow(t, 2) * endX,
    Math.pow(1 - t, 2) * startY + 2 * t * (1 - t) * controlY + Math.pow(t, 2) * endY,
  ];
};

export const containerToPostion = (map: AMap.Map, pixel: Position) => {
  const lngLat = map.containerToLngLat(new AMap.Pixel(...pixel));
  return [lngLat.lng, lngLat.lat] as Position;
};

export function calculateArrowVertices(
  start: Position,
  control: Position,
  end: Position,
  t: number,
  reverse?: boolean
): [Position, Position, Position] {
  // 计算贝塞尔曲线上的点
  const [x, y] = reverse ? BPoint(end, control, start, t) : BPoint(start, control, end, t);
  // 计算贝塞尔曲线的切线方向
  const dx = reverse
    ? 2 * (1 - t) * (control[0] - end[0]) + 2 * t * (start[0] - control[0])
    : 2 * (1 - t) * (control[0] - start[0]) + 2 * t * (end[0] - control[0]);
  const dy = reverse
    ? 2 * (1 - t) * (control[1] - end[1]) + 2 * t * (start[1] - control[1])
    : 2 * (1 - t) * (control[1] - start[1]) + 2 * t * (end[1] - control[1]);

  // 计算切线方向的角度
  const angle = Math.atan2(dy, dx);

  // 计算三角形箭头的顶点
  const length = 10 / Math.sqrt(3); // 等边三角形的边长
  const vertex1: Position = [x + length * Math.cos(angle), y + length * Math.sin(angle)];
  const vertex2: Position = [
    x + length * Math.cos(angle + (2 * Math.PI) / 3),
    y + length * Math.sin(angle + (2 * Math.PI) / 3),
  ];
  const vertex3: Position = [
    x + length * Math.cos(angle - (2 * Math.PI) / 3),
    y + length * Math.sin(angle - (2 * Math.PI) / 3),
  ];

  return [vertex1, vertex2, vertex3];
}
// 这是文心一言给的，不太对但是，可以学习一下
// export function getArrowVertices(
//   start: Position,
//   control: Position,
//   end: Position,
//   t: number
// ): Position[] {
//   // 展开Position为x和y
//   const [startX, startY] = start;
//   const [controlX, controlY] = control;
//   const [endX, endY] = end;

//   // 计算贝塞尔曲线上的点
//   const B = (1 - t) ** 2 * startX + 2 * (1 - t) * t * controlX + t ** 2 * endX;
//   const C = (1 - t) ** 2 * startY + 2 * (1 - t) * t * controlY + t ** 2 * endY;
//   const curvePoint = [B, C];

//   // 简化：使用起点和终点的连线方向作为切线方向的近似（这不是精确的切线方向）
//   const tangentX = endX - startX;
//   const tangentY = endY - startY;

//   // 标准化切线向量（注意：如果切线为零向量，则需要特殊处理）
//   const norm = Math.sqrt(tangentX ** 2 + tangentY ** 2);
//   if (norm === 0) {
//     // 如果起点和终点相同，无法定义切线方向，这里返回一个错误或默认方向
//     throw new Error('Start and end positions are the same, cannot determine tangent direction.');
//   }
//   const normalizedTangentX = tangentX / norm;
//   const normalizedTangentY = tangentY / norm;

//   // 箭头长度（等边三角形边长）
//   const arrowLength = 10;

//   // 计算三角形顶点（使用旋转矩阵来得到其他两个顶点）
//   const baseX = curvePoint[0];
//   const baseY = curvePoint[1];

//   // 旋转±60°得到另外两个顶点（这里使用sin和cos的近似值或查找表可能更高效）
//   const rotationRadians = Math.PI / 3;
//   const sin60 = Math.sin(rotationRadians);
//   const cos60 = Math.cos(rotationRadians);

//   // 注意：这里我们使用了一个简化的旋转方法，它可能不适用于所有情况
//   // 更准确的方法可能需要构建一个旋转矩阵并应用它
//   const rotatedX = normalizedTangentX * cos60 - normalizedTangentY * sin60;
//   const rotatedY = normalizedTangentX * sin60 + normalizedTangentY * cos60;

//   const rotatedNegX = normalizedTangentX * cos60 + normalizedTangentY * sin60; // 注意这里应该是-sin60，但因为我们只是想要一个不同的方向，所以+sin60也足够（只是方向相反）
//   const rotatedNegY = -normalizedTangentX * sin60 + normalizedTangentY * cos60; // 相应地调整y

//   // 但实际上，对于等边三角形，我们只需要旋转一个方向并取反即可
//   const vertex1: Position = [
//     baseX + arrowLength * normalizedTangentX,
//     baseY + arrowLength * normalizedTangentY,
//   ];
//   const vertex2: Position = [baseX + arrowLength * rotatedX, baseY + arrowLength * rotatedY];
//   const vertex3: Position = [baseX + arrowLength * -rotatedX, baseY + arrowLength * -rotatedY]; // 直接取反rotatedX和rotatedY

//   return [vertex1, vertex2, vertex3];
// }
