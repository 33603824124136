import { JLLSvg } from 'jlld';
import classNames from 'classnames';
import styles from './style.module.less';

interface EmptyProps {
  text?: string;
  useSolsticeStyle?: boolean;
  noPadding?: boolean;
}

const Empty = (props: EmptyProps) => {
  return (
    <div
      className={classNames('noData', { noPadding: props.noPadding })}
      {...props}
    >
      {props.useSolsticeStyle ? (
        <div className={styles.solstice}>
          <JLLSvg
            icon="draft"
            size={24}
            color="#637177"
          />
        </div>
      ) : (
        <img src="/empty-chart.png" />
      )}
      <p>{props.text || '暂无数据'}</p>
    </div>
  );
};

export default Empty;
