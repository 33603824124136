import { RelocateTrailDimensionType, RelocateTrailModeType } from '@/pages/Z-ProjectDetail/types';
import { getHalfYearAgoDate, getLastQuarter, getNextQuarter, getQuarter } from '@/utils/tool';

export const dimensionTypeOptions = [
  { label: '看迁入', value: RelocateTrailDimensionType.moveIn },
  { label: '看迁出', value: RelocateTrailDimensionType.moveOut },
];

export const modeTypeOptions = [
  { label: '地图', value: RelocateTrailModeType.map },
  { label: '列表', value: RelocateTrailModeType.list },
];

export const [year, quarter] = getLastQuarter()
  .split('-Q')
  .map((item) => Number(item));
export const endTimeStr = `${year}-Q${quarter}`;

export const RelocateTrailTimeTypeMap = {
  halfYear: getQuarter(getHalfYearAgoDate()),
  oneYear: getNextQuarter(`${year - 1}-Q${quarter}`),
  twoYears: getNextQuarter(`${year - 2}-Q${quarter}`),
  threeYears: getNextQuarter(`${year - 3}-Q${quarter}`),
  custom: 'custom',
};
