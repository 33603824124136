import Empty from '@/components/Empty/index.tsx';
import styles from './style.module.less';
import { TenantRank } from '../../types';
import classNames from 'classnames';

export default (props: { data: TenantRank[] }) => {
  const { data } = props;
  return data.length ? (
    <div className={styles.tenantRank}>
      {data.map((rank: TenantRank, index: number) => (
        <div
          key={rank.tenantName + index}
          className={styles.item}
        >
          <div className={classNames(styles.tenantName, styles[`rank${index + 1}`])}>
            <i>{index + 1}</i>
            <div>{rank.tenantName}</div>
          </div>
          <div className={styles.industry}>{rank.industry}</div>
          <div className={styles.tenantType}>{rank.tenantType}</div>
        </div>
      ))}
    </div>
  ) : (
    <Empty />
  );
};
