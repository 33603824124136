import useCommonOptionsStore from '@/store/commonOptions';
import { JLLForm, JLLSelect } from 'jlld';

const CompetitionGroupsFormItem = () => {
  const competitionGroups = useCommonOptionsStore((state) => state.competitionGroups);

  return (
    <JLLForm.Item
      name="competitionGroupId"
      noStyle
    >
      <JLLSelect
        allowClear
        options={competitionGroups}
        placeholder="选择竞品组"
      />
    </JLLForm.Item>
  );
};

export default CompetitionGroupsFormItem;
