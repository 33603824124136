import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
import { minAndMax } from '@/utils/chart';
import { getQuarter } from '@/utils/tool';
import { useMemo, useRef } from 'react';
import Empty from '@/components/Empty/index.tsx';
import { PriceChartData, PriceChartItem } from '../../types';
import { generateRentValue } from '@/components/UnitExchange/method';

interface Props extends EChartsReactProps {
  data: PriceChartData;
  legend: Record<any, string>;
  className?: string;
  unit?: string;
  emptyText?: string;
}

const colorMap = new Map([
  ['projId', '#E30613'],
  ['nearby', '#666666'],
  ['market', '#239EC7'],
  ['city', '#D96512'],
]);

export default (props: Props) => {
  const { data, legend, emptyText, ...rest } = props;
  const chartRef = useRef<ReactECharts>(null);

  const hasData = useMemo(() => {
    return (
      data &&
      Object.values(data).some(
        (childData) =>
          childData.filter(
            (item: PriceChartItem) =>
              item.specificField !== null && item.specificField !== undefined
          ).length
      )
    );
  }, [data]);

  const getOptions = () => {
    const series: any = [];
    const xData: string[] = [
      ...new Set(
        Object.values(data)
          .map((item: PriceChartItem[]) => {
            return item.map((e: PriceChartItem) => e.rowTitle);
          })
          .flat(1)
          .sort()
      ),
    ];
    const currentQuarter = getQuarter();
    Object.entries(data).map(([key, item]: [string, PriceChartItem[]]) => {
      // 非未来数据
      const currentData = xData.map((e: string) => {
        const data = item.find((row) => row.rowTitle === e);
        if (data && data.rowTitle <= currentQuarter) {
          return generateRentValue(data.specificField, 'bookRentUnit', true);
        }
        return '-';
      });
      // 未来数据
      const forecastData = xData.map((e: string) => {
        const data = item.find((row) => row.rowTitle === e);
        if (data && data.rowTitle >= currentQuarter) {
          return generateRentValue(data.specificField, 'bookRentUnit', true);
        }
        return '-';
      });
      series.push(
        {
          name: legend[key],
          type: 'line',
          data: currentData,
          symbol: 'emptyCircle', // 拐点设置为实心
          symbolSize: 6,
          animation: true,
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              color: '#fff',
              fontSize: '12px',
              fontWeight: 400,
              padding: [4, 8],
              borderRadius: 20,
              backgroundColor: '#01151C',
            },
            scale: true,
            itemStyle: {
              color: colorMap.get(key),
              borderWidth: 4,
            },
          },
          blur: {
            itemStyle: {
              opacity: 0.4,
            },
          },
        },
        {
          name: legend[key],
          type: 'line',
          symbol: 'none',
          animation: true,
          smooth: false,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 2,
                type: 'dotted',
              },
            },
          },
          emphasis: {
            focus: 'series',
            label: {
              show: true,
              color: '#fff',
              fontSize: '12px',
              fontWeight: 400,
              padding: [4, 8],
              borderRadius: 20,
              backgroundColor: '#01151C',
            },
            scale: true,
            itemStyle: {
              color: colorMap.get(key),
              borderWidth: 4,
            },
          },
          blur: {
            itemStyle: {
              opacity: 0.4,
            },
          },
          data: forecastData,
          symbol: 'emptyCircle',
          symbolSize: 6,
        }
      );
    });
    return {
      color: ['#E30613', '#666666', '#239EC7', '#D96512'],
      legend: {
        right: 0,
        top: 'middle',
        orient: 'vertical',
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
      },
      grid: {
        left: 30,
        right: '26%',
        bottom: '3%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: object[]) => {
          // 避免tooltip和emphasis效果同时出现
          if (chartRef.current) {
            chartRef.current.getEchartsInstance().dispatchAction({
              type: 'downplay',
            });
          }
          const filterDt = params.filter((item: any) => item.data !== '-');
          const dt = filterDt.filter(
            (item: any, index: number) => item.seriesName !== filterDt[index + 1]?.seriesName
          );
          const nameLens = dt.map((item: any) => item.seriesName.length);
          const maxLen = Math.max(...nameLens);
          const iconStyle = (color: string) =>
            `display:inline-block;margin-right:8px;width:8px;height:8px;border-radius: 4px;background: ${color}`;
          const itemHtml = dt
            .map(
              (item: any) => `<div style="line-height: 20px;">
              <i style="${iconStyle(item.color)}"></i>
              <span style="display:inline-flex;justify-content: space-between;color:#01151c;min-width: ${
                maxLen * 12 + 90
              }px;">
                <span>${item.seriesName}</span><span>${item.data || '--'}
                ${props.unit}</span>
              </span>
            </div>`
            )
            .join('');
          const htmlTmp = `
          <div style="min-width: 160px;height: 124px;background: #FFFFFF;font-size: 14px;">
            <p style="margin-bottom: 8px;">${(dt[0] as any)?.name}</p>
            <div>
              ${itemHtml}
            </div>
          </div>`;
          return htmlTmp;
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisTick: 'none',
        data: xData,
        axisLabel: {
          rotate: 45,
        },
      },
      yAxis: {
        type: 'value',
        ...minAndMax(series),
      },
      series,
    };
  };

  if (!hasData) {
    return (
      <Empty
        style={{ flex: 3 }}
        text={emptyText}
      />
    );
  }
  return (
    <ReactECharts
      ref={chartRef}
      {...rest}
      option={getOptions()}
      notMerge={true}
      lazyUpdate={true}
    />
  );
};
