import classNames from 'classnames';
import { JLLSvg, Tooltip } from 'jlld';
import styles from './style.module.less';
import './style.less';

interface TooltipConfig {
  placement: string;
  title: string | JSX.Element;
}

interface IconConfig {
  icon: string;
  size: number;
  color: string;
}
interface PageHeaderProps {
  title: string;
  className?: string;
  useBorder?: boolean;
  showBack?: boolean;
  children?: JSX.Element | JSX.Element[];
  showIconToolTip?: boolean;
  tooltipConfig?: TooltipConfig;
  iconConfig?: IconConfig;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <header
      className={classNames(styles.pageHeader, styles.className, {
        [styles.borderStyle]: props.useBorder,
      })}
    >
      <div className={styles.pageTitle}>
        {props.showBack ? (
          <JLLSvg
            icon="arrow-back"
            size={20}
            color="#01151c"
            className={styles.back}
            onClick={() => history.back()}
          />
        ) : null}
        <span>{props.title}</span>
        {props.showIconToolTip ? (
          <Tooltip
            placement={props.tooltipConfig?.placement}
            title={props.tooltipConfig?.title}
            className={styles.iconToolTip}
            overlayClassName="relocateAnalysisIconTooltip"
          >
            <JLLSvg
              icon={props.iconConfig?.icon}
              size={props.iconConfig?.size}
              color={props.iconConfig?.color}
            />
          </Tooltip>
        ) : null}
      </div>
      {props.children}
    </header>
  );
};

export default PageHeader;
