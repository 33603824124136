import { useEffect } from 'react';
import useUserEvent from '@/hooks/useUserEvent';
import useTransactionDetailStore from '../../store/transactionDetailStore';
import TransactionInfo from './TransactionInfo';
import PropertyInfo from './PropertyInfo';
import styles from './style.module.less';

const TransactionDetail = () => {
  const { transactionDetail } = useTransactionDetailStore();
  const { sendUserEvent } = useUserEvent();

  useEffect(() => {
    sendUserEvent({ page: '投资交易详情' }, 'e_view');
  }, []);

  return (
    <div className={styles.transactionDetail}>
      <p className={styles.transactionTime}>交易时间：{transactionDetail.time}</p>
      <TransactionInfo />
      <PropertyInfo />
    </div>
  );
};

export default TransactionDetail;
