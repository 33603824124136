import { PropertyType } from '@/constants/enums';
import { commercialAndIndustrialOptions } from '@/constants/options';
import useReactShareUserStore from '@/store/reactShareUserStore';
import { JLLCascader, JLLForm } from 'jlld';
import { JLLCascaderValue } from 'jlld/es/JLLCascader';
import { useMemo } from 'react';

export interface PropertyTypeFormItemProps {
  className?: string;
  exclusive?: boolean;
  notAllowEmpty?: boolean;
  leftAll?: boolean;
  resetValue?: JLLCascaderValue;
}

const PropertyTypeFormItem = (props: PropertyTypeFormItemProps) => {
  const user = useReactShareUserStore.getState().user;

  const currCommercialAndIndustrialOptions = useMemo(() => {
    const result = [];

    if (user.propertyType.includes(PropertyType.commercial)) {
      result.push(commercialAndIndustrialOptions[0]);
    }
    if (user.propertyType.includes(PropertyType.industrial)) {
      result.push(commercialAndIndustrialOptions[1]);
    }
    return result;
  }, [commercialAndIndustrialOptions]);

  return (
    <JLLForm.Item
      name="propertyType"
      noStyle
    >
      <JLLCascader
        search
        resetValue={props.resetValue}
        className={props.className}
        placeholder="所有物业类型"
        notAllowEmpty={props.notAllowEmpty}
        exclusive={props.exclusive}
        leftAll={props.leftAll}
        options={currCommercialAndIndustrialOptions}
      />
    </JLLForm.Item>
  );
};

export default PropertyTypeFormItem;
