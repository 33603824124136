import styles from './style.module.less';
import classNames from 'classnames';
import LineChart from './LineChart';
import useMarketPerformanceStore from '../../store';
import { ProjectRowData } from '../../types';
import BarChart from './BarChart';
import { useShallow } from 'zustand/react/shallow';
import { deepClone } from '@/utils/tool';
import { useUnitExchange } from '@/hooks/useUnitExchange';

const Performance = (props: {
  title: string;
  unit: string;
  data: ProjectRowData[][];
  chartEmptyText?: string;
}) => {
  const { title, unit, data, chartEmptyText } = props;

  return (
    <div className={classNames('marketPerformanceChart', styles.chartSection)}>
      <h3>{title}</h3>
      <LineChart
        legend={['当前周期', '未来预测']}
        unit={unit}
        data={data}
        emptyText={chartEmptyText}
      />
    </div>
  );
};

const NetAbsorption = (props: { data: ProjectRowData[][]; chartEmptyText?: string }) => {
  return (
    <div className={classNames('marketPerformanceChart', styles.chartSection)}>
      <h3>净吸纳量</h3>
      <BarChart
        legend={['当前周期', '未来预测']}
        unit="平方米"
        data={props.data}
        emptyText={props.chartEmptyText}
      />
    </div>
  );
};

export default (props: { inConstructing?: boolean; isHeadquarters?: boolean }) => {
  const { inConstructing, isHeadquarters } = props;
  const { bookRent, effectiveRent, vacancyRate, netAbsorption } = useMarketPerformanceStore(
    useShallow((state) => ({
      bookRent: state.bookRent,
      effectiveRent: state.effectiveRent,
      vacancyRate: state.vacancyRate,
      netAbsorption: state.netAbsorption,
    }))
  );
  const { generateRentValue, generateRentUnit } = useUnitExchange();

  const getExchangedData = (data: ProjectRowData[][], key: string): ProjectRowData[][] => {
    const newData = deepClone(data);
    newData.forEach((d: ProjectRowData[]) => {
      d.forEach((item) => {
        item.specificField = generateRentValue(item.specificField, key, true) as string;
      });
    });
    return newData;
  };

  const getEmptyText = (flag?: boolean) => {
    if (isHeadquarters && flag) {
      return '该项目为总部楼宇项目，不监测账面租金和有效租金';
    } else if (inConstructing) {
      return '该项目还未交付，暂无市场表现数据';
    }
    return '该项目暂未获取到相关市场表现数据，正在紧锣密鼓补充中，敬请期待';
  };

  return (
    <div className={styles.chartView}>
      <Performance
        title="账面租金"
        unit={generateRentUnit('bookRentUnit')}
        data={getExchangedData(bookRent, 'bookRentUnit')}
        chartEmptyText={getEmptyText(true)}
      />
      <Performance
        title="有效租金"
        unit={generateRentUnit('effectiveRentUnit')}
        data={getExchangedData(effectiveRent, 'effectiveRentUnit')}
        chartEmptyText={getEmptyText(true)}
      />
      <Performance
        title="空置率"
        unit="%"
        data={vacancyRate}
        chartEmptyText={getEmptyText()}
      />
      <NetAbsorption
        data={netAbsorption}
        chartEmptyText={getEmptyText()}
      />
    </div>
  );
};
