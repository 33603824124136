import ReactECharts from 'echarts-for-react';
import Empty from '@/components/Empty';
import { PageWithWatermark } from '@/components';
import { InAndOutChartItem } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '@/constants/enums';
import { thousandSplitNumber } from '@/utils/tool';
import { DataObject } from '@/types';
import { useBlockWithTitleContext, ChartType } from '../BlockWithTitle';
import styles from '../style.module.less';

interface RelocateIndustryProps {
  data: InAndOutChartItem[];
  type: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
}

const moveInTopColor = ['#C2AD9E', '#D4BEAC', '#E4CEBC'];
const moveOutTopColor = ['#3E7186', '#6797AA', '#8CBBCC'];

const RelocateIndustry = (props: RelocateIndustryProps) => {
  const { mode } = useBlockWithTitleContext();
  const dataObj: DataObject = {};
  const getColor = () => {
    const topColor =
      props.type === RelocateTrailDimensionType.moveIn ? moveInTopColor : moveOutTopColor;
    const restColor = props.data?.length - 3 > 0 ? new Array(props.data?.length - 3) : [];
    restColor.fill('#E2E3E5');
    return [...topColor, ...restColor];
  };

  const getData = () => {
    return props.data.map((item, index) => {
      dataObj[item.name] = item;
      return {
        ...{
          value: mode === ChartType.area ? item.tenantAreaRatio : item.tenantCountRatio,
          name: item.name,
        },
        ...(props.type === RelocateTrailDimensionType.moveOut && index < 2
          ? {
              label: {
                color: '#FFF',
              },
            }
          : {}),
      };
    });
  };

  const getOption = () => {
    const option = {
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          const { name, value, color } = params;
          return `
            <div style="color: #01151C;">
              <p style="margin-bottom: 8px; font-weight: bold;">${name}</p>
              <div style="margin-bottom: 4px;display: flex; justify-content: space-between;align-tiems: center;">
                <div>
                  <span style="display:inline-block;width:12px; height:12px;background: ${color};"></span>
                  <span style="display:inline-block; width:130px">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}占比</span>
                </div>
                <span>${value && typeof value === 'number' ? `${value}%` : '--'}</span>
              </div>
              <div style="display: flex; justify-content: space-between;">
                <span style="display:inline-block; width:130px; ">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}值</span>
                <span>${thousandSplitNumber(mode === ChartType.area ? dataObj[name]?.tenantArea : dataObj[name]?.tenantCount)}${mode === ChartType.area ? '㎡' : '个'}</span>
              </div>
            </div>
          `;
        },
      },
      grid: {
        top: '20px',
        left: '0px',
        right: '10px',
        bottom: '50px',
      },
      legend: {
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
        left: '0px',
        bottom: '0px',
      },
      color: getColor(),
      series: [
        {
          label: {
            position: 'inside',
            formatter: '{b} {@1}%',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 20,
            color: '#01151D',
          },
          type: 'treemap',
          itemStyle: {
            borderWidth: 0.5,
            borderColor: '#fff',
          },
          breadcrumb: {
            show: false,
          },
          nodeClick: false,
          roam: false,
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          data: getData(),
        },
      ],
    };
    return option;
  };

  const renderLegends = () => {
    const colors = getColor();
    return (
      <div>
        {props.data.map((item, index) => (
          <div
            className={styles.legendItem}
            key={index}
          >
            <span
              className={styles.legendColor}
              style={{ backgroundColor: colors[index] }}
            />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {props.data?.length > 0 ? (
        <PageWithWatermark>
          <ReactECharts
            option={getOption()}
            notMerge
            lazyUpdate
            style={{ height: '300px' }}
          />
          {renderLegends()}
        </PageWithWatermark>
      ) : (
        <div className="chartEmpty">
          <Empty
            text="当前筛选条件下，没有相关数据"
            useSolsticeStyle
          />
        </div>
      )}
    </div>
  );
};

export default RelocateIndustry;
