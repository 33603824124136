import {
  getIndustryBuildingTenantDetail,
  getIndustryTenantBuildingRank,
  getIndustryTenantExistsTime,
  getIndustryTenantRank,
} from '@/pages/Z-ProjectDetail/api';
import {
  getIndustryBuildingTenantDetailParams,
  getIndustryTenantBuildingRankParams,
  getIndustryTenantRankParams,
} from '@/pages/Z-ProjectDetail/types/apiTypes';

export default {
  getExistsTime: async (busId: string) => {
    return await getIndustryTenantExistsTime({ busId });
  },
  getTenantAnalysis: async (data: getIndustryTenantRankParams) => {
    return await getIndustryTenantRank(data);
  },
  getTenantIFS: async (data: getIndustryTenantBuildingRankParams) => {
    return await getIndustryTenantBuildingRank(data);
  },
  getTenantDetail: async (data: getIndustryBuildingTenantDetailParams) => {
    return await getIndustryBuildingTenantDetail(data);
  },
};
