import { useEffect } from 'react';
import { useMapContext } from '@uiw/react-amap-map';
import { Circle } from '@uiw/react-amap-circle';

interface SurroundingCircleProps {
  center: [number, number];
}

const SurroundingCircle = (props: SurroundingCircleProps) => {
  const { map } = useMapContext();

  if (!props.center) {
    return null;
  }

  useEffect(() => {
    map?.panBy(-200, 0, 0);
  }, [[props.center]]);

  return (
    <Circle
      radius={1000}
      strokeColor="#E30613"
      strokeWeight={1}
      strokeOpacity={0.6}
      fillColor="#FEEBEC"
      fillOpacity={0.6}
      center={props.center}
    />
  );
};

export default SurroundingCircle;
