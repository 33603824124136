import { TitleFormOptions } from '@/constants/options';
import { JLLForm, JLLMultiSelector } from 'jlld';

const ProjectOwnershipFormItem = () => {
  return (
    <JLLForm.Item
      name="titleFroms"
      noStyle
    >
      <JLLMultiSelector
        options={TitleFormOptions}
        placeholder="选择业权形态"
      />
    </JLLForm.Item>
  );
};

export default ProjectOwnershipFormItem;
