import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import { useRef, useImperativeHandle, forwardRef } from 'react';
import useMarketAnalysisStore, { queryMarketChart, setLoading } from '../../store';
import useMarketSettingStore from '../../store/settingStore';
import DoubleAxisChart from '../../../../components/DoubleAxisChart';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';

const dimensionMap = {
  projectCount: '项目数',
  bookRent: '账面租金',
  effectiveRent: '有效租金',
  vacancyRate: '空置率',
  areaTotal: '总存量',
  newSupply: '新增供应',
  netAbsorption: '净吸纳量',
  propertyManagementFees: '物管费',
  none: '无指标',
};

const convertDimension = (dimensions: string[]) => {
  return dimensions.map((item: string) => dimensionMap[item]);
};

const Chart = forwardRef((props, ref) => {
  const doubleAxisChartRef = useRef(null);
  useImperativeHandle(ref, () => ({
    downloadChart: doubleAxisChartRef?.current?.downloadChartImg,
  }));

  useOnload(queryMarketChart, ShowDataTabType.chart, setLoading);
  const chartData = useMarketAnalysisStore((state) => state.chartData);
  const marketState = useMarketSettingStore((state) => state);

  return (
    <DoubleAxisChart
      leftType={marketState.chartSettingFormValues?.leftAxisType}
      rightType={marketState.chartSettingFormValues?.rightAxisType}
      checkedLeft={convertDimension(marketState.chartSettingFormValues?.left)}
      checkedRight={convertDimension(marketState.chartSettingFormValues?.right)}
      chartData={chartData}
      dataUnitType={marketState.tableFormValues.unitType}
      ref={doubleAxisChartRef}
    />
  );
});

export default Chart;
