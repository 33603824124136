/* eslint-disable quote-props */
import axios, { AxiosRequestConfig } from 'axios';
import { jllResInterceptor, requestTokenInterceptor, responseError } from './requestInterceptors';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

// 创建axios实例 这个先不用，直接用老的request
// const vendorRequest = axios.create({
//   // axios中请求配置有baseURL选项，表示请求URL公共部分
//   baseURL: process.env.VUE_APP_API_BASE_URL,
//   // 超时
//   timeout: 0,
// });

export const request = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/leasingApi',
  // 超时
  timeout: 0,
});

request.interceptors.request.use(requestTokenInterceptor);

request.interceptors.response.use(jllResInterceptor, responseError);

export const jllRequest = <T>(config: AxiosRequestConfig<any>) => {
  return request.request<any, T>(config);
};
