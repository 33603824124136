import { Input } from 'jlld';
import './style.less';
import classNames from 'classnames';

interface InputRangeProps {
  className?: string;
  value?: [number | null, number | null];
  onChange?: (value: [number | null, number | null]) => void;
  onBlur?: () => void;
  placeholder?: string;
  placeholderStart?: string;
  placeholderEnd?: string;
  minMaxValue?: [number, number];
}

const InputRange = (props: InputRangeProps) => {
  const { value, onChange, placeholder, placeholderStart, placeholderEnd, className, onBlur } =
    props;
  const [starValue, endValue] = value ?? [null, null];

  return (
    <div className={classNames(className, 'jllInputRange')}>
      <Input
        placeholder={placeholderStart || placeholder}
        value={starValue ? `${starValue}` : ''}
        onChange={(e) => {
          // if (e.target.value.includes('.')) {
          //   return;
          // }
          onChange && onChange([e.target.value as unknown as number, endValue]);
        }}
        onBlur={(e) => {
          onBlur?.();
          if (!e.target.value) {
            return;
          }
          const minValue = Number(e.target.value);
          // if (minMaxValue && minValue) {
          //   if (minValue < minMaxValue[1]) {
          //     minValue = minMaxValue[0];
          //   } else if (minValue > minMaxValue[1]) {
          //     minValue = minMaxValue[1];
          //   }
          // }

          // if (endValue && minValue > endValue) {
          //   minValue = endValue;
          // }
          onChange && onChange([minValue === 0 ? null : minValue, endValue]);
        }}
      />
      <div className="gap-line"></div>
      <Input
        placeholder={placeholderEnd || placeholder}
        value={endValue ? String(endValue) : ''}
        onChange={(e) => {
          onChange && onChange([starValue, e.target.value as unknown as number]);
        }}
        onBlur={(e) => {
          onBlur?.();
          if (!e.target.value) {
            return;
          }
          const maxValue = Number(e.target.value);
          // if (minMaxValue && maxValue) {
          //   if (maxValue < minMaxValue[1]) {
          //     maxValue = minMaxValue[0];
          //   } else if (maxValue > minMaxValue[1]) {
          //     maxValue = minMaxValue[1];
          //   }
          // }

          // if (starValue && maxValue < starValue) {
          //   maxValue = starValue;
          // }

          onChange && onChange([starValue, maxValue === 0 ? null : maxValue]);
        }}
      />
    </div>
  );
};

export default InputRange;
