import { useEffect } from 'react';
import { AllLoading } from '../types/settingTypes';

export const useOnload = (queryMethod: any, key: keyof AllLoading, setLoading: any) => {
  useEffect(() => {
    queryMethod();
    return () => {
      setLoading(key, true);
    };
  }, []);
};
