import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getBuildingInfo } from '../../../api';
interface State {
  buildingInfo: any;
}

const initState: State = {
  buildingInfo: {},
};

const useProjectInfoStore = create(
  immer(
    combine(initState, (set, get) => ({
      getBuildingInfo: async (buildingId: string) => {
        const res = await getBuildingInfo(buildingId);
        set({
          buildingInfo: res,
        });
      },
    }))
  )
);

export default useProjectInfoStore;
