import { developerBackgroundOptions } from '@/constants/options';
import { JLLForm, JLLMultiSelector } from 'jlld';

const DevelopersFormItem = () => {
  return (
    <JLLForm.Item
      name="developers"
      noStyle
    >
      <JLLMultiSelector
        options={developerBackgroundOptions}
        placeholder="选择开发商背景"
      />
    </JLLForm.Item>
  );
};

export default DevelopersFormItem;
