import { useState, useLayoutEffect } from 'react';
import { Table, TableColumnsType } from 'jlld';
import ColumnHeaderWithSort from '@/components/ColumnHeaderWithSort';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import useTradeDetailStore from '../../../store/tradeDetailStore';
import { thousandSplitNumber } from '@/utils/tool';
import styles from '../style.module.less';
import './style.less';
import useReactShareAuthStore from '@/store/reactShareAuthStore';
import useLeftFormStore from '@/pages/G-BlockTrade/store/leftFormStore';
import useCommonOptionsStore from '@/store/commonOptions';
import { fitTable } from '../../utils';

interface DataType {
  key: string;
  busId: string;
  time: string;
  firstLevelCategory: string;
  secondLevelCategory: string;
  propertyName: string;
  city: string;
  region: string;
  saleArea: string;
  totalPrice: string;
  unitPrice: string;
  buyerCompany: string;
  buyerType: string;
  sellerCompany: string;
  sellerType: string;
}

const DetailTable = () => {
  const { auth } = useReactShareAuthStore();
  const loading = useCommonOptionsStore((state) => state.loading);
  const defaultTimeLoading = useLeftFormStore((state) => state.defaultTimeLoading);
  const { detailData, queryDetailList, setDetailQueryOrder, detailQueryOrder } =
    useTradeDetailStore();
  const [sortKey, setSortKey] = useState(detailQueryOrder[0].column);
  const [sortAsc, setSortAsc] = useState(detailQueryOrder[0].asc);

  const { data } = useMainFrameContext();

  useLayoutEffect(() => {
    fitTable(detailData.total !== 0);
  }, [detailData.total]);

  const sortIconClick = (key: string, asc: boolean) => {
    const order = [
      {
        asc: asc,
        column: key,
      },
    ];
    setSortKey(key);
    setSortAsc(asc);
    setDetailQueryOrder(order);
    queryDetailList({
      orders: order,
    });
  };

  const jumpToTransactionDetail = (record: DataType) => {
    data.router.push(
      `/block-trade/block-trade-transaction-detail/${encodeURIComponent(record.busId)}`
    );
  };

  const renderTitle = (title: string, key: string, arrowAlign?: string) => {
    return (
      <ColumnHeaderWithSort
        title={title}
        currentKey={key}
        sortKey={sortKey}
        sortAsc={sortAsc}
        arrowAlign={arrowAlign}
        sortIconClick={sortIconClick}
      />
    );
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: renderTitle('交易时间', 'time', 'right'),
      dataIndex: 'time',
      key: 'time',
      width: 120,
    },
    {
      title: '一级分类',
      dataIndex: 'firstLevelCategory',
      key: 'firstLevelCategory',
      width: 120,
    },
    {
      title: '二级分类',
      dataIndex: 'secondLevelCategory',
      key: 'secondLevelCategory',
      width: 120,
    },
    {
      title: '交易项目',
      dataIndex: 'propertyName',
      key: 'propertyName',
      width: 200,
    },
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      width: 144,
    },
    {
      title: '区域',
      dataIndex: 'region',
      key: 'region',
      width: 144,
    },
    {
      title: renderTitle('交易面积(㎡)', 'saleArea'),
      dataIndex: 'saleArea',
      key: 'saleArea',
      width: 170,
      align: 'right',
      render: (text: string) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: renderTitle('交易金额(万元)', 'totalPrice'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      width: 170,
      align: 'right',
      render: (text: string) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: renderTitle('交易单价(万元/㎡)', 'unitPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 170,
      align: 'right',
      render: (text: string) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: '买方名称',
      dataIndex: 'buyerCompany',
      key: 'buyerCompany',
      width: 200,
    },
    {
      title: '买方企业来源',
      dataIndex: 'buyerType',
      key: 'buyerType',
      width: 200,
    },
    {
      title: '卖方名称',
      dataIndex: 'sellerCompany',
      key: 'sellerCompany',
      width: 200,
    },
    {
      title: '卖方企业来源',
      dataIndex: 'sellerType',
      key: 'sellerType',
      width: 200,
    },
    (auth as any).buttonAuth?.some(
      (item: any) => item.button === 'front:blocktrade:details:review'
    ) && {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 80,
      align: 'center',
      render: (_: any, record: DataType) => (
        <p
          className="actionText"
          onClick={() => jumpToTransactionDetail(record)}
        >
          详情
        </p>
      ),
    },
  ].filter((item) => !!item);

  const paginationChange = (page: number, pageSize: number) => {
    queryDetailList({
      orders: [
        {
          asc: sortAsc,
          column: sortKey,
        },
      ],
      current: page,
      size: pageSize,
    });
  };

  const pagination = {
    current: detailData.current,
    pageSize: detailData.size,
    pageSizeOptions: [15, 50, 100],
    total: detailData.total,
    showSizeChanger: true,
    onChange: paginationChange,
    defaultPageSize: 15,
    showTotal: (total: number) => `共 ${total} 条`,
  };

  return (
    <Table
      className={styles.detailTable}
      rowClassName={styles.tableRow}
      columns={columns}
      dataSource={detailData.records}
      rowKey={'busId'}
      bordered
      loading={loading.blockTrade || defaultTimeLoading}
      scroll={{
        x: 600,
        y:
          (document.querySelector('.ant-tabs-content-holder') as HTMLElement)?.clientHeight - 175 ||
          500,
      }}
      pagination={pagination}
    />
  );
};

export default DetailTable;
