import { CommonRequestParams } from '@/pages/F-DataAnalysis/types/apiTypes';
import { jllRequest } from '@/utils/request';

// export type getFutureSupplyDetailParams = CommonRequestParams;
/**
 * 市场表现未-来供应-图表包装返回前台对象
 */
export interface FutureSupplyChartRes {
  /**
   * 示例列表
   */
  exampleList?: string[];
  /**
   * 未来供应-图表列表
   */
  futureSupplyChartList?: futureSupplyChartItem[];
}

/**
 * 市场表现未-来供应-图标返回前台对象
 */
export interface futureSupplyChartItem {
  /**
   * 标题值，这里一般为季度或者年份
   */
  rowTitle?: string;
  /**
   * 单个堆积图列表
   */
  stackingDiagramList?: StackingDiagramItem[];
}

/**
 * 堆积图-子项
 */
export interface StackingDiagramItem {
  rowTitle?: string;
  specificField?: number;
}

export interface getFutureSupplyDetailRes {
  projectName: string;
  dueTime: string;
  officeBuildArea: string;
  cityName: string;
  administrativeRegions: string;
  subMarket: string;
  projectLevel: string;
  isHeadquarters: string;
  devType: string;
  leedLevel: string;
}
/** 未来供应-列表 */
export async function getFutureSupplyTable(data: CommonRequestParams) {
  const result = await jllRequest({
    url: '/dataAnalysis/futureSupplyList',
    method: 'post',
    data,
  });
  return result;
}

/** 未来供应-图表 */
export async function getFutureSupplyChart(data: CommonRequestParams) {
  const result = await jllRequest<FutureSupplyChartRes>({
    url: '/dataAnalysis/futureSupplyChart',
    method: 'post',
    data,
  });
  return result;
}

/** 未来供应-明细 */
export async function getFutureSupplyDetail(data: CommonRequestParams) {
  const result = await jllRequest<getFutureSupplyDetailRes[]>({
    url: '/dataAnalysis/futureSupplyDetail',
    method: 'post',
    data: { ...data },
  });
  return result;
}
