import { LeaseTypeOptions, enterpriseSourceOptions } from '@/constants/options';
import useUserEvent from '@/hooks/useUserEvent';
import classNames from 'classnames';
import {
  ConfigProvider,
  JLLCascader,
  JLLDatePicker,
  JLLForm,
  JLLMultiSelector,
  Checkbox,
  Radio,
} from 'jlld';
import styles from './style.module.less';
import useCommonOptionsStore from '@/store/commonOptions';
import React from 'react';
import { PickerType } from 'jlld/es/JLLDatePicker';

export const IndustrialPicker = (props: {
  placeholder?: string;
  search?: boolean;
  searchPlaceholder?: string;
}) => {
  const industryOptions = useCommonOptionsStore((state) => state.industryOptions);
  return (
    <JLLForm.Item
      noStyle
      name="secondaryIndustries"
    >
      <JLLCascader
        search={props.search || false}
        searchPlaceholder={props.searchPlaceholder}
        className="headerFormItem"
        placeholder={props.placeholder || '行业'}
        options={industryOptions}
      />
    </JLLForm.Item>
  );
};

export const EnterpriseSourcePicker = () => (
  <JLLForm.Item
    noStyle
    name="enterpriseSources"
  >
    <JLLMultiSelector
      needComfirm
      className="headerFormItem"
      placeholder="全部企业来源"
      options={enterpriseSourceOptions}
    />
  </JLLForm.Item>
);

export const LeaseDatePicker = (props: {
  minMaxDate: [string, string];
  type: PickerType;
  onTypeChange: (type: PickerType) => void;
}) => {
  return (
    <JLLForm.Item
      noStyle
      name="queryTime"
    >
      <JLLDatePicker.JLLQuarterPicker
        onTypeChange={props.onTypeChange}
        type={props.type}
        minMaxDate={props.minMaxDate}
        className="headerFormItem"
      />
    </JLLForm.Item>
  );
};

export const LeaseUserTypePicker = () => {
  const { sendUserEvent } = useUserEvent();
  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            buttonColor: 'rgb(1, 21, 28)',
            buttonSolidCheckedBg: 'rgb(238, 239, 240)',
            buttonSolidCheckedHoverBg: 'rgb(237, 243, 244)',
            buttonCheckedBgDisabled: 'rgb(243, 243, 243)',
            buttonCheckedColorDisabled: 'rgb(152, 152, 152)',
            buttonSolidCheckedActiveBg: 'rgb(237, 243, 244)',
            colorBorder: 'rgb(238, 239, 240)',
            colorPrimaryBorder: 'rgb(238, 239, 240)',
            colorPrimary: 'rgb(1, 21, 28)',
            colorPrimaryHover: 'rgb(238, 239, 240)',
            colorPrimaryActive: 'rgb(1, 21, 28)',
            buttonSolidCheckedColor: 'rgb(1, 21, 28)',
          },
        },
      }}
    >
      <JLLForm.Item
        noStyle
        name="leaseType"
      >
        <Radio.Group
          buttonStyle="solid"
          className={classNames('headerFormItem', styles.radioButtonGroup)}
          onChange={(e) => {
            sendUserEvent({ button: LeaseTypeOptions[e.target.value - 1]?.label }, 'e_click');
          }}
        >
          {LeaseTypeOptions.map((item) => (
            <Radio.Button
              key={item?.label}
              value={item.value}
            >
              {item?.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </JLLForm.Item>
    </ConfigProvider>
  );
};

export interface CheckEnterpriseSourcesProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const CheckEnterpriseSources = (props: CheckEnterpriseSourcesProps) => {
  const onCheckAllChange = (e: any) => {
    const checked = e.target.checked;
    props.onChange?.(checked ? enterpriseSourceOptions.map((item) => item.value) : []);
  };

  return (
    <React.Fragment>
      <Checkbox
        style={{ marginRight: 8 }}
        indeterminate={
          props.value &&
          props.value.length > 0 &&
          props.value.length < enterpriseSourceOptions.length
        }
        onChange={onCheckAllChange}
        checked={props.value && props.value.length === enterpriseSourceOptions.length}
      >
        全部
      </Checkbox>
      <Checkbox.Group
        options={enterpriseSourceOptions}
        value={props.value}
        onChange={(checkedList) => {
          const list = checkedList as string[];
          props.onChange?.(list);
        }}
      />
    </React.Fragment>
  );
};
