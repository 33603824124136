import React from 'react';
import styles from './style.module.less';
import classNames from 'classnames';

export interface lineData {
  label: string;
  value: number;
}
export interface BarlineProps {
  className?: string;
  title?: string;
  lineDataList?: lineData[];
  color?: string;
}

const HorizontalDomBarLineChart = (props: BarlineProps) => {
  if (!props.lineDataList) {
    return null;
  }

  return (
    <div className={classNames(props.className, 'domBarLine')}>
      {props.title && <div className={styles.title}>{props.title}</div>}
      <div className={styles.barLineGrid}>
        {props.lineDataList.map((item) => {
          const style: React.CSSProperties = {
            width: item.value + '%',
          };

          if (props.color) {
            style.backgroundColor = props.color;
          }

          return (
            <React.Fragment key={item.label}>
              <div className={styles.barLineItemLabel}>{item.label}</div>
              <div className={styles.barLineItemProgress}>
                <div
                  className={styles.barLineItemProgressInner}
                  style={style}
                ></div>
              </div>
              <div className={styles.barLineItemValue}>{item.value}%</div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default HorizontalDomBarLineChart;
