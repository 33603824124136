import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

// a4纸的尺⼨[595.28,841.89]，html 页⾯⽣成的 canvas 在pdf中图⽚的宽⾼
/* eslint-disable camelcase */
const a4_width = 595.28; // a4纸默认宽度
/* eslint-disable camelcase */
const a4_height = 841.89; // a4默认高度

const hPadding = 40; // 左右间距
const vPadding = 30; // 上下间距
const a4Width = a4_width - hPadding * 2; // 左右55的间隔
const a4Height = a4_height - vPadding * 2; // 上下30的间隔
/**
 * [获取页面导出的pdf文件]
 * @param   {[Object]}  options  [导出pdf配置项，包括一个title属性设置文件名，以及query属性设置获取元素的条件]
 * title  文件标题
 * el 导出dom
 * className 模块名称
 */

/* eslint-disable*/
export function getPdf(options) {
  const title = options.title || '标题'; // 导出文件名，默认为“标题”
  let children = [];
  if (Array.isArray(options.el)) {
    children = options.el.reduce((canvas, el) => {
      const cur = el.getElementsByClassName(options.className || 'html2pdf-cell');
      return [...canvas, ...cur];
    }, []);
  } else {
    children = (options.el || document).getElementsByClassName(
      options.className || 'html2pdf-cell'
    );
  }
  // 水印dom
  const warterMarkerDom = (options.warterEl || document).getElementsByClassName(
    options.className || 'html2pdf-warter-marker'
  );
  let canvas = [];
  let i = 0;
  function toCanvas(resolve) {
    if (children.length > 1) {
      const contentWidth = children[i].clientWidth; // 获得该容器的宽
      const contentHeight = children[i].clientHeight; // 获得该容器的⾼
      try {
        html2canvas(children[i], {
          useCORS: true,
          scale: window.devicePixelRatio * 2, // 按比例增加分辨率
          background: '#fff', // 背景设为白色（默认为黑色）
        }).then((res) => {
          // 计算每个dom的高度，方便后面计算分页
          res.imgWidth = a4Width;
          res.imgHeight = (res.imgWidth / contentWidth) * contentHeight;
          canvas.push(res);
          i++;
          if (canvas.length === children.length) {
            paging();
            toPdf(resolve);
          } else {
            toCanvas(resolve);
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
  /**
   * [根据dom的高度初步进行分页，会将canvas组装为一个二维数组]
   */
  function paging() {
    const imgArr = [[]];
    let pageH = 0; // 页面的高度
    let allH = 0; // 当前组所有dom的高度和
    let j = 0;
    for (let k = 0; k < canvas.length; k++) {
      // 涉及到k--的操作，使用for循环方便
      pageH += canvas[k].imgHeight;
      if (pageH > a4Height && canvas[k].imgHeight < a4Height) {
        // 当某个页面装不下下一个dom时，则分页
        imgArr[j][0].allH = allH - canvas[k].imgHeight;
        allH = pageH = 0;
        k--;
        j++;
        imgArr.push([]);
      } else {
        if (canvas[k].imgHeight > a4Height) {
          // 特殊情况：某个dom高度大于了页面高度，特殊处理
          canvas[k].topH = a4Height - (pageH - canvas[k].imgHeight); // 该dom顶部距离页面上方的距离
          pageH = (2 * canvas[k].imgHeight - pageH) % a4Height;
          canvas[k].pageH = pageH; // 该dom底部距离页面上方的距离
        }
        imgArr[j].push(canvas[k]);
        allH += canvas[k].imgHeight;
      }
      if (k === canvas.length - 1) imgArr[j][0].allH = allH;
    }
    canvas = imgArr;
  }
  /**
   * 获取水印
   */
  function getWarterMarker() {
    if (warterMarkerDom.length) {
      const [wt] = warterMarkerDom;
      const contentWidth = wt.clientWidth; // 获得该容器的宽
      const contentHeight = wt.clientHeight; // 获得该容器的⾼

      return new Promise((resolve, reject) => {
        html2canvas(wt, {
          useCORS: true,
          scale: window.devicePixelRatio * 2, // 按比例增加分辨率
          backgroundColor: 'transparent', // 背景设为白色（默认为黑色）
        })
          .then((res) => {
            // 计算每个dom的高度，方便后面计算分页
            res.imgWidth = a4Width / 2;
            res.imgHeight = (res.imgWidth / contentWidth) * contentHeight;
            resolve(res);
          })
          .catch(() => {
            reject(null);
          });
      });
    }
    return null;
  }
  /**
   * pdf 添加水印
   */
  function appendWarterMarker(waterMark, PDF) {
    const { imgWidth, imgHeight } = waterMark;
    const imgURL = waterMark.toDataURL('image/png', 1.0);
    for (let i = 0; i < Math.ceil(a4Height / imgHeight); i++) {
      const position = vPadding + i * waterMark.imgHeight;
      PDF.addImage(
        waterMark.toDataURL(imgURL),
        'PNG',
        hPadding,
        position,
        waterMark.imgWidth,
        waterMark.imgHeight
      );
      PDF.addImage(
        waterMark.toDataURL(imgURL),
        'PNG',
        hPadding + waterMark.imgWidth,
        position,
        waterMark.imgWidth,
        waterMark.imgHeight
      );
    }
  }
  /**
   * [生成PDF文件]
   */
  async function toPdf(resolve) {
    const PDF = new jsPDF('', 'pt', 'a4');
    const waterMark = await getWarterMarker();

    canvas.forEach((page, index) => {
      let [{ allH }] = page;
      let position = 0; // pdf页面偏移
      if (index > 0) {
        appendWarterMarker(waterMark, PDF);
        PDF.addPage();
      }
      page.forEach((img, imgIndex) => {
        // 每页上边距
        if (imgIndex === 0) position = vPadding;
        if (img.imgHeight) {
          if (img.imgHeight < a4Height) {
            // 当某个dom高度小于页面宽度，直接添加图片
            PDF.addImage(
              img.toDataURL('image/jpeg', 1.0),
              'JPEG',
              hPadding,
              position,
              img.imgWidth,
              img.imgHeight
            );
            position += img.imgHeight;
            allH -= img.imgHeight;
          } else {
            // 当某个dom高度大于页面宽度，则需另行处理
            while (allH > 0) {
              PDF.addImage(
                img.toDataURL('image/jpeg', 1.0),
                'JPEG',
                hPadding,
                position,
                img.imgWidth,
                img.imgHeight
              );
              allH -= img.topH || a4Height;
              position -= img.topH || a4Height;
              img.topH = 0;
              if (allH > 0) {
                PDF.addPage();
                position = position - vPadding * 3;
              }
            }
            position = img.pageH + vPadding;
          }
        }
      });
    });
    appendWarterMarker(waterMark, PDF);
    PDF.save(`${title}.pdf`);
    resolve();
  }

  return new Promise((resolve, reject) => {
    try {
      toCanvas(resolve);
    } catch (e) {
      reject();
    }
  });
}
