import { useRef, useImperativeHandle, forwardRef } from 'react';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import useIndustryAnalysisStore, { queryIndustryAnalysisChart, setLoading } from '../../store';
import useIndustrySettingStore from '../../store/settingStore';
import DoubleBarChart from '../../../../components/DoubleBarChart';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';

const Chart = forwardRef((props, ref) => {
  useOnload(queryIndustryAnalysisChart, ShowDataTabType.chart, setLoading);
  const chartData = useIndustryAnalysisStore((state) => state.chartData);
  const settingStore = useIndustrySettingStore((state) => state);
  const doubleBarChartRef = useRef(null);

  // if (loading) {
  //   return null;
  // }
  // return null;

  useImperativeHandle(ref, () => ({
    downloadChart: doubleBarChartRef?.current?.downloadChartImg,
  }));

  return (
    <DoubleBarChart
      ref={doubleBarChartRef}
      chartData={chartData}
      valueType={settingStore.chartSettingFormValues?.indicatorProportion}
      downloadImgName="产业分析"
    />
  );
});

export default Chart;
