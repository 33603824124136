import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { CascaderOption } from 'jlld/es/JLLCascader';
import { getCitySubMarketAndDistrict } from '@/api/map';

interface State {
  latestQuarter: string;
  leftFormQueryParam: any;
  districts: CascaderOption[];
  submarkets: CascaderOption[];
  currentTab: string;
}

const initState: State = {
  currentTab: 'tradeDetail',
  latestQuarter: '',
  leftFormQueryParam: {},
  districts: [],
  submarkets: [],
};

const useBlockTradeStore = create(
  immer(
    combine(initState, (set, get) => ({
      queryDistrictSubmarkets: async (citylist: string[]) => {
        const result = await getCitySubMarketAndDistrict(citylist);
        const districts = [] as CascaderOption[];
        const submarkets = [] as CascaderOption[];
        result.forEach((cityData: any) => {
          if (cityData.cityName === '多地') {
            districts.push({
              label: '多地',
              value: '多地',
              children: [{ label: '多地', value: '多地' }],
            });
            submarkets.push({
              label: '多地',
              value: '多地',
              children: [{ label: '多地', value: '多地' }],
            });
          } else {
            districts.push({
              label: cityData.cityName,
              value: cityData.cityName,
              children: cityData.districtList?.map((item: any) => ({
                label: item,
                value: item,
              })),
            });
            submarkets.push({
              label: cityData.cityName,
              value: cityData.cityName,
              children: cityData.subMarketList?.map((item: any) => ({
                label: item.name,
                value: item.id,
              })),
            });
          }
        });

        set((state) => {
          state.districts = districts;
          state.submarkets = submarkets;
          return state;
        });
      },

      setCurrentTab: (tab: string) => {
        set({
          currentTab: tab,
        });
      },
    }))
  )
);

export default useBlockTradeStore;
