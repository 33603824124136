import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const useReactShareUserStore = create(
  immer(
    combine(
      {
        user: {} as any,
        localCity: '', // 当前登录城市
        routePath: [], // 路由历史
        activeTabName: '',
        commonConfig: {},
        compareConfig: {
          // 项目对比下载最大项目个数
          comparisonDownloadProjectNum: 99,
          // 项目对比最大项目个数
          comparisonMaxProjectNum: 99,
          // 竞品组数量
          competitionGroupNum: 99,
          // 竞品组项目最大个数
          competitionGroupProjectNum: 99,
          // 项目对比下载次数限制
          comparisonDownloadProjectDailyTimes: 10,
        },
      },
      (set) => ({
        setSelectedCity: (localCity: string) => set(() => ({ localCity })),
      })
    )
  )
);

let isSetting = false;

let isSettingVue = false;
const keys = ['user', 'localCity', 'routePath', 'activeTabName', 'commonConfig', 'compareConfig'];
(useReactShareUserStore as any).syncState = (state: any) => {
  if (!isSettingVue) {
    isSetting = true;
    // console.log('react receive user state', state);
    const newState: any = {};

    keys.forEach((key) => {
      newState[key] = state[key];
    });
    useReactShareUserStore.setState(newState);
    isSetting = false;
  }
};
useReactShareUserStore.subscribe(() => {
  if (!isSetting && (window as any).mainFrameMehtod?.piniaUserStore) {
    // console.log('react set vue state');
    isSettingVue = true;
    (window as any).mainFrameMehtod.piniaUserStore().$patch(useReactShareUserStore.getState());
    isSettingVue = false;
  }
});

export default useReactShareUserStore;
