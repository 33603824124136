import { useRef } from 'react';
import { pageShowEvent } from '@/hooks/useUserEvent';
import {
  ShowDataTabType,
  SummaryShowType,
  marketStateIndicators,
  marketTransferList,
  tabOptions,
} from '../../config';
import { CommonTableWrap } from '../../components';
import { Table, TopForm, Chart } from './components';
import MarketChartControl from './components/MarketChartControl';
import useMarketSettingStore, { SettingState, setSettingData } from './store/settingStore';
import useMarketAnalysisStore, { queryCurrentTabData } from './store';
import styles from '../../style.module.less';
import exportDataAnalysisTable from '../../exportTable';

const MarketState = () => {
  const chartRef = useRef(null);
  const market = useMarketSettingStore();
  const loading = useMarketAnalysisStore((state) => state.loading);
  pageShowEvent('数据分析-市场表现');

  const onChange =
    (key: keyof SettingState, query = true) =>
    (value: SettingState[keyof SettingState]) => {
      setSettingData({ [key]: value });

      if (query) {
        queryCurrentTabData();
      }
    };
  // 各个view在自己组件中获取数据
  const tabItems = {
    [ShowDataTabType.table]: <Table />,
    [ShowDataTabType.chart]: <Chart ref={chartRef} />,
  };

  return (
    <div className={styles.viewContainer}>
      <TopForm
        tabModuleName="市场表现"
        activeTab={market.tableActiveTab}
      />
      <CommonTableWrap
        loading={loading.topForm}
        tableLoading={loading.table}
        chartLoading={loading.chart}
        tabModuleName="市场表现"
        tabOptions={tabOptions}
        dismensionList={marketTransferList}
        dimensionValues={market.dimensionValues}
        tableFormValues={market.tableFormValues}
        activeTab={market.tableActiveTab}
        dataIndicatorOptions={marketStateIndicators}
        downloadAuth="front:city:analyze:market:export"
        onDimensionChange={(values) => {
          if (values.length === 1) {
            onChange(
              'tableFormValues',
              false
            )({
              ...market.tableFormValues,
              summaryShowType: [SummaryShowType.total],
            });
          }
          if (values.length > 1) {
            const { summaryShowType } = market.tableFormValues;
            onChange(
              'tableFormValues',
              false
            )({
              ...market.tableFormValues,
              summaryShowType: summaryShowType?.includes(SummaryShowType.category)
                ? summaryShowType
                : [...summaryShowType, SummaryShowType.category],
            });
          }
          onChange('dimensionValues')(values);
        }}
        onTableColumnChange={onChange('tableFormValues')}
        onTabChange={onChange('tableActiveTab', false)}
        customControl={market.tableActiveTab === ShowDataTabType.chart && <MarketChartControl />}
        onDownloadTable={() => {
          market.tableActiveTab === 'chart'
            ? chartRef?.current?.downloadChart()
            : exportDataAnalysisTable(
                '市场表现',
                useMarketAnalysisStore.getState().tableData,
                market.dimensionValues,
                market.tableFormValues.dataIndicator,
                market.tableFormValues.unitType,
                market.tableFormValues.summaryShowType
              );
        }}
      >
        {tabItems[market.tableActiveTab as ShowDataTabType]}
      </CommonTableWrap>
    </div>
  );
};

export default MarketState;
