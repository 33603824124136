import useCommonOptionsStore from '@/store/commonOptions';
import { Form, JLLCascader, JLLForm } from 'jlld';

const DistrictsFormItem = () => {
  const cityList = Form.useWatch('citys', Form.useFormInstance());

  const districtOptions = useCommonOptionsStore((state) => state.districts).filter(
    (item) => !cityList?.length || cityList.includes(item.value)
  );

  return (
    <JLLForm.Item
      name="districts"
      noStyle
    >
      <JLLCascader
        placeholder="全部行政区"
        search
        options={districtOptions}
      />
    </JLLForm.Item>
  );
};

export default DistrictsFormItem;
