import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { JLLSvg, Tabs, TabsProps, ConfigProvider, message } from 'jlld';
import './style.less';
import styles from './style.module.less';
import ProjectInfo from './views/A-ProjectInfo';
import MarketPerformance from './views/B-MarketPerformance';
import TenantAnalysis from './views/C-TenantAnalysis';
import RelocateTrail from './views/D-RelocateTrail';
import TransactionAnalysis from './views/E-TransactionAnalysis';
import StabilityAnalysis from './views/F-StabilityAnalysis';
import Surrounding from './views/G-Surrounding';
import BlockTrade from './views/I-BlockTrade';
import useProjectDetailStore from './store';
import { getLastQuarter } from '@/utils/tool';
import { PageWithWatermark } from '@/components';
import AuthWrapWithTooltip from '@/components/AuthWrapWithTooltip';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import useReactShareAuthStore from '@/store/reactShareAuthStore';
import useUserEvent from '@/hooks/useUserEvent';
import IntelligentInsights from './views/H-IntelligentInsights';

type TabKey =
  | 'projectInfo'
  | 'marketPerformance'
  | 'tenantAnalysis'
  | 'relocateTrail'
  | 'stabilityAnalysis'
  | 'transactionAnalysis'
  | 'surrounding'
  | 'blockTrade'
  | 'intelligentInsights';

const tabPermissions: Record<TabKey, string> = {
  projectInfo: 'front:project:info',
  marketPerformance: 'front:project:market',
  tenantAnalysis: 'front:project:tenant',
  relocateTrail: 'front:project:relocationtrace:map',
  stabilityAnalysis: 'front:project:stability',
  transactionAnalysis: 'front:project:statistics',
  surrounding: 'front:project:compare',
  blockTrade: 'front:blocktrade:propertydetail:review',
  intelligentInsights: 'front:intelligent:project:rank',
};

const items: TabsProps['items'] = [
  {
    key: 'projectInfo',
    label: '项目信息',
    children: (
      <PageWithWatermark>
        <ProjectInfo />
      </PageWithWatermark>
    ),
  },
  {
    key: 'marketPerformance',
    label: '市场表现',
    children: (
      <PageWithWatermark>
        <MarketPerformance />
      </PageWithWatermark>
    ),
  },
  {
    key: 'tenantAnalysis',
    label: '租户结构',
    children: (
      <PageWithWatermark>
        <TenantAnalysis />
      </PageWithWatermark>
    ),
  },
  {
    key: 'relocateTrail',
    label: '迁址轨迹',
    children: <RelocateTrail />,
  },
  {
    key: 'transactionAnalysis',
    label: '成交分析',
    children: (
      <PageWithWatermark>
        <TransactionAnalysis />
      </PageWithWatermark>
    ),
  },
  {
    key: 'stabilityAnalysis',
    label: '稳定性分析',
    children: (
      <PageWithWatermark>
        <StabilityAnalysis />
      </PageWithWatermark>
    ),
  },
  {
    key: 'surrounding',
    label: '周边配套',
    children: <Surrounding />,
  },
];

const industryItems = items.filter(
  (item) => item.key !== 'transactionAnalysis' && item.key !== 'stabilityAnalysis'
);

const ProjectDetail = () => {
  const {
    getProjectDetail,
    resetIntelligentInsightsData,
    projectDetail,
    loading,
    intelligentInsightsData,
    downloadBtnText,
  } = useProjectDetailStore();
  const { data: mainFrameData } = useMainFrameContext();
  const { auth } = useReactShareAuthStore();
  const { sendUserEvent } = useUserEvent();
  const [activeTab, setActiveTab] = useState('projectInfo');

  const subMarketName =
    mainFrameData.params.type === 'business'
      ? projectDetail.locationInfo?.plateId
      : projectDetail.locationInfoVO?.submarketName;

  useEffect(() => {
    getProjectDetail(
      mainFrameData.params.type,
      mainFrameData.params.id,
      auth.buttonAuth.some((item) => item.button === tabPermissions.intelligentInsights)
    );
    if (!auth.buttonAuth.some((item) => item.button === tabPermissions.projectInfo)) {
      message.error('该项目不在您购买的权限内，如需了解更多信息，欢迎联系ruijian.data@jll.com');
    }
    return resetIntelligentInsightsData;
  }, []);

  // 是否出现交易或股权变更，是-展示投资交易tab，否-不展示
  const isChange =
    mainFrameData.params.type === 'business'
      ? projectDetail?.ownerInfo?.isChange
      : projectDetail?.ownerInfoVO?.ownershipChangeStatus;

  const hasProjPrem =
    mainFrameData.params.type === 'business'
      ? projectDetail?.hasProjPerms
      : projectDetail?.hasProjPrem;

  let tabs = mainFrameData.params.type === 'business' ? [...items] : [...industryItems];

  if (intelligentInsightsData) {
    tabs.push({
      key: 'intelligentInsights',
      label: '智能洞察',
      children: <IntelligentInsights />,
    });
  }

  tabs =
    isChange === '是'
      ? [
          ...tabs,
          {
            key: 'blockTrade',
            label: '投资交易',
            children: (
              <PageWithWatermark>
                <BlockTrade />
              </PageWithWatermark>
            ),
          },
        ]
      : tabs;

  tabs = tabs.filter(({ key }) =>
    auth.buttonAuth.some((item) => item.button === tabPermissions[key])
  );
  if (loading) {
    return null;
  }

  const hasSubmarketDetailAuth = auth.buttonAuth.some(
    (item) => item.button === 'front:submarket:overview'
  );

  const jumpToSubmarketDetail = () => {
    if (!hasSubmarketDetailAuth) {
      return;
    }
    let subMarketId;
    let city;
    if (mainFrameData.params.type === 'business') {
      subMarketId = projectDetail?.locationInfo?.subMarketId;
      city = projectDetail?.locationInfo?.cityName;
    } else {
      subMarketId = projectDetail?.locationInfoVO?.submarketId;
      city = projectDetail?.locationInfoVO?.city;
    }

    localStorage.setItem('queryTime', projectDetail.reportQuarter || getLastQuarter());
    const firstPath = location.pathname.split('/')[1];
    mainFrameData.router.push(`/${firstPath}/commercial-detail/${subMarketId}?city=${city}`);
  };

  const downloadPDF = () => {
    sendUserEvent({ button: '项目详情-项目信息下载' }, 'e_click');
    window.postMessage('downloadPDF', '*');
  };

  const tabClick = (tabName: string) => {
    const tab = tabs.find(({ key }) => key === tabName);
    if (!hasProjPrem && !['projectInfo', 'surrounding', 'intelligentInsights'].includes(tabName)) {
      message.error('该项目不在您购买的权限内，如需了解更多信息，欢迎联系ruijian.data@jll.com');
    } else {
      setActiveTab(tab!.key);
      sendUserEvent({ page: `项目详情-${tab!.label}`, nextPage: tab!.label as string }, 'e_view');
    }
  };

  return (
    <div className={classNames('projectDetail', styles.projectDetail)}>
      <div className={styles.header}>
        <div
          className={styles.goBack}
          onClick={() => {
            window.history.back();
          }}
        >
          <JLLSvg
            icon="arrow-back"
            size={20}
          />
        </div>
        <div className={styles.projectInfoContainer}>
          <div className={styles.title}>{projectDetail.baseInfo.cnName}</div>
          {subMarketName ? (
            <div
              className={classNames(styles.subtitle, { [styles.noAuth]: !hasSubmarketDetailAuth })}
              onClick={jumpToSubmarketDetail}
            >
              子市场：{subMarketName}
              {hasSubmarketDetailAuth ? (
                <JLLSvg
                  icon="right-arrow"
                  size={18}
                  color="#a4a9b0"
                />
              ) : null}
            </div>
          ) : null}
        </div>
        {activeTab === 'projectInfo' ? (
          <AuthWrapWithTooltip
            type="icon-button"
            auth={'front:project:download'}
            buttonText={downloadBtnText}
            icon="download"
            iconSize={20}
            className={styles.downloadPdfButton}
            onClick={downloadPDF}
          />
        ) : null}
      </div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: '#e30613',
            },
          },
        }}
      >
        <Tabs
          className={classNames('projectDetailTabs', styles.detailTabs)}
          destroyInactiveTabPane
          defaultActiveKey="projectInfo"
          items={tabs}
          activeKey={activeTab}
          onTabClick={tabClick}
        />
      </ConfigProvider>
    </div>
  );
};

export default ProjectDetail;
