import { jllRequest } from '@/utils/request';
import { TenantAnalysisQuarterParam, CompetitionGroup } from '../types/apiTypes';
import { TenantDataQuarterVO } from '../views/B-IndustryAnalysis/types';
import { TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';
import { CommonRequestParams } from '@/pages/F-DataAnalysis/types/apiTypes';

// 查询当前用户下的所有竞品组（不包含项目那些信息）（查询效率更高）
export async function getPlainCompetitionGroups(): Promise<CompetitionGroup[]> {
  const result = await jllRequest<CompetitionGroup[]>({
    url: '/competing/queryUserCompetitionGroupDict',
    method: 'get',
  });
  return result;
}

// 获取默认时间
export async function getCityDefaultTime(
  cityNames: string[],
  timeType: number
): Promise<{
  analyzeEndTime: string;
  analyzeStartTime: string;
}> {
  return await jllRequest({
    url: '/datatime/getCityDefaultTime',
    method: 'post',
    data: {
      cityNames,
      timeType,
    },
  });
}

// 数据分析-产业分析-成交分析数据季度
export async function getTenantAnalysisQuarter(data: TenantAnalysisQuarterParam) {
  const result = await jllRequest<TenantDataQuarterVO>({
    url: '/datatime/dataAnalysis/tenantAnalysis',
    method: 'post',
    data,
  });
  return result;
}

// /** 城市分析--产业分析——列表页面查询 */
// export function queryIndustryAnalysisList(data: IndustryAnalysisParam) {
//   return jllRequest({
//     url: '/dataAnalysis/tenantAnalysisList',
//     method: 'post',
//     data,
//   });
// }

// 产业分析-流动分析-查询图表
export async function queryTenantAnalysis(data) {
  const result = await jllRequest({
    url: '/dataAnalysis/cityIndustryAnalysisQuery',
    method: 'post',
    data,
  });
  return result;
}

/** 数据分析-产业分析&流动分析 共用接口，通过不同传参来获取不同数据 */
export async function getIndustryDealData(data: CommonRequestParams) {
  const result = await jllRequest<TableDataItem[]>({
    url: '/dataAnalysis/tenantAnalysisList',
    method: 'post',
    data,
  });
  return result;
}
