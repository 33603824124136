import { Modal } from 'jlld';
import { useEffect, useState } from 'react';
import JLLTransfer, { JLLTransferProps, TransferOptionItem } from './JLLTransfer';

export interface JLLTransferModalProps extends JLLTransferProps {
  title?: string;
  open: boolean;
  onCancel?: () => void;
  onOk: (value: TransferOptionItem[]) => void;
}

const JLLTransferModal = (props: JLLTransferModalProps) => {
  const [value, setValue] = useState<TransferOptionItem[]>(props.value || []);

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value || []);
    }
  }, [props.value]);

  return (
    <Modal
      title={props.title}
      open={props.open}
      width={420}
      okButtonProps={{ disabled: !value.length }}
      onOk={() => {
        props.onOk(value);
      }}
      onCancel={() => {
        props.onCancel?.();
      }}
      destroyOnClose
    >
      <JLLTransfer
        maxLength={props.maxLength}
        listTitle={props.listTitle}
        valueTitle={props.valueTitle}
        options={props.options}
        onChange={(checkedItems) => {
          if (!props.value) {
            setValue(checkedItems);
          }
          props.onChange?.(checkedItems);
        }}
        value={value}
      />
    </Modal>
  );
};

export default JLLTransferModal;
