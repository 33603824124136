import useCommonOptionsStore from '@/store/commonOptions';
import { JLLCascader, JLLForm } from 'jlld';

const IndustrialPickerFormItem = () => {
  const industryOptions = useCommonOptionsStore((state) => state.industryOptions);
  return (
    <JLLForm.Item
      noStyle
      name="secondaryIndustries"
    >
      <JLLCascader
        search
        className="headerFormItem"
        placeholder="行业"
        options={industryOptions}
      />
    </JLLForm.Item>
  );
};

export default IndustrialPickerFormItem;
