import useMainFrameContext from '@/hooks/useMainFrameContext';
import { ServeSidePoiType, TabKey } from '@/pages/Z-ProjectDetail/types';
import { Tabs } from 'jlld';
import { TabsProps } from 'jlld/es';
import React, { memo, useEffect, useMemo } from 'react';
import useSurroundingStore from '../../store';
import ItemList from './ItemList';
import styles from './style.module.less';
import classNames from 'classnames';

const items: TabsProps['items'] = [
  {
    key: TabKey.traffic,
    label: '交通',
  },
  {
    key: TabKey.catering,
    label: '餐饮',
  },
  {
    key: TabKey.mall,
    label: '商场',
  },
  {
    key: TabKey.entertainment,
    label: '娱乐',
  },
  {
    key: TabKey.bank,
    label: '银行',
  },
  {
    key: TabKey.hotel,
    label: '酒店',
  },
  {
    key: TabKey.project,
    label: '周边项目',
  },
];

const SurroundingInfo = memo(() => {
  const {
    currentTab,
    trafficType,
    setCurrentTab,
    queryTabData,
    setTrafficType,
    tabViewData,
    poiType,
  } = useSurroundingStore();

  const { data: mainFrameData } = useMainFrameContext();

  // 页码改变
  const onPageChange = (page: number) => {
    queryTabData(mainFrameData?.params?.type, mainFrameData?.params?.id, poiType, page);
  };

  const getTabKeyData = (key: string) => {
    const { poiType } = setCurrentTab(key as TabKey);

    // if (!tabViewData[poiType]) {
    //TODO: 可以缓存一下数据
    queryTabData(mainFrameData?.params?.type, mainFrameData?.params?.id, poiType);
    // }
    // } else {
    // }
  };

  useEffect(() => {
    getTabKeyData(currentTab);
  }, []);
  const tabItems = useMemo(() => {
    return items.map((item) => {
      if (item.key === TabKey.traffic) {
        const trafficContent = (
          <div className={styles.itemListContainer}>
            <div className={styles.trafficSwitch}>
              <div className={styles.switchButtons}>
                <div
                  className={classNames({
                    [styles.active]: trafficType === ServeSidePoiType.subway,
                  })}
                  onClick={() => {
                    setTrafficType(ServeSidePoiType.subway);
                    getTabKeyData(TabKey.traffic);
                  }}
                >
                  地铁站
                </div>
                <div
                  className={classNames({ [styles.active]: trafficType === ServeSidePoiType.bus })}
                  onClick={() => {
                    setTrafficType(ServeSidePoiType.bus);
                    getTabKeyData(TabKey.traffic);
                  }}
                >
                  公交站
                </div>
              </div>
            </div>
            <ItemList
              tabKey={item.key as TabKey}
              viewData={tabViewData[poiType]!}
              onPageChange={onPageChange}
            />
          </div>
        );

        return {
          ...item,
          children: trafficContent,
        };
      }
      return {
        ...item,
        children: (
          <ItemList
            tabKey={item.key as TabKey}
            viewData={tabViewData[poiType]!}
            onPageChange={onPageChange}
          />
        ),
      };
    });
  }, [currentTab, trafficType, tabViewData]);

  return (
    <div className={styles.poiInfo}>
      <Tabs
        className="surroundingInfoTabs"
        items={tabItems}
        activeKey={currentTab}
        onChange={getTabKeyData}
      />
    </div>
  );
});

export default SurroundingInfo;
