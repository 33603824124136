import {
  getBuildingDataExistsTime,
  getIndustryMarketingChart,
  getIndustryMarketingPerformance,
} from '../../../api';
import { getIndustryMarketingChartParams } from '@/pages/Z-ProjectDetail/types/apiTypes';

export default {
  initExistsTime: async (busId: string) => {
    const result = (await getBuildingDataExistsTime({ busId })) as any;
    const [startTime, endTime] = result;
    return {
      startTime,
      endTime,
    };
  },
  getMarketPerformance: async (params: getIndustryMarketingChartParams) => {
    const data = (await getIndustryMarketingPerformance(params)) as any;
    return {
      bookRent: [data.bookRentList, data.futureBookRentList],
      effectiveRent: [data.effectiveRentList, data.futureEffectiveRentList],
      vacancyRate: [data.vacancyRateList, data.futureVacancyRateList],
      netAbsorption: [data.netAbsorptionList, data.futureNetAbsorptionList],
    };
  },
  getPriceChartData: async (params: getIndustryMarketingChartParams) => {
    return (await getIndustryMarketingChart(params)) as any;
  },
};
