import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import { RelocateTrailMapProperty } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '../../../../../types';
import useRelocateTrailStore from '../../../store';
import { PropertyType } from '@/constants/enums';
import styles from './style.module.less';

interface Props {
  data: RelocateTrailMapProperty;
  currentPropertyId: string;
  onClose: () => void;
}

const moveInTextConfig = {
  tenantNum: '迁入租户总数：',
  selfTenantNum: '来自本项目',
  otherTenantNum: '来自其他项目',
  otherSourceTenantNum: '其他来源',
  tenantArea: '迁入租户面积：',
  industry: '迁入租户代表行业',
  tipText: '* 租户进行去重统计，[当前项目迁入租户总数] 与 [迁入来源项目租户数的总和] 可能存在差异',
  onlySameProjectTipText: '* 仅存在同项目迁址信息',
  onlyOtherProjectTipText: '* 仅存在其他来源迁址信息',
};

const moveOutTextConfig = {
  tenantNum: '迁出租户总数：',
  selfTenantNum: '去往本项目',
  otherTenantNum: '去往其他项目',
  otherSourceTenantNum: '其他去向',
  tenantArea: '迁出租户面积：',
  industry: '迁出租户代表行业',
  tipText: '* 租户进行去重统计，[当前项目迁出租户总数] 与 [迁出去向项目租户数的总和] 可能存在差异',
  onlySameProjectTipText: '* 仅存在同项目迁址信息',
  onlyOtherProjectTipText: '* 仅存在其他去向迁址信息',
};

const currentPropertyConfig = {
  [RelocateTrailDimensionType.moveIn]: moveInTextConfig,
  [RelocateTrailDimensionType.moveOut]: moveOutTextConfig,
};

const otherPropertyConfig = {
  [RelocateTrailDimensionType.moveIn]: moveOutTextConfig,
  [RelocateTrailDimensionType.moveOut]: moveInTextConfig,
};

export default (props: Props) => {
  const { data, currentPropertyId } = props;
  const { selfTenantNum, otherTenantNum, otherSourceTenantNum } = data;
  const dimension = useRelocateTrailStore((state) => state.dimension);
  const topicIndustrialArr = data.topicIndustrial?.split(',');

  const config =
    data.propertyId === currentPropertyId ? currentPropertyConfig : otherPropertyConfig;
  const renderConfig = config[dimension];

  const sourceCount = Object.entries(data).filter(
    ([key, value]) =>
      ['selfTenantNum', 'otherTenantNum', 'otherSourceTenantNum'].includes(key) && value
  ).length;

  const renderTenantNumSubText = () => {
    if (sourceCount <= 1) {
      return null;
    }
    return (
      <div className={styles.subText}>
        {selfTenantNum ? (
          <p>
            {renderConfig.selfTenantNum} {selfTenantNum}
          </p>
        ) : null}
        {sourceCount === 2 ? <div className={styles.verticalDivider} /> : null}
        {otherTenantNum ? (
          <p>
            {renderConfig.otherTenantNum} {otherTenantNum}
          </p>
        ) : null}
        {sourceCount === 3 ? <div className={styles.verticalDivider} /> : null}
        {otherSourceTenantNum ? (
          <p>
            {renderConfig.otherSourceTenantNum} {otherSourceTenantNum}
          </p>
        ) : null}
      </div>
    );
  };

  const renderTipText = () => {
    if (sourceCount >= 2) {
      return renderConfig.tipText;
    } else if (sourceCount === 1) {
      if (selfTenantNum) {
        // 仅同项目
        return renderConfig.onlySameProjectTipText;
      } else if (otherSourceTenantNum) {
        // 仅其他来源
        return renderConfig.onlyOtherProjectTipText;
      }
    } else if (otherTenantNum === 1) {
      // 仅其他项目
      return null;
    }
  };

  return (
    <div className={styles.propertyInfoCard}>
      <div
        className={styles.close}
        onClick={props.onClose}
      >
        <JLLSvg
          icon="x"
          size={20}
          color="#A4A9B0"
        />
      </div>
      <p className={styles.propertyName}>{data.propertyName}</p>
      <div className={styles.propertyStatus}>
        <div className={styles.ropertyType}>
          {data.propertyType === PropertyType.commercial ? '商业办公' : '产业办公'}
        </div>
        <div className={styles.dot} />
        <div className={styles.propertyGrade}>{data.propertyGrade}</div>
      </div>
      <div className={styles.relocateInfo}>
        <div className={styles.mainText}>
          {renderConfig.tenantNum} {data.tenantNum} 个
        </div>
        {renderTenantNumSubText()}
        <div className={classNames(styles.mainText, styles.area)}>
          {renderConfig.tenantArea} {data.tenantArea} ㎡
        </div>
        <div className={styles.mainText}>{renderConfig.industry}</div>
        <div className={styles.subText}>
          {topicIndustrialArr?.map((item, index) => {
            return (
              <>
                <p>{item}</p>
                {index !== topicIndustrialArr.length - 1 && (
                  <div className={styles.verticalDivider}></div>
                )}
              </>
            );
          })}
        </div>
      </div>
      {data.propertyId === currentPropertyId ? (
        <p className={styles.tipText}>{renderTipText()}</p>
      ) : null}
    </div>
  );
};
