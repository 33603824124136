import { jllRequest } from '@/utils/request';
import {
  QueryBlockTradeDetailParam,
  QueryTransactionDetailParam,
  TransactionDetail,
} from '../types';

// 获取投资交易明细列表
export function getBlockTradeDetailList(data: QueryBlockTradeDetailParam) {
  return jllRequest({
    url: '/blocktrade/detailList',
    method: 'POST',
    data,
  });
}

// 获取投资交易统计列表
export function getBlockTradeStatisticsList(data: QueryBlockTradeDetailParam) {
  return jllRequest({
    url: '/blocktrade/statisticsList',
    method: 'POST',
    data,
  });
}

// 获取投资交易明细
export function getBlockTradeTransactionDetail(data: QueryTransactionDetailParam) {
  return jllRequest<TransactionDetail>({
    url: '/blocktrade/transactionDetail',
    method: 'POST',
    data,
  });
}
