import { ProjectType, PropertyType } from '@/constants/enums';

const BASE_URL_BUSINESS = '/assets/map/business/';
const BASE_URL_INDUSTRY = '/assets/map/industry/';

export const styles = [
  {
    url: `${BASE_URL_BUSINESS}project3.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 3,
  },
  {
    url: `${BASE_URL_BUSINESS}project4.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 10,
  },
  {
    url: `${BASE_URL_BUSINESS}project-future.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 4,
  },
  {
    url: `${BASE_URL_INDUSTRY}industry-project.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 3,
  },
  {
    url: `${BASE_URL_INDUSTRY}industry-project-active.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 10,
  },
  {
    url: `${BASE_URL_INDUSTRY}industry-project-future.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 4,
  },
  {
    url: `${BASE_URL_BUSINESS}projectActive.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 4,
  },
  {
    url: `${BASE_URL_INDUSTRY}industryProjectActive.png`,
    anchor: [14, 14],
    size: [28, 28],
    zIndex: 4,
  },
];

export const style = {
  [PropertyType.commercial]: {
    [ProjectType.built]: 0,
    [ProjectType.future]: 2,
  },
  [PropertyType.industrial]: {
    [ProjectType.built]: 3,
    [ProjectType.future]: 5,
  },
};
