/*
 * @Author: your name
 * @Date: 2021-10-09 11:23:44
 * @LastEditTime: 2021-10-12 09:49:46
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \jll-analysis-platform\src\utils\auth.js
 */
import Cookies from 'js-cookie';

const TokenKey = 'JLL-Token';
const NameKey = 'checkname';
const XToken = 'XSRF-TOKEN';

export function getXSRFToken() {
  return Cookies.get(XToken);
}

export function syncXSRFToken() {
  return Cookies.set(XToken, getXSRFToken());
}

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: process.env.VUE_APP_DOMAIN });
}

export function removeToken() {
  Cookies.remove(TokenKey);
  return Cookies.remove(TokenKey, { domain: process.env.VUE_APP_DOMAIN });
}

export function getCookieName() {
  return Cookies.get(NameKey);
}

export function removeDataAnalysisStorage() {
  localStorage.removeItem('data-market-state-storage-240529');
  localStorage.removeItem('data-industry-analysis-storage-240529');
  localStorage.removeItem('data-deal-analysis-storage-240529');
  localStorage.removeItem('data-future-supply-storage-240529');
}

export function setCookieName(name) {
  return Cookies.set(NameKey, name, { domain: process.env.VUE_APP_DOMAIN });
}

export function removeCookieName() {
  return Cookies.remove(NameKey, { domain: process.env.VUE_APP_DOMAIN });
}
