import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import classNames from 'classnames';
import { Skeleton } from 'jlld';
import { useCity } from '@/store/reactShareSelectedStore';
import PageHeader from '@/components/PageHeader';
import MapView from './components/MapView';
import ChartView from './components/ChartView';
import TopFilter from './components/TopFilter';
import useRelocateAnalysisStore, { initRelocateAnalysis, resetState, setLoading } from './store';
import { RelocateAnalysisModeType } from '@/constants/enums';
import styles from './style.module.less';
import './style.less';

const tooltipConfig = {
  placement: 'bottomRight',
  title: (
    <p>
      迁址事件定义：租赁地址发生位置搬迁的行为 <br />
      数据统计范围：仅统计优质楼宇（商办楼宇+产办楼宇）内的租户的搬迁行为
    </p>
  ),
};

const iconConfig = {
  icon: 'help',
  size: 20,
  color: '#637177',
};

const FormLoadingSkeletion = () => {
  return (
    <div className={styles.topFormsWrap}>
      <div
        className={styles.topForm}
        style={{
          overflow: 'hidden',
        }}
      >
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton.Input
            key={index}
            style={{ width: 168, marginRight: '14px' }}
            active
          />
        ))}
        <Skeleton.Input
          style={{ width: 280, marginRight: '14px' }}
          active
        />
      </div>
    </div>
  );
};

const RelocateAnalysisPage = () => {
  const city = useCity();

  const { displayMode, loading } = useRelocateAnalysisStore(
    useShallow((state) => ({
      displayMode: state.displayMode,
      loading: state.loading,
    }))
  );

  useEffect(() => {
    try {
      initRelocateAnalysis(city);
    } catch (e) {
      setLoading(false);
    }
    return resetState;
  }, []);

  return (
    <div className={classNames('page relocateAnalysis', styles.relocateAnalysisPage)}>
      <PageHeader
        title="搬迁分析"
        showIconToolTip
        tooltipConfig={tooltipConfig}
        iconConfig={iconConfig}
      >
        <div className={styles.headerContent}>
          {loading ? <FormLoadingSkeletion /> : <TopFilter />}
        </div>
      </PageHeader>
      {displayMode === RelocateAnalysisModeType.map ? <MapView /> : <ChartView />}
    </div>
  );
};

export default RelocateAnalysisPage;
