import { JLLMap } from 'jlld';
import { memo } from 'react';
import classNames from 'classnames';
import { useShallow } from 'zustand/react/shallow';
import { NORMALSTYLE } from '@/constants';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { Marker } from '@uiw/react-amap-marker';
import useSurroundingStore from '../../store';
import useProjectDetailStore from '@/pages/Z-ProjectDetail/store';
import SurroundingCircle from '../../../../components/SurroundingCircle';
import style from './style.module.less';

let timer: NodeJS.Timeout | null = null;

const Markers = memo(() => {
  const { tabViewData, currentTab, activeTitle, poiType, setActiveTitle, setAnimateTitle } =
    useSurroundingStore(
      useShallow((state) => ({
        tabViewData: state.tabViewData,
        activeTitle: state.activeTitle,
        currentTab: state.currentTab,
        poiType: state.poiType,
        setActiveTitle: state.setActiveTitle,
        setAnimateTitle: state.setAnimateTitle,
      }))
    );
  const markerDataList = tabViewData[poiType]?.dataList;

  if (!markerDataList) {
    return null;
  }

  return markerDataList?.map((item, index) => (
    <Marker
      position={item.location}
      className={style.centerPoint}
      key={item.id}
      anchor="center"
      zIndex={activeTitle === item.title ? 1000 : 10}
      onMouseOver={() => {
        setActiveTitle(item.title);
      }}
      onMouseOut={() => {
        setActiveTitle('');
      }}
    >
      <div
        className={classNames(style.centerPoint, { [style.active]: activeTitle === item.title })}
        onClick={() => {
          setAnimateTitle(item.title);
          const $item = document.querySelector(
            `.poi-list-${currentTab} .poi-title-${index}`
          ) as HTMLElement;
          if ($item) {
            $item.scrollIntoView({ behavior: 'smooth' });
          }
          timer = setTimeout(() => {
            clearTimeout(timer!);
            setAnimateTitle('');
          }, 2100);
        }}
      >
        <img src="/assets/map/poi-position.png" />
        <img src="/assets/map/poi-position-active.png" />
        <div className={style.centerPointText}>{item.title}</div>
      </div>
    </Marker>
  ));
});

const CircleBorderText = memo(() => {
  const projectDetail = useProjectDetailStore((state) => state.projectDetail);
  const circleBorderTextPosition = [
    projectDetail.locationInfo.longitude + 0.0105,
    projectDetail.locationInfo.latitude,
  ];

  if (!circleBorderTextPosition) {
    return null;
  }

  return (
    <Marker
      position={circleBorderTextPosition}
      anchor="center"
      zIndex={1000}
    >
      <div className={style.circleBorderText}>周边 1km</div>
    </Marker>
  );
});

const MapView = () => {
  const { data } = useMainFrameContext();
  const projectDetail = useProjectDetailStore((state) => state.projectDetail);
  const center = [projectDetail?.locationInfo.longitude, projectDetail?.locationInfo.latitude];

  return (
    <JLLMap
      mapStyle={NORMALSTYLE}
      center={center!}
    >
      {center && (
        <Marker
          position={center!}
          anchor="center"
        >
          <img
            className={style.centerPoint}
            src={
              data.params.type === 'business'
                ? '/assets/map/business/centerPoint.png'
                : '/assets/map/industry/centerPoint.png'
            }
          />
        </Marker>
      )}
      <Markers />
      <SurroundingCircle center={center} />
      <CircleBorderText />
    </JLLMap>
  );
};

export default MapView;
