import { useEffect } from 'react';
import useUserEvent from '@/hooks/useUserEvent';
import StatisticsTable from './StatisticsTable';
import TopForm from './TopForm';
import useTradeStatisticsStore from '../../store/tradeStatisticsStore';
import styles from './style.module.less';

const TradeStatistics = () => {
  const { queryStatisticsList, statisticsQueryType } = useTradeStatisticsStore();

  const { sendUserEvent } = useUserEvent();

  useEffect(() => {
    sendUserEvent({ page: '投资交易统计' }, 'e_view');
  }, []);

  useEffect(() => {
    queryStatisticsList();
  }, [statisticsQueryType]);

  return (
    <div className={styles.tradeStatistics}>
      <TopForm />
      <StatisticsTable />
    </div>
  );
};

export default TradeStatistics;
