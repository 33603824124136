export const barStyles = [
  {
    itemStyle: {
      borderWidth: 1,
      borderType: 'solid',
    },
  },
  {
    itemStyle: {
      borderWidth: 1,
      borderType: 'dashed',
      borderColor: '#FC888F',
      color: 'rgba(252, 136, 143, 0.6)',
    },
  },
];

export const lineStyles = [
  {
    lineStyle: { type: 'solid' },
    itemStyle: {
      borderWidth: 0,
    },
  },
  {
    lineStyle: { type: 'dashed' },
    itemStyle: {
      borderWidth: 0,
    },
  },
];
