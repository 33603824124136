import useMainFrameContext from '@/hooks/useMainFrameContext';
import { thousandSplitNumber } from '@/utils/tool';
import { PropertyType } from '@/constants/enums';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';
import useReactShareAuthStore from '@/store/reactShareAuthStore';
import { LeadingIndustrialItem } from '@/pages/D-IndustrialAnalysis/types/apiTypes';
import styles from './style.module.less';
import { Props } from './types';
import { useMapContext } from '@uiw/react-amap-map';
import { autoFit } from 'jlld/es/JLLMap/untils';

export default (props: Props) => {
  const { data } = props;
  const { map } = useMapContext();

  const { data: routerContext } = useMainFrameContext();

  const { formData, setCenter } = useIndustrialStore((state) => ({
    formData: state.formData,
    setCenter: state.setCenter,
  }));

  const { auth } = useReactShareAuthStore();
  const hasDetailAuth = (auth as any).buttonAuth?.some(
    (item: any) => item.button === 'front:project:info'
  );

  const onDetailClick = () => {
    routerContext.router.push(
      `/industrial-analysis/project-detail/${
        data?.propertyType === PropertyType.industrial ? 'industry' : 'business'
      }/${data?.id}`
    );
    setCenter(new (AMap.LngLat as any)(...data.center));
    // 未知原因，跳转路由后，地图会自动偏移
    setTimeout(() => {
      autoFit(map!);
    }, 1000);
  };

  const industryTopText = ['', '租户一级行业Top3', '新租租户一级行业Top3', '退租租户一级行业Top3'];

  return (
    <div className={styles.propertyInfoCard}>
      <div className={styles.propertyName}>{data?.name}</div>
      <div className={styles.propertyInfo}>
        <div className={styles.tenant}>
          <div className={styles.item}>
            <strong>{thousandSplitNumber(data?.tenantCount)}</strong>
            <span className={styles.dimension}>租户数（家）</span>
          </div>
          <div className={styles.item}>
            <strong>{thousandSplitNumber(data?.leaseArea?.toFixed(0))}</strong>
            <span className={styles.dimension}>租户租赁总面积（㎡）</span>
          </div>
        </div>
        <div className={styles.industryTop}>
          <div className={styles.item}>
            <span className={styles.title}>{industryTopText[formData.leaseType]}</span>
            <span className={styles.dimension}>面积占比</span>
            <span className={styles.dimension}>个数占比</span>
          </div>
          <div className={styles.content}>
            {data?.leadingIndustries.map((industry: LeadingIndustrialItem) => (
              <div
                key={industry.name}
                className={styles.item}
              >
                <span className={styles.dimension}>{industry.name}</span>
                <span className={styles.percent}>{industry.areaPercentage}%</span>
                <span className={styles.percent}>{industry.countPercentage}%</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {hasDetailAuth ? (
        <div className={styles.propertyController}>
          <div
            className={styles.detailButton}
            onClick={onDetailClick}
          >
            查看详情
          </div>
        </div>
      ) : null}
    </div>
  );
};
