export const businessInfomation = [
  {
    title: 'IFS 综合大厦',
    class: 'pane-title no-bottom project-title',
    children: [
      {
        subTitle: '基本信息',
        id: '223344',
        type: 'image',
        labelWidth: '100px',
        imageConfig: [],
        previewList: [],
        key1: 'baseInfo',
        key2: 'proBannerFiles',
      },
      {
        subTitle: '基本信息',
        id: '22334455',
        type: 'form',
        showIcon: true,
        iconCtx: '办公面积和项目等级取该项目最近季度数据进行展示',
        formConfig: [
          {
            labelWidth: '80px',
            label: '交付时间',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'dueTime',
          },
          {
            labelWidth: '90px',
            label: '项目中文名',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'cnName',
          },
          {
            labelWidth: '90px',
            label: '项目英文名',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'enName',
          },
          {
            labelWidth: '80px',
            label: '项目等级',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'level',
          },
          {
            labelWidth: '90px',
            label: 'LEED等级',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'leedLevel',
          },
          {
            labelWidth: '80px',
            label: '办公面积(平方米)',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'officeBuildArea',
          },
          {
            labelWidth: '80px',
            label: '总部楼宇',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'isHeadquarters',
          },
        ],
      },
    ],
  },
  {
    title: '业主信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        // labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '业主名称',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'ownerName',
          },
          {
            labelWidth: '90px',
            label: '业主背景',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'ownerBg',
          },
        ],
      },
      {
        type: 'form',
        labelWidth: '180px',
        formConfig: [
          {
            labelWidth: '100px',
            label: '开发商中文名',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'developerName',
          },
          {
            labelWidth: '90px',
            label: '开发商背景',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'developerBg',
          },
        ],
      },
      {
        type: 'form',
        labelWidth: '180px',
        formConfig: [
          {
            labelWidth: '100px',
            label: '物业管理公司',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'propertyCompany',
          },
          {
            labelWidth: '130px',
            label: '物业管理公司背景',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'propertyBg',
          },
          {
            labelWidth: '130px',
            label: '物业管理当期费用',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'propertyMoney',
          },
          {
            labelWidth: '130px',
            label: '物业管理当期说明',
            type: 'textarea',
            value: '',
            key1: 'ownerInfo',
            key2: 'propertyRemark',
          },
          {
            labelWidth: '80px',
            label: '业权形态',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'titleForm',
          },
          {
            labelWidth: '180px',
            label: '是否出现交易或股权变更',
            type: 'input',
            value: '',
            key1: 'ownerInfo',
            key2: 'isChange',
          },
        ],
      },
    ],
  },
  {
    title: '硬件信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        // labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '80px',
            label: '建筑高度(米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'buildingHeight',
          },
          {
            labelWidth: '90px',
            label: '建筑楼层数(层)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'buildingFloorNum',
          },
          {
            labelWidth: '100px',
            label: '是否有转换层',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'conversionLayer',
          },
          {
            labelWidth: '100px',
            label: '办公最高楼层(层)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'refugeFloors',
          },
          {
            labelWidth: '60px',
            label: '得房率(%)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'roomRate',
          },
          {
            labelWidth: '90px',
            label: '标准层层高(米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'standardLayerHeight',
          },
          {
            labelWidth: '100px',
            label: '标准层净层高(米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'netFloorHeight',
          },
          {
            labelWidth: '80px',
            label: '自用面积(平方米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'selfUseArea',
          },
          {
            labelWidth: '90px',
            label: '标准层面积(平方米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'standardFloorArea',
          },
          {
            labelWidth: '120px',
            label: '标准层网格地板(厘米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'networkFloor',
          },
          {
            labelWidth: '90px',
            label: '标准层荷载(kn/平方米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'standardLoad',
          },
        ],
      },
      {
        type: 'form',
        labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '100px',
            label: '是否有交易层',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'isExistTradeLayer',
          },
          {
            labelWidth: '80px',
            label: '电力系统',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'powerSystem',
          },
          {
            labelWidth: '100px',
            label: '电力系统描述',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'powerSystemDesc',
          },
          {
            labelWidth: '80px',
            label: '大堂高度(米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'lobbyHeight',
          },
          {
            labelWidth: '80px',
            label: '客梯品牌',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'passengerElevatorBrand',
          },
          {
            labelWidth: '130px',
            label: '单部电梯服务面积(平方米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'passengerElevatorCover',
          },
          {
            labelWidth: '80px',
            label: '客梯数量(个)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'passengerElevatorNum',
          },
          {
            labelWidth: '80px',
            label: '货梯数量(个)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'freightElevatorNum',
          },
          {
            labelWidth: '80px',
            label: '空调系统',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'airConditionerSystem',
          },
          {
            labelWidth: '100px',
            label: '空调运行时间',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'airTime',
          },
        ],
      },
      {
        type: 'form',
        labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '80px',
            label: '新风系统',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'freshAirSystem',
          },
          {
            labelWidth: '100px',
            label: '新风系统描述',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'freshAirSystemDesc',
          },
          {
            labelWidth: '50px',
            label: '幕墙',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'curtainWall',
          },
          {
            labelWidth: '100px',
            label: '男卫生间蹲位',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'manBathroomSquat',
          },
          {
            labelWidth: '100px',
            label: '女卫生间蹲位',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'womanBathroomSquat',
          },
          {
            labelWidth: '80px',
            label: '周边地铁',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'subway',
          },
          {
            labelWidth: '180px',
            label: '行政卫生间/无障碍卫生间(个)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'executiveToilet',
          },
          {
            labelWidth: '80px',
            label: '其它配套',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'other',
          },
          {
            labelWidth: '80px',
            label: '车位数量(个)',
            type: 'input',
            value: '',
            key1: 'hardwareInfo',
            key2: 'parkingSpaceNum',
          },
          {
            labelWidth: '60px',
            label: '停车费',
            type: 'textarea',
            value: '',
            key1: 'hardwareInfo',
            key2: 'parkingFee',
          },
        ],
      },
    ],
  },
];

export const industryInfomation = [
  {
    title: 'IFS 综合大厦',
    class: 'pane-title no-bottom project-title',
    children: [
      {
        subTitle: '基本信息',
        id: '223344',
        type: 'image',
        labelWidth: '100px',
        imageConfig: [],
        previewList: [],
        key1: 'baseInfo',
        key2: 'proBannerFiles',
      },
      {
        subTitle: '基本信息',
        id: '22334455',
        type: 'form',
        showIcon: true,
        iconCtx: '办公面积和项目等级取该项目最近季度数据进行展示',
        // labelWidth: 'auto',
        // labelWidth: '90px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '项目中文名',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'propertyNameCn',
          },
          {
            labelWidth: '90px',
            label: '项目英文名',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'propertyNameEn',
          },
          {
            labelWidth: '80px',
            label: '项目等级',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'propertyGrade',
          },
          {
            labelWidth: '80px',
            label: '交付时间',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'completionQuarter',
          },
          {
            labelWidth: '80px',
            label: '交付状态',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'completionStatus',
          },
          {
            labelWidth: '90px',
            label: 'LEED等级',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'leedGrade',
          },
          {
            labelWidth: '80px',
            label: '用地性质',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'landuseNature',
          },
          {
            labelWidth: '80px',
            label: '占地面积（㎡）',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'floorArea',
          },
          {
            labelWidth: '80px',
            label: '容积率',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'floorAreaRatio',
          },
          {
            labelWidth: '80px',
            label: '总建筑面积（㎡）',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'officeArea',
          },
          {
            labelWidth: '80px',
            label: '地上建筑面积（㎡）',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'aboveOfficeArea',
          },
          {
            labelWidth: '80px',
            label: '地下建筑面积（㎡）',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'undergroundOfficeArea',
          },
          {
            labelWidth: '80px',
            label: '楼栋数（栋）',
            type: 'input',
            value: '',
            key1: 'baseInfo',
            key2: 'buildingNumber',
          },
        ],
      },
    ],
  },
  {
    title: '业主信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        labelWidth: '180px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '运营方名称',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'operatorName',
          },
          {
            labelWidth: '90px',
            label: '运营方背景',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'operatorOrigin',
          },
          {
            labelWidth: '100px',
            label: '开发商中文名',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'developerName',
          },
          {
            labelWidth: '90px',
            label: '开发商背景',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'developerOrigin',
          },
          {
            labelWidth: '90px',
            label: '开发商类型',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'developerType',
          },
          {
            labelWidth: '100px',
            label: '物业管理公司',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'managementNamanagementName',
          },
          {
            labelWidth: '130px',
            label: '物业管理公司背景',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'managementOrigin',
          },
          {
            labelWidth: '130px',
            label: '物业管理费用（元/㎡/月）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'managementFeeDescription',
          },
          {
            labelWidth: '80px',
            label: '业权形态',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'ownershipStructure',
          },
          {
            labelWidth: '180px',
            label: '是否出现交易或股权变更',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'ownershipChangeStatus',
          },
        ],
      },
    ],
  },
  {
    title: '硬件信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '办公楼栋数(栋)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'officeBuildingNumber',
          },
          {
            labelWidth: '90px',
            label: '办公楼建筑面积(平方米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'builderArea',
          },
          {
            labelWidth: '90px',
            label: '标准层层高(米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'typicalfloorCeilingheight',
          },
          {
            labelWidth: '80px',
            label: '标准层净层高(米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'typicalfloorNetCeilingheight',
          },
          {
            labelWidth: '80px',
            label: '标准层网络地板(厘米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'typicalfloorRaisedFloor',
          },
          {
            labelWidth: '80px',
            label: '标准层荷载(kn/平方米)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'typicalfloorLoading',
          },
          {
            labelWidth: '90px',
            label: '电力系统',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'powerSystem',
          },
          {
            labelWidth: '80px',
            label: '电力系统描述',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'powerSystemDesc',
          },
          {
            labelWidth: '80px',
            label: '空调系统',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'airConditionSystem',
          },
          {
            labelWidth: '80px',
            label: '空调运行时间',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'airconditionOperationhour',
          },
          {
            labelWidth: '80px',
            label: '新风系统',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'freshairSystem',
          },
          {
            labelWidth: '80px',
            label: '新风系统描述',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'freshairSystemDesc',
          },
          {
            labelWidth: '80px',
            label: '车位数量(个)',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'parkingSpaceNum',
          },
          {
            labelWidth: '80px',
            label: '停车费',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'parkingFee',
          },
          {
            labelWidth: '80px',
            label: '其他硬件信息',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'hardwareOther',
          },
        ],
      },
    ],
  },
  {
    title: '产业方向',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '官方产业招引方向',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'attractionDirection',
          },
          {
            labelWidth: '80px',
            label: '产业实际招引方向1',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'actualIndustryDirection1',
          },
          {
            labelWidth: '80px',
            label: '产业实际招引方向2',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'actualIndustryDirection2',
          },
          {
            labelWidth: '80px',
            label: '项目涵盖产品形态',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'productForm',
          },
          {
            labelWidth: '80px',
            label: '商业配套空间形态',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'spaceForm',
          },
          {
            labelWidth: '90px',
            label: '主力商业品牌',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'commercialBrand',
          },
          {
            labelWidth: '80px',
            label: '公共配套服务',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'publicSupportingServices',
          },
          {
            labelWidth: '80px',
            label: '商务配套服务',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'businessSupportingServices',
          },
          {
            labelWidth: '80px',
            label: '是否有孵化器',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'hasIncubator',
          },
          {
            labelWidth: '90px',
            label: '是否有专业协会挂牌',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'hasProfessionalAssociation',
          },
          {
            labelWidth: '80px',
            label: '是否有高校研究机构挂牌',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'hasResearchInstitution',
          },
          {
            labelWidth: '80px',
            label: '是否对入住企业资质要求',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'hasQualificationRequirements',
          },
          {
            labelWidth: '80px',
            label: '产业扶持政策',
            type: 'input',
            value: '',
            key1: 'industrialDirectionInfoVO',
            key2: 'industrySupportPolicy',
          },
        ],
      },
    ],
  },
];

export const buildingInfomation = [
  {
    title: '基础信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        labelWidth: '180px',
        formConfig: [
          {
            labelWidth: '80px',
            label: '楼栋名称',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'buildingName',
          },
          {
            labelWidth: '90px',
            label: '楼层数（层）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'floorNumber',
          },
          {
            labelWidth: '100px',
            label: '办公最高楼层',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'totalStorey',
          },
          {
            labelWidth: '100px',
            label: '楼栋办公面积（㎡）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'officeArea',
          },
          {
            labelWidth: '90px',
            label: '首层高度（m）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'firstFloorHeight',
          },
          {
            labelWidth: '90px',
            label: '标准层层高（m）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'typicalfloorCeilingheight',
          },
          {
            labelWidth: '180px',
            label: '标准层面积（m²）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'typicalfloorArea',
          },
          {
            labelWidth: '90px',
            label: '是否总部载体',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'headquartersCarrier',
          },
        ],
      },
    ],
  },
  {
    title: '业主信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        labelWidth: '180px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '运营方名称',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'operatorName',
          },
          {
            labelWidth: '100px',
            label: '运营方背景',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'operatorOrigin',
          },
          {
            labelWidth: '90px',
            label: '现业主名称',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'landlordName',
          },
          {
            labelWidth: '90px',
            label: '现业主背景',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'landlordOrigin',
          },
          {
            labelWidth: '90px',
            label: '物业管理费用（元/㎡/月）',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'managementFee',
          },
          {
            labelWidth: '180px',
            label: '物业管理费说明(是否包含空调费)',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'managementFeeDescription',
          },
          {
            labelWidth: '80px',
            label: '业权形态',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'ownershipStructure',
          },
          {
            labelWidth: '100px',
            label: '是否出现交易或股权变更',
            type: 'input',
            value: '',
            key1: 'ownerInfoVO',
            key2: 'ownershipChangeStatus',
          },
        ],
      },
    ],
  },
  {
    title: '硬件信息',
    class: 'pane-title',
    children: [
      {
        type: 'form',
        labelWidth: '120px',
        formConfig: [
          {
            labelWidth: '90px',
            label: '客梯个数（个）',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'passengerElevatorNum',
          },
          {
            labelWidth: '90px',
            label: '客梯承重（KG）',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'passengerElevatorLoadBearing',
          },
          {
            labelWidth: '90px',
            label: '客梯品牌',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'passengerElevatorBrand',
          },
          {
            labelWidth: '80px',
            label: '货梯个数（个）',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'cargoElevatorNum',
          },
          {
            labelWidth: '80px',
            label: '货梯承重（KG）',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'cargoElevatorLoadBearing',
          },
          {
            labelWidth: '80px',
            label: '单部电梯服务面积',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'passengerElevatorCover',
          },
          {
            labelWidth: '90px',
            label: '幕墙',
            type: 'input',
            value: '',
            key1: 'hardwareInfoVO',
            key2: 'curtainWall',
          },
        ],
      },
    ],
  },
];
