import ExcelJS, { Border, Borders } from 'exceljs';
import {
  DataStateType,
  DataStateTypeMap,
  TableColumnKey,
  UnitType,
  tableTitleConfig,
  SummaryShowType,
} from './config';
import { TableDataItem } from './types/settingTypes';
import { getUnit } from './method';
import { generateRentValue } from '@/components/UnitExchange/method';

const commonBorder: Border = { style: 'thin', color: { argb: 'FFD4D4D4' } };

const downloadExcel = (data: ExcelJS.Buffer, fileName: string) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.xlsx`;
  a.click();
  URL.revokeObjectURL(url);
};

const stringToNumber = (str: string | number | null | undefined) => {
  if (!str) {
    return '--';
  }
  if (/^[[(].*[\])]$/.test(`${str}`)) {
    return str;
  }
  str = String(str);
  if (str.includes(',')) {
    return Number(str.replace(/,/g, ''));
  }

  if (!Number.isNaN(Number(str))) {
    return Number(str);
  }
  return str;
};

const stringToNumberList = (list: (string | number | undefined | null)[]) => {
  return list.map((item) => stringToNumber(item));
};

const exportDataAnalysisTable = (
  fileName: string,
  tableData: {
    data: Record<string, TableDataItem[]> | null;
    summaryData: TableDataItem | null;
  } | null,
  dimensionValues: DataStateType[],
  dataIndicator: TableColumnKey[],
  unitType: UnitType,
  summaryShowType: SummaryShowType[]
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileName);

  const headNameList: string[] = [];
  const rowKeyList: string[] = [];
  dimensionValues
    .map((item) => DataStateTypeMap[item])
    .map((headName, index) => {
      headNameList.push(headName);

      switch (index) {
        case 1: {
          rowKeyList.push('dynamicColTwo');
          break;
        }
        case 2: {
          rowKeyList.push('dynamicColThree');
          break;
        }
      }
    });

  dataIndicator.map((item) => {
    const { title } = tableTitleConfig[item];
    headNameList.push(`${title}${getUnit(tableTitleConfig[item], unitType)}`);
    rowKeyList.push(item);
  });

  if (tableData) {
    const { data, summaryData } = tableData;
    console.log(data, summaryData);
    console.log(headNameList, rowKeyList);

    // 设置第一行表头名，以及对应的key
    worksheet.columns = headNameList.map((header, index) => {
      return {
        header,
        key: rowKeyList[index],
        width: 20,
      };
    });
    worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'right' };
    // 所有worksheet的单元格右对齐
    for (let i = 1; i <= headNameList.length; i++) {
      worksheet.getColumn(i).alignment = { vertical: 'middle', horizontal: 'right' };
    }

    // dimensionValues.length 得单元格左对齐
    for (let i = 1; i <= dimensionValues.length; i++) {
      worksheet.getColumn(i).alignment = { vertical: 'middle', horizontal: 'left' };
    }

    if (summaryData && summaryShowType.includes(SummaryShowType.total)) {
      // dimensionValues长度转为['总体汇总' * dimensionValues.length]
      const summaryRow = Array.from({ length: dimensionValues.length }, () => '总体汇总');
      const rowStringList = [
        ...summaryRow,
        ...stringToNumberList(
          dataIndicator.map((key) => {
            if (tableTitleConfig[key]?.controlByCommonConfig) {
              return generateRentValue(
                summaryData[key],
                tableTitleConfig[key].controlByCommonConfig!,
                true
              );
            }
            return summaryData[key];
          })
        ),
      ];
      worksheet.addRow(rowStringList);
      worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'right' };

      // 合并总体汇总单元格
      worksheet.mergeCells(2, 1, 2, dimensionValues.length);
      worksheet.getCell(2, 1).alignment = { vertical: 'middle', horizontal: 'left' };
      for (let i = 1; i <= headNameList.length; i++) {
        worksheet.getCell(2, i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFEEEFF0' },
        };
        const border: Partial<Borders> = {
          top: commonBorder,
          bottom: commonBorder,
        };
        if (headNameList.length) {
          border.right = commonBorder;
        }
        worksheet.getCell(2, i).border = border;
      }
    }
    if (data) {
      let currentStartRowIndex =
        summaryData && summaryShowType.includes(SummaryShowType.total) ? 3 : 2;
      let currentEndRowIndex = currentStartRowIndex - 1;
      let hasSummary = false;
      Object.entries(data).forEach(([key, dataList]) => {
        console.log(key, dataList);
        dataList.forEach((rowDataList) => {
          currentEndRowIndex += 1;

          const rowStringList = [
            key,
            ...stringToNumberList(
              rowKeyList.map((key) => {
                if (tableTitleConfig[key]?.controlByCommonConfig) {
                  return generateRentValue(
                    rowDataList[key],
                    tableTitleConfig[key].controlByCommonConfig!,
                    true
                  );
                }
                return rowDataList[key];
              })
            ),
          ];
          hasSummary = rowStringList[1] === '分类汇总';

          worksheet.addRow(rowStringList);
        });
        if (hasSummary) {
          worksheet.mergeCells(currentEndRowIndex, 2, currentEndRowIndex, dimensionValues.length);
          for (let i = 1; i <= headNameList.length; i++) {
            worksheet.getCell(currentEndRowIndex, i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFEEEFF0' },
            };
            const border: Partial<Borders> = {
              top: commonBorder,
              bottom: commonBorder,
            };
            if (i === 2) {
              border.left = commonBorder;
            }
            if (headNameList.length) {
              border.right = commonBorder;
            }
            worksheet.getCell(currentEndRowIndex, i).border = border;
          }

          // 第一个单元格设置为白色
          worksheet.getCell(currentEndRowIndex, 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFFFF' },
          };
        }
        worksheet.mergeCells(currentStartRowIndex, 1, currentEndRowIndex, 1);
        currentStartRowIndex = currentEndRowIndex + 1;
      });
    }
  }

  workbook.xlsx.writeBuffer().then((data) => {
    downloadExcel(data, fileName);
  });
};

export const exportDataAnalysisDetailTable = (
  fileName: string,
  rows: Record<string, any>[],
  columns: any[]
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(fileName);
  worksheet.columns = columns.map((item) => {
    return {
      header: item.title!,
      key: item.key!,
      width: 20,
    };
  });
  worksheet.addRows(rows);
  workbook.xlsx.writeBuffer().then((data) => {
    downloadExcel(data, fileName);
  });
};

export default exportDataAnalysisTable;
