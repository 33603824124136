import { getPolygonAreaCenter } from '@/utils/mapTools';
import { CityData } from '../types';
import { jllRequest } from '@/utils/request';

// 查询某个城市下的子市场列表和行政区
export async function getChildAdminByCity(cityName: string, multi?: boolean) {
  let result = await jllRequest<CityData[]>({
    url: `/city/getChildAdminByCity?cityNames=${cityName}`,
    method: 'post',
  });

  result = result.map((dataItem: any) => {
    dataItem.childMarkets = dataItem.childMarkets.map((item: any) => {
      let boundaries = item.latLon && JSON.parse(item.latLon);
      boundaries = Array.isArray(boundaries) ? boundaries : [];

      return {
        id: item.childId,
        name: item.childName,
        boundaries: boundaries,
        center: getPolygonAreaCenter(boundaries),
      };
    });
    return dataItem;
  });

  // result.data[0].childMarkets = result.data[0].childMarkets.map((item: any) => {
  //   let boundaries = item.latLon && JSON.parse(item.latLon);
  //   boundaries = Array.isArray(boundaries) ? boundaries : [];

  //   return {
  //     id: item.childId,
  //     name: item.childName,
  //     boundaries: boundaries,
  //     center: getPolygonAreaCenter(boundaries),
  //   };
  // });

  if (multi) {
    return result;
  }

  return result[0];
}

export async function getCitySubMarketAndDistrict(cityList: string[]) {
  const result = await jllRequest({
    url: '/location/citySubMarketAndDistrict',
    method: 'post',
    data: {
      cityList,
    },
  });

  const data = result.map((dataItem: any) => {
    dataItem.subMarketList = dataItem.subMarketList.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        boundaries: item.boundary,
        center: getPolygonAreaCenter(item.boundary),
      };
    });
    return dataItem;
  });

  return result;
}
