import BlockTitle, { SwitchType } from '../BlockTitle';
import TenantPieChart from '../TenantPieChart';

const TenantSource = (props: { changeType?: () => void; switchType?: SwitchType }) => {
  return (
    <div>
      <BlockTitle
        title="租户来源分布"
        showSwitch
        switchType={props.switchType!}
        onChangeType={props.changeType!}
      />
      <TenantPieChart dataType={props.switchType!} />
    </div>
  );
};

export default TenantSource;
