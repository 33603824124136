import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getStabilityAnalysis, getTenantExistsTime } from '../../api';
import { ChartData } from '../../../../components/Charts/chartOptions';

const generateEChartData = (data: any, name: string): ChartData | null => {
  if (!data) {
    return null;
  }
  return {
    xAxis: data.map((item: any) => item.year).reverse(),
    data: [
      {
        name,
        data: data.reverse().map((item: any) => ({
          name: item.year,
          value: item.radio,
        })),
      },
    ],
  };
};

type BarData = Record<
  'resource' | 'industry' | 'area',
  {
    label: string;
    value: number;
  }[]
>;

const generateLineDataList = (
  data: any
): {
  label: string;
  value: number;
}[] => {
  return data.map((item: any) => ({
    label: item.name,
    value: Number(item.radio),
  }));
};

const generateBarData = (data: any): BarData | null => {
  if (!data) {
    return null;
  }

  return {
    resource: generateLineDataList(data.xsource),
    industry: generateLineDataList(data.xindustry),
    area: generateLineDataList(data.areaSegment),
  };
};

export interface State {
  existQuarterList: string[];
  quarter: string | null;
  viewData: any;
  renewTopChartData: ChartData | null;
  withdrawalTopChartData: ChartData | null;
  renewBottomBarData: BarData | null;
  withdrawalBottomBarData: BarData | null;
}

const initState: State = {
  existQuarterList: [],
  quarter: null,
  viewData: {},
  renewTopChartData: null,
  withdrawalTopChartData: null,
  renewBottomBarData: null,
  withdrawalBottomBarData: null,
};

const useStabilityStore = create(
  immer(
    combine(initState, (set, get) => ({
      setQuarter: (quarter: string) => {
        set({ quarter });
      },

      resetState() {
        set({...initState});
      },
      queryViewData: async (projectId: string, quarter: string) => {
        const viewData = await getStabilityAnalysis(projectId, quarter);

        set({
          renewTopChartData: generateEChartData(viewData.renewalHistograms, '续租率'),
          withdrawalTopChartData: generateEChartData(viewData.surrenderHistograms, '退租率'),
          renewBottomBarData: generateBarData(viewData.leaseRenewal),
          withdrawalBottomBarData: generateBarData(viewData.surrender),
          viewData,
        });
      },
      initData: async (projectId: string) => {
        const existQuarterList = await getTenantExistsTime(projectId);
        if (existQuarterList.length > 0) {
          const quarter = existQuarterList[0];
          set((state) => {
            state.existQuarterList = existQuarterList;
            state.quarter = quarter;
          });
          (get() as any).queryViewData(projectId, quarter);
        } else {
          (get() as any).resetState();
        }
      },
      queryExistQuarterList: async (projectId: string) => {
        const result = await getTenantExistsTime(projectId);
        set((state) => {
          state.existQuarterList = result;
        });
      },
    }))
  )
);

export default useStabilityStore;
