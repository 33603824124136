import { Marker } from '@uiw/react-amap-marker';
import styles from './style.module.less';

interface Props {
  position: [number, number];
}

export default (props: Props) => {
  return (
    <>
      <Marker
        position={props?.position}
        anchor="center"
        visiable={!!props?.position}
        zIndex={1000}
        offset={[0, 50]}
      >
        <div className={styles.firstEnterTip}>打点数字代表迁址租户数量</div>
      </Marker>
    </>
  );
};
