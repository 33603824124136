import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ViewSettings } from '@/pages/F-DataAnalysis/types/settingTypes';
import {
  DataStateType,
  DataType,
  ShowDataTabType,
  SummaryShowType,
  TimeType,
  UnitType,
  customTenantArea,
} from '../../../config';
import { getCityDefaultTime } from '@/pages/F-DataAnalysis/api';
import dayjs from 'dayjs';
import { deepClone, getSearchParams } from '@/utils/tool';
import { getDefaultProperty } from '@/pages/F-DataAnalysis/method';

export interface SettingState
  extends ViewSettings<{
    analyzeOneRowHead: DataStateType;
    indicatorProportion: 'areaProportion' | 'numProportion';
  }> {
  multiSelectorOptions: {
    customTenantArea?: string[];
  };
}

export const initValues = {
  leaseType: DataType.newDeal,
};

const state: SettingState = {
  topFormValues: {
    ...initValues,
    propertyType: getDefaultProperty(),
  },
  tableFormValues: {
    dataIndicator: ['areaTotal', 'areaProportionSign', 'numTotal', 'numProportionSign'],
    unitType: UnitType.area,
    summaryShowType: [SummaryShowType.category, SummaryShowType.total],
  },
  dimensionValues: [DataStateType.oneIndustryName, DataStateType.submarket],
  chartSettingFormValues: {
    analyzeOneRowHead: DataStateType.oneIndustryName,
    indicatorProportion: 'areaProportion',
  },
  tableActiveTab: ShowDataTabType.table,
  multiSelectorOptions: {},
};

const useDealAnalysisSettingStore = create<SettingState>()(
  persist(() => deepClone(state), {
    name: 'data-deal-analysis-storage-240529',
  })
);

export const resetSettingStore = () => {
  set(deepClone(state));
};

const set = useDealAnalysisSettingStore.setState;
export const get = useDealAnalysisSettingStore.getState;

export const setSettingData = (data: Partial<SettingState>) => {
  set((state) => {
    return {
      ...state,
      ...data,
    };
  });
};

export const getDefaultTime = async (cityList: string[]) => {
  if (!cityList.length) {
    return {};
  }
  const { analyzeStartTime, analyzeEndTime } = await getCityDefaultTime(cityList, TimeType.other);
  return { analyzeStartTime, analyzeEndTime };
};

export const initTopFormValues = async () => {
  const topFormValues = get().topFormValues;
  const search = getSearchParams();
  const values = {
    ...topFormValues,
    customTenantAreaType: '1',
    customTenantArea: ['待定', ...customTenantArea],
    propertyType: getDefaultProperty(),
  };

  let { analyzeStartTime, analyzeEndTime } = await getDefaultTime(values.citys!);
  // 本地设置不能在服务端返回返回外
  if (analyzeEndTime) {
    // 说明本地保存的是年度, 服务端也转年度

    if (values.analyzeEndTime && !values.analyzeEndTime.includes('Q')) {
      analyzeStartTime = dayjs(analyzeStartTime).format('YYYY');
      analyzeEndTime = dayjs(analyzeEndTime).format('YYYY');
    }

    if (
      !values.analyzeStartTime ||
      dayjs(analyzeStartTime).isBefore(dayjs(values.analyzeStartTime))
    ) {
      values.analyzeStartTime = analyzeStartTime;
    }

    if (!values.analyzeEndTime || dayjs(analyzeEndTime).isAfter(dayjs(values.analyzeEndTime))) {
      values.analyzeEndTime = analyzeEndTime;
    }
  } else {
    values.analyzeStartTime = '';
    values.analyzeEndTime = '';
  }

  if (search.competitionGroupId) {
    values.competitionGroupId = search.competitionGroupId;
  }

  setSettingData({ topFormValues: values });
};

export default useDealAnalysisSettingStore;
