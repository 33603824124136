import { thousandSplitNumber } from '@/utils/tool';
import { ColumnsType } from 'jlld/es/table';
import { TransactionTop, TransactionBottom } from '../type';

export const topColumns: ColumnsType<TransactionTop> = [
  {
    title: '交易总价(万元)',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: 160,
    render: (text) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '销售面积(㎡)',
    dataIndex: 'saleArea',
    key: 'saleArea',
    width: 160,
    render: (text) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '交易单价(万元/㎡)',
    dataIndex: 'unitPrice',
    key: 'unitPrice ',
    width: 160,
    render: (text) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '交易方式',
    dataIndex: 'mode',
    key: 'mode',
    width: 160,
  },
  {
    title: '交易股权(%)',
    dataIndex: 'tradingEquity',
    key: 'tradingEquity',
    width: 160,
  },
  {
    title: '交易楼层',
    dataIndex: 'floor',
    key: 'floor',
    width: 160,
  },
  {
    title: '交易楼栋',
    dataIndex: 'building',
    key: 'building',
    width: 160,
  },
  {
    title: '交易月份',
    dataIndex: 'month',
    key: 'month',
    width: 160,
  },
];

export const bottomColumns: ColumnsType<TransactionBottom> = [
  {
    title: '买方名称',
    dataIndex: 'buyerCompany',
    key: 'buyerCompany',
    width: 160,
  },
  {
    title: '买方企业来源',
    dataIndex: 'buyerType',
    key: 'buyerType',
    width: 160,
  },
  {
    title: '买方实际操作公司（工商注册名）',
    dataIndex: 'buyerActualOperatingCompany',
    key: 'buyerActualOperatingCompany',
    width: 320,
  },
  {
    title: '卖方名称',
    dataIndex: 'sellerCompany',
    key: 'sellerCompany',
    width: 160,
  },
  {
    title: '卖方企业来源',
    dataIndex: 'sellerType',
    key: 'sellerType',
    width: 160,
  },
  {
    title: '卖方实际操作公司（工商注册名）',
    dataIndex: 'sellerActualOperatingCompany',
    key: 'sellerActualOperatingCompany',
    width: 320,
  },
];
