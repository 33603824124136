import React, { useContext } from 'react';

export interface MainFrameContextSchema {
  data: any;
}

export const MainFrameContext = React.createContext<MainFrameContextSchema>({ data: null });

const useMainFrameContext = () => useContext(MainFrameContext);

export default useMainFrameContext;
