import { memo, useEffect, useState } from 'react';
import { useMapContext } from '@uiw/react-amap-map';

export interface HeatMapDataItem {
  id: string;
  lng: number;
  lat: number;
  count: number;
}

interface HeatmapProps {
  max?: number;
  dataList: HeatMapDataItem[];
  options: {
    radius?: number; // 	热力图中单个点的半径，默认：30，单位：pixel
    gradient?: Record<number, string>; // 	热力图的渐变区间，热力图按照设置的颜色及间隔显示热力图，例{0.4:'rgb(0, 255, 255)',0.85:'rgb(100, 0, 255)',},其中 key 表示间隔位置，取值范围： [0,1] ，value 为颜色值。默认：heatmap.js标准配色方案
    opacity?: [number, number]; // 	热力图透明度区间数组，取值范围 [0,1] ，0表示完全透明，1表示不透明，默认： [0,1]
    zooms?: [number, number]; // 	支持的缩放级别范围，取值范围 [3-20] ，默认： [3,20]
    visible?: boolean; // 	是否可见
    zIndex?: number; // 	热力图层在地图上的叠加顺序，默认 130
    ['3d']?: {
      HeatMap3DOptions: any; // 3D热力图属性
      heightScale: number; // 高度缩放因子，表示在单位高度上的缩放比例， 默认为 1
      heightBezier: number[]; // 影响高度平滑度的贝塞尔曲线因子，默认 [0.5, 0, 1, 0.5] ,
      gridSize: number; // 取样精度，越小越平滑，越大性能越高
    };
  };
}
const HeatMap = memo((props: HeatmapProps) => {
  const { map } = useMapContext();
  const [heatMap, setHeatMap] = useState<AMap.HeatMap>();

  useEffect(() => {
    if (map) {
      let instance: AMap.HeatMap;
      map.plugin(['AMap.HeatMap'], () => {
        instance = new AMap.HeatMap(map, props.options);
        setHeatMap(instance);
      });

      return () => {
        try {
          if (map.getContainer() && instance) {
            instance.setMap(null);
          }
        } catch (e) {
          console.error(e);
        }
      };
    }
  }, [map]);

  useEffect(() => {
    if (heatMap && props.dataList?.length) {
      heatMap.setDataSet({ data: props.dataList, max: props.max || 100 });
    }
  }, [heatMap, props.dataList]);

  useEffect(() => {
    heatMap?.setOptions(props.options);
  }, [props.options]);

  return null;
});

export default HeatMap;
