import { Table, TableColumnsType } from 'jlld';
import useFutureSupplyStore, { queryFutureDetail, setLoading } from '../../store';
import useFutureSupplySettingStore from '../../store/settingStore';
import { getFutureSupplyDetailRes } from '../../api';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import { thousandSplitNumber } from '@/utils/tool';
import { PropertyType } from '@/constants/enums';
import useTableLayout from '@/hooks/useTableLayout';
import './style.less';

export const columns: TableColumnsType<getFutureSupplyDetailRes> = [
  {
    title: '项目名称',
    dataIndex: 'projectName',
    key: 'projectName',
    sorter: (a, b) =>
      a.projectName.toLocaleUpperCase() > b.projectName.toLocaleUpperCase() ? 1 : -1,
  },
  {
    title: '交付时间',
    dataIndex: 'dueTime',
    key: 'dueTime',
    sorter: (a, b) => (a.dueTime.toLocaleUpperCase() > b.dueTime.toLocaleUpperCase() ? 1 : -1),
  },
  {
    title: '办公楼建筑面积(㎡)',
    dataIndex: 'officeBuildArea',
    key: 'officeBuildArea',
    render: (text) => thousandSplitNumber(text),
  },
  {
    title: '城市',
    dataIndex: 'cityName',
    key: 'cityName',
    sorter: (a, b) => (a.cityName.toLocaleUpperCase() > b.cityName.toLocaleUpperCase() ? 1 : -1),
  },
  { title: '行政区', dataIndex: 'administrativeRegions', key: 'administrativeRegions' },
  { title: '子市场', dataIndex: 'subMarket', key: 'subMarket' },
  { title: '项目等级', dataIndex: 'projectLevel', key: 'projectLevel' },
  { title: '是否总部楼宇', dataIndex: 'isHeadquarters', key: 'isHeadquarters' },
  { title: '开发商背景', dataIndex: 'devType', key: 'devType' },
  { title: 'LEED等级', dataIndex: 'leedLevel', key: 'leedLevel' },
];

const Detail = () => {
  const detailData = useFutureSupplyStore((state) => state.detailData);
  const loading = useFutureSupplyStore((state) => state.loading);
  const { topFormValues } = useFutureSupplySettingStore((state) => ({
    topFormValues: state.topFormValues,
  }));

  useOnload(queryFutureDetail, ShowDataTabType.detail, setLoading);
  const { scrollY } = useTableLayout({
    containerClassName: 'el-container',
    tableClassName: 'futureDetailTable',
    excludeHeight: 80,
  });

  const getColumns = () => {
    if (topFormValues.propertyType[PropertyType.industrial]?.length > 0) {
      return columns.filter((item) => item.title !== '是否总部楼宇');
    }
    return columns;
  };

  return (
    <Table
      className="futureDetailTable"
      rowKey="projectName"
      scroll={{ y: scrollY }}
      columns={getColumns()}
      dataSource={detailData!}
      pagination={false}
      loading={loading.detail}
    />
  );
};

export default Detail;
