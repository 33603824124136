import { enterpriseSourceOptions } from '@/constants/options';
import { JLLForm, JLLMultiSelector } from 'jlld';

const EnterpriseFormItem = () => {
  return (
    <JLLForm.Item
      name="tenantSource"
      noStyle
    >
      <JLLMultiSelector
        options={enterpriseSourceOptions}
        placeholder="选择租户来源"
      />
    </JLLForm.Item>
  );
};

export default EnterpriseFormItem;
