import { CommonRequestParams } from '@/pages/F-DataAnalysis/types/apiTypes';
import { MarketPerformanceTimeRange, MarketPerformanceTimeRangeParam } from '../types';
import { jllRequest } from '@/utils/request';
import { TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';

// 数据分析-市场表现-时间范围
export async function getMarketPerformanceTimeRange(data: MarketPerformanceTimeRangeParam) {
  return await jllRequest<MarketPerformanceTimeRange>({
    url: '/datatime/dataAnalysis/marketPerformance',
    method: 'post',
    data,
  });
}

// 数据分析-市场表现
export async function getMarketTableData(data: CommonRequestParams) {
  const result = await jllRequest<TableDataItem[]>({
    url: '/dataAnalysis/marketPerformanceList',
    method: 'post',
    data,
  });
  return result;
}

// 数据分析-市场表现-图表
export async function getMarketAnalysisChart(data: CommonRequestParams) {
  const result = await jllRequest<TableDataItem[]>({
    url: '/dataAnalysis/marketAnalysisChart',
    method: 'post',
    data,
  });
  return result;
}
