import { useRef, useImperativeHandle, forwardRef } from 'react';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';
import useDealAnalysisStore, { queryDealAnalysisChart, setLoading } from '../../store';
import useDealAnalysisSettingStore from '../../store/settingStore';
import DoubleBarChart from '../../../../components/DoubleBarChart';
import { ShowDataTabType } from '../../../../config';

const Chart = forwardRef((props, ref) => {
  const doubleBarChartRef = useRef(null);
  useImperativeHandle(ref, () => ({
    downloadChart: doubleBarChartRef?.current?.downloadChartImg,
  }));
  useOnload(queryDealAnalysisChart, ShowDataTabType.chart, setLoading);
  const chartData = useDealAnalysisStore((state) => state.chartData);
  const settingStore = useDealAnalysisSettingStore((state) => state);

  // if (loading) {
  //   return null;
  // }

  return (
    <DoubleBarChart
      ref={doubleBarChartRef}
      chartData={chartData}
      valueType={settingStore.chartSettingFormValues?.indicatorProportion}
      downloadImgName="流动分析"
    />
  );
});

export default Chart;
