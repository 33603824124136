import { useState, useEffect, useLayoutEffect } from 'react';
import { Table, TableColumnType } from 'jlld';
import ColumnHeaderWithSort from '@/components/ColumnHeaderWithSort';
import useTradeStatisticsStore from '../../../store/tradeStatisticsStore';
import { thousandSplitNumber } from '@/utils/tool';
import styles from '../style.module.less';
import './style.less';
import { fitTable } from '../../utils';

interface DataType {
  key: string;
  statisticsName: string;
  count: string;
  saleArea: string;
  totalPrice: string;
  unitPrice: string;
}

const StatisticsTable = () => {
  const [sortKey, setSortKey] = useState('statisticsName');
  const [sortAsc, setSortAsc] = useState(false);

  const {
    statisticsData,
    queryStatisticsList,
    statisticsQueryType,
    statisticsOrder,
    setStatisticsOrder,
  } = useTradeStatisticsStore();

  const sortIconClick = (key: string, asc: boolean) => {
    const order = [
      {
        asc: asc,
        column: key,
      },
    ];
    setSortKey(key);
    setSortAsc(asc);
    setStatisticsOrder(order);
    queryStatisticsList({
      orders: order,
    });
  };

  const renderTitle = (title: string, key: string, arrowAlign?: string) => {
    return (
      <ColumnHeaderWithSort
        title={title}
        currentKey={key}
        sortKey={sortKey}
        sortAsc={sortAsc}
        arrowAlign={arrowAlign}
        sortIconClick={sortIconClick}
      />
    );
  };

  const columns: TableColumnType<DataType>[] = [
    {
      title: statisticsQueryType === '1' ? renderTitle('时间', 'statisticsName', 'right') : '城市',
      dataIndex: 'statisticsName',
      key: 'statisticsName',
    },
    {
      title: renderTitle('交易个数', 'count'),
      dataIndex: 'count',
      key: 'count',
      align: 'right',
      render: (text) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: renderTitle('交易面积(万㎡)', 'saleArea'),
      dataIndex: 'saleArea',
      key: 'saleArea',
      align: 'right',
      render: (text) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: renderTitle('交易金额(万元)', 'totalPrice'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      align: 'right',
      render: (text) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: renderTitle('交易单价(万元/㎡)', 'unitPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      align: 'right',
      render: (text) => {
        return thousandSplitNumber(text, ' ');
      },
    },
  ];

  const paginationChange = (page: number, pageSize: number) => {
    queryStatisticsList({
      orders: [
        {
          asc: sortAsc,
          column: sortKey,
        },
      ],
      current: page,
      size: pageSize,
    });
  };

  const pagination = {
    current: statisticsData.current,
    pageSize: statisticsData.size,
    pageSizeOptions: [15, 50, 100],
    total: statisticsData.total,
    showSizeChanger: true,
    onChange: paginationChange,
    defaultPageSize: 15,
    showTotal: (total: number) => `共 ${total} 条`,
  };

  useEffect(() => {
    const { asc, column } = statisticsOrder[0];
    setSortKey(column);
    setSortAsc(asc);
  }, [statisticsOrder]);

  useLayoutEffect(() => {
    fitTable(statisticsData.records?.length !== 0);
  }, [statisticsData.records]);

  return (
    <Table
      className={styles.detailTable}
      rowClassName={styles.tableRow}
      columns={columns}
      dataSource={statisticsData.records}
      bordered
      rowKey={'statisticsName'}
      scroll={{
        x: 600,
        y: document.getElementsByClassName('ant-tabs-content-holder')[0]?.clientHeight - 175 || 500,
      }}
      pagination={pagination}
    />
  );
};

export default StatisticsTable;
