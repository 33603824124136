import classNames from 'classnames';
import MapView from './components/MapView';
import SurroundingInfo from './components/SurroundingInfo';
import styles from './style.module.less';
import { useEffect } from 'react';
import useSurroundingStore from './store';

const Surrounding = () => {
  const reset = useSurroundingStore((state) => state.reset);

  useEffect(() => reset, []);

  return (
    <div className={classNames('surroundingView', styles.surroundingView)}>
      <MapView />
      <SurroundingInfo />
    </div>
  );
};

export default Surrounding;
