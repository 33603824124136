import { Form, JLLSelect } from 'jlld';
import { useRef } from 'react';
import {
  ShowDataTabType,
  tabOptions,
  industryAnalysisChartTypeList,
  dealAnalysisIndicators,
  indicatorProportionOptions,
  industryTransferList,
  SummaryShowType,
} from '../../config';
import { Chart, Table, TopForm } from './components';
import useIndustrySettingStore, { SettingState, setSettingData } from './store/settingStore';
import useIndustryAnalysisStore, { queryCurrentTabData, queryIndustryAnalysisChart } from './store';
import styles from '../../style.module.less';
import { CommonTableWrap } from '../../components';
import exportDataAnalysisTable from '../../exportTable';
import { ChartRef } from '../D-FutureSupply/components/Chart';

const IndustryAnalysis = () => {
  const settingStore = useIndustrySettingStore((state) => state);
  const loading = useIndustryAnalysisStore((state) => state.loading);
  const chartRef = useRef<ChartRef>(null);

  const tabItems = {
    [ShowDataTabType.table]: <Table />,
    [ShowDataTabType.chart]: <Chart ref={chartRef} />,
  };

  const onChange =
    (key: keyof SettingState, query = true) =>
    (value: SettingState[keyof SettingState]) => {
      setSettingData({ [key]: value });

      if (query) {
        queryCurrentTabData();
      }
    };

  return (
    <div className={styles.viewContainer}>
      <TopForm tabModuleName="产业分析" />
      <CommonTableWrap
        loading={loading.topForm}
        tableLoading={loading.table}
        chartLoading={loading.chart}
        tabModuleName="产业分析"
        tabOptions={tabOptions}
        dataIndicatorOptions={dealAnalysisIndicators}
        dismensionList={industryTransferList}
        dimensionValues={settingStore.dimensionValues}
        tableFormValues={settingStore.tableFormValues}
        activeTab={settingStore.tableActiveTab}
        downloadAuth="front:city:analyze:tenant:export"
        onDimensionChange={(values) => {
          if (values.length === 1) {
            onChange(
              'tableFormValues',
              false
            )({
              ...settingStore.tableFormValues,
              summaryShowType: [SummaryShowType.total],
            });
          }
          if (values.length > 1) {
            const { summaryShowType } = settingStore.tableFormValues;
            onChange(
              'tableFormValues',
              false
            )({
              ...settingStore.tableFormValues,
              summaryShowType: summaryShowType?.includes(SummaryShowType.category)
                ? summaryShowType
                : [...summaryShowType, SummaryShowType.category],
            });
          }
          onChange('dimensionValues')(values);
        }}
        onTableColumnChange={onChange('tableFormValues')}
        onTabChange={onChange('tableActiveTab', false)}
        onDownloadTable={() => {
          settingStore.tableActiveTab === 'chart'
            ? chartRef?.current.downloadChart()
            : exportDataAnalysisTable(
                '产业分析',
                useIndustryAnalysisStore.getState().tableData,
                settingStore.dimensionValues,
                settingStore.tableFormValues.dataIndicator,
                settingStore.tableFormValues.unitType,
                settingStore.tableFormValues.summaryShowType
              );
        }}
        customControl={
          settingStore.tableActiveTab === ShowDataTabType.chart && (
            <Form
              layout="inline"
              initialValues={settingStore.chartSettingFormValues}
              onValuesChange={(_, chartSettingFormValues) => {
                onChange('chartSettingFormValues', false)(chartSettingFormValues);
                queryIndustryAnalysisChart();
              }}
            >
              <Form.Item
                label="一级分类轴"
                name="analyzeOneRowHead"
              >
                <JLLSelect
                  style={{ width: 172 }}
                  options={industryAnalysisChartTypeList}
                />
              </Form.Item>
              <Form.Item
                label="指标占比"
                name="indicatorProportion"
                style={{ marginLeft: 24 }}
              >
                <JLLSelect
                  style={{ width: 172 }}
                  options={indicatorProportionOptions}
                />
              </Form.Item>
            </Form>
          )
        }
      >
        {tabItems[settingStore.tableActiveTab]}
      </CommonTableWrap>
    </div>
  );
};

export default IndustryAnalysis;
