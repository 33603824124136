import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getBlockTradeStatisticsList } from '../api';
import { QueryBlockTradeDetailParam } from '../types';
import { getValuesFromDataOrState } from '@/utils/storeTools';
import useLeftFormStore from './leftFormStore';

interface State {
  current: number;
  size: number;
  statisticsData: any;
  statisticsQueryType: string;
  statisticsOrder: any[];
}

const initState: State = {
  current: 1,
  size: 15,
  statisticsData: {},
  statisticsQueryType: '1',
  statisticsOrder: [
    {
      asc: false,
      column: 'statisticsName',
    },
  ],
};

const useTradeStatisticsStore = create(
  immer(
    combine(initState, (set, get) => ({
      queryStatisticsList: async (data?: QueryBlockTradeDetailParam) => {
        const { current, size, statisticsOrder, statisticsQueryType } = getValuesFromDataOrState(
          ['current', 'size', 'statisticsQueryType', 'statisticsOrder'],
          get(),
          data,
          set
        );
        const leftFormQueryParams = useLeftFormStore.getState().leftFormQueryParams;
        const statisticsData = await getBlockTradeStatisticsList({
          ...data,
          orders: statisticsOrder,
          statisticsType: statisticsQueryType,
          current,
          size,
          ...(leftFormQueryParams as any),
        });
        set({
          statisticsData,
        });
        return statisticsData;
      },

      queryStatisticsDownloadList: async (data: QueryBlockTradeDetailParam) => {
        return await getBlockTradeStatisticsList(data);
      },

      setStatisticsQueryType: (type: string) => {
        set({
          statisticsQueryType: type,
        });
      },

      setStatisticsOrder: (order: any[]) => {
        set({
          statisticsOrder: order,
        });
      },

      setCurrent: (current: number) => {
        set({
          current,
        });
      },
      resetTradeStatistics: () => {
        set({ ...initState });
      },
    }))
  )
);

export default useTradeStatisticsStore;
