export const itemColor = [
  '#EF838A',
  '#F5B788',
  '#875C97',
  '#9A98C5',
  '#E79D9D',
  '#AFD4D1',
  '#B3ACD7',
  '#F291C2',
  '#ACC2D7',
  '#6DB1AB',
  '#F9DD8A',
  '#D6A9DA',
  '#D1BFB3',
  '#D96512',
  '#B0D7AC',
  '#D8D8D9',
  '#529993',
  '#5A4C9E',
  '#4C759E',
  '#DFAA0B',
  '#9D47A4',
  '#D31875',
  '#239EC7',
  '#539E4C',
  '#937058',
  '#C0BFBF',
];

export const lineStyles = [
  {
    lineStyle: { type: 'solid' },
    itemStyle: {
      borderWidth: 0,
    },
  },
  {
    lineStyle: { type: 'dashed' },
    itemStyle: {
      borderWidth: 0,
    },
  },
  {
    lineStyle: { type: 'solid' },
    itemStyle: {
      borderWidth: 0,
    },
  },
];

export const barStyles = [
  {
    itemStyle: {
      borderWidth: 1,
      // color: this.$chartMainColor,
      borderType: 'solid',
    },
  },
  {
    itemStyle: {
      borderWidth: 1,
      borderType: 'dashed',
      borderColor: '#FC888F', // this.$chartMainColor,
      color: 'rgba(252, 136, 143, 0.6)',
    },
  },
];
