import { JLLDatePicker } from 'jlld';

export interface AutoRangeDatePikcerProps {
  className?: string;
  minMax?: [string, string];
  quarterList?: [string, string];
  yearList?: [string, string];
  value?: [string, string];
  onChange?: (value: [string, string]) => void;
  typeChangedValue?: [string, string];
}

const AutoRangeDatePikcer = (props: AutoRangeDatePikcerProps) => {
  const startTime = props.value?.[0];
  const type = startTime?.includes('Q') ? 'quarter' : 'year';
  let minMax;
  if (props.minMax) {
    minMax = props.minMax;
  } else {
    minMax = type === 'quarter' ? props.quarterList : props.yearList;
  }

  return (
    <JLLDatePicker.JLLQuarterPicker
      isRange
      type={type}
      minMaxDate={minMax}
      typeChangedValue={props.typeChangedValue}
      className={props.className}
      value={props.value!}
      onChange={props.onChange!}
      rangePickerProps={{
        allowClear: false,
      }}
    />
  );
};

export default AutoRangeDatePikcer;
