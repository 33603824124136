import styles from './style.module.less';
import ReactECharts from 'echarts-for-react';
import { ChartData, getBarOptions, getLineOptions } from './chartOptions';
import { memo, useMemo, useState } from 'react';
import { JLLSvg, Tooltip } from 'jlld';
import classNames from 'classnames';
import Empty from '../Empty';

interface ChartsProps {
  title: string;
  tips: string;
  data: ChartData | null;
  // TODO:  需要deepMerge
  config?: any;
}

const LineAndBarECharts = memo((props: ChartsProps) => {
  const [chartInstance, setChartInstance] = useState<ReactECharts | null>(null);
  const [chartType, setChartType] = useState<'line' | 'bar'>('line');

  const option = useMemo(() => {
    if (!chartInstance) {
      return {};
    }

    if (chartType === 'line') {
      return getLineOptions({
        chartData: props.data,
        eChartInstance: chartInstance?.getEchartsInstance(),
        config: props.config,
      });
    }

    if (chartType === 'bar') {
      return getBarOptions({
        chartData: props.data,
        eChartInstance: chartInstance?.getEchartsInstance(),
        config: props.config,
      });
    }
  }, [chartType, props.data, props.config, chartInstance]);

  return (
    <div className={styles.charts}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span>{props.title}</span>
          <Tooltip
            title={props.tips}
            placement="topLeft"
            overlayClassName="projectDetailStabilityHelpTooltip"
          >
            <div className={styles.iconWrap}>
              <JLLSvg icon="help" />
            </div>
          </Tooltip>
        </div>
        <div className={styles.switch}>
          {['line', 'bar'].map((item) => {
            const isActive = chartType === item;
            return (
              <div
                key={item}
                className={classNames(styles.switchItem, { [styles.active]: isActive })}
                onClick={() => {
                  setChartType(item as 'line' | 'bar');
                }}
              >
                <JLLSvg
                  icon={item}
                  style={{
                    fill: chartType === item ? '#fff' : '#a5a9af',
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.eChartContainer}>
        {props.data ? (
          <ReactECharts
            ref={setChartInstance}
            option={option}
            notMerge
            lazyUpdate
          />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
});

export default LineAndBarECharts;
