import { useState } from 'react';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import BuildingList from '@/components/BuildingList';
import BasicInfo from './components/BasicInfo';
import GridInfo from './components/GridInfo';
import useProjectDetailStore from '../../store';
import useProjectInfoStore from './store';
import styles from './style.module.less';

const ProjectInfo = () => {
  const { data: mainFrameData } = useMainFrameContext();
  const { buildingDetail } = useProjectDetailStore();
  const { getBuildingInfo } = useProjectInfoStore();
  const [showBuildingInfo, setShowBuildingInfo] = useState(false);

  return (
    <div className={styles.projectDetailView}>
      {mainFrameData.params.type === 'industry' ? (
        <BuildingList
          projectId={mainFrameData.params.id}
          projectName={''}
          busId={mainFrameData.params.id}
          buildingList={buildingDetail?.buildingManagementList || []}
          onChange={(id) => {
            if (id !== mainFrameData.params.id) {
              getBuildingInfo(id);
              setShowBuildingInfo(true);
            } else {
              setShowBuildingInfo(false);
            }
          }}
          className={styles.industryBuildings}
        />
      ) : null}
      <BasicInfo
        type={mainFrameData.params.type}
        showBuilding={showBuildingInfo}
      />
      <GridInfo
        type={mainFrameData.params.type}
        showBuilding={showBuildingInfo}
      />
    </div>
  );
};

export default ProjectInfo;
