export const commercialLegends = [
  {
    name: '已交付',
    color: '#2B59E2',
  },
  {
    name: '未来项目',
    color: '#8DA6EF',
  },
];
export const industryLegends = [
  {
    name: '已交付',
    color: '#006E93',
  },
  {
    name: '未来项目',
    color: 'rgba(0, 110, 147, 0.50)',
  },
];
