import { Table, TableColumnType } from 'jlld';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { thousandSplitNumber } from '@/utils/tool';
import useTransactionDetailStore from '../../../store/transactionDetailStore';
import styles from '../style.module.less';
import '../style.less';

interface DataType {
  propertyNameCn: string;
  propertyNameEn: string;
  firstLevelCategory: string;
  secondLevelCategory: string;
  area: string;
  totalArea: string;
  city: string;
  district: string;
  submarketName: string;
  completionYear: string;
  renovationYear: string;
  address: string;
}

const jumpToProjectDetail = (record: any, data: any) => {
  if (record.propertyType !== 3) {
    data.router.push(
      `/block-trade/block-trade-transaction-detail/${data.params.transactionId}/project-detail/${
        record.propertyType === 2 ? 'industry' : 'business'
      }/${record.id}`
    );
  }
};

const getColumn = (data: any): TableColumnType<DataType>[] => {
  return [
    {
      title: '序号',
      dataIndex: '',
      key: '',
      width: 60,
      render: (text, record, index) => `${index + 1}`,
    },
    {
      title: '项目名',
      dataIndex: 'propertyNameCn',
      key: 'propertyNameCn',
      width: 200,
    },
    {
      title: '项目英文名',
      dataIndex: 'propertyNameEn',
      key: 'propertyNameEn',
      width: 200,
    },
    {
      title: '一级分类',
      dataIndex: 'firstLevelCategory',
      key: 'firstLevelCategory',
      width: 88,
    },
    {
      title: '二级分类',
      dataIndex: 'secondLevelCategory',
      key: 'secondLevelCategory',
      width: 88,
    },
    {
      title: '占地面积(万㎡)',
      dataIndex: 'area',
      key: 'area',
      width: 144,
      render: (text) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: '总建筑面积(万㎡)',
      dataIndex: 'totalArea',
      key: 'totalArea',
      width: 144,
      render: (text) => {
        return thousandSplitNumber(text, ' ');
      },
    },
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      width: 90,
    },
    {
      title: '行政区',
      dataIndex: 'district',
      key: 'district',
      width: 200,
    },
    {
      title: '子市场',
      dataIndex: 'submarketName',
      key: 'submarketName',
      width: 200,
    },
    {
      title: '建成年份',
      dataIndex: 'completionYear',
      key: 'completionYear',
      width: 88,
    },
    {
      title: '翻新年份',
      dataIndex: 'renovationYear',
      key: 'renovationYear',
      width: 88,
    },
    {
      title: '项目位置',
      dataIndex: 'address',
      key: 'address',
      width: 240,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (_, record) => (
        <p
          className="actionText"
          onClick={() => {
            jumpToProjectDetail(record, data);
          }}
        >
          {record.propertyType === 3 ? '-' : '详情'}
        </p>
      ),
    },
  ];
};

const PropertyInfo = () => {
  const { transactionDetail } = useTransactionDetailStore();
  const { data } = useMainFrameContext();

  return (
    <div className={styles.propertyInfo}>
      <p className={styles.blockTitle}>项目信息</p>
      <Table
        className={styles.detailTable}
        rowClassName={styles.tableRow}
        columns={getColumn(data)}
        dataSource={transactionDetail.propertyList}
        bordered
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{
          x: 800,
          // y:
          //   document.querySelector('.page')?.clientHeight -
          //     document.getElementById('TransactionInfo')?.clientHeight -
          //     350 || 500,
        }}
      />
    </div>
  );
};

export default PropertyInfo;
