import {
  resetSettingStoreDealAnalysis,
  resetSettingStoreFutureSupply,
  resetSettingStoreIndustryAnalysis,
  resetSettingStoreMarketState,
  resetStoreDealAnalysis,
  resetStoreFutureSupply,
  resetStoreIndustryAnalysis,
  resetStoreMarketState,
} from '@/pages/F-DataAnalysis/store';
import { removeDataAnalysisStorage } from '@/utils/auth';
import useMainStore from './main';
import { reset } from './commonOptions';

(window as any).resetResetStore = () => {
  reset();
  useMainStore.getState().leaveSystem();
  removeDataAnalysisStorage();
  resetSettingStoreMarketState();
  resetStoreMarketState();
  resetSettingStoreIndustryAnalysis();
  resetStoreIndustryAnalysis();
  resetSettingStoreDealAnalysis();
  resetStoreDealAnalysis();
  resetSettingStoreFutureSupply();
  resetStoreFutureSupply();
};
