import { ReactNode } from 'react';
import styles from './style.module.less';
import Transaction from '../Transaction';
import Property from '../Property';
import { BlockTradeDetail } from '../type';

const TradeDetailBlock = (props: { title: string; children: ReactNode }) => {
  return (
    <div className={styles.tradeDetailBlock}>
      <div className={styles.blockTitle}>{props.title}</div>
      {props.children}
    </div>
  );
};

export default (props: { data: BlockTradeDetail }) => {
  const {
    building,
    buyerActualOperatingCompany,
    buyerCompany,
    buyerType,
    floor,
    mode,
    month,
    propertyList,
    remark,
    saleArea,
    sellerActualOperatingCompany,
    sellerCompany,
    sellerType,
    time,
    totalPrice,
    tradingEquity,
    unitPrice,
  } = props.data;
  return (
    <div className={styles.tradeDetailCard}>
      <div className={styles.title}>{time}</div>
      <div className={styles.content}>
        <TradeDetailBlock title="交易信息">
          <Transaction
            topData={[
              { month, totalPrice, saleArea, unitPrice, mode, tradingEquity, floor, building },
            ]}
            bottomData={[
              {
                buyerCompany,
                buyerActualOperatingCompany,
                buyerType,
                sellerCompany,
                sellerActualOperatingCompany,
                sellerType,
              },
            ]}
            remark={remark}
          />
        </TradeDetailBlock>
        <TradeDetailBlock title="项目信息">
          <Property data={propertyList} />
        </TradeDetailBlock>
      </div>
    </div>
  );
};
