import useReactShareAuthStore from '@/store/reactShareAuthStore';

export default () => {
  const { auth } = useReactShareAuthStore();
  const dealPerArr = ['front:city:analyze:deal', 'front:deal:details:query'];
  const futurePerArr = [
    'front:dataanalysis:futuresupply',
    'front:dataanalysis:futuresupply:export',
    'front:dataanalysis:futuresupply:details:query',
    'front:dataanalysis:futuresupply:details:export',
  ];

  const permissions = (auth as any)?.buttonAuth?.map((item) => item.button);
  const hasMarketAuth = permissions?.includes('front:city:analyze:market');
  const hasIndustryAuth = permissions?.includes('front:city:analyze:tenant');
  const hasDealAuth = permissions?.some((item: any) => dealPerArr.includes(item));
  const hasFutureAuth = permissions?.some((item: any) => futurePerArr.includes(item));

  const options = [];

  if (hasMarketAuth) {
    options.push({
      value: 'market-state',
      label: '市场表现',
    });
  }
  if (hasIndustryAuth) {
    options.push({
      value: 'industry-analysis',
      label: '产业分析',
    });
  }
  if (hasDealAuth) {
    options.push({
      value: 'deal-analysis',
      label: '流动分析',
    });
  }
  if (hasFutureAuth) {
    options.push({
      value: 'future-supply',
      label: '未来供应',
    });
  }
  return { options };
};
