import { Tooltip, JLLSvg, Button } from 'jlld';
import './index.less';

import useReactShareAuthStore from '@/store/reactShareAuthStore';

interface TooltipAlignType {
  offset: number[];
}

interface AuthWrapWithTooltipProps {
  type?: 'icon-button' | 'icon';
  className?: string;
  buttonText?: string;
  icon?: string;
  iconColor?: string;
  iconSize?: number;
  auth: string;
  placement?: string;
  tooltip?: string;
  disabled?: boolean;
  align?: TooltipAlignType;
  loading?: boolean;
  onClick?: () => void;
}

const AuthWrapWithTooltip = (props: AuthWrapWithTooltipProps) => {
  const { type = 'icon' } = props;
  const { auth } = useReactShareAuthStore();
  const hasAuth = (auth as any).buttonAuth?.some((item: any) => item.button === props.auth);

  const tooltipText = hasAuth
    ? ''
    : props.tooltip || '抱歉，您的账户暂无权限体验该功能，如需体验请联系ruijian.data@jll.com';
  const renderChildren = () => {
    if (type === 'icon') {
      return (
        <JLLSvg
          className={props.className}
          icon={props.icon!}
          size={props.iconSize}
          color={props.iconColor}
          onClick={props.onClick}
          disabled={!hasAuth}
        />
      );
    } else if (type === 'icon-button') {
      return (
        <Button
          loading={props.loading}
          icon={
            <JLLSvg
              icon={props.icon!}
              size={props.iconSize}
              disabled={!hasAuth || props.disabled}
              color={props.iconColor || '#01151c'}
            />
          }
          className={props.className}
          onClick={props.onClick}
          disabled={!hasAuth || props.disabled}
        >
          {props.buttonText}
          {props.loading}
        </Button>
      );
    }
  };

  return (
    <>
      <Tooltip
        align={props.align}
        placement={props.placement || 'top'}
        title={tooltipText}
        overlayClassName="projectDetailStabilityHelpTooltip"
      >
        {renderChildren()}
      </Tooltip>
    </>
  );
};

export default AuthWrapWithTooltip;
