import { TopForms, ReportList } from './views';
import styles from './style.module.less';
import classNames from 'classnames';
import useUserEvent from '@/hooks/useUserEvent';
import useCommonOptionsStore from '@/store/commonOptions';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import PageHeader from '@/components/PageHeader';
import { queryReportPageOptions } from '@/store/commonOptions/pageActions';

export default () => {
  const loading = useCommonOptionsStore((state) => state.loading);

  const { sendUserEvent } = useUserEvent();

  useEffect(() => {
    sendUserEvent({ page: '报告' }, 'e_view');
    if (loading.report) {
      queryReportPageOptions();
    }
  }, [loading.report]);

  // useEffect(() => {
  //   return () => {
  //     leavePage('report');
  //   };
  // }, []);

  if (loading.report) {
    return null;
  }

  return (
    <div className={classNames('page', styles.marketReportPage)}>
      <PageHeader title="报告">
        <TopForms />
      </PageHeader>
      <ReportList />
    </div>
  );
};
