import { leedLevelOptions } from '@/constants/options';
import { JLLForm, JLLMultiSelector } from 'jlld';

const LeedLevelsFormItem = () => {
  return (
    <JLLForm.Item
      name="leedLevels"
      noStyle
    >
      <JLLMultiSelector
        options={leedLevelOptions}
        placeholder="选择LEED等级"
      />
    </JLLForm.Item>
  );
};

export default LeedLevelsFormItem;
