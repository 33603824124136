import { thousandSplitNumber } from '@/utils/tool';
import { RentKey } from './type';
import { generateRentUnit, generateRentValue } from './method';

export default (props: {
  rentKey: RentKey;
  value: number | string | undefined | null;
  showUnit?: boolean;
}) => {
  return (
    <>
      {thousandSplitNumber(generateRentValue(props.value, props.rentKey))}
      {props.showUnit ? generateRentUnit(props.rentKey) : null}
    </>
  );
};
