import { JLLMultiSelector } from 'jlld';

export interface ExclusiveGroupMultiSelectorProps {
  associatedValues?: string[];
  exclusiveValueGroups: string[][];
  value?: string[];
  onChange?: (value: string[]) => void;
  options: { label: string; value: string }[];
}

const ExclusiveGroupMultiSelector = (props: ExclusiveGroupMultiSelectorProps) => {
  const { exclusiveValueGroups, associatedValues, value, onChange, options } = props;

  const newOptions = options.map((option) => {
    // 当前option所在分组
    const groupValues = exclusiveValueGroups.find((group) => group.includes(option.value));
    // associatedValues 是否有值在groupValues中
    const hasAssociatedValueInGroup = associatedValues?.some((v) => groupValues?.includes(v));
    // 隔壁组件选了，这边就不能选
    if (hasAssociatedValueInGroup) {
      return {
        ...option,
        disabled: true,
      };
    }
    // 没选就不用判断了
    if (!value || value.length === 0) {
      return {
        ...option,
        disabled: false,
      };
    }
    // value是否有值在groupValues中
    const hasValueInGroup = value?.some((v) => groupValues?.includes(v));
    // 同组有值选了，同组都可以选
    if (hasValueInGroup) {
      return {
        ...option,
        disabled: false,
      };
    }

    return {
      ...option,
      disabled: true,
    };
  });

  return (
    <JLLMultiSelector
      style={{ width: 170 }}
      hideAll
      value={value}
      options={newOptions}
      onChange={(value) => {
        onChange?.(value as string[]);
      }}
    />
  );
};

export default ExclusiveGroupMultiSelector;
