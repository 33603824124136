import classNames from 'classnames';
import PageHeader from '@/components/PageHeader';
import useUserEvent from '@/hooks/useUserEvent';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { useEffect } from 'react';
import TransactionDetail from './views/TransactionDetail';
import useTransactionDetailStore from './store/transactionDetailStore';
import styles from './transactionDetail.style.module.less';
import { PageWithWatermark } from '@/components';

export default () => {
  const { sendUserEvent } = useUserEvent();

  const { queryBlockTradeTransactionDetail } = useTransactionDetailStore();
  const { data: mainFrameData } = useMainFrameContext();

  useEffect(() => {
    sendUserEvent({ page: '投资交易详情' }, 'e_view');

    queryBlockTradeTransactionDetail(mainFrameData.params.transactionId);
  }, []);

  return (
    <div className={classNames('page', styles.blockTradeTransactionDetail)}>
      <PageHeader
        title="交易详情"
        useBorder
        showBack
      />
      <div className={styles.mainContainer}>
        <PageWithWatermark>
          <TransactionDetail />
        </PageWithWatermark>
      </div>
    </div>
  );
};
