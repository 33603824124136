import { JLLForm, JLLSelect, Form } from 'jlld';
import useMarketSettingStore, { SettingState, setSettingData } from '../../store/settingStore';
import { queryMarketChart } from '../../store';
import './style.less';
import ExclusiveGroupMultiSelector from '@/pages/F-DataAnalysis/components/ExclusiveGroupMultiSelector';

export const classifyType = [
  { value: 'DATETIME', label: '时间' },
  { value: 'CITY_NAME', label: '城市' },
  { value: 'ADMIN_DISTRICT', label: '行政区' },
  { value: 'CHILD_MARKET', label: '子市场' },
  { value: 'PROJECT_LEVEL', label: '项目等级' },
  { value: 'HEAD_QUARTERS', label: '是否总部楼宇' },
  { value: 'TITLE_FORM', label: '业权形态' },
  { value: 'DEVELOPER_TYPE', label: '开发商背景' },
  { value: 'LEED', label: 'LEED等级' },
  { value: 'VACANCY_SEGMENT', label: '空置率分段' },
  { value: 'BOOK_RENT_SEGMENT', label: '账面租金分段' },
  { value: 'PRO_MANAGEMENT_FEES', label: '物管费分段' },
];

export const chartType = [
  { value: 'bar', label: '柱状图' },
  { value: 'line', label: '折线图' },
  { value: 'area', label: '面积图' },
];

const options = [
  {
    label: '空置率',
    value: 'vacancyRate',
  },
  {
    label: '项目数',
    value: 'projectCount',
  },
  {
    label: '物管费',
    value: 'propertyManagementFees',
  },
  {
    label: '无指标',
    value: 'none',
  },
  {
    label: '总存量',
    value: 'areaTotal',
  },
  {
    label: '新增供应',
    value: 'newSupply',
  },
  {
    label: '净吸纳量',
    value: 'netAbsorption',
  },
  {
    label: '账面租金',
    value: 'bookRent',
  },
  {
    label: '有效租金',
    value: 'effectiveRent',
  },
];

// const rightList = [
//   {
//     label: '总存量',
//     value: 'areaTotal',
//   },
//   {
//     label: '新增供应',
//     value: 'newSupply',
//   },
//   {
//     label: '净吸纳量',
//     value: 'netAbsorption',
//   },
//   {
//     label: '账面租金',
//     value: 'bookRent',
//   },
//   {
//     label: '有效租金',
//     value: 'effectiveRent',
//   },
// ];

const exclusiveValueGroups = [
  // ['账面租金', '有效租金'],
  // ['总存量', '新增供应', '净吸纳量'],
  // ['空置率'],
  // ['项目数'],
  // ['物管费'],
  // ['无指标'],
  ['projectCount'],
  ['bookRent', 'effectiveRent'],
  ['vacancyRate'],
  ['areaTotal', 'newSupply', 'netAbsorption'],
  ['propertyManagementFees'],
  ['none'],
];

const MarketChartControl = () => {
  const settingStore = useMarketSettingStore();
  const [form] = Form.useForm();

  const left = Form.useWatch('left', form);
  const right = Form.useWatch('right', form);

  const onChange =
    (key: keyof SettingState, query = true) =>
    (value: SettingState[keyof SettingState]) => {
      setSettingData({ [key]: value });
    };

  return (
    <JLLForm
      form={form}
      layout="inline"
      initialValues={settingStore.chartSettingFormValues}
      onValuesChange={(_, chartSettingFormValues) => {
        onChange('chartSettingFormValues')(chartSettingFormValues);
        queryMarketChart();
      }}
    >
      <JLLForm.Item
        name="analyzeOneRowHead"
        label="分类轴"
      >
        <JLLSelect
          style={{ width: 120 }}
          options={classifyType}
        />
      </JLLForm.Item>
      <JLLForm.Item
        name="left"
        label="左轴"
        className="leftGap"
      >
        <ExclusiveGroupMultiSelector
          options={options}
          exclusiveValueGroups={exclusiveValueGroups}
          associatedValues={right}
        />
      </JLLForm.Item>
      <JLLForm.Item name="leftAxisType">
        <JLLSelect
          style={{ width: 100 }}
          options={chartType}
        />
      </JLLForm.Item>
      <JLLForm.Item
        name="right"
        label="右轴"
        className="leftGap"
      >
        <ExclusiveGroupMultiSelector
          options={options}
          exclusiveValueGroups={exclusiveValueGroups}
          associatedValues={left}
        />
      </JLLForm.Item>
      <JLLForm.Item name="rightAxisType">
        <JLLSelect
          style={{ width: 100 }}
          options={chartType}
        />
      </JLLForm.Item>
    </JLLForm>
  );
};

export default MarketChartControl;
