import { JLLForm } from 'jlld';
import styles from '../../style.module.less';
import AutoRangeDatePikcer from './AutoRangeDatePikcer';

export interface TimePickerProps {
  typeChangedValue?: [string, string];
  quarterList?: [string, string];
  yearList?: [string, string];
  minMax?: [string, string];
}

const TimePicker = (props: TimePickerProps) => {
  return (
    <JLLForm.Item
      name="dateValue"
      noStyle
    >
      <AutoRangeDatePikcer
        minMax={props.minMax}
        yearList={props.yearList}
        quarterList={props.quarterList}
        className={styles.timePicker}
        typeChangedValue={props.typeChangedValue}
      />
    </JLLForm.Item>
  );
};

export default TimePicker;
