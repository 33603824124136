import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  getIndustryPropertyInfo,
  getProjectSpecificInfo,
  getBuildingList,
  getIntelligentInsights,
} from '../api';
import { PropertyType } from '../types';
import { IntelligentInsightsRes } from '../types/apiTypes';

export interface State {
  projectDetail: any;
  buildingDetail: any;
  loading: boolean;
  downloadBtnText: string;
  intelligentInsightsData: IntelligentInsightsRes | null;
}

const initState: State = {
  projectDetail: {},
  buildingDetail: {},
  loading: true,
  downloadBtnText: '下载项目信息',
  intelligentInsightsData: null,
};

const useProjectDetailStore = create(
  immer(
    combine(initState, (set, get) => ({
      getProjectDetail: async (
        type: PropertyType,
        id: string,
        hasIntelligentInsightsPermission = false
      ) => {
        let projectDetail: any = null;
        let buildingDetail: any = null;
        if (type === 'business') {
          projectDetail = await getProjectSpecificInfo(id);
        } else {
          projectDetail = await getIndustryPropertyInfo({ businessId: id, queryTime: '' });
          buildingDetail = await getBuildingList(id);
        }

        const intelligentInsightsData =
          (hasIntelligentInsightsPermission && (await getIntelligentInsights(id))) || null;
        set((state) => {
          if (projectDetail) {
            state.projectDetail = projectDetail;
          }
          if (buildingDetail) {
            state.buildingDetail = buildingDetail;
          }
          state.intelligentInsightsData = intelligentInsightsData;
          state.loading = false;
        });
      },
      resetIntelligentInsightsData: () => {
        set({
          intelligentInsightsData: null,
        });
      },
      setDownloadBtnText: (text: string) => {
        set({
          downloadBtnText: text,
        });
      },
    }))
  )
);

export default useProjectDetailStore;
