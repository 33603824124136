import { MapMode } from '@/pages/D-IndustrialAnalysis/types';

export interface MapSwitch {
  name: string;
  mode: MapMode;
  active: MapMode[];
}

export const mapModeSwitchConfig = [
  {
    name: '区域视图',
    mode: 'districtAndMarketMode' as MapMode,
    active: ['districtAndMarketMode', 'projectMarksMode'] as MapMode[],
  },
  {
    name: '热力视图',
    mode: 'heatMapMode' as MapMode,
    active: ['heatMapMode', 'areaMarksMode', 'heatProjectMarksMode'] as MapMode[],
  },
];
