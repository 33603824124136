import useReactShareUserStore from '@/store/reactShareUserStore';

export type RentKey = 'bookRentUnit' | 'effectiveRentUnit';

enum UnitType {
  month = 1,
  day = 2,
}

export const useUnitExchange = () => {
  const commonConfig = useReactShareUserStore((state) => state.commonConfig);
  return {
    generateRentValue: (
      rawValue: number | string | undefined | null,
      key: RentKey,
      returnRawData?: boolean
    ) => {
      const value = Number(rawValue);
      const type = (commonConfig as any).unitConfig[key];
      if (!value || Number.isNaN(value)) {
        return returnRawData ? rawValue : '--';
      }
      if (type === UnitType.month) {
        return value.toFixed(1);
      }
      return ((Number(value) * 12) / 365).toFixed(1);
    },
    generateRentUnit: (key: RentKey) => {
      const type = (commonConfig as any).unitConfig[key];
      if (type === UnitType.month) {
        return '元/m²/月';
      }
      return '元/m²/天';
    },
  };
};
