import { JLLForm, JLLMultiSelector, JLLSelect, Modal, message } from 'jlld';
import { SummaryShowType, showTypeOptions, unitOptions } from '../../config';
import { useForm } from 'jlld/es/form/Form';
import { MultiOptionItem } from 'jlld/es/JLLMultiSelector';
import { tableSettingValues } from '../../types/settingTypes';

interface TableControlModalProps {
  initialValues?: tableSettingValues;
  open?: boolean;
  dataIndicatorOptions: MultiOptionItem[];
  indicatorsRequired?: boolean;
  value?: tableSettingValues;
  disableShowcategory?: boolean;
  onOk?: (value: tableSettingValues) => void;
  onCancel?: () => void;
}

const TableControlModal = (props: TableControlModalProps) => {
  const [form] = useForm<tableSettingValues>();
  const typeOptions: MultiOptionItem[] = showTypeOptions.map((item) => ({
    ...item,
    disabled: item.value === SummaryShowType.category && props.disableShowcategory,
  }));
  return (
    <Modal
      title="表格设置"
      open={props.open}
      onOk={() => {
        // 指标排序
        const dataIndicator = form.getFieldValue('dataIndicator');
        if (props.indicatorsRequired && dataIndicator.length === 0) {
          message.error('请先选择指标！');
          return;
        }
        props.onOk?.({
          ...form.getFieldsValue(),
          dataIndicator: props.dataIndicatorOptions
            .filter((item) => dataIndicator.includes(item.value))
            .map((item) => item.value),
        });
      }}
      onCancel={props.onCancel}
    >
      <JLLForm
        form={form}
        initialValues={props.initialValues}
      >
        <JLLForm.Item
          label="数值指标"
          name="dataIndicator"
        >
          <JLLMultiSelector options={props.dataIndicatorOptions} />
        </JLLForm.Item>
        <JLLForm.Item
          label="单位切换"
          name="unitType"
          extra="切换指标”总存量、新增供应、净吸纳量”单位"
        >
          <JLLSelect options={unitOptions} />
        </JLLForm.Item>
        <JLLForm.Item
          label="汇总显示"
          name="summaryShowType"
        >
          <JLLMultiSelector
            hideAll
            options={typeOptions}
          />
        </JLLForm.Item>
      </JLLForm>
    </Modal>
  );
};

export default TableControlModal;
