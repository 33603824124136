import { reportTypesOptions } from '../config';

export const reportListColumns = [
  { title: '报告名称', dataIndex: 'reportName', key: 'reportName', width: '23%' },
  {
    title: '发布时间',
    dataIndex: 'pubdate',
    key: 'pubdate',
    sorter: true,
    showSorterTooltip: false,
    width: '23%',
  },
  {
    title: '报告类型',
    dataIndex: 'reportType',
    key: 'reportType',
    width: '23%',
    render: (text: string) => {
      return reportTypesOptions.find((e) => e.value === text)?.label;
    },
  },
  {
    title: '城市',
    dataIndex: 'cityName',
    key: 'cityName',
    width: '23%',
  },
];
