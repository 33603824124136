import { Position } from 'jlld/es/JLLMap/types';

export type PropertyType = 'business' | 'industry';

export enum TabKey {
  traffic = '2',
  catering = '3',
  entertainment = '4',
  bank = '5',
  hotel = '6',
  project = '7',
  mall = '9',
}
// 服务端要的
export enum ServeSidePoiType {
  subway = 1,
  bus,
  catering,
  entertainment,
  bank,
  hotel,
  project,
}

export interface ProjectAroundItem {
  id: string;
  address: string;
  distance: number;
  title: string;
  location: Position;
}

export enum RelocateTrailDimensionType {
  moveIn = '1',
  moveOut = '2',
}

export enum RelocateTrailModeType {
  map = '1',
  list = '2',
}
