import dayjs from 'dayjs';
import { JLLDatePicker } from 'jlld';
import useMarketPerformanceStore from '../../store';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { useShallow } from 'zustand/react/shallow';
import { compareQuarter } from '@/utils/tool';
import styles from './style.module.less';

export default () => {
  const { data } = useMainFrameContext();
  const { startTime, endTime, rangeStartTime, rangeEndTime, loadChartData, setTime } =
    useMarketPerformanceStore(
      useShallow((state) => ({
        startTime: state.startTime,
        endTime: state.endTime,
        rangeStartTime: state.rangeStartTime,
        rangeEndTime: state.rangeEndTime,
        loadChartData: state.loadChartData,
        setTime: state.setTime,
      }))
    );

  const disabledDate = (current: any) => {
    return (
      compareQuarter(dayjs(current).format('YYYY-[Q]Q'), rangeEndTime) === 1 ||
      compareQuarter(dayjs(current).format('YYYY-[Q]Q'), rangeStartTime) === -1
    );
  };

  return (
    <JLLDatePicker.JLLRangePicker
      picker="quarter"
      separator="至"
      className={styles.marketPerformanceRangePicker}
      allowClear={false}
      value={[startTime, endTime]}
      disabledDate={disabledDate}
      onChange={(values: any) => {
        setTime(values);
        loadChartData(data.params.id, data.params.type);
      }}
    />
  );
};
