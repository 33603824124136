import { EChartsType } from 'echarts';
import { textWidth } from './tool';

export const minAndMax = (series: { name: string; data: string[] }[], emptyCharacter = '-') => {
  // 首先求数据中的最大最小值
  const values = series.reduce((prev: number[], cur) => {
    const value = cur.data
      .map((data) => {
        if (!!data && typeof data === 'object') {
          return data[1];
        }
        return data;
      })
      .filter((it) => it !== emptyCharacter)
      .map((item) => Number(item));
    return prev.concat(value);
  }, []);

  if (values.length === 0) {
    return {
      min: 0,
      max: 5,
      interval: 1,
    };
  }
  const getMin = Math.floor(Math.min(...values));
  const getMax = Math.ceil(Math.max(...values));
  const gap = Math.ceil((getMax - getMin) / 4);
  const value = {
    min: getMin - gap,
    max: getMin + 4 * gap,
    interval: gap,
  };
  if (getMin >= 0 && value.min < 0) {
    value.max = 0 - value.min + value.max;
    value.min = 0;
  }
  // 大小相等
  if (getMin === getMax) {
    if (getMin > 0) {
      const gap1 = Math.ceil(getMax / 5);
      value.max = 5 * gap1;
      value.min = 0;
      value.interval = gap1;
    } else if (getMin === 0) {
      value.max = 5;
      value.min = 0;
      value.interval = 1;
    } else {
      const gap1 = Math.floor(getMin / 5);
      value.min = 5 * gap1;
      value.max = 0;
    }
  }
  // if (value.max > 100) {
  //   // const val1 = value.max - 100;
  //   value.max = 100;
  //   let gapinner = 0;
  //   value.min = 100;
  //   while (value.min >= getMin && value.min > 0) {
  //     gapinner += 1;
  //     value.min = value.max - gapinner * 5;
  //   }
  //   // value.min = 0; // value.min - val1;
  //   value.interval = gapinner; // gap;
  // }
  return value;
};

export const isRotate = (eChartInstance: EChartsType, series: any[]) => {
  const xAxis = series.map((i) => {
    return i.data.map((item: [string]) => item[0]);
  });
  const widthTxt = textWidth(xAxis.join(''));
  const boxWidth = eChartInstance.getWidth() - 100;
  return widthTxt > boxWidth;
};

export const setRotate = (options: any, eChartInstance: EChartsType) => {
  const flag = isRotate(eChartInstance, options.series);
  options.xAxis[0].axisLabel.align = flag ? 'right' : 'center';
  options.xAxis[0].axisLabel.rotate = flag ? 45 : 0;
  options.xAxis[0].axisLabel.interval = flag ? Math.floor(options.xAxis[0].data.length / 18) : 0;
};
