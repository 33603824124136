import { Button, Form, JLLSvg, Modal } from 'jlld';
import styles from './style.module.less';
import TagsInput from '@/components/TagsInput';
import { useForm } from 'jlld/es/form/Form';
import { useState } from 'react';
import { OptionItem } from 'jlld/es/JLLSelect';

// [{label: 1, value: 1 }, {label: 2, value: 2}, {label: 3, value: 3}] to [1-2, 2-3]
const transferMethod = (value: OptionItem[]) => {
  const result: string[] = [];
  for (let i = 0; i < value.length - 1; i++) {
    result.push(`${value[i].value}-${value[i + 1].value}`);
  }
  return result;
};
// [1-2, 2-3] to [{label: 1, value: 1 }, {label: 2, value: 2}, {label: 3, value: 3}]
const reverseTransferMethod = (value: string[]) => {
  const result: OptionItem[] = [];
  for (let i = 0; i < value.length; i++) {
    const [start, end] = value[i].split('-');
    result.push({ label: start, value: start });
    if (i === value.length - 1) {
      result.push({ label: end, value: end });
    }
  }
  return result;
};

export interface DataSliceSettingProps {
  optionsList: { label: string; key: string; max?: number }[];
  onChange: (values: Record<string, string[]>) => void;
  value?: Record<string, string[]>;
}

interface InputFormItemProps {
  max?: number;
  value?: Record<string, string[]>;
  label?: string;
  name?: string;
  onChange?: (value: Record<string, string[]>) => void;
}

const errorMsg = '数值不在常规范围，请确认！';
const infoMsg = '请输入一段递增整数，按Enter键分割每个数字，最多可分10段';

const InputFormItem = (props: InputFormItemProps) => {
  const { name, label, max } = props;
  const [help, setHelp] = useState<string>(infoMsg);

  const validator = (_, value: OptionItem[]) => {
    if (!value || value.length === 0) {
      setHelp(infoMsg);
      return Promise.resolve(infoMsg);
    }
    if (value && value.length > 10) {
      setHelp('最多只能分10段');
      return Promise.reject(new Error('最多只能分10段'));
    }
    const valueNumberList = value.map((item: OptionItem) => Number(item.value));
    // 必须输入数字且为整数
    if (!valueNumberList.every((item: number) => Number.isInteger(item))) {
      setHelp('请输入一组整数');
      return Promise.reject(new Error('请输入一组整数'));
    }
    // 数值需要递增
    for (let i = 1; i < valueNumberList.length; i++) {
      if (valueNumberList[i] <= valueNumberList[i - 1]) {
        setHelp('请输入一组递增的整数');
        return Promise.reject(new Error('请输入一组递增的整数'));
      }
    }
    // 数值需要在范围内
    if (max && valueNumberList[valueNumberList.length - 1] > max) {
      setHelp(errorMsg);
      return Promise.reject(new Error(errorMsg));
    }

    setHelp(infoMsg);
    return Promise.resolve(infoMsg);
  };

  return (
    <Form.Item
      className={styles.tagInfoItem}
      name={name}
      label={label}
      help={help}
      rules={[
        () => ({
          validator,
        }),
      ]}
    >
      <TagsInput maxCount={10} />
    </Form.Item>
  );
};

const DataSliceSetting = (props: DataSliceSettingProps) => {
  const [open, setOpen] = useState(false);
  const { optionsList, onChange, value } = props;
  const [form] = useForm();

  // const initialValues: Record<string, OptionItem[]> = reverseTransferMethod
  const initialValues: Record<string, OptionItem[]> = {};
  Object.entries(value || {}).forEach(([key, value]) => {
    initialValues[key] = value ? reverseTransferMethod(value) : [];
  });
  return (
    <div className={styles.dataSliceSetting}>
      <div
        className={styles.settingButton}
        onClick={() => {
          setOpen(true);
        }}
      >
        <JLLSvg
          icon="settings"
          size={20}
        />
        <div style={{ marginLeft: 8 }}>数值分段设置</div>
      </div>
      <Modal
        width={800}
        title="设置数据分段"
        open={open}
        onOk={() => {
          form.validateFields().then(() => {
            const newValues: Record<string, string[]> = {};
            Object.entries(form.getFieldsValue()).forEach((item) => {
              const [key, value] = item as [string, OptionItem[]];
              newValues[key] = value ? transferMethod(value) : [];
            });
            onChange(newValues);
            setOpen(false);
          });
        }}
        onCancel={() => {
          setOpen(false);
        }}
        footer={(_, { OkBtn, CancelBtn }: { OkBtn: any; CancelBtn: any }) => (
          <>
            <Button
              type="text"
              className="reset"
              onClick={() => {
                const resetValues: Record<string, OptionItem[]> = {};
                Object.keys(initialValues).forEach((key) => {
                  resetValues[key] = [];
                });

                form.setFieldsValue(resetValues);
                onChange(resetValues);
              }}
            >
              重置
            </Button>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={(_, values) => {
            form.validateFields().then(() => {
              onChange(values);
            });
          }}
        >
          {optionsList.map((item) => {
            return (
              <InputFormItem
                key={item.key}
                name={item.key}
                label={item.label}
                max={item.max}
              />
            );
          })}
        </Form>
      </Modal>
    </div>
  );
};

export default DataSliceSetting;
