import TenantRank from '../TenantRank';
import styles from './style.module.less';
import { ReactNode, useState } from 'react';
import BarChart from './BarChart';
import PieChart from '../../../../../../components/Charts/PieChart';
import classNames from 'classnames';
import { PropertyType } from '@/constants/enums';
import useTenantAnalysisStore, { unit } from '../../store';
import { PieItem, Tab } from '../../types';
import FloorDetail from '../FloorDetail';
import useMainFrameContext from '@/hooks/useMainFrameContext';

const tabs: Tab[] = ['面积', '个数'];

const filterFormList = (list: any[], tab: Tab) => {
  return {
    data: list.map((item) => ({
      name: item.name,
      value: tab === '个数' ? item.tenantNum : item.areaOccupied,
    })),
    unit: unit[tab],
  };
};

const ChartCard = (props: {
  title: string;
  children: ReactNode;
  className?: string;
  tabs?: Tab[];
  onTabClick?: (tab: Tab) => void;
}) => {
  const { title, children, className, tabs } = props;
  const [currentTab, setCurrentTab] = useState<Tab>('面积');

  const onTabClick = (tab: Tab) => {
    setCurrentTab(tab);
    props.onTabClick?.(tab);
  };

  return (
    <div className={classNames(styles.tenantAnalysisChart, className)}>
      <div className={styles.title}>
        <h3>{title}</h3>
        {tabs ? (
          <div className={styles.tabs}>
            {tabs.map((tab) => (
              <div
                key={tab}
                className={classNames(styles.item, { [styles.active]: tab === currentTab })}
                onClick={() => onTabClick(tab)}
              >
                {tab}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {children}
    </div>
  );
};

const TenantRankChart = () => {
  const { rankingList } = useTenantAnalysisStore((state) => ({
    rankingList: state.rankingList,
  }));
  return (
    <ChartCard
      title="租户Top排行榜"
      className={styles.tenantRankChart}
    >
      <TenantRank data={rankingList} />
    </ChartCard>
  );
};

const TenantProportionChart = () => {
  const { getTenantProportion } = useTenantAnalysisStore((state) => ({
    getTenantProportion: state.getTenantProportion,
  }));
  return (
    <ChartCard title="当前租户占比分析">
      <PieChart
        name="当前租户占比分析"
        radius={['30%', '55%']}
        unit="%"
        threshold={10}
        distanceToLabelLine={-20}
        data={getTenantProportion()}
      />
    </ChartCard>
  );
};

const IndustryFormChart = () => {
  const industryFormList = useTenantAnalysisStore((state) => state.industryFormList);
  const [industryFormTab, setIndustryFormTab] = useState<Tab>('面积');
  const industryFormChartData = filterFormList(industryFormList, industryFormTab);

  return (
    <ChartCard
      title="租户二级行业构成"
      tabs={tabs}
      onTabClick={(tab: Tab) => setIndustryFormTab(tab)}
    >
      <PieChart
        name="租户二级行业构成"
        radius={['0%', '55%']}
        threshold={10}
        distanceToLabelLine={-20}
        data={industryFormChartData.data as PieItem[]}
        unit={industryFormChartData.unit}
      />
    </ChartCard>
  );
};

const TenantAreaChart = () => {
  const { tenantArea } = useTenantAnalysisStore((state) => ({
    tenantArea: state.tenantArea,
  }));
  return (
    <ChartCard title="租户面积段占比">
      <BarChart
        legend={['租户个数']}
        data={[tenantArea]}
        unit="个"
      />
    </ChartCard>
  );
};

const TypeFormChart = () => {
  const typeFormList = useTenantAnalysisStore((state) => state.typeFormList);
  const [typeFormTab, setTypeFormTab] = useState<Tab>('面积');
  const typeFormChartData = filterFormList(typeFormList, typeFormTab);

  return (
    <ChartCard
      title="租户类型构成"
      tabs={tabs}
      onTabClick={(tab: Tab) => setTypeFormTab(tab)}
    >
      <PieChart
        name="租户类型构成"
        radius={['30%', '55%']}
        threshold={10}
        distanceToLabelLine={-20}
        data={typeFormChartData.data as PieItem[]}
        unit={typeFormChartData.unit}
      />
    </ChartCard>
  );
};

const FloorDetailChart = (props: { projectName: string; propertyType: PropertyType }) => {
  return (
    <ChartCard
      title={props.projectName}
      className={classNames(styles.floorDetailChart, styles[props.propertyType])}
    >
      <FloorDetail />
    </ChartCard>
  );
};

export default (props: { projectName: string }) => {
  const { data } = useMainFrameContext();
  const busId = useTenantAnalysisStore((state) => state.busId);
  const isProjectSummary = busId === data.params.id;

  return (
    <>
      {isProjectSummary ? (
        <div className={classNames(styles.chartView, styles[data.params.type])}>
          <TenantRankChart />
          <TenantProportionChart />
          <IndustryFormChart />
          <TenantAreaChart />
          <TypeFormChart />
        </div>
      ) : null}
      {!isProjectSummary || data.params.type === 'business' ? (
        <FloorDetailChart
          projectName={props.projectName}
          propertyType={data.params.type}
        />
      ) : null}
    </>
  );
};
