import { Skeleton } from 'jlld';
import styles from './style.module.less';

const FormLoadingSkeletion = () => {
  return (
    <div className={styles.topFormsWrap}>
      <div
        className={styles.topForm}
        style={{
          height: 80,
          overflow: 'hidden',
        }}
      >
        {Array.from({ length: 12 }, (_, index) => (
          <Skeleton.Input
            key={index}
            style={{ width: 168, marginRight: 16 }}
            active
          />
        ))}
      </div>
      <div className={styles.controllers}>
        <Skeleton.Button style={{ margin: '0 12px' }} />
        <Skeleton.Button />
      </div>
    </div>
  );
};

export default FormLoadingSkeletion;
