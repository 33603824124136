import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getBlockTradeDetailList } from '../api';
import { QueryBlockTradeDetailParam } from '../types';
import { getValuesFromDataOrState } from '@/utils/storeTools';
import useLeftFormStore from './leftFormStore';

interface State {
  current: number;
  size: number;
  detailData: any;
  detailQueryOrder: any[];
  detailQueryCompanyName: string;
  detailQueryProjectName: string;
}

const initState: State = {
  current: 1,
  size: 15,
  detailData: {},
  detailQueryOrder: [
    {
      asc: false,
      column: 'time',
    },
  ],
  detailQueryCompanyName: '',
  detailQueryProjectName: '',
};

const useTradeDetailStore = create(
  immer(
    combine(initState, (set, get) => ({
      queryDetailList: async (data: QueryBlockTradeDetailParam) => {
        const { current, size, detailQueryProjectName, detailQueryCompanyName, detailQueryOrder } =
          getValuesFromDataOrState(
            [
              'current',
              'size',
              'detailQueryProjectName',
              'detailQueryCompanyName',
              'detailQueryOrder',
            ],
            get(),
            data,
            set
          );
        const leftFormQueryParams = useLeftFormStore.getState().leftFormQueryParams;

        const detailData = await getBlockTradeDetailList({
          ...data,
          projectName: detailQueryProjectName,
          companyName: detailQueryCompanyName,
          orders: detailQueryOrder,
          current,
          size,
          ...(leftFormQueryParams as any),
        });

        set({
          detailData,
        });

        return detailData;
      },

      queryDetailDownloadList: async (): Promise<any> => {
        const leftFormQueryParams = useLeftFormStore.getState().leftFormQueryParams;
        const { detailQueryOrder, detailQueryProjectName, detailQueryCompanyName } = get();
        return await getBlockTradeDetailList({
          ...(leftFormQueryParams as any),
          projectName: detailQueryProjectName,
          companyName: detailQueryCompanyName,
          current: 1,
          size: 100000,
          orders: detailQueryOrder,
        });
      },

      setDetailQueryOrder: (order: any[]) => {
        set({
          detailQueryOrder: order,
        });
      },

      setDetailQueryCompanyName: (name: string) => {
        set({
          detailQueryCompanyName: name,
        });
      },

      setDetailQueryProjectName: (name: string) => {
        set({
          detailQueryProjectName: name,
        });
      },
      resetTradeDetail: () => {
        set({ ...initState });
      },
    }))
  )
);

export default useTradeDetailStore;
