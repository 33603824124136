import { Form, JLLSelect } from 'jlld';
import { useRef } from 'react';
import {
  ShowDataTabType,
  SummaryShowType,
  allTabOptions,
  tabOptions,
  futureSupplyChartTypeList,
  futureSupplyTransferList,
  futureSupplyIndicators,
} from '../../config';
import styles from '../../style.module.less';
import { CommonTableWrap } from '../../components';
import { Chart, Detail, Table, TopForm } from './components';
import useFutureSupplySettingStore, { SettingState, setSettingData } from './store/settingStore';
import useFutureSupplyStore, { queryCurrentTabData, queryFutureChart } from './store';
import exportDataAnalysisTable, { exportDataAnalysisDetailTable } from '../../exportTable';
import useUserAuth from '@/hooks/useUserAuth';
import { ChartRef } from './components/Chart';
import { columns } from './components/Detail';
import { PropertyType } from '@/constants/enums';

const FutureSupply = () => {
  const chartRef = useRef<ChartRef>(null);
  const settingStore = useFutureSupplySettingStore();
  const loading = useFutureSupplyStore((state) => state.loading);

  const tabItems = {
    [ShowDataTabType.table]: <Table />,
    [ShowDataTabType.chart]: <Chart ref={chartRef} />,
    [ShowDataTabType.detail]: <Detail />,
  };

  const hasDetailAuth = useUserAuth('front:dataanalysis:futuresupply:details:query');

  const onChange =
    (key: keyof SettingState, query = true) =>
    (value: SettingState[keyof SettingState]) => {
      setSettingData({ [key]: value });

      if (query) {
        queryCurrentTabData();
      }
    };

  const getColumns = () => {
    if (settingStore.topFormValues.propertyType[PropertyType.industrial]?.length > 0) {
      return columns.filter((item) => item.title !== '是否总部楼宇');
    }
    return columns;
  };

  const getFutureSupplyTransferList = () => {
    if (
      settingStore.topFormValues?.propertyType &&
      settingStore.topFormValues?.propertyType[PropertyType.industrial]?.length > 0
    ) {
      return futureSupplyTransferList.map((item) => {
        if (item.label === '是否总部楼宇') {
          return {
            ...item,
            disabled: true,
          };
        }
        return item;
      });
    }
    return futureSupplyTransferList;
  };

  const onDownload = () => {
    switch (settingStore.tableActiveTab) {
      case ShowDataTabType.table: {
        exportDataAnalysisTable(
          '未来供应',
          useFutureSupplyStore.getState().tableData,
          settingStore.dimensionValues,
          settingStore.tableFormValues.dataIndicator,
          settingStore.tableFormValues.unitType,
          settingStore.tableFormValues.summaryShowType
        );
        break;
      }
      case ShowDataTabType.chart: {
        chartRef?.current!.downloadChartImg();
        break;
      }
      case ShowDataTabType.detail: {
        const detailData = useFutureSupplyStore.getState().detailData;
        if (detailData) {
          exportDataAnalysisDetailTable('数据分析-未来供应明细', detailData, getColumns());
        }
        break;
      }
    }
  };

  return (
    <div className={styles.viewContainer}>
      <TopForm tabModuleName="未来供应" />
      <CommonTableWrap
        tabModuleName="未来供应"
        tableLoading={loading.table}
        chartLoading={loading.chart}
        detailLoading={loading.detail}
        tabOptions={hasDetailAuth ? allTabOptions : tabOptions}
        dataIndicatorOptions={futureSupplyIndicators}
        indicatorsRequired={true}
        dismensionList={getFutureSupplyTransferList()}
        dimensionValues={settingStore.dimensionValues}
        tableFormValues={settingStore.tableFormValues}
        activeTab={settingStore.tableActiveTab}
        downloadAuth="front:dataanalysis:futuresupply:export"
        detailAuth="front:dataanalysis:futuresupply:details:export"
        onDimensionChange={(values) => {
          if (values.length === 1) {
            onChange(
              'tableFormValues',
              false
            )({
              ...settingStore.tableFormValues,
              summaryShowType: [SummaryShowType.total],
            });
          }
          if (values.length > 1) {
            const { summaryShowType } = settingStore.tableFormValues;
            onChange(
              'tableFormValues',
              false
            )({
              ...settingStore.tableFormValues,
              summaryShowType: summaryShowType?.includes(SummaryShowType.category)
                ? summaryShowType
                : [...summaryShowType, SummaryShowType.category],
            });
          }
          onChange('dimensionValues')(values);
        }}
        onTableColumnChange={onChange('tableFormValues')}
        onTabChange={onChange('tableActiveTab', false)}
        onDownloadTable={onDownload}
        customControl={
          settingStore.tableActiveTab === ShowDataTabType.chart && (
            <Form
              layout="inline"
              initialValues={settingStore.chartSettingFormValues}
              onValuesChange={(_, chartSettingFormValues) => {
                onChange('chartSettingFormValues', false)(chartSettingFormValues);
                queryFutureChart();
              }}
            >
              <Form.Item
                label="分类轴"
                name="analyzeOneRowHead"
                noStyle
              >
                <JLLSelect
                  style={{ width: 172 }}
                  options={futureSupplyChartTypeList}
                />
              </Form.Item>
            </Form>
          )
        }
      >
        {tabItems[settingStore.tableActiveTab]}
      </CommonTableWrap>
    </div>
  );
};

export default FutureSupply;
