// export const getValuesFromDataOrState = <T>(
//   data: Record<string, any>,
//   state: T,
//   keyList: string[]
// ) => {
//   const values: Partial<T> = {};

//   keyList.forEach((key) => {
//     if (data[key]) {
//       values[key] = data[key];
//     } else if (state[key]) {
//       values[key] = state[key];
//     } else {
//       values[key] = null;
//     }
//   });

//   return values;
// };
// type ValidStateType<T> = {
//   [K in keyof T]: T[K];
// };

export const getValuesFromDataOrState = <T extends Record<string, any>>(
  keyList: Array<keyof T & keyof Record<string, any>>,
  state: T,
  data?: Record<string, any>,
  // zustand set
  set?: any
) => {
  const values: Partial<T> = {};
  const newData: Record<string, any> = data || {};
  keyList.forEach((key) => {
    if (Reflect.has(newData, key)) {
      values[key] = newData[key];
    } else if (Reflect.has(state, key)) {
      values[key] = state[key];
    }
  });

  if (set) {
    set(values);
  }

  return values;
};

export const setNewState = (set: any, keyList: string[], valueData: Record<string, any>) => {
  const setValueData: any = {};
  keyList.forEach((key) => {
    setValueData[key] = valueData[key];
  });

  set(setValueData);
};
