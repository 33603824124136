import styles from './style.module.less';
import { Table } from 'jlld';
import { topColumns, bottomColumns } from './config';
import { TransactionBottom, TransactionTop } from '../type';

export default (props: {
  topData: TransactionTop[];
  bottomData: TransactionBottom[];
  remark: string | undefined;
}) => {
  return (
    <>
      <Table
        className={styles.transactionTopTable}
        columns={topColumns}
        dataSource={props.topData}
        pagination={false}
      />
      <Table
        columns={bottomColumns}
        dataSource={props.bottomData}
        pagination={false}
      />
      {props.remark ? <div className={styles.transactionRemark}>备注：{props.remark}</div> : null}
    </>
  );
};
