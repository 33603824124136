import { setValuesToOptions } from '@/utils/tool';
import {
  AirConditionerSystem,
  DeveloperBackground,
  DeveloperType,
  ElectricSystem,
  EnterpriseSource,
  FreshAirSystem,
  HeadQuarters,
  IndustrialOffice,
  LeaseType,
  LeedLevel,
  ProjectLabel,
  ProjectLevel,
  ProjectTitle,
  PropertyType,
  TitleForm,
} from './enums';

export const projectAreaOptions = [
  {
    label: '30000m²及以下',
    value: [0, 30000],
  },
  {
    label: '30000-50000m²',
    value: [30000, 50000],
  },
  {
    label: '50000-100000m²',
    value: [50000, 100000],
  },
  {
    label: '100000-150000m²',
    value: [100000, 150000],
  },
  {
    label: '150000m²以上',
    value: [150000],
  },
];

export const buildingHeightOptions = [
  {
    label: '100米以下',
    value: [0, 100],
  },
  {
    label: '100-150米',
    value: [100, 150],
  },
  {
    label: '150-200米',
    value: [150, 200],
  },
  {
    label: '200-300米',
    value: [200, 300],
  },
  {
    label: '300-400米',
    value: [300, 400],
  },
  {
    label: '400米以上',
    value: [400],
  },
];

export const vacantAreaOptions = [
  {
    label: '1000m²以下',
    value: [0, 1000],
  },
  {
    label: '1000-3000m²',
    value: [1000, 3000],
  },
  {
    label: '3000-5000m²',
    value: [3000, 5000],
  },
  {
    label: '5000-10000m²',
    value: [5000, 10000],
  },
  {
    label: '10000-15000m²',
    value: [10000, 15000],
  },
  {
    label: '15000-20000m²',
    value: [15000, 20000],
  },
  {
    label: '20000m²以上',
    value: [20000],
  },
];

export const vacancyRateOptions = [
  {
    label: '3%以下',
    value: [0, 3],
  },
  {
    label: '3%-5%',
    value: [3, 5],
  },
  {
    label: '5%-8%',
    value: [5, 8],
  },
  {
    label: '8%-10%',
    value: [8, 10],
  },
  {
    label: '10%-15%',
    value: [10, 15],
  },
  {
    label: '15%-20%',
    value: [15, 20],
  },
  {
    label: '20%-30%',
    value: [20, 30],
  },
  {
    label: '30%-40%',
    value: [30, 40],
  },
  {
    label: '40%以上',
    value: [40],
  },
];

export const rentOptions = [
  {
    label: '<50',
    value: [0, 50],
  },
  {
    label: '50-70',
    value: [50, 70],
  },
  {
    label: '70-90',
    value: [70, 90],
  },
  {
    label: '90-110',
    value: [90, 110],
  },
  {
    label: '110-130',
    value: [110, 130],
  },
  {
    label: '130-150',
    value: [130, 150],
  },
  {
    label: '150-200',
    value: [150, 200],
  },
  {
    label: '200-300',
    value: [200, 300],
  },
  {
    label: '300-400',
    value: [300, 400],
  },
  {
    label: '>=400',
    value: [400],
  },
];

export const propertyManagementFees = [
  {
    label: '10以下',
    value: [0, 10],
  },
  {
    label: '10-15',
    value: [10, 15],
  },
  {
    label: '15-20',
    value: [15, 20],
  },
  {
    label: '20-25',
    value: [20, 25],
  },
  {
    label: '25-30',
    value: [25, 30],
  },
  {
    label: '30以上',
    value: [30],
  },
];

export const freshAirSystemOptions = [
  {
    label: '有',
    value: FreshAirSystem.yes,
  },
  {
    label: '无',
    value: FreshAirSystem.no,
  },
];

export const airConditionerSystemOptions = [
  {
    label: 'VAV',
    value: AirConditionerSystem.VAV,
  },
  {
    label: 'VRV',
    value: AirConditionerSystem.VRV,
  },
  {
    label: '风机盘管',
    value: AirConditionerSystem.fanCoil,
  },
  {
    label: '其他（TBC）',
    value: AirConditionerSystem.others,
  },
];

export const electricSystemOptions = [
  {
    label: '双回路供电',
    value: ElectricSystem.dualCircuitPower,
  },
  {
    label: '非双回路供电',
    value: ElectricSystem.nonDualCircuitPower,
  },
  {
    label: '三回路供电',
    value: ElectricSystem.tripleCircuitPower,
  },
  {
    label: '其他供电',
    value: ElectricSystem.others,
  },
];

export const projectLabelOptions = [
  {
    label: '高端酒店',
    value: ProjectLabel.luxuryHotel,
  },
  {
    label: '精致管理',
    value: ProjectLabel.delicateManagement,
  },
  {
    label: '一流服务',
    value: ProjectLabel.firstClassService,
  },
  {
    label: '一公里内地铁可达',
    value: ProjectLabel.subwayAccessibleWithinOneKilometer,
  },
];

export const commercialOptions = [
  {
    value: ProjectLevel.gradeA,
    label: '甲级',
  },
  {
    value: ProjectLevel.gradeB,
    label: '乙级',
  },
  {
    value: ProjectLevel.gradeD,
    label: '优质楼宇',
  },
];

export const industrialOptions = [
  {
    value: IndustrialOffice.industrialPark,
    label: '产业园区',
  },
  {
    value: IndustrialOffice.industrialBuilding,
    label: '产业楼宇',
  },
];

export const commercialAndIndustrialOptions = [
  {
    value: PropertyType.commercial,
    label: '商业办公',
    children: commercialOptions,
  },
  {
    value: PropertyType.industrial,
    label: '产业办公',
    children: industrialOptions,
  },
];

export const developerTypeOptions = [
  {
    label: '传统地产开发商',
    value: DeveloperType.traditionalRealEstateDeveloper,
  },
  {
    label: '政府/平台开发商',
    value: DeveloperType.governmentOrPlatformDeveloper,
  },
  {
    label: '专业产业园开发商',
    value: DeveloperType.professionalIndustrialParkDeveloper,
  },
  {
    label: '行业龙头企业',
    value: DeveloperType.industryLeaderEnterprise,
  },
  {
    label: '待定',
    value: DeveloperType.undecided,
  },
];

export const enterpriseSourceOptions = [
  {
    value: EnterpriseSource.foreignInvestedEnterprises,
    label: '外资（含合资）',
  },
  {
    value: EnterpriseSource.domesticEnterprisesOutsideProvince,
    label: '中资（省外）',
  },
  {
    value: EnterpriseSource.domesticEnterprisesWithinProvince,
    label: '中资（省内）',
  },
  {
    value: EnterpriseSource.other,
    label: '其他',
  },
];

export const LeaseTypeOptions = [
  {
    label: '现存租户',
    value: LeaseType.existing,
  },
  {
    label: '新租租户',
    value: LeaseType.new,
  },
  {
    label: '退租租户',
    value: LeaseType.vacating,
  },
];

export const TitleFormOptions = [
  {
    label: '单一业权',
    value: TitleForm.single,
  },
  {
    label: '散售业权',
    value: TitleForm.distribution,
  },
  {
    label: '部分散售',
    value: TitleForm.partial,
  },
  {
    label: '待定',
    value: TitleForm.pending,
  },
];

export const firstLevelCategoryOptions = [
  {
    value: 1,
    label: '商业',
  },
  {
    value: 2,
    label: '工业',
  },
  {
    value: 3,
    label: '住宅',
  },
  {
    value: 4,
    label: '公寓',
  },
  {
    value: 5,
    label: '车库',
  },
  {
    value: 6,
    label: '其它',
  },
];

export const buyerSellerTypeOptions = [
  {
    value: 1,
    label: '外资机构',
  },
  {
    value: 2,
    label: '内资房地产基金',
  },
  {
    value: 3,
    label: '开发商',
  },
  {
    value: 4,
    label: '民营企业',
  },
  {
    value: 5,
    label: '国营机构',
  },
  {
    value: 6,
    label: '机构投资者',
  },
  {
    value: 7,
    label: '私人',
  },
  {
    value: 8,
    label: '未公开',
  },
];

export const headQuarterOptions = [
  {
    label: '否',
    value: HeadQuarters.no,
  },
  {
    label: '是',
    value: HeadQuarters.yes,
  },
  {
    label: '待定',
    value: HeadQuarters.pending,
  },
];

export const leedLevelOptions = [
  {
    label: '铂金级',
    value: LeedLevel.platinum,
  },
  {
    label: '金级',
    value: LeedLevel.gold,
  },
  {
    label: '银级',
    value: LeedLevel.silver,
  },
  {
    label: '认证级',
    value: LeedLevel.certified,
  },
  {
    label: '预认证',
    value: LeedLevel.precertified,
  },
  {
    label: '无',
    value: LeedLevel.none,
  },
  {
    label: '待定',
    value: LeedLevel.pending,
  },
];

export const projectTitleOptions = [
  {
    label: '单一业权',
    value: ProjectTitle.singleOwnership,
  },
  {
    label: '散售业权',
    value: ProjectTitle.scattered,
  },
  {
    label: '部分散售',
    value: ProjectTitle.partialScattered,
  },
  {
    label: '待定',
    value: ProjectTitle.pending,
  },
];

export const developerBackgroundOptions = [
  {
    label: '外资',
    value: DeveloperBackground.foreign,
  },
  {
    label: '中资',
    value: DeveloperBackground.domestic,
  },
  {
    label: '本土',
    value: DeveloperBackground.local,
  },
  {
    label: '待定',
    value: DeveloperBackground.pending,
  },
];
const propertyTypeLevelOne = ['商业', '工业', '住宅', '公寓', '车库', '其它'];
const propertyTypeLevelTwo = [
  ['办公', '商业', '酒店', '综合体'],
  ['研发办公-产业园区', '研发办公-产业楼宇', '仓储物流', '厂房'],
  [],
  ['长租公寓', '服务公寓', '养老公寓', '保障性住房'],
  ['车库', '车位'],
  [],
];
// 资产类型
export const propertyTypeOptions = setValuesToOptions(propertyTypeLevelOne).map((item, index) => {
  return {
    ...item,
    children: propertyTypeLevelTwo[index].length
      ? setValuesToOptions(propertyTypeLevelTwo[index])
      : [item],
  };
});
