import { Position } from 'jlld/es/JLLMap/types';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getProjectAround } from '../../api';
import { ServeSidePoiType, ProjectAroundItem, PropertyType, TabKey } from '../../types';
import { deepClone } from '@/utils/tool';
// 也可以这么写
// type TabViewData = {
//   [key in PoiType]: ProjectAroundItem[];
// };

export interface TabViewData {
  allPages: number;
  pageNo: number;
  dataList: ProjectAroundItem[];
}

export interface State {
  trafficType: ServeSidePoiType.subway | ServeSidePoiType.bus;
  tabViewData: Partial<Record<ServeSidePoiType, TabViewData>>;
  activeTitle: string;
  animateTitle: string;
  currentTab: TabKey;
  pageNo: number;
  poiType: ServeSidePoiType;
}

const initState: State = {
  trafficType: ServeSidePoiType.subway,
  activeTitle: '',
  animateTitle: '',
  currentTab: TabKey.traffic,
  pageNo: 1,
  poiType: ServeSidePoiType.subway,
  tabViewData: {
    // [PoiType.subway]: [],
    // [PoiType.bus]: [],
    // [PoiType.catering]: [],
    // [PoiType.entertainment]: [],
    // [PoiType.bank]: [],
    // [PoiType.hotel]: [],
    // [PoiType.project]: [],
  },
};

const useSurroundingStore = create(
  immer(
    combine(deepClone(initState), (set, get) => ({
      // 获取当前tab的poiType，因为有公交地铁的问题要单独获取
      getPoiType: (key?: TabKey) => {
        let poiType: ServeSidePoiType;
        const tab = key || get().currentTab;
        if (tab === TabKey.traffic) {
          poiType = get().trafficType;
        } else {
          poiType = Number(tab);
        }

        return poiType;
      },
      setTrafficType: (trafficType: ServeSidePoiType.subway | ServeSidePoiType.bus) => {
        set(() => ({ trafficType, poiType: trafficType }));
      },
      setCurrentTab: (currentTab: TabKey) => {
        const newState = {
          currentTab,
          poiType: (get() as any).getPoiType(currentTab) as ServeSidePoiType,
        };
        set(() => newState);

        return newState;
      },
      setActiveTitle: (activeTitle: string) => set(() => ({ activeTitle })),
      setAnimateTitle: (animateTitle: string) => set(() => ({ animateTitle })),

      queryTabData: async (
        propertyType: PropertyType,
        id: string,
        poiType: ServeSidePoiType,
        currentPage = 1
      ) => {
        const result = await getProjectAround({
          projectId: id,
          type: poiType,
          articlesPage: 100,
          currentPage,
          propertyType: propertyType === 'industry' ? 2 : 1,
        });

        set((state) => {
          state.tabViewData[poiType] = {
            allPages: result.pages,
            pageNo: result.current,
            dataList: result.records,
          };
        });
      },
      reset: () => {
        set(deepClone(initState));
      },
    }))
  )
);

export default useSurroundingStore;
