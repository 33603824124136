import { Table } from 'jlld';
import { useShallow } from 'zustand/react/shallow';
import useTableLayout from '@/hooks/useTableLayout';
import useTransactionAnalysisStore from '../../store';

export default (props: { projectId: string }) => {
  const { surrenderData, getSurrenderDetail } = useTransactionAnalysisStore(
    useShallow((state: any) => ({
      surrenderData: state.surrenderData,
      getSurrenderDetail: state.getSurrenderDetail,
    }))
  );

  const columns = [
    {
      title: '成交季度',
      dataIndex: 'clinchQuarter',
      key: 'clinchQuarter',
    },
    {
      title: '企业名称',
      dataIndex: 'tenantCompanyName',
      key: 'tenantCompanyName',
    },
    {
      title: '面积(平方米)',
      dataIndex: 'area',
      key: 'area',
    },

    {
      title: '一级行业',
      dataIndex: 'oneIndustry',
      key: 'oneIndustry',
    },
    {
      title: '二级行业',
      dataIndex: 'twoIndustry',
      key: 'twoIndustry',
    },
    {
      title: '楼层位置',
      dataIndex: 'floorLocation',
      key: 'floorLocation',
    },
    {
      title: '成交类型',
      dataIndex: 'clinchType',
      key: 'clinchType',
    },
  ];

  const paginationChange = (page: number, pageSize: number) => {
    getSurrenderDetail({
      current: page,
      projectId: props.projectId,
      size: pageSize,
    });
  };

  const pagination = {
    current: surrenderData.current,
    pageSize: surrenderData.size,
    pageSizeOptions: [15, 50, 100],
    showSizeChanger: true,
    total: surrenderData.total,
    showTotal: (total: number) => `共 ${total} 条`,
    onChange: paginationChange,
  };

  const { scrollY } = useTableLayout({
    containerClassName: 'el-container',
    tableClassName: 'surrenderTable',
    excludeHeight: 130,
  });

  return (
    <Table
      className="surrenderTable"
      dataSource={surrenderData.records}
      columns={columns}
      pagination={pagination}
      scroll={{ y: scrollY }}
    />
  );
};
