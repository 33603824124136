import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './style.module.less';
import { memo } from 'react';
import useUserEvent from '@/hooks/useUserEvent';
import useTabOptions from './useTabOptions';

interface TabProps {
  activeTabName: string;
}

export default memo((props: TabProps) => {
  const { sendUserEvent } = useUserEvent();

  const { options } = useTabOptions();

  return (
    <div className={styles.customTabs}>
      {options.map((item: any) => (
        <Link
          id={item.value}
          key={item.value}
          to={item.value}
          className={classNames(styles.tabsBtn, {
            [styles.isActive]: props.activeTabName === item.value,
          })}
          onClick={() => {
            sendUserEvent({ button: `数据分析-${item.label}` }, 'e_click');
          }}
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
});
