import { DataTreesRes } from '@/types/apiTypes';
import {
  AuthCityData,
  AuthCityItem,
  BlockTradeDefaultTime,
  CompetitionGroup,
  QueryBlockTradeDefaultTime,
} from '../types';
import { jllRequest } from '@/utils/request';

export async function getAuthCityList(): Promise<AuthCityData> {
  const result = await jllRequest({
    url: '/user/getCityList',
    method: 'get',
  });

  Object.values(result).forEach((value: any) => {
    value.showName = value.name.replace('市', '');
    value.center = value.center.split(',')?.map((item: string) => Number(item));
  });

  return result;
}

// 返回省市区树形数据
export async function queryDataTrees() {
  const result = await jllRequest<DataTreesRes>({
    url: '/newcitycodetable/queryDataTrees',
    method: 'post',
  });
  return result;
}

export interface CityPickerOptionSecondLevel {
  label: string;
  value: string;
  children: AuthCityItem[];
}

export interface CityPickerOptionFirstLevel {
  label: string;
  value: string;
  children: CityPickerOptionSecondLevel[];
}

// 返回三个层级的数据
export async function queryCityPickerOptions(
  authCityData: AuthCityData
): Promise<CityPickerOptionFirstLevel[]> {
  const dataTrees = await queryDataTrees();
  const provinceData: Map<string, AuthCityItem[]> = new Map();
  const areaData: Map<string, AuthCityItem[]> = new Map();
  const levelData: Map<string, AuthCityItem[]> = new Map([
    ['一线', []],
    ['1.5线', []],
    ['二线', []],
    ['三线', []],
    ['四线', []],
  ]);

  const setData = (originData: Map<string, AuthCityItem[]>, key: string, value: AuthCityItem) => {
    if (!originData.get(key)) {
      originData.set(key, []);
    }
    originData.get(key)!.push({ ...value, label: value.showName, value: value.name });
  };

  dataTrees.forEach((areaItem) => {
    if (areaItem.childList) {
      areaItem.childList.forEach((provinceItem) => {
        provinceItem?.childList?.forEach((cityItem) => {
          if (authCityData[cityItem.name]) {
            setData(provinceData, provinceItem.name, authCityData[cityItem.name]);
            setData(areaData, areaItem.name, authCityData[cityItem.name]);
            setData(levelData, cityItem.level, authCityData[cityItem.name]);
          }
        });
      });
    }
  });

  levelData.forEach((_, key) => {
    if (levelData.get(key)?.length === 0) {
      levelData.delete(key);
    }
  });

  return [
    {
      label: '省份',
      value: '省份',
      children: [...provinceData.entries()].map(([key, value]) => ({
        label: key,
        value: key,
        children: value,
      })),
    },
    {
      label: '大区',
      value: '大区',
      children: [...areaData.entries()].map(([key, value]) => ({
        label: key,
        value: key,
        children: value,
      })),
    },
    {
      label: '能级',
      value: '能级',
      children: [...levelData.entries()].map(([key, value]) => ({
        label: key,
        value: key,
        children: value,
      })),
    },
  ];
}

// 投资交易数据季度
export function getBlockTradeDefaultTime(data: QueryBlockTradeDefaultTime) {
  return jllRequest<BlockTradeDefaultTime>({
    url: '/datatime/blocktrade',
    method: 'POST',
    data,
  });
}

// 查询当前用户下的所有竞品组（不包含项目那些信息）
export async function getPlainCompetitionGroups() {
  const result = await jllRequest<CompetitionGroup[]>({
    url: '/competing/queryUserCompetitionGroupDict',
    method: 'get',
  });
  return result.map((item) => ({
    label: item.name,
    value: item.competitionGroupId,
  }));
}
