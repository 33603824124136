import { ConfigProvider, themes } from 'jlld';
// import zhCN from 'jlld/locale/zh_CN'; // 引入中文包
import zhCN from 'jlld/es/locale/zh_CN';
import IndustrialAnalysis from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/index.tsx';
import RelocateAnalysis from '@/pages/D-IndustrialAnalysis/views/D-RelocateAnalysis';
import DataAnalysis from '@/pages/F-DataAnalysis';
import ProjectDetail from '@/pages/Z-ProjectDetail';
import BlockTrade from '@/pages/G-BlockTrade';
import BlockTradeTransactionDetail from '@/pages/G-BlockTrade/transactionDetail.tsx';
import MarketReport from '@/pages/H-MarketReport';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainFrameContext } from './hooks/useMainFrameContext.ts';
import Empty from './components/Empty/index.tsx';
import MarketState from './pages/F-DataAnalysis/views/A-MarketState/index.tsx';
import DataIndustryAnalysis from './pages/F-DataAnalysis/views/B-IndustryAnalysis/index.tsx';
import DealAnalysis from './pages/F-DataAnalysis/views/C-DealAnalysis/index.tsx';
import FutureSupply from './pages/F-DataAnalysis/views/D-FutureSupply/index.tsx';
import '@/store/shareStore.ts';
import useMainStore from './store/main.ts';
import 'dayjs/locale/zh-cn';
import './global.less';
import './overrides.less';
const { jll } = themes;

const PageMap: Record<string, ReactDOM.Root | null> = {
  'project-detail': null,
  'industrial-analysis': null,
  'industrial-analysis-relocate-analysis': null,
  'market-report': null,
  'block-trade': null,
};

(window as any).renderJllReact = async (container: HTMLElement, pageName: string, data: any) => {
  PageMap[pageName] = ReactDOM.createRoot(container as HTMLElement);
  const appLoading = useMainStore.getState().loading;

  if (appLoading) {
    await new Promise((resolve) => {
      useMainStore.subscribe(() => {
        if (!useMainStore.getState().loading) {
          resolve(true);
        }
      });
    });
  }

  switch (pageName) {
    case 'project-detail': {
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <ProjectDetail />
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }
    case 'industrial-analysis-relocate-analysis': {
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <RelocateAnalysis />
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }
    case 'industrial-analysis-industry-distribution': {
      // useFormStore.getState().queryIndustryOptions();
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <IndustrialAnalysis />
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }

    case 'market-report': {
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <MarketReport />
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }
    case 'data-analysis': {
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <BrowserRouter basename="/data-analysis">
              <Routes>
                <Route
                  path="/"
                  element={<DataAnalysis />}
                >
                  <Route
                    path="market-state"
                    element={<MarketState />}
                  />
                  <Route
                    path="industry-analysis"
                    element={<DataIndustryAnalysis />}
                  />
                  <Route
                    path="deal-analysis"
                    element={<DealAnalysis />}
                  />
                  <Route
                    path="future-supply"
                    element={<FutureSupply />}
                  />
                  <Route
                    path="/*"
                    element={<MarketState />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }
    case 'block-trade': {
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <BlockTrade />
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }
    case 'block-trade-transaction-detail': {
      PageMap[pageName]?.render(
        <MainFrameContext.Provider value={{ data }}>
          <ConfigProvider
            locale={zhCN}
            theme={{ ...jll }}
            renderEmpty={() => <Empty />}
          >
            <BlockTradeTransactionDetail />
          </ConfigProvider>
        </MainFrameContext.Provider>
      );
      break;
    }
  }
};

(window as any).unmountJllReact = (pageName: string) => {
  PageMap[pageName]?.unmount();
};
