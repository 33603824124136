export enum ReportTypes {
  'cityQuarterlyReport' = '1',
  'industryReport' = '2',
  'other' = '3',
}

export const reportTypesOptions = [
  {
    label: '城市季度报告',
    value: ReportTypes.cityQuarterlyReport,
  },
  {
    label: '行业报告',
    value: ReportTypes.industryReport,
  },
  {
    label: '其它类型报告',
    value: ReportTypes.other,
  },
];
