import { JLLForm, Skeleton } from 'jlld';
import styles from './style.module.less';

const formConfig = [
  '交易时间',
  '资产类型',
  '城市',
  '行政区/子市场',
  '买方企业类型',
  '卖方企业类型',
  '交易面积(㎡)',
  '交易金额(万元)',
  '交易单价(万元/㎡)',
];

const FormLoadingSkeletion = () => {
  return (
    <div className={styles.leftFormsContainer}>
      <JLLForm
        className={styles.leftForm}
        layout="vertical"
      >
        {formConfig.map((label) => (
          <JLLForm.Item
            key={label}
            label={label}
          >
            <Skeleton.Input
              style={{ width: 269 }}
              active
            />
          </JLLForm.Item>
        ))}
      </JLLForm>
      <div className={styles.leftFormFooter}>
        <Skeleton.Button style={{ margin: '0 8px' }} />
        <Skeleton.Button />
      </div>
    </div>
  );
};

export default FormLoadingSkeletion;
