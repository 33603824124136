import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { LeftFormData } from '../views/LeftForms/formContext';
import { PickerType } from 'jlld/es/JLLDatePicker';
import { LeftFormQueryParams } from '../types';
import { defaultTimeRange } from '../config';
import { getBlockTradeDefaultTime } from '@/api';
import { CityHead } from '@/types/apiTypes';
import { propertyTypeOptions } from '@/constants/options';

export const getTimeRange = async (timeRange: string[], type?: PickerType) => {
  if (!timeRange || !timeRange.length) {
    return Promise.resolve(null);
  }
  let [startTime, endTime] = [timeRange[0], timeRange[timeRange?.length - 1]];
  if (!startTime || !endTime) {
    return Promise.resolve([startTime, endTime]);
  }
  const [start, end] = defaultTimeRange;
  // 存在交集
  if (!(endTime < start || end < startTime)) {
    startTime = start > startTime ? start : startTime;
    endTime = end < endTime ? end : endTime;
  }
  if (type === 'year') {
    startTime = startTime.split('-')[0];
    endTime = endTime.split('-')[0];
  }
  return Promise.resolve(startTime || endTime ? [startTime, endTime] : null);
};

const initState = {
  leftFormQueryParams: {
    startTime: '',
    endTime: '',
  } as LeftFormQueryParams,
  quarterList: [],
  yearList: [],
  defaultTimeLoading: true,
};

const useLeftFormStore = create(
  immer(
    combine(initState, (set) => ({
      getDefaultTime: async (cityList?: string[], type?: PickerType) => {
        const result = await getBlockTradeDefaultTime({
          cityList,
          queryTypeList: [1, 2],
        });
        let startTime = '';
        let endTime = '';
        const { quarterList, yearList } = result || {};
        [startTime, endTime] =
          (await getTimeRange((type === 'year' ? yearList : quarterList) || [], type)) || [];

        set((state) => ({
          leftFormQueryParams: { ...state.leftFormQueryParams, startTime, endTime },
          defaultTimeLoading: false,
          quarterList,
          yearList,
        }));
        return { startTime, endTime };
      },
      setLeftFormQueryParams: (formData: LeftFormData, subAreaType: 'districts' | 'submarkets') => {
        const {
          buyerType,
          levelCategory,
          timeRange,
          sellerType,
          tradeArea,
          tradeTotalPrice,
          tradeUnitPrice,
          cityList,
          districtsSubmarkets,
        } = formData;

        const leftFormQueryParams: LeftFormQueryParams = {};

        leftFormQueryParams.startArea = tradeArea?.[0];
        leftFormQueryParams.endArea = tradeArea?.[1];
        leftFormQueryParams.startTotalPrice = tradeTotalPrice?.[0];
        leftFormQueryParams.endTotalPrice = tradeTotalPrice?.[1];
        leftFormQueryParams.startUnitPrice = tradeUnitPrice?.[0];
        leftFormQueryParams.endUnitPrice = tradeUnitPrice?.[1];
        leftFormQueryParams.buyerType = buyerType;
        leftFormQueryParams.sellerType = sellerType;
        if (levelCategory && Object.keys(levelCategory).length > 0) {
          leftFormQueryParams.assetCategoryList = Object.entries(levelCategory)
            .filter(([_, secondLevelCategory]) => {
              return secondLevelCategory.length > 0;
            })
            .map(([firstLevelCategory, secondLevelCategory]) => {
              if (
                firstLevelCategory === secondLevelCategory[0] &&
                propertyTypeOptions.find((item) => item.value === firstLevelCategory)?.children
                  .length === 1
              ) {
                secondLevelCategory = [];
              }
              return {
                firstLevelCategory,
                secondLevelCategory,
              };
            });
        } else {
          leftFormQueryParams.assetCategoryList = [];
        }
        leftFormQueryParams.startTime = timeRange?.[0];
        leftFormQueryParams.endTime = timeRange?.[1];

        const cityHeads = cityList?.map((cityName) => {
          if (districtsSubmarkets && districtsSubmarkets[cityName]) {
            const result = { cityName } as CityHead;
            if (subAreaType === 'districts') {
              result.adminAreaList = districtsSubmarkets[cityName];
            } else {
              result.subMarketList = districtsSubmarkets[cityName];
            }
            return result;
          }
          return { cityName };
        });

        leftFormQueryParams.cityHeads = cityHeads;

        set({ leftFormQueryParams });

        return leftFormQueryParams;
      },

      resetLeftFormQueryParams: () => {
        set({ leftFormQueryParams: initState.leftFormQueryParams, defaultTimeLoading: true });
      },
    }))
  )
);

export default useLeftFormStore;
