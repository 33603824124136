import { Table } from 'jlld';
import classNames from 'classnames';
import AuthWrapWithTooltip from '@/components/AuthWrapWithTooltip';
import { reportListColumns } from './config';
import useMarketReportStore from '../../store';
import { Report } from '../../store/types';
import styles from './style.module.less';
import './style.less';

const onDownloadClick = (report: Report) => {
  const { downloadUrl } = report;
  if (downloadUrl) {
    window.open(downloadUrl, '_blank');
  }
};

const onPreviewClick = (report: Report) => {
  const { previewUrl } = report;
  if (previewUrl) {
    window.open(previewUrl, '_blank');
  }
};

export default () => {
  const {
    currentPage,
    pageSize,
    total,
    sortType,
    reportList,
    formData,
    setTableChange,
    queryReportList,
  } = useMarketReportStore();

  const columns = [
    ...reportListColumns.map((column) => ({ ...column, className: styles.reportListColumn })),
    {
      title: '操作',
      key: 'opt',
      width: '8%',
      className: styles.reportListColumn,
      render: (_: any, record: Report) => {
        return (
          <>
            {record.reviewType === 1 && (
              <AuthWrapWithTooltip
                auth={'front:research-report:download'}
                key="download"
                className={styles.downloadButton}
                icon="download"
                iconSize={16}
                iconColor="#667579"
                align={{ offset: [-60, -10] }}
                onClick={() => onDownloadClick(record)}
              />
            )}
            <AuthWrapWithTooltip
              auth={'front:research-report:preview'}
              key="preview"
              className={styles.previewButton}
              icon="preview"
              iconSize={16}
              iconColor="#667579"
              align={{ offset: [-60, -10] }}
              onClick={() => onPreviewClick(record)}
            />
          </>
        );
      },
    },
  ];

  const pagination = {
    current: currentPage,
    pageSize,
    pageSizeOptions: [15, 50, 100],
    total,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共 ${total} 条`,
  };

  const onTableChange = (pagination: any, filters: any, sort: any) => {
    const { current, pageSize } = pagination;
    const order = sort.order === 'ascend' ? 1 : 2;
    setTableChange(!order || order === sortType ? current : 1, pageSize, order);
    queryReportList(formData);
  };

  return (
    <div className={styles.reportList}>
      <Table
        rowKey="index"
        className={classNames('reportTable', styles.reportTable)}
        scroll={{ y: 'calc(100% - 60px)' }}
        columns={columns}
        dataSource={reportList.map((report, index) => ({ ...report, index }))}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};
