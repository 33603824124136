import React, { memo, useRef } from 'react';
import { Marker } from '@uiw/react-amap-marker';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';
import DistrictAndMarketInfoCard from '../DistrictAndMarketInfoCard';
import AreaPolygon, { AreaPolygonType } from '@/components/AreaPolygon';
import useZoomChange from 'jlld/es/JLLMap/useZoomChange';
import { Position } from 'jlld/es/JLLMap/types';

function checkIntersect(rect1: DOMRect, rect2: DOMRect): boolean {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}

export interface PathItem {
  name: string;
  path: Position[] | Position[][];
  center: Position;
  isEmpty: boolean;
}

export interface DistrictAndMarketPolygonProps {
  pathList: PathItem[];
  showType?: 'borderOnly';
}

export default memo((props: DistrictAndMarketPolygonProps) => {
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const timer = useRef<NodeJS.Timeout>();
  const windowTimer = useRef<NodeJS.Timeout>();
  useZoomChange(() => {
    // 计算边界碰撞，如果有重叠区域，第二个重叠的开始隐藏
    // 转为数组
    const allMarkers = [...document.querySelectorAll('.areaNameDomMark')] as HTMLElement[];

    allMarkers.forEach((element) => {
      element.style.display = 'block';
    });
    const checkAndHide = (list: HTMLElement[]) => {
      const nextMarkerList: HTMLElement[] = [];
      const currentMarker = list[0];

      for (let i = 1; i < list.length; i++) {
        if (
          checkIntersect(currentMarker.getBoundingClientRect(), list[i].getBoundingClientRect())
        ) {
          list[i].style.display = 'none';
        } else {
          nextMarkerList.push(list[i]);
        }
      }
      if (nextMarkerList.length) {
        checkAndHide(nextMarkerList);
      }
    };
    checkAndHide(allMarkers);
  });

  const onWindowMouseOver = () => {
    setTimeout(() => clearTimeout(timer.current));
  };
  const onWindowMouseOut = () => {
    windowTimer.current = setTimeout(() => {
      setActiveId(null);
    }, 400);
  };

  return props.pathList.map((item) => {
    let styleType: AreaPolygonType;
    // if (mapMode === 'heatMapMode' || mapMode === 'areaMarksMode') {
    if (props.showType) {
      styleType = props.showType;
    } else if (item.isEmpty) {
      styleType = 'empty';
    } else {
      styleType = 'normal';
    }
    return (
      <React.Fragment key={item.name}>
        <AreaPolygon
          type={styleType}
          path={item.path}
          zIndex={9999}
          onMouseOver={() => {
            clearTimeout(windowTimer.current);
            clearTimeout(timer.current);
            setActiveId(item.name);
          }}
          onMouseOut={() => {
            timer.current = setTimeout(() => {
              setActiveId(null);
            }, 400);
          }}
        />
        {/* <LabelMarker
          name={item.name}
          zooms={[8, 13]}
          key={item.name}
          zIndex={200}
          text={{
            content: item.name,
            direction: 'top',
            offset: [0, 0],
            style: {
              strokeColor: '#ffffff',
              fontSize: 14,
              fillColor: '#60666E',
              strokeWidth: 4,
              backgroundColor: 'rgba(0,0,0,0)',
            },
          }}
          position={item.center}
          onMouseOver={(e) => {
            console.log('label over');
          }}
          onMouseOut={() => {
            console.log('label out');
          }}
          onMouseMove={() => {
            console.log('move');
          }}
        /> */}

        <Marker
          position={item.center}
          anchor="center"
          offset={[0, 2]}
        >
          <div
            className="textStrokeWhite areaNameDomMark"
            style={{
              position: 'absolute',
              fontSize: 14,
              color: '#60666E',
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {item.name}
          </div>
        </Marker>
        {!item.isEmpty && activeId === item.name && styleType === 'normal' && (
          <AutoFitInfoWindow
            visible
            arrowStyle={{ backgroundColor: '#fff' }}
            position={item.center}
            onMouseEnter={onWindowMouseOver}
            onMouseLeave={onWindowMouseOut}
          >
            <DistrictAndMarketInfoCard activeItem={item} />
          </AutoFitInfoWindow>
        )}
      </React.Fragment>
    );
  });
});
