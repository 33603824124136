import React, { memo, useRef, useState } from 'react';
import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import { Marker } from '@uiw/react-amap-marker';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';
import DistrictAndMarketInfoCard from '../DistrictAndMarketInfoCard';
import AreaPolygon, { AreaPolygonType } from '@/components/AreaPolygon';
import CircleSVG from '@/components/CircleSVG';
import useZoomChange from 'jlld/es/JLLMap/useZoomChange';
import { Position } from 'jlld/es/JLLMap/types';
import styles from './style.module.less';

function checkIntersect(rect1: DOMRect, rect2: DOMRect): boolean {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}

const MarkerStyle = {
  dot: 'dot',
  label: 'label',
};

export interface PathItem {
  areaName?: string;
  name?: string;
  path: Position[] | Position[][];
  center: Position;
  isEmpty: boolean;
  current?: boolean;
  tenantNum: string;
  selfTenantNum: number;
  otherTenantNum: number;
  otherSourceTenantNum: number;
}

export interface DistrictAndMarketPolygonProps {
  pathList: PathItem[];
  showType?: 'borderOnly';
}

// TODO 把这个组件搞成通用的
export default memo((props: DistrictAndMarketPolygonProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [highlightArea, setHighlightArea] = useState<string | null>(null);
  const [markerStyle, setMarkerStyle] = useState(MarkerStyle.dot);
  const timer = useRef<NodeJS.Timeout>();
  const windowTimer = useRef<NodeJS.Timeout>();
  useZoomChange((zoom) => {
    // 计算边界碰撞，如果有重叠区域，第二个重叠的开始隐藏
    // 转为数组
    const allMarkers = [...document.querySelectorAll('.areaNameDomMark')] as HTMLElement[];
    setMarkerStyle(zoom > 13 ? MarkerStyle.label : MarkerStyle.dot);
    allMarkers.forEach((element) => {
      element.style.display = 'block';
    });
    const checkAndHide = (list: HTMLElement[]) => {
      const nextMarkerList: HTMLElement[] = [];
      const currentMarker = list[0];

      for (let i = 1; i < list.length; i++) {
        if (
          checkIntersect(currentMarker.getBoundingClientRect(), list[i].getBoundingClientRect())
        ) {
          list[i].style.display = 'none';
        } else {
          nextMarkerList.push(list[i]);
        }
      }
      if (nextMarkerList.length) {
        checkAndHide(nextMarkerList);
      }
    };
    checkAndHide(allMarkers);
  });

  const getCircleData = (item: PathItem) => {
    const { selfTenantNum, otherTenantNum, otherSourceTenantNum } = item;
    const total = (selfTenantNum || 0) + (otherTenantNum || 0) + (otherSourceTenantNum || 0);
    const result = [];
    if (selfTenantNum) {
      result.push({
        color: '#D1B9A7',
        percent: selfTenantNum / total,
      });
    }
    if (otherTenantNum) {
      result.push({
        color: '#40798D',
        percent: otherTenantNum / total,
      });
    }
    if (otherSourceTenantNum) {
      result.push({
        color: '#D4D4D4',
        percent: otherSourceTenantNum / total,
      });
    }
    return result;
  };

  return props.pathList.map((item) => {
    let styleType: AreaPolygonType;
    if (props.showType) {
      styleType = props.showType;
    } else if (item.isEmpty) {
      styleType = 'relocateEmpty';
    } else if (item.current) {
      if (item.areaName === highlightArea) {
        styleType = 'relocateCurrentHighlight';
      } else {
        styleType = 'relocateCurrent';
      }
    } else {
      if (item.areaName === highlightArea) {
        styleType = 'relocateOtherHighlight';
      } else {
        styleType = 'relocateOther';
      }
    }
    return (
      <React.Fragment key={item.areaName || item.name}>
        <AreaPolygon
          type={styleType}
          path={item.path}
          zIndex={9999}
          onMouseOver={() => {
            clearTimeout(windowTimer.current);
            clearTimeout(timer.current);
            setHighlightArea(item.areaName! || item.name!);
          }}
          onMouseOut={() => {
            timer.current = setTimeout(() => {
              setHighlightArea(null);
            }, 400);
          }}
        />
        {markerStyle === MarkerStyle.dot ? (
          <Marker
            position={item.center}
            anchor="center"
            onClick={() => {
              setActiveId(item.areaName! || item.name!);
            }}
            zIndex={item.current ? 100 : 10}
          >
            <div className={styles.markerDom}>
              {item.current ? (
                <div className={styles.currentCircle}>
                  <CircleSVG
                    data={getCircleData(item)}
                    strokeWidth={4}
                    radius={42}
                    value={item.tenantNum}
                  />
                </div>
              ) : !item.isEmpty ? (
                <div className={classNames(styles.dot, styles.otherDot)}>{item.tenantNum}</div>
              ) : (
                <div className={classNames(styles.emptyAreaName)}>{item.areaName || item.name}</div>
              )}
            </div>
          </Marker>
        ) : (
          <Marker
            position={item.center}
            anchor="center"
            onClick={() => {
              setActiveId(item.areaName || item.name);
            }}
          >
            {!item.isEmpty ? (
              <div className={styles.markerDom}>
                <div className={classNames(styles.expendDot, { [styles.current]: item.current })}>
                  {item.current ? (
                    <JLLSvg
                      icon="location_filled"
                      size={16}
                      color="#FFF"
                    />
                  ) : (
                    <></>
                  )}
                  <p
                    className={classNames(styles.areaName, {
                      [styles.currentAreaName]: item.current,
                    })}
                  >
                    {item.areaName || item.name}
                  </p>
                  {item.current ? <></> : <p>{item.tenantNum}</p>}
                </div>
              </div>
            ) : (
              <div className={classNames(styles.emptyAreaName)}>{item.areaName || item.name}</div>
            )}
          </Marker>
        )}

        {!item.isEmpty && activeId === item.areaName && styleType !== 'relocateEmpty' && (
          <AutoFitInfoWindow
            visible
            arrowStyle={{ backgroundColor: '#fff' }}
            position={item.center}
          >
            <DistrictAndMarketInfoCard
              data={item}
              onClose={() => {
                setActiveId(null);
              }}
            />
          </AutoFitInfoWindow>
        )}
      </React.Fragment>
    );
  });
});
