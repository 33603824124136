import classNames from 'classnames';
import ReactECharts from 'echarts-for-react';
import styles from './style.module.less';
import useProjectDetailStore from '../../store';

const config = ['硬件配套指数', '服务营运指数', '周边配套指数', '交通便利指数', '商业聚焦指数'];

const IntelligentInsights = () => {
  const data = useProjectDetailStore((state) => state.intelligentInsightsData);
  let option;
  if (data) {
    const { businessIndex, hardwareIndex, livingIndex, operationIndex, trafficIndex } = data;
    option = {
      tooltip: {
        trigger: 'axis',
      },
      radar: {
        indicator: config.map((item) => ({ name: item, max: 10 })),
        axisName: {
          color: '#60666E',
          fontSize: '14px',
          fontFamily: 'Source Sans Pro',
        },
        axisLine: {
          lineStyle: {
            color: '#DDDFE1',
          },
        },
      },
      series: [
        {
          name: '智能洞察',
          type: 'radar',
          color: '#8DA6EF',
          tooltip: {
            trigger: 'item',
            borderColor: 'transparent',
            textStyle: {
              fontFamily: 'Source Sans Pro',
              fontSize: '12px',
              lineHeight: '16px',
              color: '#60666E',
            },
            formatter(params) {
              const str = params.value.map(
                (
                  item,
                  index
                ) => `<div style="padding: 6px;font-family: 'Source Sans Pro';font-size: 12px;">
                  <span style="padding-bottom: 6px;color: #60666E;">${config[index]}</span>
                  <span style="padding-left: 80px;color: #01151c;">${item || '--'}</span></div>`
              );
              return `<div>${str.join('')}</div>`;
            },
          },
          data: [
            { value: [hardwareIndex, operationIndex, livingIndex, trafficIndex, businessIndex] },
          ],
        },
      ],
    };
  }

  return (
    <div className={classNames('detailTabView', styles.intelligentInsights)}>
      <div className={styles.chartView}>
        <ReactECharts
          style={{ width: '800px', height: '550px', margin: '120px auto 0' }}
          option={option}
          notMerge
        />
      </div>
      <div className={styles.footer}>
        <span>更多洞察内容敬请期待，如果您有更多的建议和想法可发送邮件到睿见客服邮箱</span>{' '}
        <span>ruijian.data@jll.com</span>
      </div>
    </div>
  );
};

export default IntelligentInsights;
