import {
  getBusinessFloorDetail,
  getBusinessTenantAnalysis,
  getBusinessTenantIFSProject,
  getTenantExistsTime,
} from '@/pages/Z-ProjectDetail/api';
import {
  getBusinessFloorDetailParams,
  getBusinessTenantAnalysisParams,
  getBusinessTenantIFSProjectParams,
} from '@/pages/Z-ProjectDetail/types/apiTypes';

export default {
  getExistsTime: async (projectId: string) => {
    const result = await getTenantExistsTime(projectId);
    return result.reverse();
  },
  // todo: 没有权限的用户
  getTenantAnalysis: async (data: getBusinessTenantAnalysisParams) => {
    const result = await getBusinessTenantAnalysis(data);
    const { rankingVOS, tenantAnalysis, industryFromVOS, tenantArea, typeFromVOS } = result;
    return {
      rankingList: [...rankingVOS],
      tenantProportion: {
        newRentRate: tenantAnalysis['新租率'],
        survivalRate: tenantAnalysis['存续率'],
        vacancyRate: tenantAnalysis['空置率'],
      },
      industryFormList: [...industryFromVOS],
      tenantArea,
      typeFormList: [...typeFromVOS],
    };
  },
  getTenantIFS: async (data: getBusinessTenantIFSProjectParams) => {
    const result = await getBusinessTenantIFSProject(data);
    return {
      vacantRate: result.vacantRate,
      occupancyRate: result.occupancyRate,
      titleList: result.industryList,
      floorList: result.floorMap.map((item) => ({
        floor: item.floorId,
        floorBusId: item.floorVOS.floorVOList?.[0]?.floorId,
        rentedArea: item.floorVOS.rentedArea,
        vacantArea: item.floorVOS.vacantArea,
        floorTenantList: item.floorVOS.floorVOList.map((floor) => ({
          rowTitle: floor.name,
          specificField: floor.percentage,
        })),
      })),
    };
  },
  getTenantDetail: async (data: getBusinessFloorDetailParams) => {
    const result = await getBusinessFloorDetail(data);
    return result?.[0]?.floorTenantVOS?.map((item: any) => ({
      name: item.tenantName,
      leaseArea: item.leaseArea,
      oneIndustry: item.tenantOneIndustry,
      twoIndustry: item.tenantTwoIndustry,
      tenantSource: item.tenantSource,
    }));
  },
};
