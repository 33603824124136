import { JLLDatePicker, JLLForm } from 'jlld';
import styles from '../../style.module.less';

const DeliveryTimeFormItem = () => {
  return (
    <JLLForm.Item
      name="deliveryTime"
      label={<span style={{ color: 'rgba(0, 0, 0, 0.88)' }}>交付时间</span>}
    >
      <JLLDatePicker.JLLQuarterPicker
        isRange
        type="year"
        className={styles.timePicker}
      />
    </JLLForm.Item>
  );
};

export default DeliveryTimeFormItem;
