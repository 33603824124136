import { useEffect } from 'react';
import dayjs from 'dayjs';
import { JLLDatePicker } from 'jlld';
import styles from './style.module.less';
import Charts from '../../../../components/Charts';
import classNames from 'classnames';
import useStabilityStore from './store';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import HorizontalDomBarLineChart from '../../../../components/BarLineChart';

const StabilityAnalysis = () => {
  const {
    quarter,
    withdrawalTopChartData,
    renewTopChartData,
    renewBottomBarData,
    withdrawalBottomBarData,
    setQuarter,
    initData,
    queryViewData,
    existQuarterList,
  } = useStabilityStore();
  const { data: mainFrameData } = useMainFrameContext();

  useEffect(() => {
    initData(mainFrameData?.params?.id);
  }, []);

  const renewBottomBarDataOptions = [
    {
      title: '当期续租率来源',
      dataList: renewBottomBarData?.resource,
    },
    {
      title: '当期续租率面积段',
      dataList: renewBottomBarData?.area,
    },
    {
      title: '当期续租率行业',
      dataList: renewBottomBarData?.industry,
    },
  ];

  const withdrawalBottomBarDataOptions = [
    {
      title: '当期退租率来源',
      dataList: withdrawalBottomBarData?.resource,
    },
    {
      title: '当期退租率面积段',
      dataList: withdrawalBottomBarData?.area,
    },
    {
      title: '当期退租率行业',
      dataList: withdrawalBottomBarData?.industry,
    },
  ];

  return (
    <div className={classNames('detailTabView', styles.stabilityView)}>
      <div className="topForms">
        <JLLDatePicker
          allowClear={false}
          style={{ backgroundColor: '#fff' }}
          onChange={(value) => {
            // TODO 这里的类型有问题，需要后续升级JLLD才行
            setQuarter(value as string);
            queryViewData(mainFrameData?.params?.id, value as string);
          }}
          value={quarter as any}
          picker="quarter"
          disabledDate={(current) => {
            return current && existQuarterList.indexOf(dayjs(current).format('YYYY-[Q]Q')) === -1;
          }}
        />
      </div>
      <div className={styles.chartsContainer}>
        <div className={styles.multiChartsCard}>
          <Charts
            title="续租率"
            tips="当期租户存续未变化面积除以基期总承用面积，分子为当期，分母为基期。更新频率：季度或年度更新。"
            data={renewTopChartData}
          />
          {renewBottomBarDataOptions.map((item) => (
            <HorizontalDomBarLineChart
              key={item.title}
              className={styles.stabilityBarLine}
              title={item.title}
              lineDataList={item.dataList}
            />
          ))}
        </div>
        <div className={styles.multiChartsCard}>
          <Charts
            title="退租率"
            tips="当期租户退租面积除以基期总承用面积，分子为当期，分母为基期。更新频率：季度或年度更新。"
            data={withdrawalTopChartData}
            config={{
              color: ['#666666'],
            }}
          />
          {withdrawalBottomBarDataOptions.map((item) => (
            <HorizontalDomBarLineChart
              key={item.title}
              className={styles.stabilityBarLine}
              title={item.title}
              lineDataList={item.dataList}
              color="#666666"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StabilityAnalysis;
