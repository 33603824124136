import { useImperativeHandle, forwardRef } from 'react';
import { PageWithWatermark } from '@/components';
import { getPdf } from '@/utils/html2pdf';
import { IndustryBaseInfo, IndustryLocationInfo } from '../../types/apiTypes';
import '../style.less';

interface IndustryBuildingExportProps {
  ref: any;
  buildingName: string;
  information: any[];
  exportImages: any[];
  exportBaseInfo: IndustryBaseInfo;
  exportLocationInfo: IndustryLocationInfo;
}

const sliceData = (data, gap = 2) => {
  const arr = [];
  for (let i = 0; i < data.length; i += gap) {
    arr.push(data.slice(i, i + 2));
  }
  return arr;
};

const IndustryBuildingExport = forwardRef<IndustryBuildingExportProps, IndustryBuildingExportProps>(
  (props, ref) => {
    const { information, exportImages, buildingName } = props;

    const getExportInformation = () => {
      const tempInformation: any[] = [];
      information.forEach((item, index) => {
        const { title, children } = item;
        // 本地开发，图片地址跨域，用本地图片
        const formConfig = children.reduce((prev, cur) => prev.concat(cur.formConfig), []);
        tempInformation.push({ title, formConfig: sliceData(formConfig) });
      });
      const imageAry =
        process.env.NODE_ENV === 'development'
          ? [
              {
                fileUrl: `${window.location.origin}/production-detail/mock.jpg`,
                fileId: Math.random(),
              },
              {
                fileUrl: `${window.location.origin}/production-detail/mock2.jpg`,
                fileId: Math.random(),
              },
              {
                fileUrl: `${window.location.origin}/production-detail/Design_51_P45.jpg`,
                fileId: Math.random(),
              },
            ]
          : exportImages;
      tempInformation.unshift({ type: 'image', title: '', images: [imageAry, imageAry] });
      return tempInformation;
    };

    const exportInformation = getExportInformation();

    const exportPdf = (title: string) => {
      const exportPDFFrame = document
        .getElementById('exportIndustryBuildingPDF')
        ?.contentDocument.getElementById('PDF-INDUSTRY-BUILDING');
      const exportPDFImageFrame = document
        .getElementById('exportIndustryBuildingPDFImage')
        ?.contentDocument.getElementById('PDF-IMAGE-INDUSTRY-BUILDING');

      return getPdf({
        title,
        el: [exportPDFImageFrame, exportPDFFrame],
        warterEl: exportPDFFrame,
      });
    };

    useImperativeHandle(ref, () => ({
      exportPdf,
    }));

    const appendIframeTemplate = () => {
      const exportPDFFrame = document?.getElementById('exportIndustryBuildingPDF')?.contentDocument?.getElementById('PDF-INDUSTRY-BUILDING');
      const inforTemplate = document.querySelectorAll('.export-information');
      const warter = document.querySelector('.html2pdf-warter-marker');
      inforTemplate.forEach((item) => {
        exportPDFFrame?.appendChild(item);
      });
      exportPDFFrame?.appendChild(warter);
    };

    const appendIframeTemplateImage = () => {
      const exportPDFFrame = document?.getElementById('exportIndustryBuildingPDFImage')?.contentDocument?.getElementById('PDF-IMAGE-INDUSTRY-BUILDING');
      const inforTemplate = document.querySelector('.export-image-information-building');
      exportPDFFrame?.appendChild(inforTemplate);
    };

    const loadImg = ($evt) => {
      try {
        const { width: pWidth, heiht: pHeight } = $evt.target.parentNode.getBoundingClientRect();
        const { width, height } = $evt.target.getBoundingClientRect();
        if (width / height > pWidth / pHeight) {
          $evt.target.style.width = '100%';
          $evt.target.style.height = 'auto';
        } else {
          $evt.target.style.height = '100%';
          $evt.target.style.width = 'auto';
        }
      } catch (e) {
        console.log(e);
      }
    };

    const loadErr = (e) => {
      const img = e.srcElement;
      if (img) {
        img.setAttribute('src', `${window.location.origin}/production-detail/default.svg`);
        img.onerror = null;
      }
    };

    return (
      <div className="export-container">
        <iframe
          id="exportIndustryBuildingPDFImage"
          src={`${window.location.origin}/production-detail/pdf-image-industry-building.html`}
          onLoad={() => appendIframeTemplateImage()}
        ></iframe>
        <iframe
          id="exportIndustryBuildingPDF"
          src={`${window.location.origin}/production-detail/pdf-industry-building.html`}
          onLoad={() => appendIframeTemplate()}
        ></iframe>

        {exportInformation.map((infor, index) => {
          if (infor.type == 'image') {
            return (
              <div
                className="export-image-information-building"
                key={index}
              >
                <div className="export-image html2pdf-cell">
                  <div className="top-jllt-logo">
                    <img src={`${window.location.origin}/production-detail/pdf_logo.png`} />
                  </div>
                  <div className="pane-title no-bottom">
                    <div className="project-detail">
                      <div className="project-name">
                        <p className="cn-name">{buildingName}</p>
                      </div>
                    </div>
                  </div>
                  {infor.images[0].length > 0 ? (
                    <div className="export-image-box">
                      {infor.images[0].map((item: any, i: any) => (
                        <div
                          className="export-img-box"
                          key={i}
                        >
                          <div
                            className="export-blur"
                            style={{ backgroundImage: `url(${item?.fileUrl})` }}
                          />
                          <img
                            className="export-img"
                            src={item?.fileUrl}
                            onLoad={loadImg}
                            onError={loadErr}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          }
          return (
            <div
              className="export-information"
              key={index}
            >
              <div className="html2pdf-cell">
                <div className="pane-title">
                  <span className="format-title">{infor.title}</span>
                </div>
              </div>
              <div className="data-container html2pdf-cell">
                <div className="split-part left">
                  <div>
                    {infor.formConfig?.map(([item0], i: number) => (
                      <div
                        className="data-row"
                        key={i}
                      >
                        <p className="label">{item0.label}</p>
                        <p className="value">
                          {item0.value}
                          {item0.value ? item0.unit || '' : ''}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="split-part right">
                  <div>
                    {infor.formConfig?.map(([item0, item1], i: number) => {
                      return item1 ? (
                        <div
                          className="data-row"
                          key={i}
                        >
                          <p className="label">{item1.label}</p>
                          <p className="value">
                            {item1.value}
                            {item1.value ? item1.unit || '' : ''}
                          </p>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="html2pdf-warter-marker">
          <PageWithWatermark>
            <div style={{ width: '400px', height: '300px' }} />
          </PageWithWatermark>
        </div>
      </div>
    );
  }
);

export default IndustryBuildingExport;
