import styles from './style.module.less';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Building } from './type';

const BuildingList = (props: {
  busId: string;
  projectId: string;
  projectName: string;
  buildingList: Building[];
  className?: string;
  onChange: (id: string) => void;
}) => {
  const { busId, projectId, buildingList, onChange, className } = props;
  const [currentBusId, setCurrentBusId] = useState<string>();

  useEffect(() => {
    setCurrentBusId(busId);
  }, [busId]);

  const onItemClick = (item: Building) => {
    setCurrentBusId(item.businessId);
    onChange(item.businessId);
  };

  const options = [{ buildingName: '园区概览', businessId: projectId }, ...(buildingList || [])];

  return (
    <div className={classNames(styles.buildingList, className)}>
      {options.map((item) => (
        <div
          key={item.businessId}
          className={classNames(styles.item, { [styles.active]: currentBusId === item.businessId })}
          onClick={() => onItemClick(item)}
        >
          <div className={styles.content}>
            <span title={item.buildingName}>{item.buildingName}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BuildingList;
