import { Table } from 'jlld';
import './style.less';
import styles from './style.module.less';
import { ColumnsType } from 'jlld/es/table';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';
import {
  industryCompareAnalysisItem,
  regionalDistributionItem,
} from '@/pages/D-IndustrialAnalysis/types/apiTypes';

const CompareBarTable = (props: { dataType: string }) => {
  const summary = useIndustrialStore((state) => state.summary);

  const industryObj: Record<string, Record<string, regionalDistributionItem>> = {};

  summary?.industryCompareAnalysis?.forEach((item: industryCompareAnalysisItem) => {
    item?.regionalDistribution?.forEach((regionItem: regionalDistributionItem) => {
      if (!industryObj[regionItem.name]) {
        industryObj[regionItem.name] = {};
      }
      industryObj[regionItem.name][item.name] = regionItem;
    });
  });

  const allIndustry = Object.keys(industryObj);

  const dataSource = summary?.industryCompareAnalysis?.map((item) => {
    const temp: Record<string, any> = {};
    temp.name = item.name;
    temp.key = item.name;
    allIndustry.forEach((industryName) => {
      temp[industryName] =
        props.dataType === 'area'
          ? industryObj[industryName][item.name]?.areaPercentage
          : industryObj[industryName][item.name]?.countPercentage;
    });
    return temp;
  });

  dataSource?.sort((a, b) => Number(b[allIndustry[0]]) - Number(a[allIndustry[0]]));

  const getBarWidth = (num: number, industry: string) => {
    let maxValue = 0;
    let scale = '1';
    dataSource?.forEach((item) => {
      maxValue = Math.max(Number(item[industry] || 0), maxValue);
    });

    if (allIndustry.length === 1) {
      scale = (350 / maxValue).toFixed(2);
    } else if (allIndustry.length === 2) {
      scale = (130 / maxValue).toFixed(2);
    } else {
      scale = (105 / maxValue).toFixed(2);
    }
    if (maxValue === 0) {
      scale = '0';
    }
    return num * Number(scale);
  };

  const columns: ColumnsType<any> = allIndustry.map((industry) => {
    return {
      title: () => {
        return (
          <div className="headerCell">
            <p className="industryName">{industry}</p>
          </div>
        );
      },
      dataIndex: industry,
      className: 'dataCell',
      showSorterTooltip: false,
      sorter: (a, b) => {
        return (a[industry] || 0) - (b[industry] || 0);
      },
      render: (text: any, record, index: number) => (
        <div className="dataCellContent">
          <div className="dataBarWrap">
            <div
              className="dataBar"
              style={{
                width: `${getBarWidth(record[industry] || 0, industry)}px`,
              }}
            />
          </div>
          <p className="dataPercent">{record[industry] || 0}%</p>
        </div>
      ),
    };
  });

  columns.unshift({
    title: () => <div></div>,
    dataIndex: 'name',
    className: 'nameCell',
  });

  return (
    <div className="compareBarTable">
      <Table
        rowClassName={styles.tableRow}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default CompareBarTable;
