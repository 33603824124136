import { headQuarterOptions } from '@/constants/options';
import { JLLForm, JLLMultiSelector } from 'jlld';

const HeadQuarterFormItem = (props: { disabled?: boolean }) => {
  return (
    <JLLForm.Item
      name="isHeadquarters"
      noStyle
    >
      <JLLMultiSelector
        options={headQuarterOptions}
        placeholder="是否为总部楼宇"
        disabled={props.disabled}
      />
    </JLLForm.Item>
  );
};

export default HeadQuarterFormItem;
