import { useEffect, useState } from 'react';
import styles from './style.module.less';
import TransactionCard from './views/TransactionCard';
import { getBlockTrade } from '../../api';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { BlockTradeDetail } from './views/type';

const PropertyType = {
  business: 1,
  industry: 2,
};

const BlockTradeEmptyView = () => {
  return (
    <div className={styles.blockTradeEmptyView}>
      <img
        src="/empty.png"
        width={120}
        height={120}
      />
      <div className={styles.tips}>当前项目暂未获取到相关数据</div>
      <div className={styles.tips}>
        如需帮助，请联系我们通过ruijian.data@jll.com邮箱获取进一步的信息和支持
      </div>
    </div>
  );
};

export default () => {
  const { data } = useMainFrameContext();
  const [tradeData, setTradeData] = useState<BlockTradeDetail[]>();

  useEffect(() => {
    getBlockTrade({
      propertyId: data.params.id,
      propertyType: (PropertyType as any)[data.params.type],
    }).then((result: any) => {
      setTradeData(result);
    });
  }, []);

  if (!tradeData?.length) {
    return <BlockTradeEmptyView />;
  }

  return (
    <div className={styles.blockTradePage}>
      {tradeData.map((item, index) => (
        <TransactionCard
          key={index}
          data={item}
        />
      ))}
    </div>
  );
};
