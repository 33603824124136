import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import { useShallow } from 'zustand/react/shallow';
import ChartBlock from './ChartBlock';
import CityInfoCard from './CityInfoCard';
import IndustryCompare from './IndustryCompare';
import IndustryCompetitive from './IndustryCompetitive';
import RentArea from './RentArea';
import TenantSource from './TenantSource';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/views/B-IndustryDistribution/store';
import useCommonOptionsStore from '@/store/commonOptions';
import styles from './style.module.less';

const ChartView = () => {
  const [hideChart, setHideChart] = useState<boolean>(false);
  const [hideIcon, setHideIcon] = useState<boolean>(false);
  const { scaleToSubMarket, mapMode } = useIndustrialStore(
    useShallow((state) => ({
      scaleToSubMarket: state.scaleToSubMarket,
      mapMode: state.mapMode,
      summary: state.summary,
    }))
  );
  const hasData = useCommonOptionsStore((state) => state.hasData);

  useEffect(() => {
    const mode =
      mapMode === 'heatMapMode' ||
      mapMode === 'areaMarksMode' ||
      mapMode === 'heatProjectMarksMode';
    setHideChart(mode);
    setHideIcon(mode);
  }, [mapMode]);

  return (
    <div className={styles.rightSide}>
      {!hideIcon && hasData ? (
        <div
          className={styles.hideChart}
          onClick={() => setHideChart(!hideChart)}
        >
          <JLLSvg
            icon={hideChart ? 'double-left-arrow' : 'double-right-arrow'}
            size={17}
            color="#667579"
          />
        </div>
      ) : null}
      <div className={classNames(styles.ChartContainer, hideChart || !hasData ? styles.hide : '')}>
        <CityInfoCard />
        <ChartBlock>
          <IndustryCompetitive />
        </ChartBlock>
        {scaleToSubMarket ? null : (
          <ChartBlock>
            <IndustryCompare />
          </ChartBlock>
        )}
        <ChartBlock>
          <TenantSource />
        </ChartBlock>
        <ChartBlock>
          <RentArea />
        </ChartBlock>
      </div>
    </div>
  );
};

export default ChartView;
