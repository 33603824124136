import useReactShareUserStore from '@/store/reactShareUserStore';
import { Watermark } from 'jlld';
import { ReactNode } from 'react';

export default (props: { children: ReactNode }) => {
  const user = useReactShareUserStore((state) => state.user);
  return (
    <Watermark
      height={400}
      width={300}
      rotate={30}
      gap={[50, 50]}
      font={{
        color: 'rgba(0, 0, 0, 0.15)',
        fontSize: 12,
        fontFamily: 'Microsoft Yahei',
      }}
      content={[user.name, '睿见数据']}
    >
      {props.children}
    </Watermark>
  );
};
