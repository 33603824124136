import { AuthCityData } from '@/types';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { reset } from './commonOptions';
import './resetStore';
import { deepClone } from '@/utils/tool';

interface State {
  authCityData: AuthCityData;
  loading: boolean;
}

const initState: State = {
  authCityData: {},
  // 这个状态是页面表单数据加载，按需用
  loading: true,
};

const useMainStore = create(
  immer(
    combine(initState, (set) => ({
      leaveSystem: () => {
        reset();
        set(deepClone(initState));
      },
    }))
  )
);

let isSetting = false;

let isSettingVue = false;

(useMainStore as any).syncState = (state: any) => {
  if (!isSettingVue) {
    isSetting = true;
    const cities = deepClone(state.cityList);
    Object.values(cities).forEach((value: any) => {
      value.showName = value.name.replace('市', '');
      value.center = value.center.split(',')?.map((item: string) => Number(item));
    });

    useMainStore.setState({
      loading: false,
      authCityData: cities,
    });
    isSetting = false;
  }
};

useMainStore.subscribe(() => {
  if (!isSetting && (window as any).mainFrameMehtod?.piniaCityStore) {
    isSettingVue = true;
    (window as any).mainFrameMehtod.piniaCityStore().$patch(useMainStore.getState());
    isSettingVue = false;
  }
});

export default useMainStore;
