import './style.less';
import styles from './style.module.less';
import { RentalPerformance, ChartView, TopForms } from './views';
import useMarketPerformanceStore from './store';
import useProjectDetailStore from '../../store';
import classNames from 'classnames';
import { useEffect } from 'react';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { useShallow } from 'zustand/react/shallow';

export default () => {
  const { data } = useMainFrameContext();
  const { initProjectExistsTime, loadChartData } = useMarketPerformanceStore(
    useShallow((state) => ({
      initProjectExistsTime: state.initProjectExistsTime,
      loadChartData: state.loadChartData,
    }))
  );
  const projectDetail = useProjectDetailStore((state) => state.projectDetail);

  const inConstructing = () => {
    if (data.params.type === 'industry') {
      return projectDetail.baseInfo?.completionStatus !== '已交付';
    } else if (data.params.type === 'business') {
      return projectDetail.baseInfo?.dueState !== '2';
    }
  };

  const isHeadquarters = projectDetail.baseInfo?.isHeadquarters === '是';

  useEffect(() => {
    initProjectExistsTime(data.params.id, data.params.type).then(() => {
      loadChartData(data.params.id, data.params.type);
    });
  }, []);

  return (
    <div className={classNames('page marketPerformancePage', styles.marketPerformancePage)}>
      <TopForms />
      <div className={styles.scrollWrap}>
        <RentalPerformance inConstructing={inConstructing()} />
        <ChartView
          inConstructing={inConstructing()}
          isHeadquarters={isHeadquarters}
        />
      </div>
    </div>
  );
};
