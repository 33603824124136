import classNames from 'classnames';
import { memo } from 'react';
import useSurroundingStore, { TabViewData } from '../../store';
import style from './style.module.less';
import { JLLSvg } from 'jlld';
import { ServeSidePoiType, TabKey } from '@/pages/Z-ProjectDetail/types';
import { useShallow } from 'zustand/react/shallow';

interface ItemListProps {
  viewData: TabViewData;
  trafficType?: ServeSidePoiType.subway | ServeSidePoiType.bus;
  tabKey?: TabKey;
  onPageChange: (page: number) => void;
}

const ItemList = memo((props: ItemListProps) => {
  const { animateTitle, setActiveTitle } = useSurroundingStore(
    useShallow((state) => ({
      animateTitle: state.animateTitle,
      setActiveTitle: state.setActiveTitle,
    }))
  );
  const { viewData } = props;

  return (
    <div className={style.itemListContainer}>
      <div className={classNames(style.poiList, `poi-list-${props.tabKey}`)}>
        {viewData?.dataList.length === 0 ? (
          <p className={style.listEmpty}>暂未获取到相关信息</p>
        ) : (
          viewData?.dataList?.map((item, index) => (
            <div
              className={classNames(style.poiItem, `poi-title-${index}`, {
                [style.clickAnimation]: item.title === animateTitle,
              })}
              key={item.id}
              onMouseEnter={() => {
                setActiveTitle(item.title);
              }}
              onMouseLeave={() => {
                setActiveTitle('');
              }}
            >
              <div className={style.itemTitle}>
                <span>{item.title}</span>
                <span className={style.itemDistance}>约{item.distance}m</span>
              </div>
              <div className={style.itemAddress}>{item.address}</div>
            </div>
          ))
        )}
      </div>
      {viewData && viewData.allPages > 1 && (
        <div className={style.pageNavigator}>
          <JLLSvg
            className={style.pageControler}
            icon="left-arrow"
            onClick={() => {
              if (viewData && viewData.pageNo > 1) {
                props.onPageChange(viewData.pageNo - 1);
              }
            }}
          />
          {Array.from({ length: viewData.allPages }, (_, i) => i + 1).map((pageNo) => (
            <div
              className={classNames(style.pageNumItem, {
                [style.active]: viewData.pageNo === pageNo,
              })}
              key={pageNo}
              onClick={() => {
                props.onPageChange(pageNo);
              }}
            >
              {pageNo}
            </div>
          ))}
          <JLLSvg
            className={style.pageControler}
            icon="right-arrow"
            onClick={() => {
              if (viewData && viewData.pageNo < viewData.allPages) {
                props.onPageChange(viewData.pageNo + 1);
              }
            }}
          />
        </div>
      )}
    </div>
  );
});

export default ItemList;
