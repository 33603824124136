import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const useReactShareAuthStore = create(
  immer(
    combine(
      {
        auth: {},
      },
      () => ({})
    )
  )
);

let isSetting = false;

let isSettingVue = false;
const keys = ['auth'];
(useReactShareAuthStore as any).syncState = (state: any) => {
  if (!isSettingVue) {
    isSetting = true;
    // console.log('react receive auth state', state);
    const newState: any = {};

    keys.forEach((key) => {
      newState[key] = state[key];
    });
    useReactShareAuthStore.setState(newState);
    isSetting = false;
  }
};
useReactShareAuthStore.subscribe(() => {
  if (!isSetting && (window as any).mainFrameMehtod?.piniaRoutersStore) {
    // console.log('react set vue state');
    isSettingVue = true;
    (window as any).mainFrameMehtod.piniaRoutersStore().$patch(useReactShareAuthStore.getState());
    isSettingVue = false;
  }
});

export default useReactShareAuthStore;
