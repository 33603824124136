import classNames from 'classnames';
import { JLLSvg } from 'jlld';
import useRelocateTrailStore from '../../../store';
import { PropertyType } from '@/constants/enums';
import styles from './style.module.less';

interface Props {
  data: RelocateTrailMapProperty;
  currentPropertyId: string;
  onClose: () => void;
}

export interface RelocateTrailMapProperty {
  center: [number, number];
  otherSourceTenantNum: number;
  otherTenantNum: number;
  propertyGrade: string;
  propertyId: string;
  propertyName: string;
  propertyType: string;
  selfTenantNum: number;
  tenantArea: number;
  tenantNum: number;
  topicIndustrial: string;
  current?: boolean;
}

export enum RelocateTrailDimensionType {
  moveIn = '1',
  moveOut = '2',
}

const moveInTextConfig = {
  tenantNum: '迁入租户总数：',
  selfTenantNum: '来自本区域',
  otherTenantNum: '来自其他区域',
  otherSourceTenantNum: '其他来源',
  tenantArea: '迁入租户面积：',
  industry: '迁入租户代表行业',
  tipText: '* 租户进行去重统计，[当前区域迁入租户总数] 与 [迁入来源区域租户数的总和] 可能存在差异',
  onlySameProjectTipText: '* 仅存在同项目迁址信息',
  onlyOtherProjectTipText: '* 仅存在其他来源迁址信息',
};

const moveOutTextConfig = {
  tenantNum: '迁出租户总数：',
  selfTenantNum: '去往本区域',
  otherTenantNum: '去往其他区域',
  otherSourceTenantNum: '其他去向',
  tenantArea: '迁出租户面积：',
  industry: '迁出租户代表行业',
  tipText: '* 租户进行去重统计，[当前区域迁出租户总数] 与 [迁出去向区域租户数的总和] 可能存在差异',
  onlySameProjectTipText: '* 仅存在同项目迁址信息',
  onlyOtherProjectTipText: '* 仅存在其他去向迁址信息',
};

const currentPropertyConfig = {
  [RelocateTrailDimensionType.moveIn]: moveInTextConfig,
  [RelocateTrailDimensionType.moveOut]: moveOutTextConfig,
};

const otherPropertyConfig = {
  [RelocateTrailDimensionType.moveIn]: moveOutTextConfig,
  [RelocateTrailDimensionType.moveOut]: moveInTextConfig,
};

export default (props: Props) => {
  const { data, currentPropertyId } = props;
  const { selfTenantNum, otherTenantNum, otherSourceTenantNum } = data;
  const dimension = useRelocateTrailStore((state) => state.dimension);
  const topicIndustrialArr = data.topicIndustrial?.split(',');

  const config = data.current ? currentPropertyConfig : otherPropertyConfig;
  const renderConfig = config[dimension];

  const sourceCount = Object.entries(data).filter(
    ([key, value]) =>
      ['selfTenantNum', 'otherTenantNum', 'otherSourceTenantNum'].includes(key) && value
  ).length;

  const renderTenantNum = () => {
    if (sourceCount <= 1) {
      return null;
    }
    const renderData = [];
    if (selfTenantNum) {
      renderData.push({
        value: selfTenantNum,
        legendText: renderConfig.selfTenantNum,
        bgColor: '#D1B9A7',
        textColor: '#01151D',
      });
    }
    if (otherTenantNum) {
      renderData.push({
        value: otherTenantNum,
        legendText: renderConfig.otherTenantNum,
        bgColor: '#40798D',
        textColor: '#FFF',
      });
    }
    if (otherSourceTenantNum) {
      renderData.push({
        value: otherSourceTenantNum,
        legendText: renderConfig.otherSourceTenantNum,
        bgColor: '#D4D4D4',
        textColor: '#01151D',
      });
    }

    return (
      <div className={styles.subText}>
        <div style={{ display: 'flex' }}>
          {renderData.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: `${item.bgColor}`,
                color: `${item.textColor}`,
                textAlign: 'center',
                height: '24px',
                lineHeight: '24px',
                flex: `${item.value}`,
                borderRight: '2px solid #FFF',
              }}
            >
              {item.value}
            </div>
          ))}
        </div>
        <div className={styles.legendWrap}>
          {renderData.map((item, index) => (
            <div
              key={index}
              className={styles.legendItem}
            >
              <span
                style={{
                  display: 'inline-block',
                  backgroundColor: `${item.bgColor}`,
                  width: '12px',
                  height: '12px',
                  marginRight: '4px',
                }}
              />
              {item.legendText}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTipText = () => {
    if (sourceCount >= 2) {
      return renderConfig.tipText;
    } else if (sourceCount === 1) {
      if (selfTenantNum) {
        // 仅同项目
        return renderConfig.onlySameProjectTipText;
      } else if (otherTenantNum) {
        // 仅其他来源
        return renderConfig.onlyOtherProjectTipText;
      }
    } else if (otherTenantNum === 1) {
      // 仅其他项目
      return null;
    }
  };

  return (
    <div className={styles.districtAndMarketInfoCard}>
      <div
        className={styles.close}
        onClick={props.onClose}
      >
        <JLLSvg
          icon="x"
          size={20}
          color="#A4A9B0"
        />
      </div>
      <p className={styles.propertyName}>{data.areaName}</p>
      <div className={styles.relocateInfo}>
        <div className={styles.mainText}>
          {renderConfig.tenantNum} {data.tenantNum} 个
        </div>
        {renderTenantNum()}
        <div className={classNames(styles.mainText, styles.area)}>
          {renderConfig.tenantArea} {data.tenantArea} ㎡
        </div>
        <div className={styles.mainText}>{renderConfig.industry}</div>
        <div className={styles.subText}>
          {topicIndustrialArr?.map((item, index) => {
            return (
              <>
                <span>{item}</span>
                {index !== topicIndustrialArr.length - 1 && (
                  <div className={styles.verticalDivider}></div>
                )}
              </>
            );
          })}
        </div>
      </div>
      {data.propertyId === currentPropertyId ? (
        <p className={styles.tipText}>{renderTipText()}</p>
      ) : null}
    </div>
  );
};
