import ReactECharts from 'echarts-for-react';
import { useRef, useImperativeHandle, forwardRef } from 'react';
import { message } from 'jlld';
import { useOnload } from '@/pages/F-DataAnalysis/hooks/useOnload';
import useFutureSupplyStore, { queryFutureChart, setLoading } from '../../store';
import { thousandSplitNumber } from '@/utils/tool';
import { downloadChart } from '@/utils/downloadChart';
import { itemColor } from '@/components/Charts/config';
import Empty from '@/components/Empty';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';

export interface ChartRef {
  downloadChartImg: () => void;
}

const Chart = forwardRef<ChartRef>((props, ref) => {
  const chartRef = useRef(null);
  const chartData = useFutureSupplyStore((state) => state.chartData);

  const downloadChartImg = () => {
    if (chartData?.futureSupplyChartListVO?.length === 0) {
      message.error('图表暂无数据，不可下载！');
      return;
    }
    downloadChart(chartRef, `${Date.now()}未来供应`);
  };

  useImperativeHandle(ref, () => ({
    downloadChartImg,
  }));

  useOnload(queryFutureChart, ShowDataTabType.chart, setLoading);
  const loading = useFutureSupplyStore((state) => state.loading.chart);
  if (loading) {
    return null;
  }

  const getSeries = (data) => {
    if (!data) {
      return [];
    }
    const { exampleList, futureSupplyChartListVO } = data;
    const series = {};
    exampleList.forEach((item) => {
      series[item] = [];
    });
    futureSupplyChartListVO.forEach((dataItem, i) => {
      dataItem.stackingDiagramList.forEach((item) => {
        series[item.rowTitle][i] = item.specificField;
      });
    });
    return Object.keys(series).map((item) => {
      return {
        name: item,
        type: 'bar',
        stack: 'future',
        emphasis: {
          focus: 'series',
        },
        barMaxWidth: 150,
        barMinWidth: 80,
        data: series[item],
      };
    });
  };

  const option = {
    graphic: {
      type: 'text',
      silent: true,
      left: 'center',
      bottom: 0,
      z: 100,
      style: {
        fill: '#8d949d',
        text: ['powered by JLL睿见数据', 'JLL睿见数据提供技术及数据支持'].join('\n\n'),
        fontSize: 12,
        lineHeight: 12,
        textAlign: 'center',
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      ...{
        formatter(params: any) {
          let str = `<div style="padding: 6px 6px 0;"><p style="height: 16px; line-height: 16px; color: #01151c; margin-bottom: 5px; font-weight: bold;">
            <span>${params[0].name}</span>
          </p>`;
          for (let i = 0; i < params.length; i += 1) {
            str += `
          <div style="display: flex; align-items: center; margin-bottom: 8px; justify-content: space-between;">
            <div style="display: flex; align-items: center; width: ${
              params[i]?.seriesName.length * 18 + 24
            }px;">
              <i style="width: 12px; height: 12px; background:${
                params[i].color
              }; margin-right: 12px;"></i>
              <span style="color: #60666E;">${params[i]?.seriesName}</span>
            </div>
            <span style="color: #01151c;">${thousandSplitNumber(params[i]?.data || '--')} ㎡</span>
          </div>
        `;
          }
          return `${str}<div>`;
        },
      },
    },
    legend: {
      icon: 'rect',
      type: 'plain',
      orient: 'horizontal',
      itemWidth: 11,
      itemHeight: 11,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: chartData?.futureSupplyChartListVO?.map((item) => item.rowTitle),
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'm²',
      },
    ],
    color: itemColor,
    series: getSeries(chartData),
  };

  return chartData?.futureSupplyChartListVO?.length > 0 ? (
    <ReactECharts
      style={{
        height: `${document.getElementById('CommonTableWrapContent')?.clientHeight - 40}px`,
      }}
      ref={chartRef}
      option={option}
      notMerge
    />
  ) : (
    <Empty />
  );
});

export default Chart;
