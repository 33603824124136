import styles from './style.module.less';
import { useShallow } from 'zustand/react/shallow';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import dayjs from 'dayjs';
import { JLLDatePicker } from 'jlld';
import useTenantAnalysisStore from '../../store';

export default () => {
  const { data } = useMainFrameContext();
  const { existTime, queryTime, setTime, queryChartData } = useTenantAnalysisStore(
    useShallow((state) => ({
      existTime: state.existTime,
      queryTime: state.queryTime,
      setTime: state.setTime,
      queryChartData: state.queryChartData,
    }))
  );

  return (
    <JLLDatePicker
      picker="quarter"
      className={styles.marketPerformanceRangePicker}
      value={queryTime}
      allowClear={false}
      onChange={(value) => {
        setTime(value as string);
        queryChartData(data.params.id, data.params.type);
      }}
      disabledDate={(current) => {
        return current && existTime.indexOf(dayjs(current).format('YYYY-[Q]Q')) === -1;
      }}
    />
  );
};
