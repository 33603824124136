import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PageHeader from '@/components/PageHeader';
import { pageShowEvent } from '@/hooks/useUserEvent';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './style.module.less';
import { useCity } from '@/store/reactShareSelectedStore';
import { queryDataAnalysisPageOptions } from '@/store/commonOptions/pageActions';
import useCommonOptionsStore, { setLoading } from '@/store/commonOptions';
import { CustomTab, FormLoadingSkeletion, useTabOptions } from './components';
import { setSettingData as setA, get as getA } from './views/A-MarketState/store/settingStore';
import { setSettingData as setB, get as getB } from './views/B-IndustryAnalysis/store/settingStore';
import { setSettingData as setC, get as getC } from './views/C-DealAnalysis/store/settingStore';
import { setSettingData as setD, get as getD } from './views/D-FutureSupply/store/settingStore';
import useMainFrameContext from '@/hooks/useMainFrameContext';

export default () => {
  pageShowEvent('数据分析');
  // 获取router路径
  const { pathname } = useLocation();
  const { data } = useMainFrameContext();

  const city = useCity();
  const loading = useCommonOptionsStore((state) => state.loading);
  const { options } = useTabOptions();

  useEffect(() => {
    if (pathname === '/') {
      data.router.push(`/data-analysis/${options[0].value}`);
      document.getElementById(options[0].value)?.click();
    }
  }, [pathname]);

  useEffect(() => {
    queryDataAnalysisPageOptions([city]);
    // 产品要求切换页面变为一个城市
    [
      [setA, getA],
      [setB, getB],
      [setC, getC],
      [setD, getD],
    ].forEach(([set, get]) => {
      // TODO: 这个get不好设置类型
      set({ topFormValues: { ...(get as any)().topFormValues, citys: [city] } });
    });
    return () => {
      setLoading('dataAnalysis', true);
    };
  }, []);

  return (
    <div className={classNames('page', styles.dataAnalysis)}>
      <PageHeader
        title="数据分析"
        useBorder
      >
        <div className={styles.headerTabWrap}>
          <CustomTab activeTabName={pathname.slice(1)} />
        </div>
      </PageHeader>
      {loading.dataAnalysis ? <FormLoadingSkeletion /> : <Outlet />}
    </div>
  );
};
