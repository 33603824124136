import useReactShareUserStore from '@/store/reactShareUserStore';
import { RentKey, UnitType } from './type';

export const generateRentValue = (
  rawValue: number | string | undefined | null,
  key: RentKey,
  returnRawData?: boolean
) => {
  const userStore = useReactShareUserStore.getState();
  const value = Number(rawValue);
  const type = (userStore.commonConfig as any).unitConfig[key];

  if (!value || Number.isNaN(value)) {
    return returnRawData ? rawValue : '--';
  }

  if (type === UnitType.month) {
    return value.toFixed(1);
  }

  return ((Number(value) * 12) / 365).toFixed(1);
};

export const generateRentUnit = (key: RentKey) => {
  const userStore = useReactShareUserStore.getState();
  const type = (userStore.commonConfig as any).unitConfig[key];
  if (type === UnitType.month) {
    return '元/m²/月';
  }
  return '元/m²/天';
};
