import { useEffect, useMemo, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import Empty from '@/components/Empty/index.tsx';
import { PieItem } from '../../pages/Z-ProjectDetail/views/C-TenantAnalysis/types';
import { getPieChartPotions } from '@/components/Charts/chartOptions';

export default (props: {
  name: string;
  data: PieItem[];
  radius?: string[];
  unit?: string;
  threshold?: number;
  distanceToLabelLine?: number;
}) => {
  const { name, data, radius, unit, threshold = Infinity, distanceToLabelLine = 5 } = props;
  const chartRef = useRef<ReactECharts>(null);
  const hasData = useMemo(() => {
    return data?.filter((item: PieItem) => !!item.value).length;
  }, [data]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      chartRef.current && chartRef.current.getEchartsInstance().resize();
    });
    return () => {
      chartRef.current && chartRef.current.getEchartsInstance().dispose();
      window.removeEventListener('resize', () => {
        chartRef.current?.getEchartsInstance().resize();
      });
    };
  }, []);

  if (!hasData) {
    return <Empty />;
  }

  return (
    <ReactECharts
      ref={chartRef}
      option={getPieChartPotions(name, data, threshold, distanceToLabelLine, unit, radius)}
      notMerge
      lazyUpdate
    />
  );
};
