import useCommonOptionsStore from '@/store/commonOptions';
import { JLLCascader, JLLForm } from 'jlld';
import { CascaderOption } from 'jlld/es/JLLCascader';
import { useShallow } from 'zustand/react/shallow';

interface Props {
  style?: object;
  single?: boolean;
  hideRightAll?: boolean;
  searchPlaceholder?: string;
}

const DistrictAndSubMarketItem = (props: Props) => {
  const { districtAndSubmarketOptions } = useCommonOptionsStore(
    useShallow((state) => ({
      districtAndSubmarketOptions: [
        {
          label: '子市场',
          value: 'submarket',
          children: state?.submarkets[0]?.children as CascaderOption[],
        },
        {
          label: '行政区',
          value: 'district',
          children: state?.districts[0]?.children as CascaderOption[],
        },
      ],
    }))
  );

  return (
    <JLLForm.Item
      noStyle
      name="districtAndSubmarket"
    >
      <JLLCascader
        exclusive
        rightAll={!props.hideRightAll}
        leftAll={false}
        search
        searchPlaceholder={props.searchPlaceholder}
        nonRemovable={props.single}
        className="headerFormItem"
        style={props.style}
        placeholder="全部子市场"
        options={districtAndSubmarketOptions}
        single={props.single}
      />
    </JLLForm.Item>
  );
};

export default DistrictAndSubMarketItem;
