import { Radio, ConfigProvider, JLLForm } from 'jlld';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import useRelocateTrailStore, {
  setTopDimension,
  queryRelocateTrailListData,
  queryRelocateTrailMapData,
  setDisplayMode,
  hideFirstTimeEnterTip,
} from '../../store';
import { PropertyType } from '@/constants/enums';
import { RelocateTrailDimensionType, RelocateTrailModeType } from '../../../../types';
import styles from './style.module.less';
import { radioButtonConfig } from '@/jll';
import useUserAuth from '@/hooks/useUserAuth';
import { getRelocateTrailParams } from '@/pages/Z-ProjectDetail/types/apiTypes';
import { useForm } from 'jlld/es/form/Form';
import { useShallow } from 'zustand/react/shallow';
import {
  dimensionTypeOptions,
  endTimeStr,
  modeTypeOptions,
  RelocateTrailTimeTypeMap,
} from './constants';
import DateTimePicker from '@/components/DateTimePicker';
import { useRef } from 'react';

interface TopFilterForm {
  dimension: RelocateTrailDimensionType;
  time: (typeof RelocateTrailTimeTypeMap)[keyof typeof RelocateTrailTimeTypeMap];
  rangeTime: [string, string];
  mode: RelocateTrailModeType;
}

export default () => {
  const [form] = useForm<TopFilterForm>();
  const { data: mainFrameData } = useMainFrameContext();
  const { mode, dimension } = useRelocateTrailStore(
    useShallow((state) => ({ mode: state.displayMode, dimension: state.dimension }))
  );

  const { id: propertyId, type } = mainFrameData.params;

  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);

  const propertyType = type === 'business' ? PropertyType.commercial : PropertyType.industrial;

  const hasListAuth = useUserAuth('front:project:relocationtrace:list');
  const prevTime = useRef(RelocateTrailTimeTypeMap.threeYears);
  const onFormValueChange = (changedValues: Partial<TopFilterForm>, allValues: TopFilterForm) => {
    if (
      changedValues.time?.[0] === RelocateTrailTimeTypeMap.custom &&
      changedValues.time?.[0] !== prevTime.current
    ) {
      prevTime.current = changedValues.time?.[0];
      return;
    }

    prevTime.current = changedValues.time?.[0];

    if (changedValues.dimension) {
      setTopDimension(changedValues.dimension);
    }

    if (changedValues.mode) {
      setDisplayMode(changedValues.mode);
    }

    const params = {
      propertyId,
      propertyType,
      dimension: allValues.dimension,
    } as getRelocateTrailParams;
    const [time, rangeTime] = allValues.time || [];

    // 无论表单哪个值变化，这个时间都要判断&赋值
    if (time === RelocateTrailTimeTypeMap.custom) {
      params.startTime = rangeTime[0];
      params.endTime = rangeTime[1];
    } else {
      params.startTime = time;
      params.endTime = endTimeStr;
    }

    if (allValues.mode === RelocateTrailModeType.list) {
      queryRelocateTrailListData(params);
    } else {
      queryRelocateTrailMapData(params);
    }

    if (trailMapData?.otherProperty?.length > 0) {
      hideFirstTimeEnterTip();
    }
  };
  return (
    <div className={styles.topFilter}>
      <JLLForm
        form={form}
        layout="inline"
        initialValues={{
          time: [
            RelocateTrailTimeTypeMap.threeYears,
            [RelocateTrailTimeTypeMap.threeYears, endTimeStr],
          ],
          dimension,
          mode,
        }}
        onValuesChange={onFormValueChange}
      >
        <ConfigProvider
          theme={{
            components: {
              Radio: radioButtonConfig,
            },
          }}
        >
          <JLLForm.Item
            name="dimension"
            style={{ marginRight: 0 }}
          >
            <Radio.Group
              options={dimensionTypeOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </JLLForm.Item>
        </ConfigProvider>
        <JLLForm.Item
          name="time"
          style={{ marginLeft: '8px' }}
        >
          <DateTimePicker />
        </JLLForm.Item>
        <ConfigProvider
          theme={{
            components: {
              Radio: radioButtonConfig,
            },
          }}
        >
          <JLLForm.Item
            name="mode"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            hidden={!hasListAuth}
          >
            <Radio.Group
              options={modeTypeOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </JLLForm.Item>
        </ConfigProvider>
      </JLLForm>
    </div>
  );
};
