import { jllRequest } from '@/utils/request';
import {
  IndustryPropertyInfoRes,
  getIndustryProjectInfoParams,
  getMarketPerformanceParams,
  getRankMapParams,
  getProjectAroundParams,
  getProjectAroundRes,
  getProjectSpecificInfoRes,
  getBuildingDataExistsTimeParams,
  getIndustryMarketingChartParams,
  getBusinessTenantAnalysisParams,
  getBusinessTenantIFSProjectParams,
  getIndustryTenantExistsTimeParams,
  getIndustryTenantRankParams,
  getIndustryTenantBuildingRankParams,
  getIndustryBuildingTenantDetailParams,
  getBusinessFloorDetailParams,
  getClinchStatisticsParams,
  getClinchChartParams,
  getBlockTradeParams,
  IntelligentInsightsRes,
  getRelocateTrailParams,
  getRelocateTrailListRes,
  getRelocateTrailMapRes,
} from '../types/apiTypes';

export async function getTenantExistsTime(projectId: string) {
  const result = await jllRequest<getProjectAroundRes>({
    url: `/business/property/queryTenantExistsTime/${projectId}`,
    method: 'get',
  });
  return result;
}

/**
 * -稳定性分析 */
export function getStabilityAnalysis(projectId: string, dueTime?: string) {
  return jllRequest({
    url: '/business/property/stabilityAnalysis',
    method: 'post',
    data: {
      projectId,
      dueTime,
    },
  });
}

export async function getProjectAround(data: getProjectAroundParams) {
  const result = await jllRequest<getProjectAroundRes>({
    url: '/propertypoi/projectDetail',
    method: 'post',
    data,
  });
  return result;
}

// 查询产业办公项目详情
export async function getIndustryPropertyInfo(data: getIndustryProjectInfoParams) {
  const result = await jllRequest<IndustryPropertyInfoRes>({
    url: '/industrial/property/industryPropertyInfo',
    method: 'post',
    data,
  });

  const { baseInfo, hardwareInfoVO, industrialDirectionInfoVO, locationInfoVO, ownerInfoVO } =
    result;
  // TODO: 用到哪些字段，就把哪些字段放到formatResult里面
  const formatResult = {
    ...result,
    industrialDirectionInfoVO: {
      ...industrialDirectionInfoVO,
    },
    hardwareInfo: {
      ...hardwareInfoVO,
    },
    baseInfo: {
      ...baseInfo,
      cnName: baseInfo.propertyNameCn,
    },
    locationInfo: {
      ...locationInfoVO,
    },
    ownerInfo: {
      ...ownerInfoVO,
    },
  };

  return formatResult;
}

// 查询产业办公楼栋列表
// export function getBuildingList(propertyId: string): Promise<getProjectSpecificInfoRes> {
//   return jllRequest({
//     url: `/industrial/property/queryBuildingManagementList/${propertyId}`,
//     method: 'get',
//   });
// }

// 查询产业办公楼栋信息
export function getBuildingInfo(businessId: string) {
  return jllRequest({
    url: `/industrial/property/industryBuildingManagementInfo/${businessId}`,
    method: 'get',
  });
}

// 查询商业办公项目详情
export async function getProjectSpecificInfo(projectId: string, queryTime = '') {
  const result = await jllRequest<getProjectSpecificInfoRes>({
    url: '/business/property/projectSpecificInfo',
    method: 'post',
    data: { projectId, queryTime },
  });
  return result;
}

// 市场表现
export async function getProjectMarketPerformance(data: getMarketPerformanceParams) {
  const result = await jllRequest({
    url: '/business/property/projectMarketPerformance',
    method: 'post',
    data,
  });
  return result;
}

// 价格走势
export async function getPriceChartData(data: getRankMapParams) {
  const result = await jllRequest({
    url: '/business/property/priceChartData',
    method: 'post',
    data,
  });
  return result;
}

// 产办——市场表现-有项目数据的季度
export function getBuildingDataExistsTime(params: getBuildingDataExistsTimeParams) {
  return jllRequest({
    url: '/industrial/property/queryBuildingDataExistsTime',
    method: 'get',
    params,
  });
}

// 产办——市场表现-价格走势
export function getIndustryMarketingChart(data: getIndustryMarketingChartParams) {
  return jllRequest({
    url: '/industrial/property/priceChartData',
    method: 'post',
    data,
  });
}

// 产办——市场表现-项目维度指标走势(账面租金、有效租金、空置率、净吸纳量
export function getIndustryMarketingPerformance(data: getIndustryMarketingChartParams) {
  return jllRequest({
    url: '/industrial/property/propertyMarketPerformance',
    method: 'post',
    data,
  });
}

// 项目详情 - 获取楼栋列表
export function getBuildingList(propertyId: string) {
  return jllRequest({
    url: `/industrial/property/queryBuildingManagementList/${propertyId}`,
    method: 'get',
  });
}

// 商办-租户分析-租户排名-有权限
export function getBusinessTenantAnalysis(data: getBusinessTenantAnalysisParams) {
  return jllRequest({
    url: `/business/property/tenantAnalysis`,
    method: 'post',
    data,
  });
}

// 成交分析-成交明细
export async function queryClinchStatistics(data: getClinchStatisticsParams) {
  const result = await jllRequest({
    url: '/business/property/queryClinchStatistics',
    method: 'post',
    data,
  });

  return result.pageInfo;
}

// 商办-租户分析-IFS项目
export function getBusinessTenantIFSProject(data: getBusinessTenantIFSProjectParams) {
  return jllRequest({
    url: '/business/property/tenantIFSProject',
    method: 'post',
    data,
  });
}

// 商办-租户分析-楼层详情
export function getBusinessFloorDetail(data: getBusinessFloorDetailParams) {
  return jllRequest({
    url: '/business/property/tenantProjectDetails',
    method: 'post',
    data,
  });
}

// 产办-租户分析-有租户的季度时间
export function getIndustryTenantExistsTime(params: getIndustryTenantExistsTimeParams) {
  return jllRequest({
    url: '/industrial/property/queryTenantExistsTime',
    method: 'get',
    params,
  });
}

// 产办-租户分析-排名及占比
export function getIndustryTenantRank(data: getIndustryTenantRankParams) {
  return jllRequest({
    url: '/industrial/property/rankingAndProportion',
    method: 'post',
    data,
  });
}

// 产办-租户分析-楼层租户
export function getIndustryTenantBuildingRank(data: getIndustryTenantBuildingRankParams) {
  return jllRequest({
    url: '/industrial/property/buildingFloorTenant',
    method: 'post',
    data,
  });
}

// 产办-租户分析-楼层租户详情
export function getIndustryBuildingTenantDetail(data: getIndustryBuildingTenantDetailParams) {
  return jllRequest({
    url: '/industrial/property/buildingTenantDetail',
    method: 'post',
    data,
  });
}

// 成交分析-退租明细
export async function querySurrenderDetail(data: getClinchStatisticsParams) {
  const result = await jllRequest({
    url: '/business/property/querySurrenderDetail',
    method: 'post',
    data,
  });
  return result.pageInfo;
}

// 成交分析-图表数据查询
export function queryClinchChart(data: getClinchChartParams) {
  return jllRequest({
    url: '/business/property/queryClinchChart',
    method: 'post',
    data,
  });
}

// 投资交易
export function getBlockTrade(data: getBlockTradeParams) {
  return jllRequest({
    url: '/blocktrade/propertyBlockTrade',
    method: 'post',
    data,
  });
}

// 智能洞察
export async function getIntelligentInsights(propertyId: string) {
  const result = await jllRequest<IntelligentInsightsRes>({
    url: '/project/intelligent',
    method: 'get',
    params: {
      propertyId,
    },
  });

  return result;
}

// 迁址轨迹-列表
export async function getRelocateTrailListData(data: getRelocateTrailParams) {
  return jllRequest<getRelocateTrailListRes[]>({
    url: '/relocationTrace/list',
    method: 'post',
    data,
  });
}

// 迁址轨迹-地图
export async function getRelocateTrailMapData(data: getRelocateTrailParams) {
  return jllRequest<getRelocateTrailMapRes>({
    url: '/relocationTrace/map',
    method: 'post',
    data,
  });
}
