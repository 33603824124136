import styles from './style.module.less';
import { Table } from 'jlld';
import { ColumnsType } from 'jlld/es/table';
import { thousandSplitNumber } from '@/utils/tool';
import { BlockTradeProperty } from '../type';

export const getColumns = (): ColumnsType<BlockTradeProperty> => [
  {
    title: '序号',
    dataIndex: 'statisticsName',
    key: 'statisticsName',
    width: 60,
    render: (_, __, index) => index + 1,
  },
  {
    title: '项目中文名',
    dataIndex: 'propertyNameCn',
    key: 'propertyNameCn',
    width: 200,
  },
  {
    title: '项目英文名',
    dataIndex: 'propertyNameEn',
    key: 'propertyNameEn',
    width: 212,
  },
  {
    title: '一级分类',
    dataIndex: 'firstLevelCategory',
    key: 'firstLevelCategory',
    width: 100,
  },
  {
    title: '二级分类',
    dataIndex: 'secondLevelCategory',
    key: 'secondLevelCategory',
    width: 100,
  },
  {
    title: '占地面积(万㎡)',
    dataIndex: 'area',
    key: 'area',
    width: 144,
    render: (text: string) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '总建筑面积(万㎡)',
    dataIndex: 'totalArea',
    key: 'totalArea',
    width: 144,
    render: (text: string) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '城市',
    dataIndex: 'city',
    key: 'city',
    width: 91,
  },
  {
    title: '行政区',
    dataIndex: 'district',
    key: 'district',
    width: 200,
  },
  {
    title: '子市场',
    dataIndex: 'submarketName',
    key: 'submarketName',
    width: 200,
  },
  {
    title: '建成年份',
    dataIndex: 'completionYear',
    key: 'completionYear',
    width: 88,
  },
  {
    title: '翻新年份',
    dataIndex: 'renovationYear',
    key: 'renovationYear',
    width: 88,
  },
  {
    title: '项目位置',
    dataIndex: 'address',
    key: 'address',
    width: 240,
  },
  { width: 0, fixed: 'right' },
];

export default (props: { data: BlockTradeProperty[] | undefined }) => {
  const columns = getColumns();

  return (
    <Table
      rowKey="id"
      className={styles.propertyTable}
      columns={columns}
      dataSource={props.data}
      pagination={false}
      scroll={{
        x: 600,
      }}
    />
  );
};
