import { CityHead } from '@/types/apiTypes';
import { JLLCascaderValue } from 'jlld/es/JLLCascader';

// 数字按千分位
export const thousandSplitNumber = (
  num: number | string | undefined | null,
  emptyString?: string
) => {
  if (!num) {
    return emptyString || '--';
  }
  return num?.toString().replace(/\d+/, (n) => {
    return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
      return `${$1},`;
    });
  });
};

// 格式化下载的数字
export const formatDownloadNumber = (num: number | string | null) => {
  return num === null ? num : Number(num);
};

export const createUUid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise, no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const textWidth = (txt: string) => {
  const a = document.createElement('span');
  a.style.display = 'inline-block';
  a.style.fontFamily = 'digitRegular';
  a.style.padding = '0';
  a.style.margin = '0';
  a.style.fontSize = '17px';
  a.innerText = txt;
  document.body.append(a);
  const width = a.clientWidth;
  document.body.removeChild(a);
  return width;
};

// 获取某个日期季度
export function getQuarter(date = new Date()) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const quarter = Math.ceil(month / 3);
  return `${year}-Q${quarter}`;
}

// 获取半年前的日期
export function getHalfYearAgoDate() {
  const now = new Date();
  now.setDate(now.getDate() - 183);
  return now;
}

// 获取当前日期上个季度
export function getLastQuarter() {
  const date = new Date();
  let year = date.getFullYear();
  const month = date.getMonth() + 1;
  let quarter = Math.ceil(month / 3);
  if (quarter == 1) {
    year--;
    quarter = 4;
  } else {
    quarter--;
  }
  return `${year}-Q${quarter}`;
}

// 获取下个季度
export function getNextQuarter(time: string) {
  let [year, quarter] = time.split('-Q').map((item: string) => Number(item));
  if (quarter == 4) {
    year++;
    quarter = 1;
  } else {
    quarter++;
  }
  return `${year}-Q${quarter}`;
}

// 项目详情-市场表现/租户分析-默认时间范围
export const getDefaultTimeRange = () => {
  const current = getQuarter();
  const [currentYear, currentSeason] = current.split('-');
  // 当前时间往前3年，往后2年
  const startYear = +currentYear - 3;
  const endYear = +currentYear + 2;
  return [`${startYear}-${currentSeason}`, `${endYear}-${currentSeason}`];
};

/**
 * 格式化数据显示
 * @param field
 * @param excludedEmptyString
 * @returns excludedEmptyString=true时0或者''会正常展示
 */

export const formatField = (
  field: number | string | undefined | null,
  excludedEmptyString = false
) => {
  if (!excludedEmptyString) {
    return String(field ?? '--');
  }
  return String(field || '--');
};

export const formatFieldWithReplace = (fieldValue: number | string | null, emptyStr: string) => {
  return fieldValue || emptyStr || '--';
};

/**
 * 比较"yyyy-Qq"这种类型的季度大小
 * @param field
 * @param date1
 * @param date2
 * @returns 1 | 0 | -1 : date1 > date2 | date1 === date2 | date1 < date2
 */
export const compareQuarter = (date1: string, date2: string) => {
  const [year1, quarter1] = date1?.split('-Q') || [];
  const [year2, quarter2] = date2?.split('-Q') || [];

  if (date1 === date2) {
    return 0;
  }
  if (year1 < year2) {
    return -1;
  } else if (year1 > year2) {
    return 1;
  }
  return quarter1 > quarter2 ? 1 : -1;
};

// 获取对应key的index
export const getKeyListIndex = (keyList: string[], list: any[]) => {
  return keyList.map((key) => {
    return list.findIndex((i) => i.value === key);
  });
};

// A元素插到B元素之前
export const insertBefore = (indexA: number, indexB: number, list: any[]) => {
  if (indexA > -1 && indexB > -1 && indexA > indexB) {
    const temp = list[indexA];
    list.splice(indexA, 1);
    list.splice(indexB, 0, temp);
  }
};

type FunctionArray = Array<(input: any) => any>;

export function combine(...fns: FunctionArray): (input: any) => any {
  return (initialValue: any) => {
    let result = initialValue;
    for (const fn of fns) {
      result = fn(result);
    }
    return result;
  };
}

// 千分符
export const numFormat = (num) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num - 0)) {
    return num;
  }
  if (num === null) {
    return num;
  }
  const flag = num.toString().indexOf('-') === 0 ? '-' : ''; // 正负数
  num = num.toString().replace('-', '').split('.');
  const arr = num[0].split('').reverse();
  let res: any = [];
  for (let i = 0, len = arr.length; i < len; i++) {
    if (i % 3 === 0 && i !== 0) {
      res.push(',');
    }
    res.push(arr[i]);
  }
  res.reverse();
  if (num[1]) {
    res = res.join('').concat(`.${num[1]}`);
  } else {
    res = res.join('');
  }
  return `${flag}${res}`;
};

export const cityListToCityHeads = (
  cityList: string[],
  districts?: JLLCascaderValue,
  submarkets?: JLLCascaderValue
) => {
  const cityHeads: CityHead[] = cityList?.map((cityName) => {
    const result: CityHead = { cityName, adminAreaList: [], subMarketList: [] };
    if (districts && districts[cityName]) {
      result.adminAreaList = districts[cityName] as string[];
    }
    if (submarkets && submarkets[cityName]) {
      result.subMarketList = submarkets[cityName] as string[];
    }
    return result;
  });
  return cityHeads;
};

export const clearEmptyObject = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      Reflect.deleteProperty(obj, key);
    }
  });
  return obj;
};
export const setValuesToOptions = (values: string[]) =>
  values.map((value) => ({
    value,
    label: value,
  }));

export const deepClone = (obj: any) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepClone(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj: any, key) => {
      newObj[key] = deepClone(obj[key]);
      return newObj;
    }, {});
  }
};

export const getFutureMinMaxDate = (): [string, string] => {
  const now = new Date();
  const currYear = now.getFullYear();
  const currMonth = now.getMonth() + 1;
  const currQuarter = Math.floor(currMonth % 3 === 0 ? currMonth / 3 : currMonth / 3 + 1);
  const futureYear = currYear + 3;
  return [`${currYear}-Q${currQuarter}`, `${futureYear}-Q4`];
};

export const getSearchParams = () => {
  const queryString = window.location.search;
  const searchParams: { [key: string]: string } = {};
  if (!queryString) {
    return searchParams;
  }

  const params = queryString.substring(1).split('&');

  for (let i = 0; i < params.length; i++) {
    const [key, value] = params[i].split('=');
    searchParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
  }
  return searchParams;
};
