import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import useDealAnalysisSettingStore from './settingStore';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import {
  dimensionValuesToParams,
  generateCommonTableData,
  generateQueryParams,
  topFormValuesToParams,
} from '@/pages/F-DataAnalysis/method';
import { AllLoading, TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';
import {
  getTenantAnalysisQuarter,
  queryTenantAnalysis,
  getIndustryDealData,
} from '@/pages/F-DataAnalysis/api';
import { getDealAnalysisDetailRes, getDealDataDetailList } from '../api';
import { deepClone } from '@/utils/tool';

type State = {
  loading: AllLoading;
  tableData: {
    data: Record<string, TableDataItem[]> | null;
    summaryData: TableDataItem | null;
  } | null;
  chartData: null;
  detailData: getDealAnalysisDetailRes[] | null;
  exportDetailData: getDealAnalysisDetailRes[] | null;
  detailTotal: number;
  detailCurrentPage: number;
  quarterList: [string, string];
  yearList: [string, string];
};

const state: State = {
  loading: { topForm: true, table: true, chart: true, detail: true },
  tableData: null,
  chartData: null,
  detailData: null,
  exportDetailData: null,
  detailTotal: 0,
  detailCurrentPage: 1,
  quarterList: ['', ''],
  yearList: ['', ''],
};

const useDealAnalysisStore = create<State>()(immer(() => state));

const set = useDealAnalysisStore.setState;
// const get = useDataAnalysisSettingStore.getState;
export const resetDataAnalysisPageStore = () => {
  set(deepClone(state));
};

const analyzeType = 2;

// 获取时间范围
export const getTimeRange = async (cityList: string[]) => {
  if (!cityList.length) {
    return;
  }
  const { busTenantDataQuarterList, busTenantDataYearList } = await getTenantAnalysisQuarter({
    cityList,
    queryTypeList: [1, 3],
  });
  set((state) => {
    if (busTenantDataQuarterList?.length) {
      state.quarterList = [
        busTenantDataQuarterList[0],
        busTenantDataQuarterList[busTenantDataQuarterList.length - 1],
      ];
    }
    if (busTenantDataYearList?.length) {
      state.yearList = [
        busTenantDataYearList[0],
        busTenantDataYearList[busTenantDataYearList.length - 1],
      ];
    }
  });
};

export const queryDealTable = async () => {
  const { topFormValues, dimensionValues, tableFormValues } =
    useDealAnalysisSettingStore.getState();
  const queryParams = generateQueryParams(topFormValues, dimensionValues, tableFormValues);
  set((state) => {
    state.loading[ShowDataTabType.table] = true;
  });
  if (queryParams) {
    const result = await getIndustryDealData({
      ...queryParams,
      analysisTime: queryParams.analyzeEndTime,
      analyzeType: 2,
    });
    set((state) => {
      const { data, summaryData } = generateCommonTableData(result);

      state.tableData = {
        data,
        summaryData,
      };
      state.loading[ShowDataTabType.table] = false;
    });
  } else {
    set((state) => {
      state.loading[ShowDataTabType.table] = false;
    });
  }
};

export const queryDealAnalysisChart = async () => {
  const { topFormValues, chartSettingFormValues } = useDealAnalysisSettingStore.getState();
  const queryParams = topFormValuesToParams(topFormValues);
  set((state) => {
    state.loading[ShowDataTabType.chart] = true;
  });
  if (queryParams) {
    const temp = {};
    if (queryParams.deliveryTime) {
      temp['deliveryStartTime'] = queryParams.deliveryTime[0];
      temp['deliveryEndTime'] = queryParams.deliveryTime[1];
    }
    const chartData = await getIndustryDealData({
      ...queryParams,
      ...chartSettingFormValues,
      analysisTime: queryParams.analyzeEndTime,
      dataUnitType: '1',
      analyzeType: 2,
      analyzeTwoRowHead: '',
      analyzeThreeRowHead: '',
      ...temp,
    });
    console.log('chartData', chartData);
    set((state) => {
      state.chartData = chartData;
      state.loading[ShowDataTabType.chart] = false;
    });
  } else {
    set((state) => {
      state.loading[ShowDataTabType.chart] = false;
    });
  }
};

export const queryDealAnalysisDetail = async (current?: number, articlesPage?: number) => {
  const { topFormValues, dimensionValues } = useDealAnalysisSettingStore.getState();
  const queryParams = topFormValuesToParams(topFormValues);
  const dimensionParams = dimensionValuesToParams(dimensionValues);
  set((state) => {
    state.loading[ShowDataTabType.detail] = true;
  });
  if (queryParams) {
    const temp = {};
    if (queryParams.deliveryTime) {
      temp['deliveryStartTime'] = queryParams.deliveryTime[0];
      temp['deliveryEndTime'] = queryParams.deliveryTime[1];
    }
    const data = await getDealDataDetailList({
      ...queryParams,
      ...temp,
      analyzeType,
      // Todo: 和江旭确认了，实际analyzeOneRowHead这个参数不需要，但是现在不传查不出数据，先放这里服务端重构好了把这个参数去掉
      analyzeOneRowHead: dimensionParams.analyzeOneRowHead,
      currentPage: current || 1,
      articlesPage: articlesPage || 50,
    });
    if (articlesPage) {
      // 只有下载时才会传入articlesPage
      set((state) => {
        state.exportDetailData = data.records;
        state.loading[ShowDataTabType.detail] = false;
      });
    } else {
      // 一般的查询
      set((state) => {
        state.detailCurrentPage = current || 1;
        state.detailData = data.records;
        state.detailTotal = data.total;
        state.loading[ShowDataTabType.detail] = false;
      });
    }
  } else {
    set((state) => {
      state.loading[ShowDataTabType.detail] = false;
    });
  }
};

export const queryCurrentTabData = async () => {
  const { tableActiveTab } = useDealAnalysisSettingStore.getState();
  switch (tableActiveTab) {
    case ShowDataTabType.table: {
      queryDealTable();
      break;
    }
    case ShowDataTabType.chart: {
      queryDealAnalysisChart();
      break;
    }
    case ShowDataTabType.detail: {
      queryDealAnalysisDetail(1);
      break;
    }
  }
};

export const setLoading = (key: keyof AllLoading, loading: boolean) => {
  set((state) => {
    state.loading[key] = loading;
  });
};

export default useDealAnalysisStore;
