import BlockTitle, { SwitchType } from '../BlockTitle';
import CompareBarTable from '../CompareBarTable';

const IndustryCompare = (props: { changeType?: () => void; switchType?: SwitchType }) => {
  return (
    <div>
      <BlockTitle
        title="行业对比分析"
        showSwitch
        switchType={props.switchType!}
        onChangeType={props.changeType!}
      />
      <CompareBarTable dataType={props.switchType!} />
    </div>
  );
};

export default IndustryCompare;
