import reactShareAuthStore from './reactShareAuthStore';
import reactShareSelectedStore from './reactShareSelectedStore';
import reactShareUserStore from './reactShareUserStore';
import reactShareMainStore from './main';

(window as any).shareStore = {
  reactShareUserStore,
  reactShareSelectedStore,
  reactShareAuthStore,
  reactShareMainStore,
};
