import { Form, FormInstance } from 'jlld';
import React, { ReactElement, useContext } from 'react';

// export const ViewFormContext = React.createContext<any>({ form: null });

// export const useViewForm = function <T = any>() {
//   return useContext<T>(ViewFormContext);
// };

// export const ViewFormProvider = function <T>(props: { children: ReactElement }) {
//   const [form] = Form.useForm<T>();

//   return <ViewFormContext.Provider value={{ form }}>{props.children}</ViewFormContext.Provider>;
// };

const createViewFormContext = function <T>() {
  const ViewFormContext = React.createContext<{ form: FormInstance<T> }>({
    form: null as unknown as FormInstance<T>,
  });

  const useViewForm = function () {
    return useContext(ViewFormContext);
  };

  const ViewFormProvider = function (props: { children: ReactElement }) {
    const [form] = Form.useForm<T>();

    return <ViewFormContext.Provider value={{ form }}>{props.children}</ViewFormContext.Provider>;
  };

  return { useViewForm, ViewFormProvider, ViewFormContext };
};

export default createViewFormContext;
