import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { PropertyType } from '../../../types';
import { MarketPerformanceChartData, PriceChart, ProjectRowData, TimeRange } from '../types';
import business from './business';
import industry from './industry';
import { getDefaultTimeRange, deepClone } from '@/utils/tool';

interface State {
  startTime: string;
  endTime: string;
  rangeStartTime: string;
  rangeEndTime: string;
  rankData: PriceChart;
  bookRent: ProjectRowData[][];
  effectiveRent: ProjectRowData[][];
  vacancyRate: ProjectRowData[][];
  netAbsorption: ProjectRowData[][];
}

const initState: State = {
  startTime: '',
  endTime: '',
  rangeStartTime: '',
  rangeEndTime: '',
  rankData: {} as PriceChart,
  bookRent: [],
  effectiveRent: [],
  vacancyRate: [],
  netAbsorption: [],
};

const getTimeRange = (
  timeRange: [string | undefined, string | undefined]
): [string | undefined, string | undefined] => {
  const [startTime, endTime] = timeRange;
  if (!startTime || !endTime) {
    return [startTime, endTime];
  }
  const [start, end] = getDefaultTimeRange();
  if (endTime < start || end < startTime) {
    return [startTime, endTime];
  }
  return [start > startTime ? start : startTime, end < endTime ? end : endTime];
};

const useMarketPerformanceStore = create(
  immer(
    combine(initState, (set, get) => ({
      initProjectExistsTime: async (projectId: string, propertyType: PropertyType) => {
        let result;
        if (propertyType === 'business') {
          result = business.getMarketPerformance({
            projectId,
          });
        } else if (propertyType === 'industry') {
          result = industry.initExistsTime(projectId);
        }
        const { startTime, endTime } = (await result) as TimeRange;
        const [start, end] = getTimeRange([startTime, endTime]);
        if (!start && !end) {
          get().resetPage();
        }
        set({
          startTime: start,
          endTime: end,
          rangeStartTime: startTime,
          rangeEndTime: endTime,
        });
        return result;
      },
      getMarketPerformanceChartData: async (projectId: string, propertyType: PropertyType) => {
        const { startTime, endTime } = get();
        let result;
        if (propertyType === 'business') {
          result = await business.getMarketPerformance({
            projectId,
            startTime,
            endTime,
          });
        } else if (propertyType === 'industry') {
          result = await industry.getMarketPerformance({
            busId: projectId,
            startTime,
            endTime,
          });
        }
        const { bookRent, effectiveRent, vacancyRate, netAbsorption } =
          result as MarketPerformanceChartData;
        set({
          bookRent,
          effectiveRent,
          vacancyRate,
          netAbsorption,
        });
      },
      getPriceChartData: async (projectId: string, propertyType: PropertyType) => {
        const { startTime, endTime } = get();
        let rankData;
        if (propertyType === 'business') {
          rankData = await business.getPriceChartData({
            projectId,
            startTime,
            endTime,
          });
        } else if (propertyType === 'industry') {
          rankData = await industry.getPriceChartData({
            busId: projectId,
            startTime,
            endTime,
          });
        }
        set({
          rankData,
        });
      },
      loadChartData: async (projectId: string, propertyType: PropertyType) => {
        const { startTime, endTime, getMarketPerformanceChartData, getPriceChartData } = get();
        if (!!startTime && !!endTime) {
          getMarketPerformanceChartData(projectId, propertyType);
          getPriceChartData(projectId, propertyType);
        }
      },
      setTime: (timeRange?: [string, string]) => {
        const [startTime, endTime] = timeRange || [];
        set({
          startTime,
          endTime,
        });
      },
      resetPage: () => {
        set(deepClone(initState));
      },
    }))
  )
);

export default useMarketPerformanceStore;
