import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getDefaultTimeRange } from '@/utils/tool';
import {
  queryClinchStatistics,
  querySurrenderDetail,
  queryClinchChart,
  getTenantExistsTime,
} from '../../../api';

type TransactionType = '1' | '2';
type DataShowType = 'table' | 'chart';
interface State {
  clinchData: [];
  surrenderData: [];
  chartData: [];
  transactionType: TransactionType;
  dataShowType: DataShowType;
  periodStartTime: string;
  periodEndTime: string;
  rangeStartTime: string;
  rangeEndTime: string;
}

const initState: State = {
  clinchData: [],
  surrenderData: [],
  chartData: [],
  transactionType: '1',
  dataShowType: 'table',
  periodStartTime: '',
  periodEndTime: '',
  rangeStartTime: '',
  rangeEndTime: '',
};

const useTransactionAnalysisStore = create(
  immer(
    combine(initState, (set, get) => ({
      initPage: async (data: any) => {
        const [startTime, endTime] = getDefaultTimeRange();
        const res = await getTenantExistsTime(data.projectId);

        const { 0: end } = res;
        const start = res[res.length - 1];
        let tempStartTime;
        let tempEndTime;
        if (endTime < start || end < startTime) {
          [tempStartTime, tempEndTime] = [start, end];
        } else {
          tempStartTime = startTime > start ? startTime : start;
          tempEndTime = endTime < end ? endTime : end;
        }

        set({
          periodStartTime: tempStartTime,
          rangeStartTime: tempStartTime,
          periodEndTime: tempEndTime,
          rangeEndTime: tempEndTime,
        });
      },

      getClinchStatistics: async (data: any) => {
        const { periodStartTime, periodEndTime } = get();
        let pageInfo;
        if (periodStartTime && periodEndTime) {
          pageInfo = await queryClinchStatistics({
            ...data,
            timeInterval: `${periodStartTime}至${periodEndTime}`,
          });
        }
        set({
          clinchData: pageInfo || [],
        });
      },

      getSurrenderDetail: async (data: any) => {
        const { periodStartTime, periodEndTime } = get();
        let pageInfo;
        if (periodStartTime && periodEndTime) {
          pageInfo = await querySurrenderDetail({
            ...data,
            timeInterval: `${periodStartTime}至${periodEndTime}`,
          });
        }
        set({
          surrenderData: pageInfo || [],
        });
      },

      resetStoreData: () => {
        set({
          clinchData: [],
          chartData: [],
        });
      },

      getClinchChartData: async (param: any) => {
        const data = await queryClinchChart(param);
        set({
          chartData: data,
        });
      },

      setTransactionType: (transactionType: TransactionType) => {
        set({
          transactionType,
        });
      },

      setDataShowType: (dataShowType: DataShowType) => {
        set({
          dataShowType,
        });
      },

      setPeriodTime: (time: string) => {
        const [startTime, endTime] = time;
        set({
          periodStartTime: startTime,
          periodEndTime: endTime,
        });
      },
    }))
  )
);

export default useTransactionAnalysisStore;
