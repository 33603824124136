import { useEffect } from 'react';
import useUserEvent from '@/hooks/useUserEvent';
import DetailTable from './DetailTable';
import TopForm from './TopForm';
import useLeftFormStore from '../../store/leftFormStore';
import useTradeDetailStore from '../../store/tradeDetailStore';
import styles from './style.module.less';
import FormLoadingSkeletion from './TopForm/FormLoadingSkeletion';
import useCommonOptionsStore from '@/store/commonOptions';

const TradeDetail = () => {
  const { sendUserEvent } = useUserEvent();

  const loading = useCommonOptionsStore((state) => state.loading);
  const defaultTimeLoading = useLeftFormStore((state) => state.defaultTimeLoading);
  const { queryDetailList } = useTradeDetailStore((state: any) => ({
    queryDetailList: state.queryDetailList,
  }));

  useEffect(() => {
    if (!defaultTimeLoading) {
      queryDetailList();
      sendUserEvent({ page: '投资交易列表' }, 'e_view');
    }
  }, [defaultTimeLoading]);

  return (
    <div className={styles.tradeDetail}>
      {loading.blockTrade || defaultTimeLoading ? <FormLoadingSkeletion /> : <TopForm />}
      <DetailTable />
    </div>
  );
};

export default TradeDetail;
