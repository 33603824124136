const exportImg = (chartRef: any) => {
  // echart返回一个 base64 的 URL
  return chartRef.current?.getEchartsInstance().getDataURL({
    pixelRatio: 5, // 导出的图片分辨率比率,默认是1
    backgroundColor: '#fff', // 图表背景色
    excludeComponents: [
      // 保存图表时忽略的工具组件,默认忽略工具栏
      'toolbox',
    ],
    type: 'png', // 图片类型支持png和jpeg
  });
};

const base64ToBlob = (chartRef: any) => {
  // 将base64转换blob
  const img = exportImg(chartRef);
  const parts = img.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  return new Blob([uInt8Array], {
    type: contentType,
  });
};

export const downloadChart = (chartRef: any, fileName: string) => {
  const aLink = document.createElement('a');
  const blob = base64ToBlob(chartRef);
  const evt = document.createEvent('HTMLEvents');
  evt.initEvent('click', true, true);
  aLink.download = fileName; // 下载图片的名称
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
};
