import { Input, JLLSvg, JLLForm, Button } from 'jlld';
import classNames from 'classnames';
import { useDebounceFn } from 'ahooks';
import { downloadSheet } from '@/utils/xlsx';
import useUserEvent from '@/hooks/useUserEvent';
import { formatDownloadNumber } from '@/utils/tool';
import AuthWrapWithTooltip from '@/components/AuthWrapWithTooltip';
import useTradeDetailStore from '../../../store/tradeDetailStore';
import styles from '../style.module.less';
import outerStyles from '../../../style.module.less';
import useReactShareAuthStore from '@/store/reactShareAuthStore';
import { useForm } from 'jlld/es/form/Form';

const DetailExcelHeader = [
  '交易时间',
  '一级分类',
  '二级分类',
  '交易项目',
  '城市',
  '区域',
  '交易面积(㎡)',
  '交易金额(万元)',
  '交易单价(万元/㎡)',
  '买方名称',
  '买方企业来源',
  '卖方名称',
  '卖方企业来源',
];

const DetailExcelHeaderWidth = [
  { wch: 15 },
  { wch: 25 },
  { wch: 35 },
  { wch: 25 },
  { wch: 25 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];

const TopForm = () => {
  const { auth } = useReactShareAuthStore();
  const [form] = useForm();

  const {
    detailData,
    queryDetailList,
    queryDetailDownloadList,
    setDetailQueryCompanyName,
    setDetailQueryProjectName,
  } = useTradeDetailStore();

  const { sendUserEvent } = useUserEvent();

  const { run } = useDebounceFn(
    (val) => {
      let name;
      const { projectName, companyName } = val;
      if (projectName !== undefined) {
        name = projectName.trim();
        setDetailQueryProjectName(name);
      } else {
        const name = companyName.trim();
        setDetailQueryCompanyName(name);
      }
      queryDetailList({
        ...form.getFieldsValue(),
        current: 1,
      });
    },
    {
      wait: 300,
    }
  );

  const downloadExcel = async () => {
    if (!(detailData.records?.length > 0)) return;
    sendUserEvent({ button: '交易明细-下载表格' }, 'e_click');
    const res = await queryDetailDownloadList();
    downloadSheet({
      fileName: '睿见数据-投资交易-交易明细',
      header: DetailExcelHeader,
      dataList: res.records?.map((item: any) => [
        item.time,
        item.firstLevelCategory,
        item.secondLevelCategory,
        item.propertyName,
        item.city,
        item.region,
        formatDownloadNumber(item.saleArea),
        formatDownloadNumber(item.totalPrice),
        formatDownloadNumber(item.unitPrice),
        item.buyerCompany,
        item.buyerType,
        item.sellerCompany,
        item.sellerType,
      ]),
      cols: DetailExcelHeaderWidth,
    });
  };

  return (
    <div className={styles.topForm}>
      <div className={styles.queryWrapper}>
        <JLLForm
          className={classNames(styles.leftForm)}
          form={form}
          layout="inline"
          onValuesChange={(value) => run(value)}
        >
          <JLLForm.Item
            name="projectName"
            label="交易项目"
          >
            <Input
              placeholder="请输入项目名称"
              allowClear
              className={styles.detailQueryInput}
            />
          </JLLForm.Item>
          <JLLForm.Item
            name="companyName"
            label="买方/卖方企业名称"
          >
            <Input
              placeholder="请输入企业名称"
              allowClear
              className={styles.detailQueryInput}
            />
          </JLLForm.Item>
        </JLLForm>
      </div>
      <AuthWrapWithTooltip
        type="icon-button"
        auth={'front:blocktrade:details:download'}
        buttonText="下载表格"
        icon="download"
        iconSize={20}
        className={classNames(
          outerStyles.download,
          detailData.records?.length > 0 ? '' : outerStyles.downloadDisabled
        )}
        align={{ offset: [-20, -10] }}
        disabled={detailData.records?.length <= 0}
        onClick={downloadExcel}
      />
    </div>
  );
};

export default TopForm;
