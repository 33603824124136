import { Table, TableColumnType } from 'jlld';
import classNames from 'classnames';
import Empty from '@/components/Empty';
import { PageWithWatermark } from '@/components';
import { RelocateTrailDimensionType } from '@/constants/enums';
import { Top5DetailItem } from '../../../../../types/apiTypes';
import styles from './style.module.less';
import './style.less';

interface DataType {
  key: string;
  statisticsName: string;
  count: string;
  saleArea: string;
  totalPrice: string;
  unitPrice: string;
}

interface TopFiveTableProps {
  data: Top5DetailItem[];
  dimension: RelocateTrailDimensionType;
}

const TopFiveTable = (props: TopFiveTableProps) => {
  const cellFormatter = (text: string) => {
    return text || '-';
  };

  const columns: TableColumnType<DataType>[] = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 50,
      render: (text, record, index) => <div className={styles.rowIndex}>{index + 1}</div>,
    },
    {
      title:
        props.dimension === RelocateTrailDimensionType.moveIn ? '迁入租户名称' : '迁出租户名称',
      dataIndex: 'tenantName',
      key: 'tenantName',
      align: 'left',
      render: cellFormatter,
    },
    {
      title: props.dimension === RelocateTrailDimensionType.moveIn ? '迁入时间' : '迁出时间',
      dataIndex: 'time',
      key: 'time',
      align: 'left',
      render: cellFormatter,
    },
    {
      title: '目标项目',
      dataIndex: 'targetPropertyName',
      key: 'targetPropertyName',
      align: 'left',
      render: cellFormatter,
    },
    {
      title: props.dimension === RelocateTrailDimensionType.moveIn ? '来源项目' : '去向项目',
      dataIndex: 'prevPropertyName',
      key: 'prevPropertyName',
      align: 'left',
      render: cellFormatter,
    },
    {
      title: '一级行业',
      dataIndex: 'primaryIndustry',
      key: 'primaryIndustry',
      align: 'left',
      render: cellFormatter,
    },
    {
      title: '租户来源',
      dataIndex: 'tenantOrigin',
      key: 'tenantOrigin',
      align: 'left',
      render: cellFormatter,
    },
  ];

  return props.data?.length > 0 ? (
    <div className={classNames('topFiveTable', styles.dataTableWrap)}>
      <PageWithWatermark>
        <Table
          rowClassName={styles.tableRow}
          columns={columns}
          dataSource={props.data}
          rowKey={'statisticsName'}
          pagination={false}
        />
      </PageWithWatermark>
    </div>
  ) : (
    <div>
      <Empty
        text={
          props.dimension === RelocateTrailDimensionType.moveIn
            ? '当前筛选条件下没有相关的迁入记录'
            : '当前筛选条件下没有相关的迁出记录'
        }
        useSolsticeStyle
      />
    </div>
  );
};

export default TopFiveTable;
