import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ViewSettings } from '@/pages/F-DataAnalysis/types/settingTypes';
import { getFutureMinMaxDate } from '@/utils/tool';
import { DataStateType, ShowDataTabType, SummaryShowType, UnitType } from '../../../config';
import { getDefaultProperty } from '@/pages/F-DataAnalysis/method';
import { deepClone } from '@/utils/tool';
import { PropertyType } from '@/constants/enums';

export type SettingState = ViewSettings<{
  analyzeOneRowHead: DataStateType;
}>;

export const getInitValue = () => {
  return {
    dateValue: getFutureMinMaxDate(),
    propertyType: getDefaultProperty(),
  };
};

const initValue = getInitValue();

const state: SettingState = {
  topFormValues: { ...initValue },
  defaultDateValue: initValue.dateValue,
  tableFormValues: {
    dataIndicator: ['projectCount', 'futureSupply'],
    unitType: UnitType.area,
    summaryShowType: [SummaryShowType.category, SummaryShowType.total],
  },
  dimensionValues: [DataStateType.datetime, DataStateType.cityName],
  chartSettingFormValues: {
    analyzeOneRowHead: DataStateType.projectLevel,
  },
  tableActiveTab: ShowDataTabType.table,
};

const useFutureSupplySettingStore = create<SettingState>()(
  persist(() => deepClone(state), {
    name: 'data-future-supply-storage-240529',
  })
);

export const resetSettingStore = () => {
  set(deepClone(state));
};

const set = useFutureSupplySettingStore.setState;
export const get = useFutureSupplySettingStore.getState;

export const initTopFormValues = () => {
  const topFormValues = get().topFormValues;
  const { [PropertyType.commercial]: commercial, [PropertyType.industrial]: industry } =
    topFormValues?.propertyType;

  const hasProperty = commercial?.length || industry?.length;
  const values = {
    ...topFormValues,
    propertyType: hasProperty ? topFormValues.propertyType : getDefaultProperty(),
  };

  setSettingData({ topFormValues: values });
};

export const setSettingData = (data: Partial<SettingState>) => {
  set((state) => {
    return {
      ...state,
      ...data,
    };
  });
};

export default useFutureSupplySettingStore;
