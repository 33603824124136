import { Tabs, TabsProps } from 'jlld';
import styles from './style.module.less';
import TradeDetail from '../TradeDetail';
import TradeStatistics from '../TradeStatistics';
import useBlockTradeStore from '../../store/index';
import useTradeStatisticsStore from '../../store/tradeStatisticsStore';
import useTradeDetailStore from '../../store/tradeDetailStore';
import classNames from 'classnames';
import useReactShareAuthStore from '@/store/reactShareAuthStore';
import { PageWithWatermark } from '@/components';

const items: TabsProps['items'] = [
  {
    key: 'tradeDetail',
    label: '交易明细',
    children: (
      <PageWithWatermark>
        <TradeDetail />
      </PageWithWatermark>
    ),
  },
  {
    key: 'tradeStatistics',
    label: `交易统计`,
    children: (
      <PageWithWatermark>
        <TradeStatistics />
      </PageWithWatermark>
    ),
  },
];

const tabs = ['front:blocktrade:details:list', 'front:blocktrade:statistics:list'].map(
  (item, index) => ({
    ...items[index],
    permission: item,
  })
);

const RightTables = () => {
  const { setStatisticsQueryType, setCurrent } = useTradeStatisticsStore();
  const { setDetailQueryCompanyName, setDetailQueryProjectName } = useTradeDetailStore();
  const { setCurrentTab } = useBlockTradeStore();
  const { auth } = useReactShareAuthStore();

  const handleTabsChange = (activeKey: string) => {
    setCurrentTab(activeKey);
    if (activeKey === 'tradeDetail') {
      setStatisticsQueryType('1');
      setCurrent(1);
      setDetailQueryCompanyName('');
      setDetailQueryProjectName('');
    }
  };

  return (
    <div className={styles.rightTables}>
      <Tabs
        tabBarStyle={{ paddingLeft: 20 }}
        className={classNames(styles.tabs)}
        destroyInactiveTabPane
        defaultActiveKey="tradeDetail"
        items={tabs?.filter((tab) =>
          (auth as any).buttonAuth?.some((item: any) => item.button === tab.permission)
        )}
        onChange={handleTabsChange}
      />
    </div>
  );
};

export default RightTables;
