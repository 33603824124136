import { useState } from 'react';
import useZoomChange from 'jlld/es/JLLMap/useZoomChange';

type Range = [number, number];
type InScope = boolean;

const useZoomInRange = (range: Range, inScope: InScope = true) => {
  const [zoomInRange, setZoomInRange] = useState(false);
  const [start, end] = range;

  const listener = (zoom: number) => {
    if (inScope) {
      setZoomInRange(zoom >= start && zoom <= end);
    } else {
      setZoomInRange(zoom > end || zoom < start);
    }
  };
  useZoomChange(listener);

  return zoomInRange;
};

export default useZoomInRange;
