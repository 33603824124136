import React, { ReactElement, useState } from 'react';
import { SwitchType } from '../BlockTitle';
import styles from './style.module.less';

const ChartBlock = (props: { children: ReactElement }) => {
  const [switchType, setSwitchType] = useState<SwitchType>('area');

  const changeType = (type: SwitchType) => {
    setSwitchType(type);
  };

  const newElement = React.cloneElement(props.children, {
    switchType,
    changeType,
  });

  return <div className={styles.chartBlock}>{newElement}</div>;
};

export default ChartBlock;
