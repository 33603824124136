import { JLLForm, Skeleton } from 'jlld';
import styles from '../style.module.less';

const formConfig = ['交易项目', '买方/卖方企业名称'];

const FormLoadingSkeletion = () => {
  return (
    <div className={styles.topForm}>
      <div className={styles.queryWrapper}>
        <JLLForm
          className={styles.leftForm}
          layout="inline"
        >
          {formConfig.map((label) => (
            <JLLForm.Item
              key={label}
              label={label}
            >
              <Skeleton.Input
                className={styles.detailQueryInput}
                style={{ width: 215 }}
                active
              />
            </JLLForm.Item>
          ))}
        </JLLForm>
      </div>
    </div>
  );
};

export default FormLoadingSkeletion;
