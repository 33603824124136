import { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Image, message, JLLSvg } from 'jlld';
import BusinessInfomationExport from '@/pages/Z-ProjectDetail/components/BusinessInfomationExport';
import IndustryInfomationExport from '@/pages/Z-ProjectDetail/components/IndustryInfomationExport';
import IndustryBuildingExport from '@/pages/Z-ProjectDetail/components/IndustryBuildingExport';
import useProjectDetailStore from '../../../../store';
import useProjectInfoStore from '../../store';
import { businessInfomation, industryInfomation, buildingInfomation } from '../../config';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import './style.less';

interface BasicProjectInfoProps {
  type: string;
  showBuilding: boolean;
}

const BasicProjectInfo = (props: BasicProjectInfoProps) => {
  const { type, showBuilding } = props;
  const [projectImages, setProjectImages] = useState([]);
  const [industrySummaryImages, setIndustrySummaryImages] = useState([]);

  const businessExportRef = useRef();
  const industryExportRef = useRef();
  const industryBuildingExportRef = useRef();

  const { projectDetail, setDownloadBtnText } = useProjectDetailStore();
  const { baseInfo, locationInfoVO, locationInfo } = projectDetail;

  const { buildingInfo } = useProjectInfoStore();

  const getDeliverStatus = () => {
    if (type === 'industry') {
      switch (baseInfo.completionStatus) {
        case '在建':
          return <div className="left-side deliver-status future">在建</div>;
        case '已交付':
          return <div className="left-side deliver-status delivered">已交付</div>;
        case '已规划':
          return <div className="left-side deliver-status future">已规划</div>;
      }
    } else if (type === 'business') {
      switch (baseInfo.dueState) {
        case '1':
          return <div className="left-side deliver-status future">在建</div>;
        case '2':
          return <div className="left-side deliver-status delivered">已交付</div>;
        case '3':
          return <div className="left-side deliver-status future">已规划</div>;
      }
    }
  };

  const getCarousel = () => {
    return (
      <Image.PreviewGroup>
        <Swiper
          style={{ height: '200px', textAlign: 'center' }}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={{
            nextEl: '.jpi-swiper-button-next', // 默认的下一个按钮
            prevEl: '.jpi-swiper-button-prev', // 默认的上一个按钮
          }}
          pagination={{
            clickable: true,
          }}
        >
          {projectImages.map((item) => (
            <SwiperSlide key={item?.fileUrl}>
              <Image
                loading="lazy"
                preview={{
                  src: `${item?.fileUrl}&width=1000&height=1000`,
                }}
                src={`${item?.fileUrl}&width=550&height=300`}
                style={{
                  width: '80%',
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Image.PreviewGroup>
    );
  };

  const downloadPDF = async () => {
    try {
      setDownloadBtnText('正在生成PDF...');
      if (type === 'business') {
        await businessExportRef?.current?.exportPdf(`${baseInfo.cnName}项目信息`);
      } else {
        if (showBuilding) {
          await industryBuildingExportRef?.current?.exportPdf(
            `${buildingInfo.buildingName}楼宇信息`
          );
        } else {
          await industryExportRef?.current?.exportPdf(`${baseInfo.cnName}项目信息`);
        }
      }
      setDownloadBtnText('已生成PDF');
      setTimeout(() => {
        setDownloadBtnText('下载项目信息');
      }, 2000);
    } catch (e) {
      message.error('下载PDF文件失败');
    }
  };

  const getPDFExportDom = () => {
    if (type === 'business') {
      return (
        <BusinessInfomationExport
          ref={businessExportRef}
          information={businessInfomation}
          exportBaseInfo={baseInfo}
          exportLocationInfo={locationInfo}
        />
      );
    } else if (type === 'industry' && !showBuilding) {
      return (
        <IndustryInfomationExport
          ref={industryExportRef}
          information={industryInfomation}
          exportBaseInfo={baseInfo}
          exportLocationInfo={locationInfoVO}
          exportImages={projectImages}
        />
      );
    }
    return (
      <IndustryBuildingExport
        ref={industryBuildingExportRef}
        buildingName={buildingInfo.buildingName}
        information={buildingInfomation}
        exportBaseInfo={baseInfo}
        exportLocationInfo={locationInfoVO}
        exportImages={projectImages}
      />
    );
  };

  window.onmessage = (e) => {
    if (e.data === 'downloadPDF') {
      downloadPDF();
    }
  };

  useEffect(() => {
    if (type === 'business') {
      const allImageFileUrl = (baseInfo.proBannerFiles || []).map((item: any) => item?.fileUrl);
      const tempProjectImages = (baseInfo.proBannerFiles || []).map((item: any) => {
        // 将当前元素的预览图放到第一个
        const temp = [...allImageFileUrl];
        const index = temp.indexOf(item?.fileUrl);
        if (index !== -1) {
          temp.splice(index, 1);
          temp.unshift(item?.fileUrl);
        }
        return { ...item, ...{ previewList: temp } };
      });
      setProjectImages(tempProjectImages);
    } else if (type === 'industry') {
      const { insideImgList, facadeImgList, standardLayerImgList } = buildingInfo;
      if (showBuilding) {
        const buildingImagesTemp = [
          ...insideImgList,
          ...facadeImgList,
          ...standardLayerImgList,
        ].sort((a, b) => a.fileSort - b.fileSort);
        const allImageFileUrl = buildingImagesTemp.map((item) => item?.fileUrl);
        const carouselImages = buildingImagesTemp.map((item) => {
          const temp = [...allImageFileUrl];
          const index = temp.indexOf(item?.fileUrl);
          if (index !== -1) {
            temp.splice(index, 1);
            temp.unshift(item?.fileUrl);
          }
          return { ...item, ...{ previewList: temp } };
        });
        setProjectImages(carouselImages);
      } else {
        // 产业项目概览
        const projectImagesTemp = (
          projectDetail.industryExteriorImgList?.concat(projectDetail.otherTypeImgList) || []
        ).sort((a, b) => a.fileSort - b.fileSort);

        const allImageFileUrl = projectImagesTemp.map((item) => item?.fileUrl);
        const tempProjectImages = projectImagesTemp.map((item) => {
          const temp = [...allImageFileUrl];
          const index = temp.indexOf(item?.fileUrl);
          if (index !== -1) {
            temp.splice(index, 1);
            temp.unshift(item?.fileUrl);
          }
          return { ...item, ...{ previewList: temp } };
        });
        setIndustrySummaryImages(tempProjectImages);
        setProjectImages(tempProjectImages);
      }
    }
  }, [baseInfo, buildingInfo]);

  useEffect(() => {
    if (type === 'industry' && !showBuilding) {
      setProjectImages(industrySummaryImages);
    }
  }, [showBuilding]);

  return (
    <div className="project-summary-desc pane-info-form">
      {getPDFExportDom()}

      <div className="image-container">
        <div className="jpi-swiper-button-prev">
          <JLLSvg
            icon="left-arrow"
            size={20}
            color="#fff"
          />
        </div>
        <div className="jpi-swiper-button-next">
          <JLLSvg
            icon="right-arrow"
            size={20}
            color="#fff"
          />
        </div>

        {projectImages.length > 0 ? (
          getCarousel()
        ) : (
          <div className="empty-image-container">
            <img
              src="/assets/default.svg"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        )}
      </div>
      <div className="project-detail">
        <div className="project-name">
          {showBuilding ? (
            <p className="cn-name">{buildingInfo.buildingName}</p>
          ) : (
            <>
              <p className="cn-name">{baseInfo.cnName}</p>
              <p className="en-name">{baseInfo.enName}</p>
            </>
          )}
        </div>
        {!showBuilding && (
          <>
            <div className="project-status">
              <div className="status-item">
                {getDeliverStatus()}
                <div className="right-side">
                  {props.type === 'industry' ? baseInfo.completionQuarter : baseInfo.dueTime}交付
                </div>
              </div>

              <div className="status-item">
                <div className="left-side">项目等级</div>
                <div className="right-side">
                  {props.type === 'industry' ? baseInfo.propertyGrade : baseInfo.level}
                </div>
              </div>

              <div className="status-item">
                <div className="left-side">LEED等级</div>
                <div className="right-side">
                  {props.type === 'industry' ? baseInfo.leedGrade : baseInfo.leedLevel}
                </div>
              </div>
            </div>
            <div className="location-info">
              <img src="/assets/location.png" />
              {props.type === 'industry' ? (
                <div>
                  <p>
                    {locationInfoVO?.province} · {locationInfoVO?.region} · {locationInfoVO?.city} ·
                    {locationInfoVO?.district} · {locationInfoVO?.submarketName}
                  </p>
                  <p>{locationInfoVO?.address}</p>
                </div>
              ) : (
                <div>
                  <p>
                    {locationInfo?.provinceName} · {locationInfo?.regionId} ·{' '}
                    {locationInfo?.cityName} ·{locationInfo?.adminDistrict} ·{' '}
                    {locationInfo?.plateId}
                  </p>
                  <p>{locationInfo?.address}</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicProjectInfo;
