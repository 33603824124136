import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { getBlockTradeTransactionDetail } from '../api';

interface State {
  transactionDetail: any;
}

const initState: State = {
  transactionDetail: {},
};

const useTransactionDetailStore = create(
  immer(
    combine(initState, (set, get) => ({
      queryBlockTradeTransactionDetail: async (trasactionId: string) => {
        const res = await getBlockTradeTransactionDetail({ trasactionId });
        set({
          transactionDetail: res,
        });
        return res;
      },
    }))
  )
);

export default useTransactionDetailStore;
