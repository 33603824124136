import { Checkbox } from 'jlld';
import { OptionItem } from 'jlld/es/JLLSelect';
import styles from './style.module.less';
import { useEffect, useMemo } from 'react';
export interface MultiSelectorRowProps {
  label?: string;
  labelWidth?: number;
  options: OptionItem[] | (string | number)[];
  value?: (string | number)[];
  onChange?: (value: (string | number)[]) => void;
  withPending?: boolean;
  selectAllOnOptionsChange?: boolean;
}

const MultiSelectorRow = (props: MultiSelectorRowProps) => {
  const options = useMemo(() => {
    return props.withPending ? ['待定', ...props.options] : props.options;
  }, [props.options, props.withPending]);

  useEffect(() => {
    // set value on options changed
    if (props.selectAllOnOptionsChange) {
      props.onChange?.(options);
    } else {
      props.onChange?.([]);
    }
  }, [options, props.selectAllOnOptionsChange]);

  return (
    <div className={styles.multiSelectorRow}>
      <div
        className={styles.label}
        style={{ width: props.labelWidth }}
      >
        {props.label}
      </div>
      <div className={styles.CheckboxGroup}>
        <Checkbox
          indeterminate={
            props.value && props.value.length > 0 && props.value.length < options.length
          }
          checked={props.value?.length === options.length}
          onClick={() => {
            if (props.value?.length === options.length) {
              props.onChange?.([]);
            } else if (typeof options[0] !== 'object') {
              props.onChange?.(options as (string | number)[]);
            } else {
              props.onChange?.((options as OptionItem[]).map((option) => option.value));
            }
          }}
        >
          全部
        </Checkbox>
        <Checkbox.Group
          value={props.value}
          onChange={(checkedValues) => {
            props.onChange?.(checkedValues as string[]);
          }}
          options={options}
        />
      </div>
    </div>
  );
};

export default MultiSelectorRow;
