import { getProjectMarketPerformance, getPriceChartData } from '../../../api';
import {
  getMarketPerformanceParams,
  getRankMapParams,
} from '@/pages/Z-ProjectDetail/types/apiTypes';
import { BusinessPriceChartItem } from '../types';

const formatChart = (rowList: BusinessPriceChartItem[]) => {
  return rowList?.map((item: BusinessPriceChartItem) => {
    const { xaxisValue, sampleSize, leftAxisValue } = item;
    return {
      rowTitle: xaxisValue,
      sampleSize,
      specificField: leftAxisValue,
    };
  });
};

export default {
  getMarketPerformance: async (params: getMarketPerformanceParams) => {
    const projectMarketPerformanceData = await getProjectMarketPerformance(params);
    return {
      startTime: projectMarketPerformanceData['分析周期区间']?.[0]?.specificField,
      endTime: projectMarketPerformanceData['分析周期区间']?.[1]?.specificField,
      bookRent: [
        projectMarketPerformanceData['当前周期账面租金'],
        projectMarketPerformanceData['未来三年预测账面租金'],
      ],
      effectiveRent: [
        projectMarketPerformanceData['当前周期有效租金'],
        projectMarketPerformanceData['未来三年预测有效租金'],
      ],
      vacancyRate: [
        projectMarketPerformanceData['当前周期空置率'],
        projectMarketPerformanceData['未来三年预测空置率'],
      ],
      netAbsorption: [
        projectMarketPerformanceData['当前周期净吸纳量'],
        projectMarketPerformanceData['未来三年预测净吸纳量'],
      ],
    };
  },
  getPriceChartData: async (params: getRankMapParams) => {
    const priceChartData = await getPriceChartData(params);
    const { rankMap, chartMap } = priceChartData;
    const { city, lastQuarterFaceRent, market, nearby, projCity, projMarket, quarterRatio } =
      rankMap;
    // priceChart顺序 项目-周边3公里项目-子市场-城市
    return {
      priceRank: {
        cityRank: city,
        lastQuarterFaceRent: lastQuarterFaceRent,
        marketRank: market,
        nearbyRank: nearby,
        projCity: projCity,
        projMarket: projMarket,
        quarterRatio: quarterRatio,
      },
      priceChart: {
        projectPriceActionList: [...formatChart(chartMap.projId)],
        nearbyPriceActionList: [...formatChart(chartMap.nearby)],
        submarketPriceActionList: [...formatChart(chartMap.market)],
        cityPriceActionList: [...formatChart(chartMap.city)],
      },
    };
  },
};
