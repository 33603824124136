import { ChildMarket, DistrictPathData } from '@/types';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import useMainStore from './main';
import { getDistrictPath } from '@/utils/mapTools';

const cityDistrictMapDataCache = {};
const citySubMarketCache = {};

interface State {
  districtMapDataList: DistrictPathData[];
  subMarketMapDataList: ChildMarket[];
}

const initState: State = {
  districtMapDataList: [],
  subMarketMapDataList: [],
};

const useMapStore = create(
  immer(
    combine(initState, (set) => ({
      setDistrictAndSubMarket: (
        cityName: string,
        districtMapDataList: DistrictPathData[],
        subMarketMapDataList: ChildMarket[]
      ) => {
        set({
          districtMapDataList,
          subMarketMapDataList,
        });
      },

      setDistrict: (districtMapDataList: DistrictPathData[]) => {
        set({
          districtMapDataList,
        });
      },

      setSubMarket: (subMarketMapDataList: ChildMarket[]) => {
        set({
          subMarketMapDataList,
        });
      },

      queryDistrictAndSubMarket: async (
        cityName: string,
        adminAreas: string[],
        childMarkets: ChildMarket[]
      ) => {
        const { authCityData } = useMainStore.getState();

        const districtMapDataList = await getDistrictPath(
          authCityData[cityName].adcode,
          adminAreas
        );

        set({
          districtMapDataList,
          subMarketMapDataList: childMarkets.filter((item) => !!item?.boundaries && !!item.center),
        });
      },
      // query
    }))
  )
);

export default useMapStore;
