import { TransferOptionItem } from '../../components/JLLTransfer/JLLTransfer';
import { RentKey } from '@/components/UnitExchange/type';

export enum TimeType {
  market = 1,
  other = 2,
}

export enum DataType {
  newDeal = '2',
  // 退租
  newLeaseTermination = '3',
}

export enum DataStateType {
  datetime = 'DATETIME',
  headQuarters = 'HEAD_QUARTERS',
  oneIndustryName = 'ONE_INDUSTRY_NAME',
  twoIndustryName = 'TWO_INDUSTRY_NAME',
  cityName = 'CITY_NAME',
  adminDistrict = 'ADMIN_DISTRICT',
  submarket = 'CHILD_MARKET',
  projectLevel = 'PROJECT_LEVEL',
  titleForm = 'TITLE_FORM',
  developerType = 'DEVELOPER_TYPE',
  leed = 'LEED',
  tenantSource = 'TENANT_SOURCE',
  tenantArea = 'TENANT_AREA',
  transactionType = 'TRANSACTION_TYPE',
  vacancySegment = 'VACANCY_SEGMENT',
  bookRentSegment = 'BOOK_RENT_SEGMENT',
  proManagementFees = 'PRO_MANAGEMENT_FEES',
}

export const DataStateTypeMap = {
  [DataStateType.datetime]: '时间',
  [DataStateType.headQuarters]: '是否总部楼宇',
  [DataStateType.oneIndustryName]: '一级行业',
  [DataStateType.twoIndustryName]: '二级行业',
  [DataStateType.cityName]: '城市',
  [DataStateType.adminDistrict]: '行政区',
  [DataStateType.submarket]: '子市场',
  [DataStateType.projectLevel]: '项目等级',
  [DataStateType.titleForm]: '业权形态',
  [DataStateType.developerType]: '开发商背景',
  [DataStateType.leed]: 'LEED等级',
  [DataStateType.tenantSource]: '租户来源',
  [DataStateType.tenantArea]: '租户面积段',
  [DataStateType.transactionType]: '成交类型',
  [DataStateType.vacancySegment]: '空置率分段',
  [DataStateType.bookRentSegment]: '账面租金分段',
  [DataStateType.proManagementFees]: '物管费分段',
};

export const indicatorProportionOptions = [
  {
    value: 'numProportion',
    label: '个数',
  },
  {
    value: 'areaProportion',
    label: '占比',
  },
];

const futureSupplyTransferListA = [
  { value: DataStateType.cityName, label: '城市' },
  { value: DataStateType.adminDistrict, label: '行政区' },
  { value: DataStateType.submarket, label: '子市场' },
  { value: DataStateType.projectLevel, label: '项目等级' },
];

const futureSupplyTransferListB = [
  { value: DataStateType.titleForm, label: '业权形态' },
  { value: DataStateType.developerType, label: '开发商背景' },
  { value: DataStateType.leed, label: 'LEED等级' },
];

export const futureSupplyChartTypeList: TransferOptionItem[] = [
  ...futureSupplyTransferListA,
  {
    value: DataStateType.headQuarters,
    label: '是否总部楼宇',
  },
  ...futureSupplyTransferListB,
];

export const marketTransferList: TransferOptionItem[] = [
  { value: DataStateType.datetime, label: '时间' },
  ...futureSupplyChartTypeList,
  { value: DataStateType.vacancySegment, label: '空置率分段' },
  { value: DataStateType.bookRentSegment, label: '账面租金分段' },
  { value: DataStateType.proManagementFees, label: '物管费分段' },
];

export const industryTransferList: TransferOptionItem[] = [
  { value: DataStateType.oneIndustryName, label: '一级行业' },
  { value: DataStateType.twoIndustryName, label: '二级行业' },
  ...futureSupplyTransferListA,
  ...futureSupplyTransferListB,
  { value: DataStateType.tenantSource, label: '租户来源' },
  { value: DataStateType.tenantArea, label: '租户面积段' },
];

export const dealAnalysisTransferList = [
  ...industryTransferList,
  { value: DataStateType.transactionType, label: '成交类型' },
];

export const futureSupplyTransferList = [
  { value: DataStateType.datetime, label: '时间' },
  ...futureSupplyChartTypeList,
];

export const industryAnalysisChartTypeList = [
  ...industryTransferList.slice(0, 10),
  industryTransferList[industryTransferList.length - 1],
];

export const dealAnalysisChartTypeList = [
  ...industryTransferList.slice(0, 10),
  { value: DataStateType.transactionType, label: '成交类型' },
  industryTransferList[industryTransferList.length - 1],
];

export const needSortList = [
  DataStateType.oneIndustryName,
  DataStateType.twoIndustryName,
  DataStateType.cityName,
  DataStateType.submarket,
  DataStateType.adminDistrict,
];

export enum UnitType {
  area = '1',
  tenThousandArea = '2',
}

export const unitOptions = [
  { label: '㎡', value: UnitType.area },
  { label: '万㎡', value: UnitType.tenThousandArea },
];

export enum SummaryShowType {
  category = '1',
  total = '2',
}

export const showTypeOptions = [
  { label: '分类汇总', value: SummaryShowType.category },
  { label: '总体汇总', value: SummaryShowType.total },
];

export interface TableTitle {
  title: string;
  unit?: string;
  tip?: string;
  controlByCommonConfig?: RentKey;
  controlByUnitType?: boolean;
}

export const tableTitleConfig: Record<string, TableTitle> = {
  projectCount: { title: '项目个数', unit: '个' },
  stock: { title: '总存量', unit: '㎡', controlByUnitType: true },
  newSupply: {
    title: '新增供应',
    unit: '㎡',
    tip: '“新增供应”指标为时期指标，筛选到未来时间时，Q4代表一整年的数据',
    controlByUnitType: true,
  },
  netAbsorption: {
    title: '净吸纳量',
    unit: '㎡',
    tip: '“净吸纳量”指标为时期指标，筛选到未来时间时，Q4代表一整年的数据',
    controlByUnitType: true,
  },
  vacancyRate: { title: '空置率', unit: '%' },
  vacancyRateQ2Q: { title: '空置率环比', unit: '百分点' },
  vacancyRateY2Y: { title: '空置率同比', unit: '百分点' },
  bookRent: {
    title: '账面租金',
    unit: '元/m²/天',
    controlByCommonConfig: 'bookRentUnit',
  },
  bookRentQ2Q: { title: '账面租金环比', unit: '%' },
  bookRentY2Y: { title: '账面租金同比', unit: '%' },
  effectiveRent: {
    title: '有效租金',
    unit: '元/m²/月',
    controlByCommonConfig: 'effectiveRentUnit',
  },
  effectiveRentQ2Q: { title: '有效租金环比', unit: '%' },
  effectiveRentY2Y: { title: '有效租金同比', unit: '%' },
  propertyManagementFees: { title: '物管费', unit: '元/m²/月' },
  areaTotal: { title: '面积', unit: '㎡', controlByUnitType: true },
  areaProportionSign: { title: '面积占比', unit: '%' },
  numTotal: { title: '租户个数', unit: '个' },
  numProportionSign: { title: '个数占比', unit: '%' },
  futureSupply: { title: '未来供应', unit: '㎡', controlByUnitType: true },
};

export type TableColumnKey = keyof typeof tableTitleConfig;

const makeOptions = (keyList: TableColumnKey[]) => {
  const result = keyList.map((key) => {
    return {
      label: tableTitleConfig[key].title,
      value: key,
    };
  });
  return result;
};

export const marketStateIndicators = makeOptions([
  // '项目数量',
  // '总存量',
  // '新增供应',
  // '净吸纳量',
  // '空置率',
  // '空置率同比',
  // '空置率环比',
  // '账面租金',
  // '账面租金同比',
  // '账面租金环比',
  // '有效租金',
  // '有效租金同比',
  // '有效租金环比',
  // '物管费',
  'projectCount',
  'stock',
  'newSupply',
  'netAbsorption',
  'vacancyRate',
  'vacancyRateY2Y',
  'vacancyRateQ2Q',
  'bookRent',
  'bookRentY2Y',
  'bookRentQ2Q',
  'effectiveRent',
  'effectiveRentY2Y',
  'effectiveRentQ2Q',
  'propertyManagementFees',
]);

export const Q2QIndicators = ['vacancyRateQ2Q', 'bookRentQ2Q', 'effectiveRentQ2Q'];
export const Y2YIndicators = ['vacancyRateY2Y', 'bookRentY2Y', 'effectiveRentY2Y'];

export const dealAnalysisIndicators = makeOptions([
  'areaTotal',
  'areaProportionSign',
  'numTotal',
  'numProportionSign',
]);

export const futureSupplyIndicators = makeOptions([
  // '项目个数', '未来供应'
  'projectCount',
  'futureSupply',
]);

export enum ShowDataTabType {
  table = 'table',
  chart = 'chart',
  detail = 'detail',
}

export const tabOptions = [
  {
    label: '列表',
    value: ShowDataTabType.table,
  },
  {
    label: '图表',
    value: ShowDataTabType.chart,
  },
];

export const allTabOptions = [
  ...tabOptions,
  {
    label: '明细',
    value: ShowDataTabType.detail,
  },
];

export const vacancySegment = [
  '0-10',
  '10-20',
  '20-30',
  '30-40',
  '40-50',
  '50-60',
  '60-70',
  '70-80',
  '80-90',
  '90-100',
];
export const bookRentSegment = [
  '0-30',
  '30-60',
  '60-90',
  '90-120',
  '120-150',
  '150-180',
  '180-210',
  '210-300',
  '300-500',
  '500-9999',
];
export const managementFeesSegment = [
  '0-5',
  '5-10',
  '10-15',
  '15-20',
  '20-25',
  '25-30',
  '30-35',
  '35-40',
  '40-45',
  '45-999999',
];

export const customTenantArea = [
  '0-300',
  '300-400',
  '400-500',
  '500-800',
  '800-1000',
  '1000-1500',
  '1500-2000',
  '2000-5000',
  '5000-10000',
  '10000-999999',
];
