import createViewFormContext from '@/components/ViewFormContextProvider';

export interface LeftFormData {
  timeRange: [string, string];
  levelCategory: Record<string, string[]>;
  cityList: string[];
  districtsSubmarkets: Record<string, string[]>;
  buyerType: number[];
  sellerType: number[];
  tradeArea: [number, number];
  tradeTotalPrice: [number, number];
  tradeUnitPrice: [number, number];
}

const contextData = createViewFormContext<LeftFormData>();

export default contextData;
