import { JLLDatePicker } from 'jlld';
import { useDebounceFn } from 'ahooks';
import { useRef } from 'react';
import styles from './style.module.less';
import { getHalfYearAgoDate, getLastQuarter, getNextQuarter, getQuarter } from '@/utils/tool';
import { PickerType } from 'jlld/es/JLLDatePicker';

const [year, quarter] = getLastQuarter()
  .split('-Q')
  .map((item) => Number(item));

const RelocateTrailTimeTypeMap = {
  halfYear: getQuarter(getHalfYearAgoDate()),
  oneYear: getNextQuarter(`${year - 1}-Q${quarter}`),
  twoYears: getNextQuarter(`${year - 2}-Q${quarter}`),
  threeYears: getNextQuarter(`${year - 3}-Q${quarter}`),
  custom: 'custom',
};

const timeTypeOptions = [
  {
    label: '近半年',
    value: RelocateTrailTimeTypeMap.halfYear,
    pickerType: 'quarter' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '近1年',
    value: RelocateTrailTimeTypeMap.oneYear,
    pickerType: 'quarter' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '近2年',
    value: RelocateTrailTimeTypeMap.twoYears,
    pickerType: 'quarter' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '近3年',
    value: RelocateTrailTimeTypeMap.threeYears,
    pickerType: 'quarter' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '自定义',
    value: RelocateTrailTimeTypeMap.custom,
    pickerType: 'quarter' as PickerType,
    pickerDisabled: false,
  },
];

const rangeEnd = getLastQuarter();
interface DateTimePickerProps {
  value?: [string, [string, string]];
  onChange?: (value: [string?, [string, string]?]) => void;
  rangeStart?: string;
}
const DateTimePicker = (props: DateTimePickerProps) => {
  const [selectorValue, dateValue] = props.value || [];
  const dateValueRef = useRef<[string, string]>([props.rangeStart || '2019-Q1', rangeEnd]);
  const selectorValueRef = useRef('');
  const { run } = useDebounceFn(
    () => {
      if (selectorValueRef.current !== 'custom') {
        props.onChange!([selectorValueRef.current, [selectorValueRef.current, rangeEnd]]);
      } else {
        props.onChange!([selectorValueRef.current, dateValueRef.current]);
      }
    },
    {
      wait: 30,
    }
  );

  return (
    <JLLDatePicker.JLLQuarterPicker
      isRange
      allowClear={false}
      showDateTypeList={[]}
      selectorValue={selectorValue}
      className={styles.transactionTimeRangePicker}
      minMaxDate={[props.rangeStart || '2019-Q1', rangeEnd]}
      customOptions={timeTypeOptions}
      value={dateValue}
      onChange={(changeDateValue) => {
        dateValueRef.current = changeDateValue as [string, string];
        run();
      }}
      onSelectorChange={(changeSelectorValue) => {
        selectorValueRef.current = changeSelectorValue;
        run();
      }}
    />
  );
};

export default DateTimePicker;
