import { Table, TableColumnsType } from 'jlld';
import useTransactionDetailStore from '../../../store/transactionDetailStore';
import { thousandSplitNumber } from '@/utils/tool';
import styles from '../style.module.less';
import '../style.less';

interface TopDataType {
  totalPrice: string;
  saleArea: string;
  unitPrice: number;
  mode: string;
  tradingEquity: string;
  floor: string;
  building: string;
  month: string;
}

interface BottomDataType {
  buyerCompany: string;
  buyerType: string;
  buyerActualOperatingCompany: number;
  sellerCompany: string;
  sellerType: string;
  sellerActualOperatingCompany: string;
}

const columnsTop: TableColumnsType<TopDataType> = [
  {
    title: '交易总价(万元)',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    width: 160,
    render: (text) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '销售面积(㎡)',
    dataIndex: 'saleArea',
    key: 'saleArea',
    width: 160,
    render: (text) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '交易单价(万元/㎡)',
    dataIndex: 'unitPrice',
    key: 'unitPrice ',
    width: 160,
    render: (text) => {
      return thousandSplitNumber(text, ' ');
    },
  },
  {
    title: '交易方式',
    dataIndex: 'mode',
    key: 'mode',
    width: 160,
  },
  {
    title: '交易股权(%)',
    dataIndex: 'tradingEquity',
    key: 'tradingEquity',
    width: 160,
  },
  {
    title: '交易楼层',
    dataIndex: 'floor',
    key: 'floor',
  },
  {
    title: '交易楼栋',
    dataIndex: 'building',
    key: 'building',
  },
  {
    title: '交易月份',
    dataIndex: 'month',
    key: 'month',
    width: 160,
  },
];

const columnsBottom: TableColumnsType<BottomDataType> = [
  {
    title: '买方名称',
    dataIndex: 'buyerCompany',
    key: 'buyerCompany',
    width: 100,
  },
  {
    title: '买方企业来源',
    dataIndex: 'buyerType',
    key: 'buyerType',
    width: 88,
  },
  {
    title: '买方实际操作公司（工商注册名）',
    dataIndex: 'buyerActualOperatingCompany',
    key: 'buyerActualOperatingCompany',
    width: 150,
  },
  {
    title: '卖方名称',
    dataIndex: 'sellerCompany',
    key: 'sellerCompany',
    width: 100,
  },
  {
    title: '卖方企业来源',
    dataIndex: 'sellerType',
    key: 'sellerType',
    width: 88,
  },
  {
    title: '卖方实际操作公司（工商注册名）',
    dataIndex: 'sellerActualOperatingCompany',
    key: 'sellerActualOperatingCompany',
    width: 150,
  },
];

const TransactionInfo = () => {
  const {
    transactionDetail: {
      month,
      totalPrice,
      saleArea,
      unitPrice,
      mode,
      tradingEquity,
      floor,
      building,
      buyerCompany,
      buyerType,
      buyerActualOperatingCompany,
      sellerCompany,
      sellerType,
      sellerActualOperatingCompany,
      remark,
    },
  } = useTransactionDetailStore();

  const topDataSource = [
    {
      month,
      totalPrice,
      saleArea,
      unitPrice,
      mode,
      tradingEquity,
      floor,
      building,
    },
  ];

  const bottomDataSource = [
    {
      buyerCompany,
      buyerType,
      buyerActualOperatingCompany,
      sellerCompany,
      sellerType,
      sellerActualOperatingCompany,
    },
  ];

  return (
    <div
      className={styles.transactionInfo}
      id="TransactionInfo"
    >
      <p className={styles.blockTitle}>交易信息</p>
      <Table
        className={styles.transactionInfoTable}
        rowClassName={styles.tableRow}
        columns={columnsTop}
        dataSource={topDataSource}
        bordered
        pagination={false}
        rowKey={(record) => record.month || record.totalPrice}
        scroll={{
          x: 800,
        }}
      />
      <Table
        className={styles.transactionInfoTable}
        rowClassName={styles.tableRow}
        columns={columnsBottom}
        dataSource={bottomDataSource}
        bordered
        pagination={false}
        rowKey={'buyerCompany'}
        scroll={{
          x: 800,
        }}
      />
      <p className={styles.remark}>备注：{remark}</p>
    </div>
  );
};

export default TransactionInfo;
