import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  FutureSupplyChartRes,
  getFutureSupplyChart,
  getFutureSupplyDetail,
  getFutureSupplyDetailRes,
  getFutureSupplyTable,
} from '../api';
import useFutureSupplySettingStore from './settingStore';
import {
  generateCommonTableData,
  generateQueryParams,
  topFormValuesToParams,
} from '@/pages/F-DataAnalysis/method';
import { AllLoading, TableDataItem } from '@/pages/F-DataAnalysis/types/settingTypes';
import { ShowDataTabType } from '@/pages/F-DataAnalysis/config';
import { thousandSplitNumber, numFormat } from '@/utils/tool';
import { deepClone } from '@/utils/tool';

type State = {
  loading: AllLoading;
  tableData: {
    data: Record<string, TableDataItem[]> | null;
    summaryData: TableDataItem | null;
  } | null;
  chartData: FutureSupplyChartRes | null;
  detailData: getFutureSupplyDetailRes[] | null;
};

const state: State = {
  loading: {
    topForm: true,
    table: true,
    chart: true,
    detail: true,
  },
  tableData: null,
  chartData: null,
  detailData: null,
};

const useFutureSupplyStore = create<State>()(immer(() => state));

const set = useFutureSupplyStore.setState;
// const get = useDataAnalysisSettingStore.getState;

export const resetDataAnalysisPageStore = () => {
  set(deepClone(state));
};

export const queryFutureTable = async () => {
  const { tableFormValues, topFormValues, dimensionValues } =
    useFutureSupplySettingStore.getState();
  const queryParams = generateQueryParams(topFormValues, dimensionValues, tableFormValues);
  set((state) => {
    state.loading[ShowDataTabType.table] = true;
  });

  if (queryParams) {
    try {
      const tableData = await getFutureSupplyTable(queryParams);
      set((state) => {
        const { data, summaryData } = generateCommonTableData(tableData);
        const dataConvert = {};
        if (data) {
          Object.keys(data).forEach((k) => {
            dataConvert[k] = data[k].map((item) => {
              return {
                ...item,
                futureSupply: thousandSplitNumber(numFormat(item?.futureSupply)),
                projectCount: thousandSplitNumber(numFormat(item?.projectCount)),
              };
            });
          });
        }
        state.tableData = {
          data: dataConvert,
          summaryData: {
            ...summaryData,
            futureSupply: thousandSplitNumber(numFormat(summaryData?.futureSupply)),
            projectCount: thousandSplitNumber(numFormat(summaryData?.projectCount)),
          },
        };
        state.loading[ShowDataTabType.table] = false;
      });
    } catch (e) {
      set((state) => {
        state.tableData = {
          data: [],
          summaryData: {},
        };
      });
    }
  }
  set((state) => {
    state.loading[ShowDataTabType.table] = false;
  });
};

export const queryFutureChart = async () => {
  const { topFormValues, chartSettingFormValues } = useFutureSupplySettingStore.getState();
  const queryParams = topFormValuesToParams(topFormValues);
  set((state) => {
    state.loading[ShowDataTabType.chart] = true;
  });
  if (queryParams) {
    try {
      const chartData = await getFutureSupplyChart({ ...queryParams, ...chartSettingFormValues });
      set((state) => {
        state.chartData = chartData;
        state.loading[ShowDataTabType.chart] = false;
      });
    } catch (e) {
      set((state) => {
        state.chartData = {
          exampleList: [],
          futureSupplyChartListVO: [],
        };
      });
    }
  }
  set((state) => {
    state.loading[ShowDataTabType.chart] = false;
  });
};

export const queryFutureDetail = async () => {
  const { tableFormValues, topFormValues, dimensionValues } =
    useFutureSupplySettingStore.getState();
  const queryParams = generateQueryParams(topFormValues, dimensionValues, tableFormValues);
  set((state) => {
    state.loading[ShowDataTabType.detail] = true;
  });
  if (queryParams) {
    const detailData = await getFutureSupplyDetail(queryParams);
    set((state) => {
      state.detailData = detailData;
      state.loading[ShowDataTabType.detail] = false;
    });
  } else {
    set((state) => {
      state.loading[ShowDataTabType.detail] = false;
    });
  }
};

export const queryCurrentTabData = async () => {
  const { tableActiveTab } = useFutureSupplySettingStore.getState();
  switch (tableActiveTab) {
    case ShowDataTabType.table: {
      queryFutureTable();
      break;
    }
    case ShowDataTabType.chart: {
      queryFutureChart();
      break;
    }
    case ShowDataTabType.detail: {
      queryFutureDetail();
      break;
    }
  }
};

export const setLoading = (key: keyof AllLoading, loading: boolean) => {
  set((state) => {
    state.loading[key] = loading;
  });
};

export default useFutureSupplyStore;
