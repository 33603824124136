import JLLTransferModal from '@/components/JLLTransfer';
import { TransferOptionItem } from '@/components/JLLTransfer/JLLTransfer';
import { DataStateType } from '../../config';
import { useLayoutEffect, useState } from 'react';
import { combine } from '@/utils/tool';
import { autoSort, disableValueSort } from './method';

export interface DimensionTransferModalProps {
  open: boolean;
  list: TransferOptionItem[];
  // 里面的只设置value值不可以控制disabled等状态，外边的不必，所以直接返回DataStateType[]
  value?: DataStateType[];
  onOk: (value: DataStateType[]) => void;
  onCancel?: () => void;
}

const valueToOptionItemList = (value: DataStateType[], list: TransferOptionItem[]) => {
  return combine(
    autoSort,
    disableValueSort
  )(value.map((v) => list.find((item) => item.value === v)));
};

export default (props: DimensionTransferModalProps) => {
  const [newtransferList, setNewtransferList] = useState<TransferOptionItem[]>(props.list);
  const [value, setValue] = useState<TransferOptionItem[]>([]);

  const handleChange = (valueItemList: TransferOptionItem[]) => {
    const valueList = valueItemList.map((item) => item.value);
    // 有子市场，不能选行政区, 有行政区，不能选子市场
    const hasChildMarket = valueList.includes(DataStateType.submarket);
    const hasAdminDistrict = valueList.includes(DataStateType.adminDistrict);
    // 设置左边的选项是否可选
    if (hasChildMarket) {
      setOptionItemDisabled(DataStateType.adminDistrict);
    } else if (hasAdminDistrict) {
      setOptionItemDisabled(DataStateType.submarket);
    } else {
      setNewtransferList(props.list);
    }
    // 设置右边values
    combine(autoSort, disableValueSort, setValue)(valueItemList);
  };

  useLayoutEffect(() => {
    if (props.value) {
      handleChange(valueToOptionItemList(props.value, newtransferList) || []);
    }
  }, [props.value]);

  const setOptionItemDisabled = (stateType: DataStateType, disabled?: boolean) => {
    const newtransferList = props.list.map((item) => {
      if (item.value === stateType) {
        return { ...item, disabled: disabled ?? true };
      }
      return item;
    });
    setNewtransferList(newtransferList);
  };

  return (
    <JLLTransferModal
      open={props.open}
      maxLength={3}
      title="分析维度设置"
      listTitle="全部维度"
      valueTitle="显示维度"
      options={newtransferList}
      value={value}
      onOk={(value) => {
        props.onOk(value.map((item) => item.value as DataStateType));
      }}
      onCancel={props.onCancel}
      onChange={(value) => {
        handleChange(value);
      }}
    />
  );
};
