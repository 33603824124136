import classNames from 'classnames';
import PageHeader from '@/components/PageHeader';
// import { ViewFormProvider } from '@/components/ViewFormContextProvider';
import useUserEvent from '@/hooks/useUserEvent';
import useCommonOptionsStore from '@/store/commonOptions';
import { queryBlockTradePageOptions } from '@/store/commonOptions/pageActions';
import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import useLeftFormStore from './store/leftFormStore';
import LeftForms from './views/LeftForms';
import RightTables from './views/RightTables';
import styles from './style.module.less';
import formContext from './views/LeftForms/formContext';
import FormLoadingSkeletion from './views/LeftForms/FormLoadingSkeletion';
import useTradeDetailStore from './store/tradeDetailStore';
import useTradeStatisticsStore from './store/tradeStatisticsStore';

export default () => {
  const { ViewFormProvider } = formContext;
  const { sendUserEvent } = useUserEvent();
  const { loading } = useCommonOptionsStore(
    useShallow((state) => ({
      loading: state.loading,
    }))
  );

  const { getDefaultTime, defaultTimeLoading, resetLeftFormQueryParams } = useLeftFormStore(
    useShallow((state: any) => ({
      getDefaultTime: state.getDefaultTime,
      defaultTimeLoading: state.defaultTimeLoading,
      resetLeftFormQueryParams: state.resetLeftFormQueryParams,
    }))
  );

  const resetTradeDetail = useTradeDetailStore((state) => state.resetTradeDetail);
  const resetTradeStatistics = useTradeStatisticsStore((state) => state.resetTradeStatistics);

  useEffect(() => {
    sendUserEvent({ page: '投资交易' }, 'e_view');
    queryBlockTradePageOptions();
    getDefaultTime();
    return () => {
      resetLeftFormQueryParams();
      resetTradeDetail();
      resetTradeStatistics();
    };
  }, []);

  return (
    <ViewFormProvider>
      <div className={classNames('page', styles.blockTrade)}>
        <PageHeader
          title="投资交易"
          useBorder
        />
        <div className={styles.mainContainer}>
          {loading.blockTrade || defaultTimeLoading ? <FormLoadingSkeletion /> : <LeftForms />}
          <RightTables />
        </div>
      </div>
    </ViewFormProvider>
  );
};
