import useCommonOptionsStore from '@/store/commonOptions';
import { JLLCityPicker, JLLForm } from 'jlld';

const CitysFormItem = () => {
  const cityPickerOptions = useCommonOptionsStore((state) => state.cityPickerOptions);

  return (
    <JLLForm.Item
      noStyle
      name="citys"
    >
      <JLLCityPicker
        searchOneTab
        mode="multiple"
        placeholder="请选择城市"
        options={cityPickerOptions!}
      />
    </JLLForm.Item>
  );
};

export default CitysFormItem;
