import { TransferOptionItem } from '@/components/JLLTransfer/JLLTransfer';
import { getKeyListIndex, insertBefore } from '@/utils/tool';
import { needSortList } from '../../config';

// 自动按优先级排序
export const autoSort = (valueItemList: TransferOptionItem[]) => {
  const [oneLevelIndex, twoLevelIndex, cityIndex, childMarketIndex, adminDistrictIndex] =
    getKeyListIndex(needSortList, valueItemList);
  // 保持一级维度在二级维度之前, 保持城市在子市场之前, 保持城市在行政区之前
  [
    [oneLevelIndex, twoLevelIndex],
    [cityIndex, childMarketIndex],
    [cityIndex, adminDistrictIndex],
  ].forEach(([indexA, indexB]) => {
    insertBefore(indexA, indexB, valueItemList);
  });

  return valueItemList;
};
// 禁用如果A，B相邻上下移
const disableUpAndDown = (indexA: number, indexB: number, valueItemList: TransferOptionItem[]) => {
  if (indexA > -1 && indexB > -1 && indexA + 1 === indexB) {
    valueItemList[indexA].disabledDown = true;
    valueItemList[indexB].disabledUp = true;
  } else {
    if (valueItemList[indexA]?.disabledDown) {
      valueItemList[indexA].disabledDown = false;
    }
    if (valueItemList[indexB]?.disabledUp) {
      valueItemList[indexB].disabledUp = false;
    }
  }
};
// 设置各优先级之间无法上下移动
export const disableValueSort = (valueItemList: TransferOptionItem[]) => {
  // 如果城市和行政区都选中切相邻，行政区禁用上移，禁止城市下移
  const [oneLevelIndex, twoLevelIndex, cityIndex, childMarketIndex, adminDistrictIndex] =
    getKeyListIndex(needSortList, valueItemList);

  const needDisabledList = [[oneLevelIndex, twoLevelIndex]];

  let childMarketOrAdminDistrictIndex = -1;

  if (childMarketIndex > -1) {
    childMarketOrAdminDistrictIndex = childMarketIndex;
  } else if (adminDistrictIndex > -1) {
    childMarketOrAdminDistrictIndex = adminDistrictIndex;
  }

  if (childMarketOrAdminDistrictIndex > -1) {
    needDisabledList.push([cityIndex, childMarketOrAdminDistrictIndex]);
  }

  needDisabledList.forEach(([indexA, indexB]) => {
    disableUpAndDown(indexA, indexB, valueItemList);
  });

  return valueItemList;
};
