import classNames from 'classnames';
import styles from './style.module.less';
import { Tooltip, JLLSvg } from 'jlld';

export type SwitchType = 'area' | 'count';

const BlockTitle = (props: {
  title: string;
  showSwitch?: boolean;
  switchType: SwitchType;
  onChangeType: (type: SwitchType) => void;
  showTip?: boolean;
  tipText?: string;
}) => {
  return (
    <div className={styles.blockTitle}>
      <div className={styles.titleText}>
        {props.title}
        {props.showTip ? (
          <div className={styles.tipIcon}>
            <Tooltip title={props.tipText}>
              <JLLSvg
                icon="info"
                size={20}
                color="#667579"
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
      {props.showSwitch ? (
        <div className={styles.switchArea}>
          <div
            className={classNames(
              styles.switchButton,
              props.switchType === 'area' ? styles.active : ''
            )}
            onClick={() => props?.onChangeType('area')}
          >
            面积占比
          </div>
          <div
            className={classNames(
              styles.switchButton,
              props.switchType === 'count' ? styles.active : ''
            )}
            onClick={() => props?.onChangeType('count')}
          >
            个数占比
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BlockTitle;
