import styles from './style.module.less';

interface Props {
  subText: string;
}

export default (props: Props) => {
  return (
    <div className={styles.mapLegend}>
      <div className={styles.legendWrap}>
        <div className={styles.legend}>
          <div className={styles.iconWrap}>
            <img
              src="/assets/map/map_legend_red_pin.png"
              className={styles.currentPin}
            />
          </div>
          <div className={styles.curProperty}>当前项目</div>
        </div>
        <div className={styles.legend}>
          <div className={styles.iconWrap}>
            <div className={styles.trailDot} />
          </div>
          <div>{props.subText}</div>
        </div>
      </div>
    </div>
  );
};
